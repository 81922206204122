import React from 'react';
import { Box } from '@material-ui/core';
import { AllowedTo } from 'common/components';

import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as CanceledIcon } from 'img/icons/times.svg';
import { ReactComponent as PendingIcon } from 'img/icons/hourglass.svg';
import { PersonAppointmentStatus } from '../../../interfaces';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface IPersonIconStatusProps {
  personStatus: PersonAppointmentStatus;
}

const useStyles = makeStyles((theme: Theme) => ({
  personStatus: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',

    '& svg': {
      width: theme.spacing(1.3),
      height: theme.spacing(1.3),
      fill: theme.palette.primary.contrastText,
    },
  },
}));

const PersonIconStatus: React.FC<IPersonIconStatusProps> = (
  props: IPersonIconStatusProps,
): JSX.Element => {
  const classes = useStyles();
  const { personStatus } = props;

  return (
    <>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Confirmed}>
        <Box bgcolor="primary.main" className={classes.personStatus}>
          <CheckIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Canceled}>
        <Box bgcolor="error.main" className={classes.personStatus}>
          <CanceledIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Pending}>
        <Box bgcolor="warning.main" className={classes.personStatus}>
          <PendingIcon />
        </Box>
      </AllowedTo>
    </>
  );
};

export default PersonIconStatus;
