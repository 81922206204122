import { LanguageLocales } from 'common/state/settings/constants';
import { LocalStorageService } from '../storage/WebStorageService';
import { IObject, UserRole } from 'common/interfaces/common';

class AppService {
  private static APP_REGISTER = 'selectedRegister';

  private static APP_CLUB = 'selectedClubId';

  private static APP_ENTRANCE = 'selectedEntranceId';

  private static APP_LOCALE = 'locale';

  private static APP_TIMEZONE = 'timezone';

  private static APP_LEVEL = 'permissionsLevel';

  private static APP_SIDEBAR = 'isSidebarOpen';

  private static USER_ROLE = 'role';

  private localStorageService: LocalStorageService;

  constructor() {
    this.localStorageService = new LocalStorageService({ prefix: 'app.' });
  }

  public setAppLocale(locale: LanguageLocales): void {
    this.localStorageService.set(AppService.APP_LOCALE, locale);
  }

  public getAppLocale(): string {
    return this.localStorageService.get(AppService.APP_LOCALE);
  }

  public clearAppLocaleData(): void {
    this.localStorageService.remove(AppService.APP_LOCALE);
  }

  public setUserRole(role: UserRole): void {
    this.localStorageService.set(AppService.USER_ROLE, role);
  }

  public getUserRole(): string {
    return this.localStorageService.get(AppService.USER_ROLE);
  }

  public clearUserRole(): void {
    this.localStorageService.remove(AppService.USER_ROLE);
  }

  public setAppSelectedRegister(clubId: string, registerId: string): void {
    const selectedRegistersObj = this.getAppSelectedRegisters();
    this.localStorageService.set(AppService.APP_REGISTER, {
      ...(selectedRegistersObj || {}),
      [clubId]: registerId,
    });
  }

  public getAppSelectedRegisters(): IObject {
    return this.localStorageService.get(AppService.APP_REGISTER);
  }

  public deletAppSelectedRegister(clubId: string): void {
    const selectedRegistersObj = this.getAppSelectedRegisters();
    if (selectedRegistersObj) {
      delete selectedRegistersObj[clubId];
      this.localStorageService.set(AppService.APP_REGISTER, selectedRegistersObj);
    }
  }

  public clearAppSelectedRegisters(): void {
    this.localStorageService.remove(AppService.APP_REGISTER);
  }

  public setUserSelectedOrgId(userId: number, orgId: string): void {
    this.localStorageService.set(String(userId), orgId);
  }

  public getUserSelectedOrgId(userId: number): string {
    return this.localStorageService.get(String(userId));
  }

  public setAppIsSidebarOpen(isOpen: boolean): void {
    this.localStorageService.set(AppService.APP_SIDEBAR, isOpen);
  }

  public getAppIsSidebarOpen(): boolean {
    return this.localStorageService.get(AppService.APP_SIDEBAR);
  }

  public setAppSelectedEntrance(entranceId: string): void {
    this.localStorageService.set(AppService.APP_ENTRANCE, entranceId);
  }

  public getAppSelectedEntrance(): string {
    return this.localStorageService.get(AppService.APP_ENTRANCE);
  }

  public clearAppSelectedEntrance(): void {
    this.localStorageService.remove(AppService.APP_ENTRANCE);
  }

  public setAppSelectedTimezone(timezone: string): void {
    this.localStorageService.set(AppService.APP_TIMEZONE, timezone);
  }

  public getAppSelectedTimezone(): string {
    return this.localStorageService.get(AppService.APP_TIMEZONE);
  }

  public clearAppSelectedTimezone(): void {
    this.localStorageService.remove(AppService.APP_TIMEZONE);
  }
}

const service = new AppService();

export default service;
