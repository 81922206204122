// eslint-disable-next-line import/no-cycle
import { Alert, DialogComponent } from '../index';
import React, { useEffect, useState } from 'react';
import { AlertTypes } from '../../interfaces/alerts';
import commonMessages from 'common/messages/messages';
import { FormattedMessage } from 'react-intl';
import messages from '../InvoiceOperating/messages';

interface ITransactIFrameProps {
  memberId: number;
  corpId: string;
  isOpen: boolean;
  onClose: () => void;
}

const ITransactIFrame = ({ memberId, corpId, isOpen, onClose }: ITransactIFrameProps) => {
  const [loadCounter, setLoadCounter] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    if (loadCounter > 1) {
      document.getElementById('iTransactIFrameId').style.display = 'none';
      setShowThankYou(true);
    }
  }, [loadCounter]);

  const passParamsToIFrame = () => {
    return `/ITransactIFrame.html?memberId=${memberId}&corpId=${corpId}&domain=${process.env.REACT_APP_API_URL}`;
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.creditCardITransactTokenizeTitle} />}
      onClose={onClose}
      size="md"
      submitBtn={null}
      cancelBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
    >
      <iframe
        title="iTransactIFrame"
        id="iTransactIFrameId"
        src={passParamsToIFrame()}
        onLoad={() => setLoadCounter(counter => counter + 1)}
        style={{ height: '500px', width: '600px', borderStyle: 'none' }}
      />
      {showThankYou && (
        <Alert
          title={<FormattedMessage {...messages.creditCardITransactTokenizeThankYouTitle} />}
          severity={AlertTypes.Success}
        />
      )}
    </DialogComponent>
  );
};

export default ITransactIFrame;
