import { fromJS, List, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';
import { v4 as uuid } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';

import {
  SET_MAIN_PANEL_PERSON,
  SET_RIGHT_PANEL_PERSON,
  UPDATE_CURRENTLY_CHECKING_IN,
  UPDATE_PERSON_PHOTO,
} from 'modules/front-desk/constants';
import { UPDATE_PERSON_PHOTO_LOADING } from 'common/components/PersonProfile/constants';
import { UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION } from 'common/constants/actionTypes';
import { initReqStateImt } from 'common/constants/initialState';

import { IBookingEventImt, IRecentAppointmentItemImt } from 'modules/booking/interfaces';
import {
  IBillingImt,
  ICampaignImt,
  ICheckInHistoryImt,
  IDocumentsHistoryImt,
  IEmailImt,
  IFamilyMemberImt,
  IReminderRecentImt,
  IModificationHistoryImt,
  INoteCodeImt,
  INoteImt,
  IPackageInstanceSubscriptionImt,
  IPaymentImt,
  ISMSImt,
  IPaymentAccount,
  IPaymentAccountImt,
  IReminderDtoImt,
  ILeadCallHistoryDto,
  ICallNoteDto,
  IBalanceHistoryItemImt,
  IRewardsHistoryItemImt,
} from '../interfaces';
import {
  ISMSImt as IHistorySMSImt,
  ICampaignImt as IHistoryCampaignImt,
  ILeadEmailHistoryDtoImt,
} from 'modules/crm/interfaces/leads';

import membershipReducer from './membership/reducers';

import * as actionTypes from './constants';
import * as servicesActionTypes from './services/constants';
import * as referralsActionTypes from './referrals/constants';
import * as familyMembersActionTypes from './familyMembers/constants';
import * as ticketsActionTypes from './tickets/constants';

import {
  familyMemberServiceModalsReducer,
  inventoryModalsReducer,
  serviceEventDetailsReducer,
  serviceEventsReducer,
  serviceModalsReducer,
  serviceResourceTagsReducer,
} from 'common/components/PersonProfile/state/servicesModals/reducers';
import { AlertCondition } from 'common/interfaces/alerts';
import { INamedEntityImt, IObject } from 'common/interfaces/common';
import { ISimpleClubInventoryItemImt } from 'modules/pos-settings/interfaces/inventoryItems';

import { IMemberAlertImt } from 'modules/front-desk/interfaces';
import { ActionResult, IActionResultData } from 'common/constants/globalConstants';
import { IPaymentMethodItemImt } from 'modules/pos-settings/interfaces/paymentMethods';
import { IRegisterPosClubItem } from 'modules/pos-settings/interfaces/register';
import { IServiceItemDetailsImt } from 'common/interfaces/service';

const defaultServiceSectionState = fromJS({
  membershipInfo: {
    inventories: [],
    services: [],
  },
  posInfo: {
    inventories: [],
    services: [],
  },
  familyMembershipServices: [],
});

const defaultReferralSectionState = fromJS({
  referredBy: null,
  referrals: [],
});

const initialPaymentMethodsState = {
  cards: List<IPaymentAccount>(),
  list: List<IPaymentMethodItemImt>(),
  paymentMethodsModalDataLoading: false,
  addCardResult: null,
  addCardResultLoading: false,
  deleteCardResult: null,
  deleteCardResultLoading: null,
  subscriptions: List<IPackageInstanceSubscriptionImt>(),
  updateSubscriptionsAction: initReqStateImt,
};

export const personInitialState = {
  profile: null,
  profileLoading: false,
  updateProfileAction: initReqStateImt,
  additionalFields: [],

  // alerts
  alerts: List<IMemberAlertImt>(),
  alertsLoading: false,
  remindLaterActionResult: null,

  // sales info
  salesInfo: {},
  salesInfoLoading: false,
  salesInfoActionStatus: initReqStateImt,

  // documents

  documents: null,
  isDocumentsLoading: false,
  isDocumentHTMLLoading: false,
  documentActionStatus: initReqStateImt,

  // tickets
  tickets: List(),
  ticketsError: null,
  createTicketResult: null,
  ticketsLoading: false,
  ticketLoading: false,
  createTicketLoading: false,

  notes: List<INoteImt>(),
  notesLoading: false,
  createdNote: null,
  addNoteLoading: false,
  updatedNote: null,
  updatingNoteLoading: false,
  deletedNote: null,
  deletingNoteLoading: false,

  noteCodes: List<INoteCodeImt>(),
  noteCodesIsLoading: false,

  paymentMethods: initialPaymentMethodsState,

  appointmentsHistory: List<IBookingEventImt>(),
  appointmentsHistoryLoading: false,
  appointmentsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  recentAppointments: List<IRecentAppointmentItemImt>(),
  recentAppointmentsLoading: false,

  appointmentServices: List<INamedEntityImt>(),
  appointmentServicesLoading: false,

  servicesForEventRedeem: List<IServiceItemDetailsImt>(),
  servicesForEventRedeemLoading: false,

  appointment: {},
  isAppointmentLoading: false,
  appointmentActionStatus: initReqStateImt,

  fallthroughCapture: null,
  fallthroughCaptureLoading: false,

  fallthroughCaptureActionResult: null,
  fallthroughCaptureActionLoading: false,

  familyMembers: List<IFamilyMemberImt>(),
  familyMembersLoading: false,
  addFamilyMemberLoading: false,
  addFamilyMemberActionResult: null,

  suggestedProducts: List<ISimpleClubInventoryItemImt>(),
  suggestedProductsLoading: false,

  // payments
  payments: List<IPaymentImt>(),
  paymentsMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  paymentsLoading: false,
  recentPayments: List<IPaymentImt>(),
  recentPaymentsLoading: false,
  voidInvoice: initReqStateImt,
  paymentDetails: null,
  paymentDetailsLoading: false,

  checkingSavings: List<IPaymentAccountImt>(),
  fetchCheckingSavingsLoading: false,
  addCheckingSavingsLoading: false,
  addCheckingSavingsActionResult: null,
  deleteCheckingSavingsLoading: false,
  deleteCheckingSavingsActionResult: null,

  // sms
  sms: List<ISMSImt>(),
  createSmsActionResult: null,
  smsLoading: false,

  smsDetails: null,
  smsDetailsLoading: false,

  smsHistory: List<IHistorySMSImt>(),
  smsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  smsHistoryLoading: false,

  reminders: List<IReminderDtoImt>(),
  remindersMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  remindersLoading: false,

  reminder: null,
  reminderLoading: false,

  addReminderAction: initReqStateImt,

  recentReminders: List<IReminderRecentImt>(),
  recentRemindersLoading: false,

  emails: List<ILeadEmailHistoryDtoImt>(),
  emailsMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  emailsLoading: false,

  recentEmails: List<IEmailImt>(),
  recentEmailsLoading: false,

  email: fromJS({}),
  createEmailActionResult: null,
  isEmailLoading: false,

  calls: List<ILeadCallHistoryDto>(),
  callsMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  callsLoading: false,
  recentCalls: List<ICallNoteDto>(),
  recentCallsLoading: false,
  call: {},
  isCallLoading: false,
  createCallAction: initReqStateImt,

  campaigns: List<ICampaignImt>(),
  campaignsLoading: false,

  campaignsHistory: List<IHistoryCampaignImt>(),
  campaignsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  campaignsHistoryLoading: false,

  pastDueBillings: List<IBillingImt>(),
  pastDueBillingsLoading: false,

  upcomingBillings: List<IBillingImt>(),
  upcomingBillingsLoading: false,

  updateBillingScheduleAmountAction: initReqStateImt,

  referrals: defaultReferralSectionState,
  referralsLoading: false,

  services: defaultServiceSectionState,
  servicesLoading: false,

  serviceItem: null,
  serviceItemLoading: false,
  serviceRedeemLoading: false,
  serviceFreezeLoading: false,

  recentModificationHistory: List<IModificationHistoryImt>(),
  recentModificationHistoryLoading: false,
  modificationHistory: List<IModificationHistoryImt>(),
  modificationHistoryLoading: false,
  modificationHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  modificationHistoryItem: {},
  modificationHistoryItemLoading: false,

  recentCheckInsHistory: List<ICheckInHistoryImt>(),
  recentCheckInsHistoryLoading: false,
  checkInsHistory: List<ICheckInHistoryImt>(),
  checkInsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  checkInsHistoryLoading: false,

  // documents history
  documentsHistory: List<IDocumentsHistoryImt>(),
  documentsHistoryMeta: fromJS({ page: 0, perPage: 5, total: 0 }),
  isDocumentsHistoryLoading: List<boolean>(),

  // rewards
  isUpdateRewardsLoading: false,
  rewardsHistory: List<IRewardsHistoryItemImt>(),
  rewardsHistoryLoading: false,

  // balance
  balanceHistory: List<IBalanceHistoryItemImt>(),
  balanceHistoryLoading: false,
};

function personProfileReducer(state = fromJS({}), action) {
  if (
    (action.type === SET_MAIN_PANEL_PERSON || action.type === SET_RIGHT_PANEL_PERSON) &&
    !state.get(action.payload) &&
    action.payload
  ) {
    return state.set(action.payload, fromJS(personInitialState));
  }

  const personId = action.meta && action.meta.personId ? Number(action.meta.personId) : null;

  if (action.type === actionTypes.UPDATE_SOCKET_SUGGESTED_PRODUCTS) {
    const updatedInventories: IRegisterPosClubItem[] = action.payload;

    return state.update(personStates => {
      const personStatesJS = personStates?.toJS();
      const personIds = Object.keys(personStatesJS);
      const newPersonStates = Map(personStates);

      personIds.forEach(pIdStr => {
        const pId = Number(pIdStr);

        if (pId) {
          const newPersonState: IObject = cloneDeep(personStatesJS[pIdStr]);

          newPersonState.suggestedProducts = newPersonState.suggestedProducts?.map(
            suggestedProduct => {
              const existedInventory = updatedInventories.find(
                updatedInventory => updatedInventory.id === suggestedProduct.id,
              );
              return existedInventory || suggestedProduct;
            },
          );

          newPersonStates.set(pId, fromJS(newPersonState));
        }
      });

      return newPersonStates;
    });
  }

  if (!personId) {
    return state;
  }

  switch (action.type) {
    case actionTypes.FETCH_PROFILE_INFO:
      return state.setIn([personId, 'profile'], fromJS(action.payload));
    case actionTypes.GET_PERSON_ADDITIONAL_INFO:
      return state.setIn([personId, 'additionalFields'], fromJS(action.payload));
    case actionTypes.UPDATE_PERSON_ADDITIONAL_INFO:
      return state
        .setIn([personId, 'additionalFields'], fromJS(action.payload))
        .setIn([personId, 'updateProfileAction', 'result'], ActionResult.SUCCESS_ACTION);
    case actionTypes.UPDATE_PROFILE_INFO:
      return state
        .updateIn([personId, 'profile'], profile =>
          fromJS({ ...profile.toJS(), ...action.payload.profile }),
        )
        .updateIn([personId, 'alerts'], alerts =>
          alerts.filter(alertItem => alertItem.get('condition') !== action.payload.alertCondition),
        );
    case actionTypes.RESET_PERSON_UPDATE_ACTION_RESULT:
      return state.setIn([personId, 'updateProfileAction'], initReqStateImt);
    case actionTypes.REMIND_LATER_PERSON_APPOINTMENT_ALERT:
    case actionTypes.IGNORE_PERSON_APPOINTMENT_ALERT:
      return state.updateIn([personId, 'alerts'], alerts => {
        return alerts?.filter(alertItem => {
          const date = alertItem.getIn(['appointment', 'date']);
          const time = alertItem.getIn(['appointment', 'time']);
          const currentDate = action.payload.date;
          const currentTime = action.payload.time;

          const dateTime = date && time ? `${date} ${time}` : null;
          const currentDateTime =
            currentDate && currentTime ? `${currentDate} ${currentTime}` : null;
          const isEqualDateTime =
            dateTime && currentDateTime ? dateTime === currentDateTime : false;

          return !(
            alertItem.getIn(['appointment', 'id']) === action.payload.appointmentId &&
            isEqualDateTime
          );
        });
      });
    case actionTypes.IGNORE_PERSON_ALERT:
    case actionTypes.UPDATE_PERSON_SALESPERSON:
    case actionTypes.UPDATE_PERSON_EMERGENCY_CONTACT_INFO:
    case actionTypes.UPDATE_PERSON_INSURANCE_INFO:
    case actionTypes.UPDATE_PERSON_ALLERGIES:
    case actionTypes.UPDATE_PERSON_MEMBERSHIP_PACKAGE:
    case actionTypes.RESOLVE_PERSON_NOTE_ALERT:
    case actionTypes.RESOLVE_PERSON_MISSING_BILLING_INFO:
    case actionTypes.RESOLVE_PAST_DUE_ALERT:
      return state.updateIn([personId, 'alerts'], alerts =>
        alerts?.filter(alertItem => alertItem.get('condition') !== action.payload),
      );
    case actionTypes.RESOLVE_PERSON_UNSIGNED_WAIVER:
    case actionTypes.REMIND_LATER_PERSON_UNSIGNED_WAIVER:
    case actionTypes.IGNORE_PERSON_UNSIGNED_WAIVER:
      const { alertCondition, documentId } = action.payload;
      return state.updateIn([personId, 'alerts'], alerts => {
        return alerts?.filter(alertItem => {
          return (
            alertItem.get('condition') !== alertCondition ||
            alertItem.getIn(['document', 'id']) !== documentId
          );
        });
      });
    case actionTypes.SET_REMIND_LATER_ACTION_RESULT:
      return state.setIn([personId, 'remindLaterActionResult'], action.payload);
    case actionTypes.FETCH_PROFILE_INFO_LOADING:
      return state.setIn([personId, 'profileLoading'], action.payload);
    case actionTypes.UPDATE_PERSON_LOADING:
      return state.setIn([personId, 'updateProfileAction', 'isLoading'], action.payload);
    case actionTypes.FETCH_PERSON_ALERTS:
      return state.setIn([personId, 'alerts'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_ALERTS_LOADING:
      return state.setIn([personId, 'alertsLoading'], action.payload);

    case UPDATE_CURRENTLY_CHECKING_IN:
      return (
        state
          .setIn([personId, 'profile', 'id'], action.payload.personId)
          .setIn([personId, 'profile', 'firstName'], action.payload.firstName)
          .setIn([personId, 'profile', 'lastName'], action.payload.lastName)
          // TODO remove separate checkin info with lastCheckinDto
          .setIn([personId, 'profile', 'checkInTime'], action.payload.checkInTime)
          .setIn([personId, 'profile', 'checkInType'], action.payload.checkInType)
          // TODO temporary not working displaying of alerts
          .setIn([personId, 'profile', 'alerts'], action.payload.alerts)
          .setIn([personId, 'profile', 'checkInId'], action.payload.id)
          .setIn([personId, 'profile', 'lastCheckinDto'], {
            id: action.payload.id,
            type: action.payload.checkInType,
            date: action.payload.checkInTime,
          })
      );

    // Photo
    case UPDATE_PERSON_PHOTO:
      const alerts = state.getIn([personId, 'profile', 'alerts']);
      const newAlerts = alerts?.filter(alert => alert !== AlertCondition.MissingPhoto);

      return state
        .setIn([personId, 'profile', 'image'], action.payload ? fromJS(action.payload) : null)
        .setIn([personId, 'profile', 'alerts'], newAlerts);
    case UPDATE_PERSON_PHOTO_LOADING:
      return state.setIn([personId, 'profile', 'imageLoading'], action.payload);
    case UPDATE_PROFILE_AFTER_EDIT_PERSON_ACTION:
      // if person state not initialized
      if (!state.get(personId)) {
        return state
          .set(personId, fromJS(personInitialState))
          .setIn([personId, 'profile'], fromJS(action.payload));
      }
      return state.setIn([personId, 'profile'], fromJS(action.payload));

    // Tags
    case actionTypes.UPDATE_PERSON_TAGS:
      return state.setIn([personId, 'profile', 'tags'], fromJS(action.payload));
    case actionTypes.UPDATE_PERSON_TAGS_LOADING:
      return state.setIn([personId, 'profileLoading'], action.payload);

    // Fallthrough Capture
    case actionTypes.FETCH_PERSON_FALLTHROUGH_CAPTURE:
      return state.setIn([personId, 'fallthroughCapture'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_FALLTHROUGH_CAPTURE_LOADING:
      return state.setIn([personId, 'fallthroughCaptureLoading'], action.payload);
    case actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE:
      return state.setIn([personId, 'fallthroughCapture'], fromJS(action.payload));
    case actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE_ACTION_SUCCESS:
      return state.setIn([personId, 'fallthroughCaptureActionResult'], fromJS(action.payload));
    case actionTypes.DELETE_PERSON_FALLTHROUGH_CAPTURE:
      return state.setIn([personId, 'fallthroughCapture'], fromJS(action.payload));

    case actionTypes.DELETE_PERSON_FALLTHROUGH_CAPTURE_LOADING:
    case actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE_LOADING:
      return state.setIn([personId, 'fallthroughCaptureActionLoading'], action.payload);

    // Notes
    case actionTypes.FETCH_PERSON_NOTES:
      return state.setIn([personId, 'notes'], fromJS(action.payload.data));
    case actionTypes.FETCH_PERSON_NOTES_LOADING:
      return state.setIn([personId, 'notesLoading'], action.payload);

    case actionTypes.ADD_PERSON_NOTE_LOADING:
      return state.setIn([personId, 'addNoteLoading'], action.payload);
    case actionTypes.ADD_PERSON_NOTE:
      return state.setIn([personId, 'createdNote'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_CREATED_NOTE:
      return state.setIn([personId, 'createdNote'], null);

    case actionTypes.UPDATE_PERSON_NOTE:
      return state.setIn([personId, 'updatedNote'], fromJS(action.payload));
    case actionTypes.UPDATE_PERSON_NOTE_LOADING:
      return state.setIn([personId, 'updatingNoteLoading'], action.payload);

    case actionTypes.DELETE_PERSON_NOTE:
      return state.setIn([personId, 'deletedNote'], fromJS(action.payload));
    case actionTypes.DELETE_PERSON_NOTE_LOADING:
      return state.setIn([personId, 'deletingNoteLoading'], action.payload);

    // Payment methods
    case actionTypes.RESET_PAYMENT_METHODS:
      return state.set([personId, 'paymentMethods'], fromJS(initialPaymentMethodsState));
    case actionTypes.FETCH_STORED_CREDIT_CARDS:
      return state.setIn([personId, 'paymentMethods', 'cards'], fromJS(action.payload));
    case actionTypes.FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING:
      return state.setIn(
        [personId, 'paymentMethods', 'paymentMethodsModalDataLoading'],
        action.payload,
      );
    case actionTypes.FETCH_PERSON_PAYMENT_METHODS:
      return state.setIn([personId, 'paymentMethods', 'list'], fromJS(action.payload));
    case actionTypes.ADD_CREDIT_CARD_RESULT:
      return state.setIn([personId, 'paymentMethods', 'addCardResult'], fromJS(action.payload));
    case actionTypes.ADD_CREDIT_CARD_RESULT_LOADING:
      return state.setIn([personId, 'paymentMethods', 'addCardResultLoading'], action.payload);
    case actionTypes.DELETE_CREDIT_CARD_RESULT:
      const { payload }: { payload: IActionResultData } = action;
      return state
        .setIn([personId, 'paymentMethods', 'deleteCardResult'], payload.result)
        .updateIn([personId, 'paymentMethods', 'cards'], list =>
          list.filter(card => card.get('id') !== payload.data),
        );
    case actionTypes.RESET_DELETE_CREDIT_CARD_RESULT:
      return state.setIn([personId, 'paymentMethods', 'deleteCardResult'], null);
    case actionTypes.DELETE_CREDIT_CARD_RESULT_LOADING:
      return state.setIn([personId, 'paymentMethods', 'deleteCardResultLoading'], action.payload);
    case actionTypes.FETCH_PERSON_SUBSCRIPTIONS:
      return state.setIn([personId, 'paymentMethods', 'subscriptions'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_SUBSCRIPTIONS:
      return state.setIn([personId, 'paymentMethods', 'subscriptions'], fromJS([]));
    case actionTypes.UPDATE_PERSON_SUBSCRIPTIONS_LOADING:
      return state.setIn(
        [personId, 'paymentMethods', 'updateSubscriptionsAction', 'isLoading'],
        action.payload,
      );
    case actionTypes.UPDATE_PERSON_SUBSCRIPTIONS:
      return state.setIn(
        [personId, 'paymentMethods', 'updateSubscriptionsAction', 'result'],
        action.payload,
      );
    case actionTypes.RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT:
      return state.setIn(
        [personId, 'paymentMethods', 'updateSubscriptionsAction'],
        initReqStateImt,
      );

    // Checking
    case actionTypes.FETCH_CHECKING_SAVINGS_DATA:
      return state.setIn([personId, 'checkingSavings'], fromJS(action.payload));
    case actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING:
      return state.setIn([personId, 'fetchCheckingSavingsLoading'], action.payload);
    case actionTypes.ADD_CHECKING_SAVINGS_DATA:
      return state.updateIn([personId, 'checkingSavings'], data => {
        const routingNumberExists = data
          .toJS()
          .some(el => el.routingNumber === action.payload.routingNumber);
        if (routingNumberExists) {
          return data;
        }
        return fromJS([...(data || []), action.payload]);
      });
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING:
      return state.setIn([personId, 'addCheckingSavingsLoading'], action.payload);
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT:
      return state.setIn([personId, 'addCheckingSavingsActionResult'], action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM:
      return state.updateIn([personId, 'checkingSavings'], data => {
        const filteredData = data.filter(item => item.get('id') !== action.payload);
        return fromJS(filteredData);
      });
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING:
      return state.setIn([personId, 'deleteCheckingSavingsLoading'], action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT:
      return state.setIn([personId, 'deleteCheckingSavingsActionResult'], action.payload);
    case actionTypes.RESET_CHECKING_SAVINGS_DATA:
      return state.setIn([personId, 'checkingSavings'], fromJS([]));

    // Note codes
    case actionTypes.FETCH_NOTE_CODES:
      return state.setIn([personId, 'noteCodes'], fromJS(action.payload.data));
    case actionTypes.RESET_NOTE_CODES:
      return state.setIn([personId, 'noteCodes'], fromJS([]));
    case actionTypes.FETCH_NOTE_CODES_IS_LOADING:
      return state.setIn([personId, 'noteCodesIsLoading'], action.payload);

    // Appointments
    case actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY: {
      const appointmentsWithUniqueId = action.payload.data?.map(item => {
        return { ...item, appointmentId: uuid() };
      });
      return state
        .setIn([personId, 'appointmentsHistory'], fromJS(appointmentsWithUniqueId))
        .setIn([personId, 'appointmentsHistoryMeta'], fromJS(action.payload.meta));
    }
    case actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING:
      return state.setIn([personId, 'appointmentsHistoryLoading'], action.payload);
    case actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS: {
      const appointmentsWithUniqueId = action.payload?.map(item => {
        return { ...item, appointmentId: uuid() };
      });

      return state.setIn([personId, 'recentAppointments'], fromJS(appointmentsWithUniqueId));
    }

    case actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS_LOADING:
      return state.setIn([personId, 'recentAppointmentsLoading'], action.payload);
    case actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS:
      return state.setIn([personId, 'appointment'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS_LOADING:
      return state.setIn([personId, 'isAppointmentLoading'], action.payload);
    case actionTypes.RESET_PERSON_APPOINTMENT_DETAILS:
      return state.setIn([personId, 'appointment'], fromJS({}));
    case actionTypes.REDEEM_PERSON_APPOINTMENT:
    case actionTypes.CANCEL_APPOINTMENT:
    case actionTypes.RESTORE_APPOINTMENT:
      const itemIndex = state
        .getIn([personId, 'recentAppointments'])
        .findIndex(item => item.get('id') === action.payload.id);
      return state
        .setIn([personId, 'recentAppointments', itemIndex], fromJS(action.payload))
        .setIn([personId, 'appointmentActionStatus', 'result'], fromJS(action.payload));
    case actionTypes.DELETE_APPOINTMENT:
    case actionTypes.UPDATE_APPOINTMENT:
    case actionTypes.CREATE_APPOINTMENT:
    case actionTypes.REMIND_APPOINTMENT:
    case actionTypes.CANCEL_APPOINTMENT_PARTICIPATION:
    case actionTypes.CONFIRM_APPOINTMENT_PARTICIPATION:
      return state.setIn([personId, 'appointmentActionStatus', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_APPOINTMENT_LOADING:
    case actionTypes.CREATE_APPOINTMENT_LOADING:
    case actionTypes.DELETE_APPOINTMENT_LOADING:
    case actionTypes.CANCEL_APPOINTMENT_LOADING:
    case actionTypes.REMIND_APPOINTMENT_LOADING:
    case actionTypes.RESTORE_APPOINTMENT_LOADING:
    case actionTypes.CANCEL_APPOINTMENT_PARTICIPATION_LOADING:
    case actionTypes.CONFIRM_APPOINTMENT_PARTICIPATION_LOADING:
      return state.setIn([personId, 'appointmentActionStatus', 'isLoading'], action.payload);
    case actionTypes.RESET_APPOINTMENT_ACTION_RESULT:
      return state.setIn([personId, 'appointmentActionStatus'], initReqStateImt);

    // Family members
    case familyMembersActionTypes.FETCH_PERSON_FAMILY_MEMBERS:
      return state.setIn([personId, 'familyMembers'], fromJS(action.payload));
    case familyMembersActionTypes.FETCH_PERSON_FAMILY_MEMBERS_LOADING:
      return state.setIn([personId, 'familyMembersLoading'], action.payload);
    case familyMembersActionTypes.ADD_PERSON_FAMILY_MEMBER_LOADING:
      return state.setIn([personId, 'addFamilyMemberLoading'], action.payload);
    case familyMembersActionTypes.RESET_ADD_PERSON_FAMILY_MEMBER_ACTION_RESULT:
      return state.setIn([personId, 'addFamilyMemberActionResult'], null);
    case familyMembersActionTypes.ADD_PERSON_FAMILY_MEMBER:
      return state.setIn([personId, 'addFamilyMemberActionResult'], ActionResult.SUCCESS_ACTION);
    case familyMembersActionTypes.DELETE_PERSON_FAMILY_MEMBER:
      return state.updateIn([personId, 'familyMembers'], membersList =>
        membersList.filter(member => member.get('id') !== action.payload),
      );
    // Suggested products
    case actionTypes.FETCH_PERSON_SUGGESTED_PRODUCTS:
      return state.setIn([personId, 'suggestedProducts'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_SUGGESTED_PRODUCTS_LOADING:
      return state.setIn([personId, 'suggestedProductsLoading'], action.payload);

    // Payments
    case actionTypes.FETCH_PERSON_PAYMENTS:
      return state
        .setIn([personId, 'payments'], fromJS(action.payload.data))
        .setIn([personId, 'paymentsMeta'], fromJS(action.payload.meta));
    case actionTypes.VOID_PERSON_INVOICE_ACTION_RESULT:
      return state.setIn([personId, 'voidInvoice', 'result'], action.payload);
    case actionTypes.VOID_PERSON_INVOICE_LOADING:
      return state.setIn([personId, 'voidInvoice', 'isLoading'], action.payload);
    case actionTypes.FETCH_PERSON_PAYMENTS_LOADING:
      return state.setIn([personId, 'paymentsLoading'], action.payload);
    case actionTypes.FETCH_RECENT_PERSON_PAYMENTS:
      return state.setIn([personId, 'recentPayments'], fromJS(action.payload));
    case actionTypes.FETCH_RECENT_PERSON_PAYMENTS_LOADING:
      return state.setIn([personId, 'recentPaymentsLoading'], action.payload);
    case actionTypes.FETCH_PAYMENT_DETAILS:
      return state.setIn([personId, 'paymentDetails'], fromJS(action.payload));
    case actionTypes.FETCH_PAYMENT_DETAILS_LOADING:
      return state.setIn([personId, 'paymentDetailsLoading'], action.payload);

    // Services List
    case servicesActionTypes.FETCH_PERSON_SERVICES_SUCCESS:
      return state.setIn([personId, 'services'], fromJS(action.payload));
    case servicesActionTypes.FETCH_PERSON_SERVICES_LOADING:
      return state.setIn([personId, 'servicesLoading'], action.payload);
    case servicesActionTypes.RESET_PERSON_SERVICES:
      return state.setIn([personId, 'services'], defaultServiceSectionState);

    // Referrals
    case referralsActionTypes.FETCH_PERSON_REFERRALS_SUCCESS:
      return state.setIn([personId, 'referrals'], fromJS(action.payload));
    case referralsActionTypes.FETCH_PERSON_REFERRALS_LOADING:
      return state.setIn([personId, 'referralsLoading'], action.payload);
    case referralsActionTypes.RESET_PERSON_REFERRALS:
      return state.setIn([personId, 'referrals'], defaultReferralSectionState);
    case referralsActionTypes.ADD_PERSON_REFERRAL_LOADING:
      return state.setIn([personId, 'createReferralLoading'], action.payload);
    case referralsActionTypes.ADD_PERSON_REFERRAL:
      return state.setIn([personId, 'createReferralResult'], action.payload);
    case referralsActionTypes.SET_REFERRED_BY_LOADING:
      return state.setIn([personId, 'referredByLoading'], action.payload);
    case referralsActionTypes.SET_REFERRED_BY:
      return state.setIn([personId, 'referredByResult'], action.payload);

    // Appointment services
    case actionTypes.FETCH_PERSON_APPOINTMENT_SERVICES_LOADING:
      return state.setIn([personId, 'servicesForEventRedeemLoading'], action.payload);
    case actionTypes.FETCH_PERSON_APPOINTMENT_SERVICES:
      return state.setIn([personId, 'appointmentServices'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_APPOINTMENT_SERVICES:
      return state.setIn([personId, 'appointmentServices'], fromJS([]));

    case actionTypes.FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM:
      return state.setIn([personId, 'servicesForEventRedeem'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM_LOADING:
      return state.setIn([personId, 'servicesForEventRedeemLoading'], action.payload);
    case actionTypes.RESET_PERSON_SERVICES_FOR_EVENT_REDEEM:
      return state.setIn([personId, 'servicesForEventRedeem'], fromJS([]));

    // Modification history
    case actionTypes.FETCH_PERSON_MODIFICATION_HISTORY:
      return state
        .setIn([personId, 'modificationHistory'], fromJS(action.payload.data))
        .setIn([personId, 'modificationHistoryMeta'], fromJS(action.payload.meta));
    case actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_LOADING:
      return state.setIn([personId, 'modificationHistoryLoading'], action.payload);
    case actionTypes.FETCH_PERSON_RECENT_MODIFICATION_HISTORY:
      return state.setIn([personId, 'recentModificationHistory'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_RECENT_MODIFICATION_HISTORY_LOADING:
      return state.setIn([personId, 'recentModificationHistoryLoading'], action.payload);
    case actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_ITEM:
      return state.setIn([personId, 'modificationHistoryItem'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_ITEM_LOADING:
      return state.setIn([personId, 'modificationHistoryItemLoading'], action.payload);
    case actionTypes.RESET_PERSON_MODIFICATION_HISTORY_ITEM:
      return state.setIn([personId, 'modificationHistoryItem'], fromJS({}));

    // CheckIns history
    case actionTypes.FETCH_PERSON_RECENT_CHECK_INS_HISTORY:
      return state.setIn([personId, 'recentCheckInsHistory'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_RECENT_CHECK_INS_HISTORY_LOADING:
      return state.setIn([personId, 'recentCheckInsHistoryLoading'], action.payload);
    case actionTypes.FETCH_PERSON_DOCUMENTS_HISTORY:
      return state
        .setIn([personId, 'documentsHistory'], fromJS(action.payload.data))
        .setIn([personId, 'documentsHistoryMeta'], fromJS(action.payload.meta));
    case actionTypes.FETCH_PERSON_DOCUMENTS_HISTORY_LOADING:
      return state.setIn([personId, 'isDocumentsHistoryLoading'], action.payload);
    case actionTypes.FETCH_PERSON_CHECK_INS_HISTORY:
      return state
        .setIn([personId, 'checkInsHistory'], fromJS(action.payload.data))
        .setIn([personId, 'checkInsHistoryMeta'], fromJS(action.payload.meta));
    case actionTypes.FETCH_PERSON_CHECK_INS_HISTORY_LOADING:
      return state.setIn([personId, 'checkInsHistoryLoading'], action.payload);

    // Recent calls
    case actionTypes.FETCH_PERSON_RECENT_CALLS_SUCCESS:
      return state.setIn([personId, 'recentCalls'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_RECENT_CALLS_LOADING:
      return state.setIn([personId, 'recentCallsLoading'], action.payload);
    // Calls
    case actionTypes.FETCH_PERSON_CALLS_SUCCESS:
      return state
        .setIn([personId, 'calls'], fromJS(action.payload.data))
        .setIn([personId, 'callsMeta'], fromJS(action.payload.meta));
    case actionTypes.RESET_PERSON_CALLS:
      return state.setIn([personId, 'calls'], List());
    case actionTypes.FETCH_PERSON_CALLS_LOADING:
      return state.setIn([personId, 'callsLoading'], action.payload);
    case actionTypes.FETCH_PERSON_CALL_DETAILS_LOADING:
      return state.setIn([personId, 'isCallLoading'], action.payload);
    case actionTypes.FETCH_PERSON_CALL_DETAILS:
      return state.setIn([personId, 'call'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_CALL_DETAILS:
      return state.setIn([personId, 'call'], fromJS({}));
    case actionTypes.ADD_PERSON_CALL:
      return state.setIn(
        [personId, 'createCallAction', 'result'],
        fromJS({ success: !!action.payload }),
      );
    case actionTypes.ADD_PERSON_CALL_LOADING:
      return state.setIn([personId, 'createCallAction', 'isLoading'], action.payload);
    case actionTypes.RESET_CREATE_PERSON_CALL_RESULT:
      return state.setIn([personId, 'createCallAction', 'result'], null);

    // SMS
    case actionTypes.FETCH_PERSON_SMS_SUCCESS:
      return state.setIn([personId, 'sms'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_SMS_LOADING:
      return state.setIn([personId, 'smsLoading'], action.payload);

    case actionTypes.FETCH_PERSON_SMS_DETAILS_SUCCESS:
      return state.setIn([personId, 'smsDetails'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_SMS_DETAILS_LOADING:
      return state.setIn([personId, 'smsDetailsLoading'], action.payload);
    case actionTypes.RESET_PERSON_SMS_DETAILS:
      return state.setIn([personId, 'smsDetails'], null);

    case actionTypes.FETCH_PERSON_SMS_HISTORY_SUCCESS:
      return state
        .setIn([personId, 'smsHistory'], fromJS(action.payload.data))
        .setIn([personId, 'smsHistoryMeta'], fromJS(action.payload.meta));
    case actionTypes.FETCH_PERSON_SMS_HISTORY_LOADING:
      return state.setIn([personId, 'smsHistoryLoading'], action.payload);
    case actionTypes.ADD_PERSON_SMS_SUCCESS:
      return state
        .updateIn([personId, 'sms'], list => list.unshift(fromJS(action.payload)))
        .setIn([personId, 'createSmsActionResult'], ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_PERSON_CREATE_SMS_ACTION_RESULT:
      return state.setIn([personId, 'createSmsActionResult'], null);

    // Reminders
    case actionTypes.FETCH_PERSON_REMINDERS_SUCCESS:
      return state
        .setIn([personId, 'reminders'], fromJS(action.payload.data))
        .setIn([personId, 'remindersMeta'], fromJS(action.payload.meta));
    case actionTypes.RESET_PERSON_REMINDERS:
      return state.setIn([personId, 'reminders'], List());
    case actionTypes.FETCH_PERSON_REMINDERS_LOADING:
      return state.setIn([personId, 'remindersLoading'], action.payload);

    // Reminder
    case actionTypes.FETCH_PERSON_REMINDER_SUCCESS:
      return state.setIn([personId, 'reminder'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_REMINDER:
      return state.setIn([personId, 'reminder'], null);
    case actionTypes.FETCH_PERSON_REMINDER_LOADING:
      return state.setIn([personId, 'reminderLoading'], action.payload);

    // Recent reminders
    case actionTypes.FETCH_PERSON_RECENT_REMINDERS_SUCCESS:
      return state.setIn([personId, 'recentReminders'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_RECENT_REMINDERS_LOADING:
      return state.setIn([personId, 'recentRemindersLoading'], action.payload);

    // Add reminder
    case actionTypes.ADD_PERSON_REMINDER:
      return state.setIn([personId, 'addReminderAction', 'result'], fromJS(action.payload));
    case actionTypes.RESET_ADD_PERSON_REMINDER:
      return state.setIn([personId, 'addReminderAction', 'result'], null);
    case actionTypes.ADD_PERSON_REMINDER_LOADING:
      return state.setIn([personId, 'addReminderAction', 'isLoading'], action.payload);

    // Recent Emails
    case actionTypes.FETCH_PERSON_RECENT_EMAILS_SUCCESS:
      return state.setIn([personId, 'recentEmails'], fromJS(action.payload));
    case actionTypes.SOCKET_UPDATE_PERSON_RECENT_EMAILS:
      return state.updateIn([personId, 'recentEmails'], emails =>
        emails.unshift(fromJS(action.payload)),
      );
    case actionTypes.FETCH_PERSON_RECENT_EMAILS_LOADING:
      return state.setIn([personId, 'recentEmailsLoading'], action.payload);
    // Emails
    case actionTypes.FETCH_PERSON_EMAILS_SUCCESS:
      const { data, meta } = action.payload;

      return state
        .setIn([personId, 'emails'], fromJS(data))
        .setIn([personId, 'emailsMeta'], fromJS(meta));
    case actionTypes.RESET_PERSON_EMAILS:
      return state
        .setIn([personId, 'emails'], fromJS([]))
        .setIn([personId, 'emailsMeta'], fromJS({ page: 0, perPage: 5, total: 0 }));
    case actionTypes.FETCH_PERSON_EMAILS_LOADING:
      return state.setIn([personId, 'emailsLoading'], action.payload);
    case actionTypes.FETCH_PERSON_EMAIL_SUCCESS:
      return state.setIn([personId, 'email'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_EMAIL_LOADING:
      return state.setIn([personId, 'emailLoading'], action.payload);
    case actionTypes.RESET_PERSON_EMAIL:
      return state.setIn([personId, 'email'], null);
    case actionTypes.ADD_PERSON_EMAIL:
      return state.setIn([personId, 'createEmailActionResult'], ActionResult.SUCCESS_ACTION);
    case actionTypes.RESET_PERSON_CREATE_EMAIL_ACTION_RESULT:
      return state.setIn([personId, 'createEmailActionResult'], null);
    case actionTypes.DELETE_PERSON_EMAIL:
      const deleteEmailIndex = state
        .getIn([personId, 'emails'])
        .findIndex(item => item.get('id') === action.payload);
      return state.deleteIn([personId, 'emails', deleteEmailIndex]);

    // Campaigns
    case actionTypes.FETCH_PERSON_CAMPAIGNS_SUCCESS:
      return state.setIn([personId, 'campaigns'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_CAMPAIGNS_LOADING:
      return state.setIn([personId, 'campaignsLoading'], action.payload);

    case actionTypes.FETCH_PERSON_CAMPAIGNS_HISTORY_SUCCESS:
      return state
        .setIn([personId, 'campaignsHistory'], fromJS(action.payload.data))
        .setIn([personId, 'campaignsHistoryMeta'], fromJS(action.payload.meta));
    case actionTypes.FETCH_PERSON_CAMPAIGNS_HISTORY_LOADING:
      return state.setIn([personId, 'campaignsHistoryLoading'], action.payload);

    // upcoming billings
    case actionTypes.FETCH_PERSON_UPCOMING_BILLINGS:
    case actionTypes.FETCH_PACKAGE_UPCOMING_BILLINGS:
      return state.setIn([personId, 'upcomingBillings'], fromJS(action.payload));

    case actionTypes.UPDATE_BILLING_SCHEDULE_AMOUNT:
      return state.setIn(
        [personId, 'updateBillingScheduleAmountAction', 'result'],
        fromJS(action.payload),
      );
    case actionTypes.UPDATE_BILLING_SCHEDULE_AMOUNT_LOADING:
      return state.setIn(
        [personId, 'updateBillingScheduleAmountAction', 'isLoading'],
        action.payload,
      );
    case actionTypes.RESET_UPDATE_BILLING_SCHEDULE_AMOUNT_ACTION_RESULT:
      return state.setIn([personId, 'updateBillingScheduleAmountAction'], initReqStateImt);
    case actionTypes.FETCH_PERSON_UPCOMING_BILLINGS_LOADING:
    case actionTypes.FETCH_PACKAGE_UPCOMING_BILLINGS_LOADING:
      return state.setIn([personId, 'upcomingBillingsLoading'], action.payload);
    case actionTypes.RESET_PACKAGE_UPCOMING_BILLINGS:
      return state.setIn([personId, 'upcomingBillings'], fromJS([]));

    // past dues
    case actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS:
      return state.setIn([personId, 'pastDueBillings'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS_LOADING:
      return state.setIn([personId, 'pastDueBillingsLoading'], action.payload);

    // salesInfo
    case actionTypes.FETCH_PERSON_SALES_INFO:
      return state.setIn([personId, 'salesInfo'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_SALES_INFO:
      return state.setIn([personId, 'salesInfo'], fromJS({}));
    case actionTypes.FETCH_PERSON_SALES_INFO_LOADING:
      return state.setIn([personId, 'salesInfoLoading'], action.payload);
    case actionTypes.UPDATE_PERSON_SALES_INFO:
      return state
        .setIn([personId, 'salesInfo'], fromJS(action.payload))
        .setIn([personId, 'salesInfoActionStatus', 'result'], fromJS(action.payload));
    case actionTypes.UPDATE_PERSON_SALES_INFO_LOADING:
      return state.setIn([personId, 'salesInfoActionStatus', 'isLoading'], action.payload);
    case actionTypes.RESET_UPDATE_PERSON_SALES_INFO:
      return state.setIn([personId, 'salesInfoActionStatus'], fromJS({}));

    // Documents
    case actionTypes.FETCH_PERSON_DOCUMENTS_LOADING:
      return state.setIn([personId, 'isDocumentsLoading'], action.payload);
    case actionTypes.FETCH_DOCUMENT_HTML_LOADING:
      return state.setIn([personId, 'isDocumentHTMLLoading'], action.payload);
    case actionTypes.FETCH_PERSON_DOCUMENTS:
      return state.setIn([personId, 'documents'], fromJS(action.payload));
    case actionTypes.RESET_PERSON_DOCUMENTS:
      return state.setIn([personId, 'documents'], null);
    case actionTypes.UPDATE_PERSON_ATTACHMENTS_LOADING:
    case actionTypes.DELETE_PERSON_ATTACHMENT_LOADING:
      return state.setIn([personId, 'documentActionStatus', 'isLoading'], action.payload);
    case actionTypes.UPDATE_PERSON_ATTACHMENTS:
    case actionTypes.DELETE_PERSON_ATTACHMENT:
      return state.setIn([personId, 'documentActionStatus', 'result'], action.payload);
    case actionTypes.RESET_PERSON_ATTACHMENTS_ACTION_RESULT:
      return state.setIn([personId, 'documentActionStatus', 'result'], null);

    // Tickets
    case ticketsActionTypes.FETCH_PERSON_TICKETS_SUCCESS:
      return state
        .setIn([personId, 'tickets'], fromJS(action.payload))
        .setIn([personId, 'ticketsError'], null);
    case ticketsActionTypes.SET_PERSON_TICKETS_ERROR:
      return state.setIn([personId, 'ticketsError'], fromJS(action.payload));
    case ticketsActionTypes.FETCH_PERSON_TICKETS_LOADING:
      return state.setIn([personId, 'ticketsLoading'], action.payload);
    case ticketsActionTypes.RESET_PERSON_TICKETS:
      return state.setIn([personId, 'tickets'], List());
    case ticketsActionTypes.FETCH_PERSON_TICKET_SUCCESS:
      const comments = action.payload?.comments || [];
      const updatedData = action.payload && {
        ...action.payload,
        comments: comments.map(item => ({ ...item, id: uuid() })),
      };
      return state
        .setIn([personId, 'ticket'], fromJS(updatedData))
        .setIn([personId, 'ticketsError'], null);
    case ticketsActionTypes.FETCH_PERSON_TICKET_LOADING:
      return state.setIn([personId, 'ticketLoading'], action.payload);
    case ticketsActionTypes.RESET_PERSON_TICKET:
      return state.setIn([personId, 'ticket'], fromJS({}));
    case ticketsActionTypes.ADD_PERSON_TICKET_SUCCESS:
      return state
        .setIn([personId, 'createTicketResult'], action.payload)
        .setIn([personId, 'ticketsError'], null);
    case ticketsActionTypes.ADD_PERSON_TICKET_LOADING:
      return state.setIn([personId, 'createTicketLoading'], action.payload);
    case ticketsActionTypes.RESET_ADD_PERSON_TICKET_ACTION_RESULT:
      return state.setIn([personId, 'createTicketResult'], null);
    // rewards
    case actionTypes.UPDATE_PERSON_REWARDS_LOADING:
      return state.setIn([personId, 'isUpdateRewardsLoading'], action.payload);
    case actionTypes.UPDATE_PERSON_REWARDS:
      return state.setIn([personId, 'profile', 'rewardPointNumber'], action.payload);
    case actionTypes.FETCH_PERSON_REWARDS_HISTORY_SUCCESS:
      return state.setIn([personId, 'rewardsHistory'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_REWARDS_HISTORY_LOADING:
      return state.setIn([personId, 'rewardsHistoryLoading'], action.payload);
    // balance
    case actionTypes.FETCH_PERSON_BALANCE_HISTORY_SUCCESS:
      return state.setIn([personId, 'balanceHistory'], fromJS(action.payload));
    case actionTypes.FETCH_PERSON_BALANCE_HISTORY_LOADING:
      return state.setIn([personId, 'balanceHistoryLoading'], action.payload);
    default:
      return state;
  }
}

function signWaiverReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.SIGN_WAIVER:
      return state.set('result', fromJS(action.payload));
    case actionTypes.SIGN_WAIVER_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_SIGN_WAIVER:
    default:
      return state;
  }
}

export default combineReducers({
  profiles: personProfileReducer,
  membership: membershipReducer,
  signWaiver: signWaiverReducer,

  serviceModals: serviceModalsReducer,
  inventoryModals: inventoryModalsReducer,
  familyMemberServiceModals: familyMemberServiceModalsReducer,

  serviceEvents: serviceEventsReducer,
  serviceResourceTags: serviceResourceTagsReducer,
  serviceEventDetails: serviceEventDetailsReducer,
});
