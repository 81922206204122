// libraries
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// state
import {
  selectPersonProfile,
  selectUpcomingBillings,
  selectUpcomingBillingsLoading,
} from 'common/components/PersonProfile/state/selectors';
import { selectTimezone } from 'common/state/settings/selectors';

import {
  fetchPackageUpcomingBillings,
  resetPackageUpcomingBillings,
  resetUpdateBillingScheduleAmountActionResult,
  updateBillingScheduleAmount,
} from 'common/components/PersonProfile/state/actions';

// components
import { Alert, AllowedTo, DateTimePicker } from 'common/components';
import { BillingsTable } from './BillingsTable';

// interfaces
import { ITableParams } from 'common/interfaces/table';
import { ISelectedData } from 'common/hooks/useMultipleSelect';
import {
  CancelMembershipData,
  IBilling,
  IBillingImt,
  IProfileInfoImt,
  ISelectableBilling,
} from 'common/components/PersonProfile/interfaces';
import { AlertTypes } from 'common/interfaces/alerts';

// constants
import { StepContext } from 'common/createContext/stepContext';
import { PeakModules } from 'common/constants/peakModules';
import {
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_TIME_FORMAT,
} from 'common/components/DateTimePicker/DatePickerLayer';
import { TableOrderByParams } from 'common/constants';

import { makeTableParams } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  getDisablePastTimeSchema,
  getFutureDateTimeSchema,
} from 'common/validationSchemas/dateSchemas';

// messages
import messages from 'common/components/PersonProfile/messages';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
import useInitial from 'common/hooks/useInitial';

const useStyles = makeStyles(() => ({
  noFutureBillingsMessage: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const ValidationSchema = yup.object().shape({
  cancellationDate: getFutureDateTimeSchema('cancellationTime'),
  cancellationTime: getDisablePastTimeSchema('cancellationDate'),
});

interface IProps {
  personId: number;
  membershipId: string;
  module: PeakModules;
  isMembershipCard: boolean;
}

const BillingStep = ({ personId, membershipId, module, isMembershipCard }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const usePersonSelector = usePersonSelectorTemplate(personId);

  const billingSchedules: ImmutableList<IBillingImt> = usePersonSelector(selectUpcomingBillings);
  const isBillingSchedulesLoading: boolean = useSelector(selectUpcomingBillingsLoading(personId));
  const currentTimezoneView: string = useSelector(selectTimezone);
  const profile: IProfileInfoImt = usePersonSelector(selectPersonProfile);
  const updateBillingScheduleAmountActionLoading: boolean = usePersonSelector(
    selectors.selectUpdateBillingScheduleAmountActionLoading,
  );
  const updateBillingScheduleAmountActionResult: IBillingImt = usePersonSelector(
    selectors.selectUpdateBillingScheduleAmountActionResult,
  );

  const [timezoneMoment] = useTimezoneMoment();

  const initialTableParams = useInitial(() => {
    return makeTableParams([], null, { orderBy: TableOrderByParams.DATE, order: 'desc' });
  });

  const [tableParams, setTableParams] = useState(initialTableParams);
  const [billings, setBillings] = useState<ISelectableBilling[]>([]);
  const [billingsSelectParams, setBillingsSelectParams] = useState<Partial<ISelectedData>>({
    isAllSelected: false,
    selectedIds: [],
  });

  const isInitialBillingsExist = useRef(false);
  const prevTableParams = useRef(tableParams);

  const initialValues = {
    cancellationDate: null,
    cancellationTime: null,
  };

  const { cancellationDate, cancellationTime } = useMemo(() => {
    const utcDate = timezoneMoment()
      .add(10, 'minutes')
      .utc();

    return {
      cancellationDate: utcDate.format(DATE_PICKER_DATE_FORMAT),
      cancellationTime: utcDate.format(DATE_PICKER_TIME_FORMAT),
    };
  }, [timezoneMoment]);

  const homeClubTimezone = profile?.getIn(['homeClub', 'timezone']);

  const clubZoneOffset = moment.tz(homeClubTimezone).utcOffset();
  const timezoneViewOffset = moment.tz(currentTimezoneView).utcOffset();

  const isSameTimezones = clubZoneOffset === timezoneViewOffset;

  const formMethods = useForm<any>({
    defaultValues: {
      ...initialValues,
      cancellationDate,
      cancellationTime,
    },
    mode: 'all',
    resolver: yupResolver(ValidationSchema),
  });

  const { getValues, handleSubmit } = formMethods;

  useEffect(() => {
    dispatch(
      fetchPackageUpcomingBillings(
        personId,
        membershipId,
        module,
        initialTableParams,
        isMembershipCard,
      ),
    );

    return () => {
      dispatch(resetPackageUpcomingBillings(null, personId));
    };
  }, [dispatch, membershipId, module, personId, isMembershipCard, initialTableParams]);

  useEffect(() => {
    if (!billingSchedules?.size && isInitialBillingsExist.current) {
      setBillings([]);
    }

    if (billingSchedules?.size) {
      if (!isInitialBillingsExist.current) {
        isInitialBillingsExist.current = true;
      }

      setBillings(
        billingSchedules.toJS().map((billing: IBilling) => ({ selected: false, ...billing })),
      );
    }
  }, [billingSchedules]);

  useEffect(() => {
    if (updateBillingScheduleAmountActionResult?.size) {
      const updatedBilling = updateBillingScheduleAmountActionResult.toJS();

      setBillings(prevState =>
        prevState.map(billingItem =>
          updatedBilling.id === billingItem.id
            ? { ...billingItem, ...updatedBilling }
            : billingItem,
        ),
      );

      dispatch(resetUpdateBillingScheduleAmountActionResult(null, personId));
    }
  }, [dispatch, personId, updateBillingScheduleAmountActionResult]);

  const { onBack, renderFooter, onNext } = useContext(StepContext);

  const classes = useStyles();

  // handlers

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      const { page: prevPage, perPage: prevPerPage } = prevTableParams.current;
      const { page, perPage } = tableProps;

      setTableParams(tableProps);

      prevTableParams.current = tableProps;

      if (prevPage !== page || prevPerPage !== perPage) {
        return;
      }

      dispatch(
        fetchPackageUpcomingBillings(personId, membershipId, module, tableProps, isMembershipCard),
      );
    },
    [dispatch, membershipId, module, personId, isMembershipCard],
  );

  const handleBillingChange = useCallback(
    (billingItemId: string, amount: number) => {
      dispatch(updateBillingScheduleAmount(personId, billingItemId, amount));
    },
    [dispatch, personId],
  );

  const handleBillingsSelect = useCallback((params: ISelectedData) => {
    const { selectedIds, isAllSelected, excludedIds } = params;
    setBillingsSelectParams(params);

    setBillings(prevState => {
      if (isAllSelected && !excludedIds.length) {
        return prevState.map(billing => {
          return {
            ...billing,
            selected: true,
          };
        });
      } else if (excludedIds.length) {
        return prevState.map(billing => {
          return {
            ...billing,
            selected: !excludedIds.includes(billing.id),
          };
        });
      } else {
        return prevState.map(billing => {
          return {
            ...billing,
            selected: Array.isArray(selectedIds) ? selectedIds.includes(billing.id) : false,
          };
        });
      }
    });
  }, []);

  // TODO need to refine
  /* const isSubmitDisabled =
    !!billings?.length &&
    !!billings.some(b => moment(b.paymentDate).isAfter(moment(cancellationDate))); */

  const handleResolveStep = (): void => {
    let resolvedBillingSchedules;

    if (billingsSelectParams.isAllSelected && !billingsSelectParams.excludedIds?.length) {
      resolvedBillingSchedules = billings;
    } else if (billingsSelectParams.excludedIds?.length) {
      resolvedBillingSchedules = billings.reduce(
        (acc, billingItem) =>
          billingsSelectParams.excludedIds?.includes(billingItem.id) ? acc : [...acc, billingItem],
        [],
      );
    } else {
      resolvedBillingSchedules = billings.filter(billingItem => billingItem.selected);
    }

    const result: Partial<CancelMembershipData> = {
      billingSchedules: resolvedBillingSchedules,
      ...getValues(),
    };

    onNext(result);
  };

  // renders
  return (
    <FormProvider {...formMethods}>
      <form>
        <DateTimePicker
          datePickerName="cancellationDate"
          timePickerName="cancellationTime"
          dateLabelMessageDescriptor={messages.cancelMembershipModalConfirmationDateLabel}
          timeLabelMessageDescriptor={messages.cancelMembershipModalConfirmationTimeLabel}
          disablePast
        />

        {!!billingSchedules?.size && !isSameTimezones && (
          <Box marginY={2}>
            <Alert
              severity={AlertTypes.Warning}
              title={
                <FormattedMessage
                  {...messages.homeClubTimezoneIsDifferentMsg}
                  values={{
                    timezoneViewOffset: `(GMT ${moment.tz(currentTimezoneView).format('Z')})`,
                    clubZoneOffset: `(GMT ${moment.tz(homeClubTimezone).format('Z')})`,
                    cancellationDate: `${moment(
                      `${getValues('cancellationDate')} ${getValues('cancellationTime')}`,
                      'YYYY-MM-DD HH:mm',
                    )
                      .utc(true)
                      .utcOffset(clubZoneOffset)
                      .format('h:mm A, dddd, MMMM D')}`,
                  }}
                />
              }
            />
          </Box>
        )}

        <AllowedTo
          perform={!billingSchedules?.size && !isInitialBillingsExist.current}
          yes={
            <Typography
              variant="h2"
              color="textSecondary"
              className={classes.noFutureBillingsMessage}
            >
              <FormattedMessage {...messages.cancelMembershipModalNoFutureBillingsMessage} />
            </Typography>
          }
          no={
            <BillingsTable
              billings={billings}
              isBillingsLoading={
                isBillingSchedulesLoading || updateBillingScheduleAmountActionLoading
              }
              onBillingChanged={handleBillingChange}
              onSelect={handleBillingsSelect}
              onChangeParams={handleChangeTableProps}
              tableParams={tableParams}
            />
          }
        />

        {renderFooter(onBack, handleSubmit(handleResolveStep))}
      </form>
    </FormProvider>
  );
};

export default React.memo(BillingStep);
