import { defineMessages } from 'react-intl';

export default defineMessages({
  services: {
    id: 'app.modules.services.mainPage.services',
    defaultMessage: 'Services',
  },
  servicePackages: {
    id: 'app.modules.services.mainPage.servicePackages',
    defaultMessage: 'Service Bundles',
  },
  documentTemplates: {
    id: 'app.modules.services.mainPage.documentTemplates',
    defaultMessage: 'Document Templates',
  },
  changeStatusTitle: {
    id: 'app.modules.services.changeStatusTitle',
    defaultMessage: 'Change service status',
  },
  duplicatePackageTitle: {
    id: 'app.modules.services.duplicatePackageTitle',
    defaultMessage: 'Duplicate {name}',
  },
  // Package Service
  newServicePackageTitle: {
    id: 'app.modules.services.titles.newServicePackage',
    defaultMessage: 'New Service Bundle',
  },
  editServicePackageTitle: {
    id: 'app.modules.services.titles.editServicePackage',
    defaultMessage: 'Edit Service Bundle',
  },
  conditionsForPurchaseTitle: {
    id: 'app.modules.services.titles.conditionsForPurchase',
    defaultMessage: 'Conditions for purchase',
  },
  generalInformationTitle: {
    id: 'app.modules.services.titles.generalInformation',
    defaultMessage: 'General information',
  },
  periodTitle: {
    id: 'app.modules.services.titles.period',
    defaultMessage: 'Select Period',
  },
  additionToExistingServicePackagesTitle: {
    id: 'app.modules.services.titles.additionToExistingServicePackages',
    defaultMessage: 'Addition to existing service bundles',
  },
  setPriceForServiceSoldInCombinationTitle: {
    id: 'app.modules.services.titles.setPriceForServiceSoldInCombination',
    defaultMessage: 'Set price for service/service bundles sold in combination',
  },
  setDefaultBillingOptionLabel: {
    id: 'app.modules.services.billingStep.setDefaultBillingOptionLabel',
    defaultMessage: 'Set default billing option',
  },
  setOutOfTermBillingOptionLabel: {
    id: 'app.modules.services.billingStep.setOutOfTermBillingOptionLabel',
    defaultMessage: 'Set out of term billing option',
  },
  // New Package Steps Label
  membershipLabel: {
    id: 'app.modules.services.labels.membership',
    defaultMessage: 'Membership',
  },
  inventoryLabel: {
    id: 'app.modules.services.labels.inventory',
    defaultMessage: 'Inventory',
  },
  employeesLabel: {
    id: 'app.modules.services.labels.employees',
    defaultMessage: 'Employees',
  },
  servicesLabel: {
    id: 'app.modules.services.labels.services',
    defaultMessage: 'Services',
  },
  membershipPackageLabel: {
    id: 'app.modules.services.labels.membershipPackage',
    defaultMessage: 'Membership bundle',
  },
  allowMembersToPurchaseOnlineLabel: {
    id: 'app.modules.services.labels.allowMembersToPurchaseOnline',
    defaultMessage: 'Allow members to purchase online',
  },
  packageDurationLabel: {
    id: 'app.modules.services.labels.packageDuration',
    defaultMessage: 'Duration',
  },
  availabilityType: {
    id: 'app.modules.services.labels.availabilityType',
    defaultMessage: 'Availability type',
  },
  billingFrozenMembershipLabel: {
    id: 'app.modules.services.labels.billingFrozenMembership',
    defaultMessage: 'Billed if membership is frozen',
  },
  pricePerBillingLabel: {
    id: 'app.modules.services.labels.pricePerBilling',
    defaultMessage: 'Price per billing',
  },
  pricePerBillingTaxedLabel: {
    id: 'app.modules.services.labels.pricePerBillingTaxed',
    defaultMessage: 'Billing with tax',
  },
  revenueCodeLabel: {
    id: 'app.modules.services.labels.revenueCode',
    defaultMessage: 'Revenue code',
  },
  paymentOptionsSectionLabel: {
    id: 'app.modules.services.labels.paymentOptions',
    defaultMessage: 'Payment options',
  },
  billingOptionNameLabel: {
    id: 'app.common.labels.billingOptionName',
    defaultMessage: 'Name',
  },
  billingOptionTypeLabel: {
    id: 'app.modules.services.labels.billingOption.type',
    defaultMessage: 'Type',
  },
  billingOptionScheduleLabel: {
    id: 'app.modules.services.labels..billingOption.schedule',
    defaultMessage: 'Schedule',
  },
  billingOptionPerBillingLabel: {
    id: 'app.modules.services.labels..billingOption.perBilling',
    defaultMessage: 'Per Billing',
  },
  billingOptionTotalAmountLabel: {
    id: 'app.modules.services.labels..billingOption.totalAmount',
    defaultMessage: 'Total Price',
  },
  reVenueCodeTaxLabel: {
    id: 'app.modules.services.labels.revenueCodeTax',
    defaultMessage: 'Tax',
  },
  billingOptionLabel: {
    id: 'app.modules.services.labels.billingOptionLabel',
    defaultMessage: 'Billing option',
  },
  taxedAmountLabel: {
    id: 'app.modules.services.labels.taxedAmount',
    defaultMessage: 'Taxed amount',
  },
  billingFrequencyLabel: {
    id: 'app.modules.services.labels.billingFrequency',
    defaultMessage: 'Billing frequency',
  },
  paymentsLabel: {
    id: 'app.modules.services.labels.payments',
    defaultMessage: 'Payments',
  },
  paymentDaysLabel: {
    id: 'app.modules.services.labels.paymentDates',
    defaultMessage: 'Payment period days',
  },
  paymentScheduleLabel: {
    id: 'app.modules.services.labels.paymentSchedule',
    defaultMessage: 'Payment schedule',
  },
  billingAmountLabel: {
    id: 'app.modules.services.labels.billingAmount',
    defaultMessage: 'Total billing amount',
  },
  renewalsLabel: {
    id: 'app.modules.services.labels.renewals',
    defaultMessage: 'Renewals',
  },
  allowAutoRenewalLabel: {
    id: 'app.common.labels.allowAutoRenewal',
    defaultMessage: 'Allow auto-renewal',
  },
  allowSplitting: {
    id: 'app.modules.services.labels.allowSplitting',
    defaultMessage: 'Allow splitting',
  },
  noAutoRenewalLabel: {
    id: 'app.common.labels.noAutoRenewal',
    defaultMessage: 'No auto-renewal',
  },
  allowSplitEachPaymentLabel: {
    id: 'app.modules.services.labels.allowSplitEachPayment',
    defaultMessage: 'Allow to split each payment',
  },
  splitEachPaymentLabel: {
    id: 'app.modules.services.labels.splitEachPaymentLabel',
    defaultMessage: 'Split each payment',
  },
  allowDownPaymentsLabel: {
    id: 'app.modules.services.labels.allowDownPayments',
    defaultMessage: 'Allow down payments',
  },
  downPaymentsLabel: {
    id: 'app.modules.services.labels.downPaymentsLabel',
    defaultMessage: 'Down payments',
  },
  allowPaymentGracePeriodLabel: {
    id: 'app.modules.services.labels.allowPaymentGracePeriod',
    defaultMessage: 'Allow payment grace period',
  },
  paymentGracePeriodLabel: {
    id: 'app.modules.services.labels.paymentGracePeriodLabel',
    defaultMessage: 'Payment grace period',
  },
  creditCardFeeLabel: {
    id: 'app.modules.services.labels.creditCardServiceFee',
    defaultMessage: 'Credit card service fee',
  },
  creditCardFeeValue: {
    id: 'app.modules.services.labels.creditCardServiceFee.value',
    defaultMessage: 'Value',
  },
  creditCardFeeUnit: {
    id: 'app.modules.services.labels.creditCardServiceFee.unit',
    defaultMessage: 'Unit',
  },
  allowInitialFeeLabel: {
    id: 'app.modules.services.labels.allowInitialFee',
    defaultMessage: 'Initial fee',
  },
  waiverTemplateLabel: {
    id: 'app.modules.services.labels.waiverTemplate',
    defaultMessage: 'Contract template',
  },
  profileInformationLabel: {
    id: 'app.modules.services.labels.profileInformation',
    defaultMessage: 'Profile information',
  },
  allowMembershipFreezeLabel: {
    id: 'app.modules.services.labels.allowMembershipFreeze',
    defaultMessage: 'Enable future freeze',
  },
  creditCardServiceFeeEnabledLabel: {
    id: 'app.modules.services.labels.creditCardServiceFee.enabled',
    defaultMessage: 'Credit card service fee',
  },
  creditCardServiceFeeValueLabel: {
    id: 'app.modules.services.labels.creditCardServiceFee.value',
    defaultMessage: 'Value',
  },
  creditCardServiceFeeUnitLabel: {
    id: 'app.modules.services.labels.creditCardServiceFee.unit',
    defaultMessage: 'Unit',
  },
  newMemberLabel: {
    id: 'app.modules.services.labels.newMember',
    defaultMessage: 'New member',
  },
  downgradeLabel: {
    id: 'app.modules.services.labels.downgrade',
    defaultMessage: 'Downgrade',
  },
  upgradeLabel: {
    id: 'app.modules.services.labels.upgrade',
    defaultMessage: 'Upgrade',
  },
  chargeFrequencyLabel: {
    id: 'app.modules.services.labels.chargeFrequency',
    defaultMessage: 'Charge frequency',
  },
  chargeAfterLabel: {
    id: 'app.modules.services.labels.chargeAfter',
    defaultMessage: 'Charge after',
  },
  chargeAfterAmountLabel: {
    id: 'app.modules.services.labels.chargeAfterAmount',
    defaultMessage: 'Charge after amount',
  },
  chargedIfMembershipIsFrozenLabel: {
    id: 'app.modules.services.labels.chargedIfMembershipIsFrozen',
    defaultMessage: 'Charged if membership is frozen',
  },
  oneTimeLabel: {
    id: 'app.modules.services.labels.oneTime',
    defaultMessage: 'One time',
  },
  perCycleLabel: {
    id: 'app.modules.services.labels.perCycle',
    defaultMessage: 'Per cycle',
  },
  perSessionLabel: {
    id: 'app.modules.services.labels.perSession',
    defaultMessage: 'Per session',
  },
  datesAvailabilityLabel: {
    id: 'app.modules.services.labels.datesAvailability',
    defaultMessage: 'Dates of service availability',
  },
  sessionsPerBillingLabel: {
    id: 'app.modules.services.labels.sessionsPerBilling',
    defaultMessage: 'Sessions per billing',
  },
  timePerBillingLabel: {
    id: 'app.modules.services.labels.timePerBilling',
    defaultMessage: 'Time per billing',
  },
  membersLabel: {
    id: 'app.modules.services.labels.members',
    defaultMessage: 'Members',
  },
  canBeRemovedUponSaleLabel: {
    id: 'app.modules.services.labels.canBeRemovedUponSale',
    defaultMessage: 'Can be removed upon sale',
  },
  allPackagesLabel: {
    id: 'app.modules.services.labels.allPackages',
    defaultMessage: 'All bundles',
  },
  financedBillingOptionType: {
    id: 'app.modules.services.labels.billingOption.Financed',
    defaultMessage: 'Financed',
  },
  paidInFullBillingOptionType: {
    id: 'app.modules.services.labels.billingOption.PaidInFull',
    defaultMessage: 'Paid in full',
  },
  percentageCreditCardFeeUnit: {
    id: 'app.modules.services.labels.creditCardFeeUnit.percentage',
    defaultMessage: 'Percentage',
  },
  flatCreditCardFeeUnit: {
    id: 'app.modules.services.labels.creditCardFeeUnit.flat',
    defaultMessage: 'Flat',
  },
  percentageCreditCardFeeUnitSymbol: {
    id: 'app.modules.services.labels.creditCardFeeUnit.percentage.symbol',
    defaultMessage: '%',
  },
  flatCreditCardFeeUnitSymbol: {
    id: 'app.modules.services.labels.creditCardFeeUnit.flat.symbol',
    defaultMessage: '$',
  },
  oneTimePaymentLabel: {
    id: 'app.modules.services.labels.oneTimePayment',
    defaultMessage: 'One time payment',
  },
  billedAccordingToBillingSettingsLabel: {
    id: 'app.modules.services.labels.billedAccordingToBillingSettings',
    defaultMessage: 'Own billing settings',
  },
  servicePackageNameLabel: {
    id: 'app.modules.services.labels.servicePackageName',
    defaultMessage: 'Service/Bundle name',
  },
  defaultPaymentOptionLabel: {
    id: 'app.modules.services.labels.defaultPaymentOption',
    defaultMessage: 'Default payment option',
  },
  // Table labels
  pricePerBillingTableLabel: {
    id: 'app.common.tableLabels.pricePerBilling',
    defaultMessage: 'PRICE PER BILLING',
  },
  // Package type options
  recurringOption: {
    id: 'app.modules.services.options.recurring',
    defaultMessage: 'Recurring',
  },
  trialOption: {
    id: 'app.modules.services.options.trial',
    defaultMessage: 'Trial',
  },
  trialOptionFree: {
    id: 'app.modules.services.options.trialFree',
    defaultMessage: 'Trial (Free)',
  },
  // Package cost type options
  freeOption: {
    id: 'app.modules.services.options.free',
    defaultMessage: 'Free',
  },
  paidOption: {
    id: 'app.modules.services.options.paid',
    defaultMessage: 'Paid',
  },
  // options
  customAccessHoursOptions: {
    id: 'app.modules.services.options.customAccessHours',
    defaultMessage: 'Custom access hours',
  },

  // TODO probably common

  amountLabel: {
    id: 'app.common.labels.amount',
    defaultMessage: 'Amount',
  },
  durationLabel: {
    id: 'app.common.labels.duration',
    defaultMessage: 'Duration',
  },
  totalAmountLabel: {
    id: 'app.common.labels.totalAmount',
    defaultMessage: 'Total amount',
  },
  initialFeeLabel: {
    id: 'app.common.labels.initialFee',
    defaultMessage: 'Initial fee',
  },
  packageNameLabel: {
    id: 'app.common.labels.packageName',
    defaultMessage: 'Bundle name',
  },
  packageTypeLabel: {
    id: 'app.common.labels.packageType',
    defaultMessage: 'Bundle type',
  },
  packageCostTypeLabel: {
    id: 'app.common.labels.packageCostType',
    defaultMessage: 'Bundle cost type',
  },
  freezeDuration: {
    id: 'app.common.labels.freezeDuration',
    defaultMessage: 'Freeze duration',
  },
  sessionsLeft: {
    id: 'app.common.labels.sessionsLeft',
    defaultMessage: 'Sessions left',
  },
  sessionsToFreeze: {
    id: 'app.common.labels.sessionsToFreeze',
    defaultMessage: 'Sessions to freeze',
  },
  sessionsToRedeem: {
    id: 'app.common.labels.sessionsToRedeem',
    defaultMessage: 'Sessions to redeem',
  },
  sessionsUseDuringFreeze: {
    id: 'app.common.labels.sessionsUseDuringFreeze',
    defaultMessage: 'Sessions can be used during the freeze',
  },
  // Personal info labels
  photoLabel: {
    id: 'app.common.labels.photo',
    defaultMessage: 'Photo',
  },
  firstNameLabel: {
    id: 'app.common.labels.firstName',
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    id: 'app.common.labels.lastName',
    defaultMessage: 'Last name',
  },
  emailLabel: {
    id: 'app.common.labels.email',
    defaultMessage: 'Email',
  },
  personalCodeLabel: {
    id: 'app.common.labels.personalCode',
    defaultMessage: 'Personal Code (id)',
  },
  birthdayLabel: {
    id: 'app.common.labels.birthday',
    defaultMessage: 'Birthday',
  },
  phoneNumberLabel: {
    id: 'app.common.labels.phoneNumber',
    defaultMessage: 'Phone number',
  },
  addressLabel: {
    id: 'app.common.labels.address',
    defaultMessage: 'Address',
  },
  emergencyContactInfoLabel: {
    id: 'app.common.labels.emergencyContactInfo',
    defaultMessage: 'Emergency contact info',
  },
  billingInfoLabel: {
    id: 'app.common.labels.billingInfo',
    defaultMessage: 'Billing information',
  },
  insuranceInfoLabel: {
    id: 'app.common.labels.insuranceInfo',
    defaultMessage: 'Insurance information',
  },
  allergiesLabel: {
    id: 'app.common.labels.allergies',
    defaultMessage: 'Allergies',
  },
  salespersonLabel: {
    id: 'app.common.labels.salesperson',
    defaultMessage: 'Salesperson',
  },
  overrideInitialFeeLabel: {
    id: 'app.common.labels.overrideInitialFee',
    defaultMessage: 'Override initial fee',
  },
  immediatePaymentLabel: {
    id: 'app.common.labels.immediatePayment',
    defaultMessage: 'Immediate payment',
  },
  searchEmployeesLabel: {
    id: 'app.common.labels.searchEmployees',
    defaultMessage: 'Search employees',
  },
  searchServicesLabel: {
    id: 'app.common.labels.searchServices',
    defaultMessage: 'Search for services or bundles to include',
  },
  searchParticipiantsLabel: {
    id: 'app.common.labels.searchParticipiants',
    defaultMessage: 'Search for participants to include',
  },
  searchFeesLabel: {
    id: 'app.common.labels.searchFees',
    defaultMessage: 'Search for fees to include',
  },
  editLabel: {
    id: 'app.common.labels.edit',
    defaultMessage: 'Edit',
  },
  // Table labels
  bulkEditTableLabel: {
    id: 'app.common.tableLabels.bulkEdit',
    defaultMessage: 'BULK EDIT',
  },
  nameTableLabel: {
    id: 'app.common.tableLabels.name',
    defaultMessage: 'NAME',
  },
  clubTableLabel: {
    id: 'app.common.tableLabels.club',
    defaultMessage: 'CLUB',
  },
  clubsTableLabel: {
    id: 'app.common.tableLabels.clubs',
    defaultMessage: 'CLUBS',
  },
  packageTypeTableLabel: {
    id: 'app.common.tableLabels.packageType',
    defaultMessage: 'BUNDLE TYPE',
  },
  employeesTableLabel: {
    id: 'app.common.tableLabels.employees',
    defaultMessage: 'EMPLOYEES',
  },
  servicesTableLabel: {
    id: 'app.common.tableLabels.services',
    defaultMessage: 'SERVICES',
  },
  itemsTableLabel: {
    id: 'app.common.tableLabels.items',
    defaultMessage: 'ITEMS',
  },
  actionsTableLabel: {
    id: 'app.common.tableLabels.name',
    defaultMessage: 'ACTIONS',
  },
  // titles
  addClubsTitle: {
    id: 'app.common.titles.addClubs',
    defaultMessage: 'Add clubs',
  },
  deleteClubTitle: {
    id: 'app.common.titles.deleteClub',
    defaultMessage: 'Delete Club',
  },
  deleteClubsTitle: {
    id: 'app.common.titles.deleteClubs',
    defaultMessage: 'Delete Clubs',
  },
  // options
  excludeOption: {
    id: 'app.common.options.exclude',
    defaultMessage: 'Exclude',
  },
  includeOption: {
    id: 'app.common.options.include',
    defaultMessage: 'Include',
  },
  requiredOption: {
    id: 'app.common.options.required',
    defaultMessage: 'Required',
  },
  recommendedOption: {
    id: 'app.common.options.recommended',
    defaultMessage: 'Recommended',
  },
  optionalOption: {
    id: 'app.common.options.optional',
    defaultMessage: 'Optional',
  },
  customOption: {
    id: 'app.common.options.custom',
    defaultMessage: 'Custom',
  },
  familyMemberOption: {
    id: 'app.common.options.familyMember',
    defaultMessage: 'Family member',
  },
  personalTrainingOption: {
    id: 'app.common.options.personalTraining',
    defaultMessage: 'Personal training',
  },
  dayCareOption: {
    id: 'app.common.options.dayCare',
    defaultMessage: 'Day care',
  },
  outOfTermSectionLabel: {
    id: 'app.modules.services.labels.outOfTerm',
    defaultMessage: 'Out of term',
  },
});
