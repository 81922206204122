import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import cx from 'classnames';

import { ReactComponent as CakeIcon } from 'img/icons/cake.svg';
import { IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';
import GuardianInfo from './GuardianInfo';
import { ActionItem, ActionsMenu } from 'common/components';
import { FormattedMessage } from 'react-intl';
import menuItems from 'common/messages/menuItems';

interface IOtherBlockProps {
  member: IKidZoneAutocompleteCustomerImt;
  isSmallScreen: boolean;
  onAddBirthday: (id: number) => void;
  className?: string;
}

const useStyles = makeStyles(() => ({
  otherBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cakeIcon: {
    width: '16px',
    height: '16px',
  },
}));

const OtherBlock = (props: IOtherBlockProps): JSX.Element => {
  const { isSmallScreen, member, className, onAddBirthday } = props;

  const classes = useStyles();

  return (
    <Box className={cx(className, classes.otherBlock)}>
      <GuardianInfo member={member} isSmallScreen={isSmallScreen} />
      <ActionsMenu horizontal>
        <ActionItem
          icon={<CakeIcon className={classes.cakeIcon} />}
          onClick={() => onAddBirthday(member.get('id'))}
        >
          <FormattedMessage {...menuItems.addDOB} />
        </ActionItem>
      </ActionsMenu>
    </Box>
  );
};

export default OtherBlock;
