import { Action } from 'redux';
import { v4 as uuid } from 'uuid';
import { batch } from 'react-redux';
import { createAction, PrepareAction } from '@reduxjs/toolkit';
import { List } from 'immutable';

import Services from 'services/network';
// state
import * as actionTypes from 'common/components/PersonProfile/state/constants';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
// interfaces
import { ITableParams } from 'common/interfaces/table';
import {
  IBalanceHistoryItem,
  IBilling,
  ICallNoteDto,
  ICampaigns,
  ICheckInHistory,
  IDocumentsHistory,
  IEmail,
  IFallthroughCapture,
  IFallthroughCaptureForm,
  IFetchPastDues,
  ILeadCallHistoryDto,
  IModificationHistory,
  IModificationHistoryItem,
  INote,
  INoteCodeRequestPayload,
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionUpdateDTO,
  IPastDue,
  IPayment,
  IPaymentAccount,
  IPersonDocuments,
  IPersonSalesInfo,
  IProfileAdditionalPayload,
  IProfileInfo,
  IReminderDto,
  IReminderRecent,
  IRewardsHistoryItem,
  ISendEmailRequestPayload,
  ISendSmsRequestPayload,
  ISMS,
  ITag,
  ITopUpRewardsData,
} from 'common/components/PersonProfile/interfaces';
import { ISuccessResponse } from 'common/interfaces/http';
import { GeneralThunkAction } from 'common/state/interfaces';
import { IAttachment, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { IPaginatedData } from 'common/interfaces/pagination';
import { AlertCondition, IRemindLaterDangerAlertRequestPayload } from 'common/interfaces/alerts';
// constants
import {
  UPDATE_PERSON_PHOTO,
  UPDATE_PERSON_PHOTO_LOADING,
} from 'common/components/PersonProfile/constants';
import { PeakModules } from 'common/constants/peakModules';
import { INoteCodeDictionaryItem } from 'common/interfaces/dictionary';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IInvoiceDetailsDto, PaymentAccountType } from 'common/interfaces/invoices';
import { ICampaign, ILeadEmailHistoryDto, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import { ActionResult } from 'common/constants';
import {
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
} from 'common/interfaces/additionalInfo';
import { IAppointmentAlertActionDTO, IMemberAlert } from 'modules/front-desk/interfaces';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { IActionResultData } from 'common/constants/globalConstants';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { printHTML } from 'common/state/printHTML/actions';
import { fetchLeadProfileSuccessAction } from 'modules/crm/state/leads/actions';
import { IRegisterPosClubItem } from 'modules/pos-settings/interfaces/register';
import { IAllergiesFormValues } from 'modules/members/interfaces';
import { fetchOpenInvoicesListAction, selectInvoice } from '../../../state/invoice/actions';

// Custom create action
interface PersonPrepAction<T> extends Action<string> {
  payload: T;
  meta: { personId: number };
}

interface PersonPrepActionFn<T> {
  (payload: T, personId: number): PersonPrepAction<T>;
}

const personPrep = <T>(payload: T, personId: number) => ({
  payload,
  meta: { personId: Number(personId) },
});

export const createPersonPrepAction = <T>(type: string): PersonPrepActionFn<T> =>
  createAction<PrepareAction<T>>(type, personPrep) as PersonPrepActionFn<T>;

// Profile info

export const fetchProfileInfoAction = createPersonPrepAction<IProfileInfo>(
  actionTypes.FETCH_PROFILE_INFO,
);
export const fetchProfileInfoLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PROFILE_INFO_LOADING,
);

export const fetchProfileInfoView = (personId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchProfileInfoLoadingAction(true, personId));

    try {
      let profile;

      switch (module) {
        case PeakModules.FrontDesk:
          profile = await Services.FrontDesk.getMemberInfoView(personId);
          break;
        case PeakModules.Members:
          profile = await Services.Members.getMemberInfoView(personId);
          break;
        case PeakModules.Crm:
          profile = await Services.Leads.getLeadInfoView(personId);
          break;
        default:
          profile = await Services.PTLeads.getLeadInfoView(personId);
      }

      dispatch(fetchProfileInfoAction(profile, personId));

      // TODO refactor after lead profile will be moved to persons state
      if (module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm) {
        dispatch(fetchLeadProfileSuccessAction(profile));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchProfileInfoLoadingAction(false, personId));
    }
  };
};

// Member alerts

export const resolvePastDueAlert = createPersonPrepAction<AlertCondition.PastDuePayments>(
  actionTypes.RESOLVE_PAST_DUE_ALERT,
);

export const fetchMemberAlertsAction = createPersonPrepAction<IMemberAlert[]>(
  actionTypes.FETCH_PERSON_ALERTS,
);
export const fetchMemberAlertsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_ALERTS_LOADING,
);

export const fetchMemberAlerts = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchMemberAlertsLoadingAction(true, personId));
    try {
      const memberAlerts = await Services.FrontDeskAlerts.getMemberAlerts(personId);
      dispatch(fetchMemberAlertsAction(memberAlerts, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchMemberAlertsLoadingAction(false, personId));
    }
  };
};

// Updating profile info

export const updatePersonsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_LOADING,
);
const updateProfileInfoAction = createPersonPrepAction<{
  profile: IProfileInfo;
  alertCondition: AlertCondition;
}>(actionTypes.UPDATE_PROFILE_INFO);

export const resetProfileUpdateActionResult = createPersonPrepAction(
  actionTypes.RESET_PERSON_UPDATE_ACTION_RESULT,
);

export const updatePersonsProfileInfo = (
  personId: number,
  profileInfo: IProfileInfo,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));
    try {
      const profile = await Services.FrontDeskAlerts.updatePartialMemberInfo(personId, profileInfo);

      dispatch(fetchRecentModificationHistory(personId, PeakModules.FrontDesk));
      dispatch(updateProfileInfoAction({ profile, alertCondition }, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const ignorePersonAlertAction = createPersonPrepAction<AlertCondition>(
  actionTypes.IGNORE_PERSON_ALERT,
);

export const ignoreAlert = (
  personId: number,
  alertCondition: AlertCondition,
  alertId: string,
  documentId?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.ignoreAlert(personId, alertId);

      if (documentId) {
        dispatch(ignorePersonUnsignedWaiverAction({ alertCondition, documentId }, personId));
      } else {
        dispatch(ignorePersonAlertAction(alertCondition, personId));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const ignorePersonAppointmentAlertAction = createPersonPrepAction<IAppointmentAlertActionDTO>(
  actionTypes.REMIND_LATER_PERSON_APPOINTMENT_ALERT,
);

export const ignorePersonAppointmentAlert = (
  personId: number,
  data: IAppointmentAlertActionDTO,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.ignoreAppointmentAlert(personId, data);

      dispatch(ignorePersonAppointmentAlertAction(data, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const remindAlertLaterAction = createPersonPrepAction<AlertCondition>(
  actionTypes.REMIND_LATER_PERSON_ALERT,
);

export const setRemindLaterActionResult = createPersonPrepAction<ActionResult>(
  actionTypes.SET_REMIND_LATER_ACTION_RESULT,
);

export const remindLaterDangerAlert = ({
  personId,
  alertCondition,
  alertId,
  noteCodeId,
  text,
}: IRemindLaterDangerAlertRequestPayload): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.remindLaterDangerAlert(personId, alertId, noteCodeId, text);

      batch(() => {
        dispatch(setRemindLaterActionResult(ActionResult.SUCCESS_ACTION, personId));
        dispatch(remindAlertLaterAction(alertCondition, personId));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

export const remindLaterAlert = (
  personId: number,
  alertCondition: AlertCondition,
  alertId: string,
  documentId?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.remindLaterAlert(personId, alertId);

      if (documentId) {
        dispatch(remindLaterPersonUnsignedWaiverAction({ alertCondition, documentId }, personId));
      } else {
        dispatch(remindAlertLaterAction(alertCondition, personId));
        dispatch(setRemindLaterActionResult(ActionResult.SUCCESS_ACTION, personId));
      }
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const remindLaterPersonAppointmentAlertAction = createPersonPrepAction<IAppointmentAlertActionDTO>(
  actionTypes.REMIND_LATER_PERSON_APPOINTMENT_ALERT,
);

export const remindLaterPersonAppointmentAlert = (
  personId: number,
  data: IAppointmentAlertActionDTO,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.remindLaterAppointmentAlert(personId, data);

      dispatch(remindLaterPersonAppointmentAlertAction(data, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const resolvePersonUnsignedWaiverAction = createPersonPrepAction<{
  alertCondition: AlertCondition;
  documentId: string;
}>(actionTypes.RESOLVE_PERSON_UNSIGNED_WAIVER);

const remindLaterPersonUnsignedWaiverAction = createPersonPrepAction<{
  alertCondition: AlertCondition;
  documentId: string;
}>(actionTypes.REMIND_LATER_PERSON_UNSIGNED_WAIVER);

const ignorePersonUnsignedWaiverAction = createPersonPrepAction<{
  alertCondition: AlertCondition;
  documentId: string;
}>(actionTypes.IGNORE_PERSON_UNSIGNED_WAIVER);

export const resolvePersonUnsignedWaiver = (
  personId: number,
  documentId: string,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(resolvePersonUnsignedWaiverAction({ alertCondition, documentId }, personId));
  };
};

export const resolvePersonMissingBillingInfoAction = createPersonPrepAction<AlertCondition>(
  actionTypes.RESOLVE_PERSON_MISSING_BILLING_INFO,
);

const updatePersonEmergencyContactInfoAction = createPersonPrepAction<AlertCondition>(
  actionTypes.UPDATE_PERSON_EMERGENCY_CONTACT_INFO,
);

export const updateEmergencyContactInfo = (
  personId: number,
  emergencyInfo: ICustomerEmergencyContactDto,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.updateEmergencyContactInfo(personId, emergencyInfo);
      dispatch(updatePersonEmergencyContactInfoAction(alertCondition, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const updatePersonInsuranceInfoAction = createPersonPrepAction<AlertCondition>(
  actionTypes.UPDATE_PERSON_INSURANCE_INFO,
);

export const updateInsuranceInfo = (
  personId: number,
  insuranceInfo: ICustomerInsuranceDto,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.updateInsuranceInfo(personId, insuranceInfo);
      dispatch(updatePersonInsuranceInfoAction(alertCondition, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const updatePersonSalespersonAction = createPersonPrepAction<AlertCondition>(
  actionTypes.UPDATE_PERSON_SALESPERSON,
);

export const updateSalesperson = (
  personId: number,
  salespersonId: string,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.updateSalesperson(personId, salespersonId);
      dispatch(updatePersonSalespersonAction(alertCondition, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

export const updatePersonMembershipPackageAction = createPersonPrepAction<AlertCondition>(
  actionTypes.UPDATE_PERSON_MEMBERSHIP_PACKAGE,
);

const updatePersonAllergiesAction = createPersonPrepAction<AlertCondition>(
  actionTypes.UPDATE_PERSON_ALLERGIES,
);

export const updateAllergies = (
  personId: number,
  allergies: IAllergiesFormValues,
  alertCondition: AlertCondition,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));
    try {
      await Services.FrontDeskAlerts.updateAllergies(personId, allergies);
      dispatch(updatePersonAllergiesAction(alertCondition, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const resolvePersonNoteAction = createPersonPrepAction<AlertCondition>(
  actionTypes.RESOLVE_PERSON_NOTE_ALERT,
);

export const resolvePersonNote = (
  personId: number,
  alertCondition: AlertCondition,
  alertId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      await Services.FrontDeskAlerts.resolveNote(personId, alertId);
      dispatch(resolvePersonNoteAction(alertCondition, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const updatePersonPhotoAction = createPersonPrepAction<IStoredFileSimpleDto>(UPDATE_PERSON_PHOTO);
const updatePersonPhotoActionLoading = createPersonPrepAction<boolean>(UPDATE_PERSON_PHOTO_LOADING);
export const updatePersonPhoto = (
  memberId: number,
  image: IStoredFileSimpleDto,
  module: PeakModules = PeakModules.Members,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      if (module === PeakModules.FrontDesk) {
        await Services.FrontDesk.uploadPhoto(memberId, image);
      } else if (module === PeakModules.KidZone) {
        await Services.KidZone.uploadPhoto(memberId, image);
      } else {
        await Services.Members.uploadPhoto(memberId, image);
      }

      dispatch(fetchRecentModificationHistory(memberId, module));
      dispatch(updatePersonPhotoAction(image, memberId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    }
  };
};

export const deletePersonPhoto = (
  memberId: number,
  module: PeakModules = PeakModules.Members,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonPhotoActionLoading(true, memberId));
    try {
      if (module === PeakModules.FrontDesk) {
        await Services.FrontDesk.deletePhoto(memberId);
      } else if (module === PeakModules.KidZone) {
        await Services.KidZone.deletePhoto(memberId);
      } else {
        await Services.Members.deletePhoto(memberId);
      }
      dispatch(updatePersonPhotoAction(null, memberId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonPhotoActionLoading(false, memberId));
    }
  };
};

// Tags

const updatePersonTagsAction = createPersonPrepAction<string[]>(actionTypes.UPDATE_PERSON_TAGS);
const updatePersonTagsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_TAGS_LOADING,
);
export const updatePersonTags = (
  personId: number,
  tagsList: List<ITag>,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonTagsLoadingAction(true, personId));
    try {
      let profile;

      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.setTags(personId, tagsList);
          profile = await Services.FrontDesk.getProfileInfo(personId);
          break;
        case PeakModules.Members:
          await Services.Members.setTags(personId, tagsList);
          profile = await Services.Members.getProfileInfo(personId);
          break;
        case PeakModules.Crm:
          await Services.Leads.setTags(personId, tagsList);
          profile = await Services.Leads.getLead(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.setTags(personId, tagsList);
          profile = await Services.PTLeads.getLead(personId);
          break;
        default:
      }

      dispatch(updatePersonTagsAction(profile.tags, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonTagsLoadingAction(false, personId));
    }
  };
};

// Fallthrough Capture

const fetchFallthroughCaptureAction = createPersonPrepAction<IFallthroughCapture | null>(
  actionTypes.FETCH_PERSON_FALLTHROUGH_CAPTURE,
);

const fetchFallthroughCaptureLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_FALLTHROUGH_CAPTURE_LOADING,
);

export const fetchPersonFallthroughCapture = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchFallthroughCaptureLoadingAction(true, personId));

    try {
      let fallthroughCapture: IFallthroughCapture | null;

      switch (module) {
        case PeakModules.Members:
          fallthroughCapture = await Services.Members.getFallthroughCapture(personId);
          break;
        case PeakModules.Crm:
          fallthroughCapture = await Services.Leads.getFallthroughCapture(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          fallthroughCapture = await Services.PTLeads.getFallthroughCapture(personId);
          break;
        default:
          fallthroughCapture = null;
          break;
      }

      dispatch(fetchFallthroughCaptureAction(fallthroughCapture, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchFallthroughCaptureLoadingAction(false, personId));
    }
  };
};

const setFallthroughResultSuccessAction = createPersonPrepAction<ISuccessResponse>(
  actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE_ACTION_SUCCESS,
);

const setFallthroughCaptureAction = createPersonPrepAction<IFallthroughCaptureForm>(
  actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE,
);

const setFallthroughCaptureLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.SET_PERSON_FALLTHROUGH_CAPTURE_LOADING,
);

export const setPersonFallthroughCapture = (
  personId: number,
  formValues: IFallthroughCaptureForm,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setFallthroughCaptureLoadingAction(true, personId));

    try {
      let fallthroughCapture: IFallthroughCapture | null;

      switch (module) {
        case PeakModules.Members:
          fallthroughCapture = await Services.Members.setFallthroughCapture(personId, formValues);
          break;
        case PeakModules.Crm:
          fallthroughCapture = await Services.Leads.setFallthroughCapture(personId, formValues);
          break;
        case PeakModules.PersonalTrainingCrm:
          fallthroughCapture = await Services.PTLeads.setFallthroughCapture(personId, formValues);
          break;
        default:
          fallthroughCapture = null;
          break;
      }

      if (!fallthroughCapture) {
        throw new Error('Invalid FallthroughCapture');
      }

      dispatch(setFallthroughCaptureAction(fallthroughCapture, personId));
      dispatch(setFallthroughResultSuccessAction({ success: !!fallthroughCapture.id }, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setFallthroughCaptureLoadingAction(false, personId));
    }
  };
};

const deleteFallthroughCaptureAction = createPersonPrepAction<IFallthroughCapture>(
  actionTypes.DELETE_PERSON_FALLTHROUGH_CAPTURE,
);

const deleteFallthroughCaptureLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.DELETE_PERSON_FALLTHROUGH_CAPTURE_LOADING,
);

export const deletePersonFallthroughCapture = (
  personId: number,
  captureId: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deleteFallthroughCaptureLoadingAction(true, personId));

    try {
      switch (module) {
        case PeakModules.Members:
          await Services.Members.deleteFallthroughCapture(personId, captureId);
          break;
        case PeakModules.Crm:
          await Services.Leads.deleteFallthroughCapture(personId, captureId);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.deleteFallthroughCapture(personId, captureId);
          break;
        default:
      }

      dispatch(deleteFallthroughCaptureAction(null, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deleteFallthroughCaptureLoadingAction(false, personId));
    }
  };
};

// Notes

const fetchPersonNotesAction = createPersonPrepAction<IPaginatedData<INote>>(
  actionTypes.FETCH_PERSON_NOTES,
);
const fetchPersonNotesLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_NOTES_LOADING,
);
export const fetchPersonNotes = (customerId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonNotesLoadingAction(true, customerId));
    try {
      let response: IPaginatedData<INote> | null;

      switch (module) {
        case PeakModules.FrontDesk:
          response = await Services.FrontDesk.getAllNotes(customerId);
          break;
        case PeakModules.Members:
          response = await Services.Members.getAllNotes(customerId);
          break;
        case PeakModules.KidZone:
          response = await Services.KidZone.getAllNotes(customerId);
          break;
        default:
          response = null;
          break;
      }

      if (!response) {
        throw new Error('Invalid Notes');
      }

      dispatch(fetchPersonNotesAction(response, customerId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonNotesLoadingAction(false, customerId));
    }
  };
};

const createPersonNoteAction = createPersonPrepAction<INote>(actionTypes.ADD_PERSON_NOTE);
const addPersonNoteLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_NOTE_LOADING,
);
export const resetPersonCreatedNoteAction = createPersonPrepAction(
  actionTypes.RESET_PERSON_CREATED_NOTE,
);
export const createPersonNote = (
  requestData: INoteCodeRequestPayload,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    if (requestData.customerId) {
      dispatch(addPersonNoteLoadingAction(true, requestData.customerId));
      try {
        let createdNote: INote;

        switch (module) {
          case PeakModules.FrontDesk:
            createdNote = await Services.FrontDesk.createNote(requestData);
            break;
          case PeakModules.KidZone:
            createdNote = await Services.KidZone.createNote(requestData);
            break;
          default:
            createdNote = await Services.Members.createNote(requestData);
        }

        dispatch(createPersonNoteAction(createdNote, requestData.customerId));
      } catch (e) {
        dispatch(enqueueErrorNotification(e));
      } finally {
        dispatch(addPersonNoteLoadingAction(false, requestData.customerId));
      }
    }
  };
};

const updatePersonNoteLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_NOTE_LOADING,
);
const updatePersonNoteAction = createPersonPrepAction<INote>(actionTypes.UPDATE_PERSON_NOTE);

export const updatePersonNoteById = (
  requestData: INoteCodeRequestPayload,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    if (requestData.customerId) {
      dispatch(updatePersonNoteLoadingAction(true, requestData.customerId));
      try {
        let updatedNote: INote;

        switch (module) {
          case PeakModules.FrontDesk:
            updatedNote = await Services.FrontDesk.updateNote(requestData);
            break;
          case PeakModules.KidZone:
            updatedNote = await Services.KidZone.updateNote(requestData);
            break;
          default:
            updatedNote = await Services.Members.updateNote(requestData);
        }

        dispatch(updatePersonNoteAction(updatedNote, requestData.customerId));
      } catch (e) {
        dispatch(enqueueErrorNotification(e));
      } finally {
        dispatch(updatePersonNoteLoadingAction(false, requestData.customerId));
      }
    }
  };
};

const deletePersonNoteLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.DELETE_PERSON_NOTE_LOADING,
);
const deletePersonNoteAction = createPersonPrepAction<INote>(actionTypes.DELETE_PERSON_NOTE);

export const deletePersonNote = (
  id: string,
  customerId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deletePersonNoteLoadingAction(true, customerId));
    try {
      let deletedNote: INote;

      switch (module) {
        case PeakModules.FrontDesk:
          deletedNote = await Services.FrontDesk.deleteNote(customerId, id);
          break;
        case PeakModules.KidZone:
          deletedNote = await Services.KidZone.deleteNote(customerId, id);
          break;
        default:
          deletedNote = await Services.Members.deleteNote(customerId, id);
      }

      dispatch(deletePersonNoteAction(deletedNote, customerId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(deletePersonNoteLoadingAction(false, customerId));
    }
  };
};

// Note codes

const fetchNoteCodes = createPersonPrepAction<IPaginatedData<INoteCodeDictionaryItem>>(
  actionTypes.FETCH_NOTE_CODES,
);
export const resetNoteCodes = createPersonPrepAction(actionTypes.RESET_NOTE_CODES);
const fetchNoteCodesIsLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_NOTE_CODES_IS_LOADING,
);

export const searchNoteCodesThunk = (search: string, customerId): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchNoteCodesIsLoading(true, customerId));
    try {
      const noteCodes = await Services.DictionaryLists.searchNoteCodesDictionary(search);
      dispatch(fetchNoteCodes(noteCodes, customerId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchNoteCodesIsLoading(false, customerId));
    }
  };
};

// Suggested products
const fetchPersonSuggestedProductsAction = createPersonPrepAction<ISimpleClubInventoryItem[]>(
  actionTypes.FETCH_PERSON_SUGGESTED_PRODUCTS,
);
const fetchPersonSuggestedProductsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SUGGESTED_PRODUCTS_LOADING,
);
export const updateSocketSuggestedProductsAction = createAction<IRegisterPosClubItem[]>(
  actionTypes.UPDATE_SOCKET_SUGGESTED_PRODUCTS,
);

export const fetchPersonSuggestedProducts = (
  personId: number,
  clubId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonSuggestedProductsLoadingAction(true, personId));
    try {
      const products = await Services.Persons.getProducts(clubId);
      dispatch(fetchPersonSuggestedProductsAction(products, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonSuggestedProductsLoadingAction(false, personId));
    }
  };
};

// Payments

const fetchPersonPaymentsAction = createPersonPrepAction<IPaginatedData<IPayment>>(
  actionTypes.FETCH_PERSON_PAYMENTS,
);
const fetchPersonPaymentsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_PAYMENTS_LOADING,
);

export const fetchPersonPayments = (
  personId: number,
  module: PeakModules,
  tableParams: ITableParams<IPayment>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonPaymentsLoadingAction(true, personId));
    try {
      let payments;

      switch (module) {
        case PeakModules.FrontDesk:
          payments = await Services.FrontDesk.getPersonPayments(personId, tableParams);
          break;
        default:
          payments = await Services.Members.getPersonPayments(personId, tableParams);
      }

      dispatch(fetchPersonPaymentsAction(payments, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonPaymentsLoadingAction(false, personId));
    }
  };
};

export const voidPersonPaymentActionResult = createPersonPrepAction<ActionResult | null>(
  actionTypes.VOID_PERSON_INVOICE_ACTION_RESULT,
);
const voidPersonPaymentLoading = createPersonPrepAction<boolean>(
  actionTypes.VOID_PERSON_INVOICE_LOADING,
);

export const voidPersonInvoice = (
  personId: number,
  invoiceId: string,
  reason: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(voidPersonPaymentLoading(true, personId));
    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.voidPersonInvoice(personId, invoiceId, reason);
          break;
        default:
          await Services.Members.voidPersonInvoice(personId, invoiceId, reason);
      }

      dispatch(fetchRecentPersonPayments(personId, module));

      dispatch(voidPersonPaymentActionResult(ActionResult.SUCCESS_ACTION, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(voidPersonPaymentLoading(false, personId));
    }
  };
};

const fetchRecentPersonPaymentsAction = createPersonPrepAction<IPayment[]>(
  actionTypes.FETCH_RECENT_PERSON_PAYMENTS,
);
const fetchRecentPersonPaymentsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_RECENT_PERSON_PAYMENTS_LOADING,
);

export const fetchRecentPersonPayments = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentPersonPaymentsLoadingAction(true, personId));

    try {
      let payments;

      switch (module) {
        case PeakModules.FrontDesk:
          payments = await Services.FrontDesk.getRecentPersonPayments(personId);
          break;
        case PeakModules.Members:
          payments = await Services.Members.getRecentPersonPayments(personId);
          break;
        default:
          payments = [];
      }

      dispatch(fetchRecentPersonPaymentsAction(payments, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentPersonPaymentsLoadingAction(false, personId));
    }
  };
};

const fetchPaymentDetailsAction = createPersonPrepAction<IInvoiceDetailsDto>(
  actionTypes.FETCH_PAYMENT_DETAILS,
);
const fetchPaymentDetailsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PAYMENT_DETAILS_LOADING,
);

export const fetchPaymentDetails = (
  personId: number,
  invoiceId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentDetailsLoadingAction(true, personId));

    try {
      let paymentDetails;

      switch (module) {
        case PeakModules.FrontDesk:
          paymentDetails = await Services.FrontDesk.getPaymentDetails(personId, invoiceId);
          break;
        default:
          paymentDetails = await Services.Members.getPaymentDetails(personId, invoiceId);
      }

      dispatch(fetchPaymentDetailsAction(paymentDetails, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentDetailsLoadingAction(false, personId));
    }
  };
};

// SMS
const fetchPersonSMSAction = createPersonPrepAction<ISMS[]>(actionTypes.FETCH_PERSON_SMS_SUCCESS);
const fetchPersonSMSLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SMS_LOADING,
);
export const fetchPersonSMS = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonSMSLoadingAction(true, personId));

    try {
      let response: ISMS[];

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadSMS(personId);
      } else {
        response = await Services.PTLeads.getLeadSMS(personId);
      }

      dispatch(fetchPersonSMSAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonSMSLoadingAction(false, personId));
    }
  };
};

const fetchPersonSMSDetailsAction = createPersonPrepAction<ISMS>(
  actionTypes.FETCH_PERSON_SMS_DETAILS_SUCCESS,
);
const fetchPersonSMSDetailsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SMS_DETAILS_LOADING,
);
export const resetPersonSMSDetailsAction = createPersonPrepAction<boolean>(
  actionTypes.RESET_PERSON_SMS_DETAILS,
);

export const fetchPersonSMSDetails = (
  personId: number,
  smsId: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonSMSDetailsLoadingAction(true, personId));

    try {
      let response: ISMS;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadSMSById(personId, smsId);
      } else {
        response = await Services.PTLeads.getLeadSMSById(personId, smsId);
      }

      dispatch(fetchPersonSMSDetailsAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonSMSDetailsLoadingAction(false, personId));
    }
  };
};

const fetchPersonSMSHistoryAction = createPersonPrepAction<IPaginatedData<ISMS>>(
  actionTypes.FETCH_PERSON_SMS_HISTORY_SUCCESS,
);
const fetchPersonSMSHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SMS_HISTORY_LOADING,
);
export const fetchPersonSMSHistory = (
  personId: number,
  module: PeakModuleForNewPersonType,
  tableParams: ITableParams<ISMS>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonSMSHistoryLoadingAction(true, personId));

    try {
      let response: IPaginatedData<ISMS>;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadSMSHistory(personId, tableParams);
      } else {
        response = await Services.PTLeads.getLeadSMSHistory(personId, tableParams);
      }

      dispatch(fetchPersonSMSHistoryAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonSMSHistoryLoadingAction(false, personId));
    }
  };
};

const addPersonSMSAction = createPersonPrepAction<ISMS>(actionTypes.ADD_PERSON_SMS_SUCCESS);
export const resetPersonCreateSmsActionResult = createPersonPrepAction(
  actionTypes.RESET_PERSON_CREATE_SMS_ACTION_RESULT,
);
const addPersonSMSLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_SMS_LOADING,
);
export const addPersonSMS = (
  personId: number,
  sms: ISendSmsRequestPayload,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonSMSLoadingAction(true, personId));
    try {
      let response: ISMS;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.addLeadSMS(personId, sms);
      } else {
        response = await Services.PTLeads.addLeadSMS(personId, sms);
      }

      dispatch(addPersonSMSAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(addPersonSMSLoadingAction(false, personId));
    }
  };
};

// Reminders

const fetchPersonRemindersAction = createPersonPrepAction<IPaginatedData<IReminderDto>>(
  actionTypes.FETCH_PERSON_REMINDERS_SUCCESS,
);
const fetchPersonRemindersLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_REMINDERS_LOADING,
);
export const resetPersonRemindersAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_REMINDERS_LOADING,
);
export const fetchPersonReminders = (
  personId: number,
  tableParams: ITableParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonRemindersLoadingAction(true, personId));

    try {
      let response: IPaginatedData<IReminderDto>;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getReminders(personId, tableParams);
      } else {
        response = await Services.PTLeads.getReminders(personId, tableParams);
      }

      dispatch(fetchPersonRemindersAction(response, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonRemindersLoadingAction(false, personId));
    }
  };
};

// Reminder

const fetchPersonReminderAction = createPersonPrepAction<IReminderDto>(
  actionTypes.FETCH_PERSON_REMINDER_SUCCESS,
);
const fetchPersonReminderLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_REMINDER_LOADING,
);
export const resetPersonReminderAction = createPersonPrepAction(
  actionTypes.FETCH_PERSON_REMINDER_LOADING,
);
export const fetchPersonReminder = (
  personId: number,
  reminderId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonReminderLoadingAction(true, personId));

    try {
      let response: IReminderDto;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getReminder(personId, reminderId);
      } else {
        response = await Services.PTLeads.getReminder(personId, reminderId);
      }

      dispatch(fetchPersonReminderAction(response, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonReminderLoadingAction(false, personId));
    }
  };
};

// Recent Reminders

const fetchPersonRecentRemindersAction = createPersonPrepAction<IReminderRecent[]>(
  actionTypes.FETCH_PERSON_RECENT_REMINDERS_SUCCESS,
);
const fetchPersonRecentRemindersLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_RECENT_REMINDERS_LOADING,
);
export const fetchPersonRecentReminders = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonRecentRemindersLoadingAction(true, personId));

    try {
      let reminders: IReminderRecent[];

      if (module === PeakModules.Crm) {
        reminders = await Services.Leads.getRecentReminders(personId);
      } else {
        reminders = await Services.PTLeads.getRecentReminders(personId);
      }

      dispatch(fetchPersonRecentRemindersAction(reminders, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonRecentRemindersLoadingAction(false, personId));
    }
  };
};

const addPersonReminderAction = createPersonPrepAction<IReminderRecent>(
  actionTypes.ADD_PERSON_REMINDER,
);
const addPersonReminderLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_REMINDER_LOADING,
);
export const resetAddPersonReminderAction = createPersonPrepAction(
  actionTypes.RESET_ADD_PERSON_REMINDER,
);

export const addPersonReminder = (
  personId: number,
  formData: IReminderRecent,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonReminderLoadingAction(true, personId));

    try {
      let reminder: IReminderRecent;

      if (module === PeakModules.Crm) {
        reminder = await Services.Leads.addReminder(personId, formData);
      } else {
        reminder = await Services.PTLeads.addReminder(personId, formData);
      }

      dispatch(addPersonReminderAction(reminder, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addPersonReminderLoadingAction(false, personId));
    }
  };
};

// Recent Emails
const fetchPersonRecentEmailsAction = createPersonPrepAction<IEmail[]>(
  actionTypes.FETCH_PERSON_RECENT_EMAILS_SUCCESS,
);
const fetchPersonRecentEmailsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_RECENT_EMAILS_LOADING,
);
export const updateSocketPersonRecentEmailsAction = createPersonPrepAction<IEmail>(
  actionTypes.SOCKET_UPDATE_PERSON_RECENT_EMAILS,
);

export const fetchPersonRecentEmails = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonRecentEmailsLoadingAction(true, personId));

    try {
      let emails: IEmail[];

      if (module === PeakModules.Crm) {
        emails = await Services.Leads.getLeadRecentEmails(personId);
      } else {
        emails = await Services.PTLeads.getLeadRecentEmails(personId);
      }

      dispatch(fetchPersonRecentEmailsAction(emails, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonRecentEmailsLoadingAction(false, personId));
    }
  };
};

// Emails
const fetchPersonEmailsAction = createPersonPrepAction<IPaginatedData<ILeadEmailHistoryDto>>(
  actionTypes.FETCH_PERSON_EMAILS_SUCCESS,
);
const fetchPersonEmailsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_EMAILS_LOADING,
);
export const resetPersonEmailsAction = createPersonPrepAction(actionTypes.RESET_PERSON_EMAILS);

export const fetchPersonEmails = (
  personId: number,
  tableParams: ITableParams<ILeadEmailHistoryDto>,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonEmailsLoadingAction(true, personId));

    try {
      let response: IPaginatedData<ILeadEmailHistoryDto>;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadEmails(personId, tableParams);
      } else {
        response = await Services.PTLeads.getLeadEmails(personId, tableParams);
      }

      dispatch(fetchPersonEmailsAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonEmailsLoadingAction(false, personId));
    }
  };
};

const fetchPersonEmailAction = createPersonPrepAction<IEmail>(
  actionTypes.FETCH_PERSON_EMAIL_SUCCESS,
);
const fetchPersonEmailLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_EMAIL_LOADING,
);
export const resetPersonEmail = createPersonPrepAction(actionTypes.RESET_PERSON_EMAIL);
export const fetchPersonEmail = (
  personId: number,
  emailId: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonEmailLoadingAction(true, personId));
    try {
      let email: IEmail;

      if (module === PeakModules.Crm) {
        email = await Services.Leads.getLeadEmail(personId, emailId);
      } else {
        email = await Services.PTLeads.getLeadEmail(personId, emailId);
      }

      dispatch(fetchPersonEmailAction(email, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonEmailLoadingAction(false, personId));
    }
  };
};

const addPersonEmailAction = createPersonPrepAction<IEmail>(actionTypes.ADD_PERSON_EMAIL);
export const resetPersonCreateEmailActionResult = createPersonPrepAction(
  actionTypes.RESET_PERSON_CREATE_EMAIL_ACTION_RESULT,
);
const addPersonEmailLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_EMAIL_LOADING,
);
export const addPersonEmail = (
  personId: number,
  emailData: ISendEmailRequestPayload,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonEmailLoadingAction(true, personId));
    try {
      let email: IEmail;

      if (module === PeakModules.Crm) {
        email = await Services.Leads.addLeadEmail(personId, emailData);
      } else {
        email = await Services.PTLeads.addLeadEmail(personId, emailData);
      }

      dispatch(addPersonEmailAction(email, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(addPersonEmailLoadingAction(false, personId));
    }
  };
};

const deletePersonEmailAction = createPersonPrepAction<string>(actionTypes.DELETE_PERSON_EMAIL);
const deletePersonEmailLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.DELETE_PERSON_EMAIL_LOADING,
);
export const deletePersonEmail = (
  personId: number,
  emailId: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deletePersonEmailLoadingAction(true, personId));
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.deleteLeadEmail(personId, emailId);
      } else {
        await Services.PTLeads.deleteLeadEmail(personId, emailId);
      }

      dispatch(deletePersonEmailAction(emailId, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(deletePersonEmailLoadingAction(false, personId));
    }
  };
};

// Recent calls
const fetchPersonRecentCallsAction = createPersonPrepAction<ICallNoteDto[]>(
  actionTypes.FETCH_PERSON_RECENT_CALLS_SUCCESS,
);
const fetchPersonRecentCallsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_RECENT_CALLS_LOADING,
);

export const fetchPersonRecentCalls = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonRecentCallsLoadingAction(true, personId));

    try {
      let calls: ICallNoteDto[];

      if (module === PeakModules.Crm) {
        calls = await Services.Leads.getLeadRecentCalls(personId);
      } else {
        calls = await Services.PTLeads.getLeadRecentCalls(personId);
      }

      dispatch(fetchPersonRecentCallsAction(calls, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonRecentCallsLoadingAction(false, personId));
    }
  };
};

// calls
const fetchPersonCallsAction = createPersonPrepAction<IPaginatedData<ILeadCallHistoryDto>>(
  actionTypes.FETCH_PERSON_CALLS_SUCCESS,
);
const fetchPersonCallsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_CALLS_LOADING,
);
export const resetPersonCallsAction = createPersonPrepAction<boolean>(
  actionTypes.RESET_PERSON_CALLS,
);

export const fetchPersonCalls = (
  personId: number,
  tableParams: ITableParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonCallsLoadingAction(true, personId));

    try {
      let response: IPaginatedData<ILeadCallHistoryDto>;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadCalls(personId, tableParams);
      } else {
        response = await Services.PTLeads.getLeadCalls(personId, tableParams);
      }

      dispatch(fetchPersonCallsAction(response, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonCallsLoadingAction(false, personId));
    }
  };
};

const fetchPersonCallAction = createPersonPrepAction<ICallNoteDto>(
  actionTypes.FETCH_PERSON_CALL_DETAILS,
);
const fetchPersonCallLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_CALL_DETAILS_LOADING,
);
export const resetPersonCallDetails = createPersonPrepAction(actionTypes.RESET_PERSON_CALL_DETAILS);

export const fetchPersonCallById = (
  callId: string,
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonCallLoading(true, personId));

    try {
      let call: ICallNoteDto;

      if (module === PeakModules.Crm) {
        call = await Services.Leads.getPersonCallById(personId, callId);
      } else {
        call = await Services.PTLeads.getPersonCallById(personId, callId);
      }

      dispatch(fetchPersonCallAction(call, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonCallLoading(false, personId));
    }
  };
};

export const resetCreatePersonCallActionResult = createPersonPrepAction(
  actionTypes.RESET_CREATE_PERSON_CALL_RESULT,
);
const addPersonCallAction = createPersonPrepAction<ICallNoteDto>(actionTypes.ADD_PERSON_CALL);
const addPersonCallLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_PERSON_CALL_LOADING,
);
export const addPersonCall = (
  personId: number,
  call: ICallNoteDto,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(addPersonCallLoadingAction(true, personId));

    try {
      let data: ICallNoteDto;

      if (module === PeakModules.Crm) {
        data = await Services.Leads.addLeadCall(personId, call);
      } else {
        data = await Services.PTLeads.addLeadCall(personId, call);
      }

      dispatch(addPersonCallAction(data, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(addPersonCallLoadingAction(false, personId));
    }
  };
};

// Campaigns
const fetchPersonCampaignsAction = createPersonPrepAction<ICampaigns>(
  actionTypes.FETCH_PERSON_CAMPAIGNS_SUCCESS,
);
const fetchPersonCampaignsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_CAMPAIGNS_LOADING,
);
export const fetchPersonCampaigns = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonCampaignsLoadingAction(true, personId));

    try {
      let campaigns;

      switch (module) {
        case PeakModules.Crm:
          campaigns = await Services.Leads.fetchCampaigns(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          campaigns = await Services.PTLeads.fetchCampaigns(personId);
          break;
        default:
          campaigns = {};
      }

      dispatch(fetchPersonCampaignsAction(campaigns, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonCampaignsLoadingAction(false, personId));
    }
  };
};

const fetchPersonCampaignsHistoryAction = createPersonPrepAction<IPaginatedData<ICampaign>>(
  actionTypes.FETCH_PERSON_CAMPAIGNS_HISTORY_SUCCESS,
);
const fetchPersonCampaignsHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_CAMPAIGNS_HISTORY_LOADING,
);
export const fetchPersonCampaignsHistory = (
  personId: number,
  module: PeakModuleForNewPersonType,
  tableParams: ITableParams<ICampaign>,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonCampaignsHistoryLoadingAction(true, personId));

    try {
      let response: IPaginatedData<ICampaign>;

      if (module === PeakModules.Crm) {
        response = await Services.Leads.getLeadCampaignsHistory(personId, tableParams);
      } else {
        response = await Services.PTLeads.getLeadCampaignsHistory(personId, tableParams);
      }

      dispatch(fetchPersonCampaignsHistoryAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonCampaignsHistoryLoadingAction(false, personId));
    }
  };
};

// Modification History

const fetchModificationHistoryAction = createPersonPrepAction<IPaginatedData<IModificationHistory>>(
  actionTypes.FETCH_PERSON_MODIFICATION_HISTORY,
);
const fetchModificationHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_LOADING,
);

export const fetchModificationHistory = (
  personId: number,
  module: PeakModules,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchModificationHistoryLoadingAction(true, personId));

    try {
      let modificationHistory;

      switch (module) {
        case PeakModules.FrontDesk:
          modificationHistory = await Services.FrontDesk.getModificationHistory(
            personId,
            tableParams,
          );
          break;
        case PeakModules.Employees:
          modificationHistory = await Services.Employees.getModificationHistory(
            personId,
            tableParams,
          );
          break;
        case PeakModules.Crm:
          modificationHistory = await Services.Leads.getModificationHistory(personId, tableParams);
          break;
        case PeakModules.PersonalTrainingCrm:
          modificationHistory = await Services.PTLeads.getModificationHistory(
            personId,
            tableParams,
          );
          break;
        case PeakModules.Members:
          modificationHistory = await Services.Members.getModificationHistory(
            personId,
            tableParams,
          );
          break;
        case PeakModules.KidZone:
          modificationHistory = await Services.KidZone.getModificationHistory(
            personId,
            tableParams,
          );
          break;
        default:
          modificationHistory = [];
      }

      dispatch(fetchModificationHistoryAction(modificationHistory, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchModificationHistoryLoadingAction(false, personId));
    }
  };
};

const fetchRecentModificationHistoryAction = createPersonPrepAction<IModificationHistory[]>(
  actionTypes.FETCH_PERSON_RECENT_MODIFICATION_HISTORY,
);
const fetchRecentModificationHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_RECENT_MODIFICATION_HISTORY_LOADING,
);

export const fetchRecentModificationHistory = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentModificationHistoryLoadingAction(true, personId));

    try {
      let modificationHistory;

      switch (module) {
        case PeakModules.FrontDesk:
          modificationHistory = await Services.FrontDesk.getRecentModificationHistory(personId);
          break;
        case PeakModules.Employees:
          modificationHistory = await Services.Employees.getModificationHistoryResent(personId);
          break;
        case PeakModules.Crm:
          modificationHistory = await Services.Leads.getRecentModificationHistory(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          modificationHistory = await Services.PTLeads.getRecentModificationHistory(personId);
          break;
        case PeakModules.Members:
          modificationHistory = await Services.Members.getRecentModificationHistory(personId);
          break;
        case PeakModules.KidZone:
          modificationHistory = await Services.KidZone.getRecentModificationHistory(personId);
          break;
        default:
          modificationHistory = [];
      }

      dispatch(fetchRecentModificationHistoryAction(modificationHistory, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentModificationHistoryLoadingAction(false, personId));
    }
  };
};

const fetchModificationHistoryItemAction = createPersonPrepAction<IModificationHistoryItem>(
  actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_ITEM,
);
const fetchModificationHistoryItemLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_MODIFICATION_HISTORY_ITEM_LOADING,
);
export const resetModificationHistoryAction = createPersonPrepAction(
  actionTypes.RESET_PERSON_MODIFICATION_HISTORY_ITEM,
);

export const fetchModificationHistoryItem = (
  personId: number,
  revisionId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchModificationHistoryItemLoadingAction(true, personId));

    try {
      let modificationHistoryItem;

      switch (module) {
        case PeakModules.FrontDesk:
          modificationHistoryItem = await Services.FrontDesk.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        case PeakModules.Employees:
          modificationHistoryItem = await Services.Employees.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        case PeakModules.Crm:
          modificationHistoryItem = await Services.Leads.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          modificationHistoryItem = await Services.PTLeads.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        case PeakModules.Members:
          modificationHistoryItem = await Services.Members.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        case PeakModules.KidZone:
          modificationHistoryItem = await Services.KidZone.getModificationHistoryItem(
            personId,
            revisionId,
          );
          break;
        default:
          modificationHistoryItem = {};
      }

      dispatch(fetchModificationHistoryItemAction(modificationHistoryItem, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchModificationHistoryItemLoadingAction(false, personId));
    }
  };
};

// CheckIns History

const fetchRecentCheckInsHistoryAction = createPersonPrepAction<ICheckInHistory>(
  actionTypes.FETCH_PERSON_RECENT_CHECK_INS_HISTORY,
);
const fetchRecentCheckInsHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_RECENT_CHECK_INS_HISTORY_LOADING,
);

export const fetchRecentCheckInsHistory = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentCheckInsHistoryLoadingAction(true, personId));

    try {
      let checkInsHistory;

      switch (module) {
        case PeakModules.FrontDesk:
          checkInsHistory = await Services.FrontDesk.getRecentCheckInsHistory(personId);
          break;
        case PeakModules.Members:
          checkInsHistory = await Services.Members.getRecentCheckInsHistory(personId);
          break;
        case PeakModules.KidZone:
          checkInsHistory = await Services.KidZone.getRecentCheckInsHistory(personId);
          break;
        default:
          checkInsHistory = [];
      }

      dispatch(fetchRecentCheckInsHistoryAction(checkInsHistory, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentCheckInsHistoryLoadingAction(false, personId));
    }
  };
};

const fetchCheckInsHistoryAction = createPersonPrepAction<IPaginatedData<ICheckInHistory>>(
  actionTypes.FETCH_PERSON_CHECK_INS_HISTORY,
);
const fetchCheckInsHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_CHECK_INS_HISTORY_LOADING,
);

export const fetchCheckInsHistory = (
  personId: number,
  module: PeakModules,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCheckInsHistoryLoadingAction(true, personId));

    try {
      let checkInsHistory;

      switch (module) {
        case PeakModules.FrontDesk:
          checkInsHistory = await Services.FrontDesk.getCheckInsHistory(personId, tableParams);
          break;
        case PeakModules.Members:
          checkInsHistory = await Services.Members.getCheckInsHistory(personId, tableParams);
          break;
        case PeakModules.KidZone:
          checkInsHistory = await Services.KidZone.getKidCheckInsHistory(personId, tableParams);
          break;
        default:
          checkInsHistory = {};
      }

      dispatch(fetchCheckInsHistoryAction(checkInsHistory, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCheckInsHistoryLoadingAction(false, personId));
    }
  };
};

const fetchDocumentsHistoryAction = createPersonPrepAction<IPaginatedData<IDocumentsHistory>>(
  actionTypes.FETCH_PERSON_DOCUMENTS_HISTORY,
);
const fetchDocumentsHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_DOCUMENTS_HISTORY_LOADING,
);

export const fetchDocumentsHistory = (
  personId: number,
  module: PeakModules,
  tableParams: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchDocumentsHistoryLoadingAction(true, personId));

    try {
      let documentsHistory;

      switch (module) {
        case PeakModules.FrontDesk:
          documentsHistory = await Services.FrontDesk.getDocumentsHistory(personId, tableParams);
          break;
        default:
          documentsHistory = await Services.Members.getDocumentsHistory(personId, tableParams);
      }

      dispatch(fetchDocumentsHistoryAction(documentsHistory, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchDocumentsHistoryLoadingAction(false, personId));
    }
  };
};

const fetchUpcomingBillingsAction = createPersonPrepAction<IBilling>(
  actionTypes.FETCH_PERSON_UPCOMING_BILLINGS,
);
const fetchUpcomingBillingsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_UPCOMING_BILLINGS_LOADING,
);
export const fetchUpcomingBillings = (
  personId: number,
  requestOptions: ITableParams,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchUpcomingBillingsLoadingAction(true, personId));
    try {
      const upcomingBillings = await Services.BillingSchedule.getPersonBillingSchedule(
        personId,
        requestOptions,
      );
      dispatch(fetchUpcomingBillingsAction(upcomingBillings, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchUpcomingBillingsLoadingAction(false, personId));
    }
  };
};

const updateBillingScheduleAmountAction = createPersonPrepAction<IBilling>(
  actionTypes.UPDATE_BILLING_SCHEDULE_AMOUNT,
);
const updateBillingScheduleAmountLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_BILLING_SCHEDULE_AMOUNT_LOADING,
);
export const resetUpdateBillingScheduleAmountActionResult = createPersonPrepAction(
  actionTypes.RESET_UPDATE_BILLING_SCHEDULE_AMOUNT_ACTION_RESULT,
);

export const updateBillingScheduleAmount = (
  personId: number,
  billingItemId: string,
  amount: number,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateBillingScheduleAmountLoadingAction(true, personId));

    try {
      const updatedBillingSchedule = await Services.BillingSchedule.updateBillingScheduleAmount(
        personId,
        billingItemId,
        amount,
      );

      dispatch(updateBillingScheduleAmountAction(updatedBillingSchedule, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateBillingScheduleAmountLoadingAction(false, personId));
    }
  };
};

const fetchPastDueBillingsAction = createPersonPrepAction<IPastDue[]>(
  actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS,
);
const fetchPastDueBillingsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_PAST_DUE_BILLINGS_LOADING,
);
export const fetchPastDues = ({
  personId,
  requestOptions,
  module,
  alertUuid,
  onGetPastDueInvoiceSuccess,
}: IFetchPastDues): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPastDueBillingsLoadingAction(true, personId));

    try {
      let pastDueBillings: IPastDue[];

      switch (module) {
        case PeakModules.FrontDesk: {
          pastDueBillings = await Services.FrontDesk.getPersonPastDues(personId, requestOptions);

          if (alertUuid && !pastDueBillings.length) {
            const pastDueInvoice = await Services.FrontDesk.getPersonPastDueInvoice(
              personId,
              alertUuid,
            );

            dispatch(fetchOpenInvoicesListAction([pastDueInvoice]));
            dispatch(selectInvoice(pastDueInvoice.id));

            if (onGetPastDueInvoiceSuccess && pastDueInvoice) {
              onGetPastDueInvoiceSuccess();
            }
          }

          break;
        }
        case PeakModules.Members: {
          pastDueBillings = await Services.Members.getPersonPastDues(personId, requestOptions);
          break;
        }
        case PeakModules.Crm: {
          pastDueBillings = await Services.Leads.getPersonPastDues(personId, requestOptions);
          break;
        }
        case PeakModules.PersonalTrainingCrm: {
          pastDueBillings = await Services.PTLeads.getPersonPastDues(personId, requestOptions);
          break;
        }
        default: {
          pastDueBillings = [];
        }
      }

      dispatch(fetchPastDueBillingsAction(pastDueBillings, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPastDueBillingsLoadingAction(false, personId));
    }
  };
};

export const fetchPastDuesForReactivate = (
  personId: number,
  requestOptions: ITableParams,
  module: PeakModules,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPastDueBillingsLoadingAction(true, personId));

    try {
      let pastDueBillings;

      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            pastDueBillings = await Services.FrontDesk.getPersonPastDuesForReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            pastDueBillings = await Services.Members.getPersonPastDuesForReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            pastDueBillings = await Services.Leads.getPersonPastDuesForReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            pastDueBillings = await Services.PTLeads.getPersonPastDuesForReactivate(
              personId,
              requestOptions,
            );
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            pastDueBillings = await Services.FrontDesk.getPersonPastDuesForServicePackageReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            pastDueBillings = await Services.Members.getPersonPastDuesForServicePackageReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            pastDueBillings = await Services.Leads.getPersonPastDuesForServicePackageReactivate(
              personId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            pastDueBillings = await Services.PTLeads.getPersonPastDuesForServicePackageReactivate(
              personId,
              requestOptions,
            );
            break;
          default:
        }
      }

      dispatch(fetchPastDueBillingsAction(pastDueBillings, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPastDueBillingsLoadingAction(false, personId));
    }
  };
};

export const fetchPastDuesForCancel = (
  personId: number,
  membershipId: string,
  requestOptions: ITableParams,
  module: PeakModules,
  isMembershipCard?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPastDueBillingsLoadingAction(true, personId));

    try {
      let pastDueBillings;

      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            pastDueBillings = await Services.FrontDesk.getPersonPastDuesForCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            pastDueBillings = await Services.Members.getPersonPastDuesForCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            pastDueBillings = await Services.Leads.getPersonPastDuesForCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            pastDueBillings = await Services.PTLeads.getPersonPastDuesForCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            pastDueBillings = await Services.FrontDesk.getPersonPastDuesForServicePackageCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            pastDueBillings = await Services.Members.getPersonPastDuesForServicePackageCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            pastDueBillings = await Services.Leads.getPersonPastDuesForServicePackageCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            pastDueBillings = await Services.PTLeads.getPersonPastDuesForServicePackageCancel(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          default:
        }
      }

      dispatch(fetchPastDueBillingsAction(pastDueBillings, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPastDueBillingsLoadingAction(false, personId));
    }
  };
};

// Package upcoming billings

const fetchPackageUpcomingBillingsAction = createPersonPrepAction<IBilling>(
  actionTypes.FETCH_PACKAGE_UPCOMING_BILLINGS,
);
const fetchPackageUpcomingBillingsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PACKAGE_UPCOMING_BILLINGS_LOADING,
);
export const resetPackageUpcomingBillings = createPersonPrepAction(
  actionTypes.RESET_PACKAGE_UPCOMING_BILLINGS,
);
export const fetchPackageUpcomingBillings = (
  personId: number,
  membershipId: string,
  module: PeakModules,
  requestOptions: ITableParams,
  isMembershipCard: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPackageUpcomingBillingsLoadingAction(true, personId));

    try {
      let packageUpcomingBillings;

      if (isMembershipCard) {
        switch (module) {
          case PeakModules.FrontDesk:
            packageUpcomingBillings = await Services.FrontDesk.getPackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            packageUpcomingBillings = await Services.Members.getPackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            packageUpcomingBillings = await Services.Leads.getPackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            packageUpcomingBillings = await Services.PTLeads.getPackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            packageUpcomingBillings = await Services.FrontDesk.getServicePackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Members:
            packageUpcomingBillings = await Services.Members.getServicePackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.Crm:
            packageUpcomingBillings = await Services.Leads.getServicePackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            packageUpcomingBillings = await Services.PTLeads.getServicePackageBillingSchedule(
              personId,
              membershipId,
              requestOptions,
            );
            break;
          default:
        }
      }

      dispatch(fetchPackageUpcomingBillingsAction(packageUpcomingBillings, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPackageUpcomingBillingsLoadingAction(false, personId));
    }
  };
};

export const signWaiverAction = createAction<ISuccessResponse>(actionTypes.SIGN_WAIVER);
export const signWaiverLoadingAction = createAction<boolean>(actionTypes.SIGN_WAIVER_LOADING);

// TODO - not used
// export const resetSignWavier = createAction(actionTypes.RESET_SIGN_WAIVER);

export const signWaiver = (
  personId: number,
  waiverId: string,
  signingType: 'sms' | 'email' | 'now',
  signatureImage?: any,
  attachmentId?: string,
) => {
  return async dispatch => {
    dispatch(signWaiverLoadingAction(true));

    try {
      const result = await Services.Persons.signWaiver(
        personId,
        waiverId,
        signingType,
        signatureImage,
        attachmentId,
      );
      dispatch(signWaiverAction(result));
    } finally {
      dispatch(signWaiverLoadingAction(false));
    }
  };
};

const fetchPersonSalesInfoAction = createPersonPrepAction<IPersonSalesInfo>(
  actionTypes.FETCH_PERSON_SALES_INFO,
);
// TODO - not used
/* export const resetPersonSalesInfoAction = createPersonPrepAction<string>(
  actionTypes.RESET_PERSON_SALES_INFO,
); */
const fetchPersonSalesInfoLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SALES_INFO_LOADING,
);

export const fetchPersonSalesInfo = (
  customerId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonSalesInfoLoadingAction(true, customerId));

    try {
      let response: IPersonSalesInfo | null;

      switch (module) {
        case PeakModules.Members:
          response = await Services.Members.getMemberSalesInfo(customerId);
          break;
        case PeakModules.Crm:
          response = await Services.Leads.getLeadSalesInfo(customerId);
          break;
        case PeakModules.PersonalTrainingCrm:
          response = await Services.PTLeads.getLeadSalesInfo(customerId);
          break;
        default:
          response = null;
          break;
      }

      if (!response) {
        throw new Error('Invalid Sales Info');
      }

      dispatch(fetchPersonSalesInfoAction(response, customerId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonSalesInfoLoadingAction(false, customerId));
    }
  };
};

const updatePersonSalesInfoAction = createPersonPrepAction<INewLeadProfileData>(
  actionTypes.UPDATE_PERSON_SALES_INFO,
);
const updatePersonSalesInfoLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_SALES_INFO_LOADING,
);
export const resetUpdatePersonSalesInfoAction = createPersonPrepAction<string>(
  actionTypes.RESET_UPDATE_PERSON_SALES_INFO,
);

export const updatePersonSalesInfo = (
  customerId: number,
  module: PeakModuleForNewPersonType,
  data: INewLeadProfileData,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonSalesInfoLoadingAction(true, customerId));
    try {
      let response: INewLeadProfileData;

      switch (module) {
        case PeakModules.Crm:
          response = await Services.Leads.updateLeadSalesInfo(customerId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          response = await Services.PTLeads.updateLeadSalesInfo(customerId, data);
          break;
        default:
          response = await Services.Members.updateMemberSalesInfo(customerId, data);
      }
      dispatch(updatePersonSalesInfoAction(response, customerId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(updatePersonSalesInfoLoadingAction(false, customerId));
    }
  };
};

// Documents

const fetchPersonDocumentsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_DOCUMENTS_LOADING,
);
const fetchPersonDocumentsAction = createPersonPrepAction<IPersonDocuments>(
  actionTypes.FETCH_PERSON_DOCUMENTS,
);
export const resetPersonDocuments = createPersonPrepAction(actionTypes.RESET_PERSON_DOCUMENTS);

export const fetchPersonDocuments = (personId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonDocumentsLoadingAction(true, personId));

    try {
      let documents: IPersonDocuments;

      switch (module) {
        case PeakModules.FrontDesk:
          documents = await Services.FrontDesk.getPersonDocuments(personId);
          break;
        case PeakModules.Members:
          documents = await Services.Members.getPersonDocuments(personId);
          break;
        case PeakModules.Crm:
          documents = await Services.Leads.getPersonDocuments(personId);
          break;
        default:
          documents = await Services.PTLeads.getPersonDocuments(personId);
      }

      const transformedDocuments = documents && {
        ...documents,
        documentGroups: documents?.documentGroups.map(item => {
          return {
            id: uuid(),
            ...item,
          };
        }),
      };

      dispatch(fetchPersonDocumentsAction(transformedDocuments, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonDocumentsLoadingAction(false, personId));
    }
  };
};

const updatePersonDocumentsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_ATTACHMENTS_LOADING,
);
const updatePersonDocumentsAction = createPersonPrepAction<ActionResult>(
  actionTypes.UPDATE_PERSON_ATTACHMENTS,
);

export const resetUpdatePersonAttachmentsAction = createPersonPrepAction(
  actionTypes.RESET_PERSON_ATTACHMENTS_ACTION_RESULT,
);

export const updatePersonAttachments = (
  personId: number,
  module: PeakModuleForNewPersonType,
  attachments: IAttachment[],
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonDocumentsLoadingAction(true, personId));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.updatePersonAttachments(personId, attachments);
          break;
        case PeakModules.Members:
          await Services.Members.updatePersonAttachments(personId, attachments);
          break;
        case PeakModules.Crm:
          await Services.Leads.updatePersonAttachments(personId, attachments);
          break;
        default:
          await Services.PTLeads.updatePersonAttachments(personId, attachments);
      }

      dispatch(updatePersonDocumentsAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonDocumentsLoadingAction(false, personId));
    }
  };
};

const deletePersonAttachmentLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.DELETE_PERSON_ATTACHMENT_LOADING,
);
const deletePersonAttachmentAction = createPersonPrepAction<ActionResult>(
  actionTypes.DELETE_PERSON_ATTACHMENT,
);

export const deletePersonAttachment = (
  personId: number,
  attachmentId: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deletePersonAttachmentLoadingAction(true, personId));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.deletePersonAttachment(personId, attachmentId);
          break;
        case PeakModules.Members:
          await Services.Members.deletePersonAttachment(personId, attachmentId);
          break;
        case PeakModules.Crm:
          await Services.Leads.deletePersonAttachment(personId, attachmentId);
          break;
        default:
          await Services.PTLeads.deletePersonAttachment(personId, attachmentId);
      }

      dispatch(deletePersonAttachmentAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deletePersonAttachmentLoadingAction(false, personId));
    }
  };
};

const getPersonAdditionalInfoAction = createPersonPrepAction<IProfileInfo>(
  actionTypes.GET_PERSON_ADDITIONAL_INFO,
);

export const getPersonAdditionalInfo = (
  personId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      let additionalInfo;

      switch (module) {
        case PeakModules.FrontDesk:
          additionalInfo = await Services.FrontDesk.getPersonAdditionalInfo(personId);
          break;
        case PeakModules.Members:
          additionalInfo = await Services.Members.getPersonAdditionalInfo(personId);
          break;
        case PeakModules.Crm:
          additionalInfo = await Services.Leads.getPersonAdditionalInfo(personId);
          break;
        default:
          additionalInfo = await Services.PTLeads.getPersonAdditionalInfo(personId);
      }

      dispatch(getPersonAdditionalInfoAction(additionalInfo, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const updatePersonAdditionalInfoAction = createPersonPrepAction<IProfileInfo>(
  actionTypes.UPDATE_PERSON_ADDITIONAL_INFO,
);

export const updatePersonAdditionalInfo = (
  personId: number,
  additionalInfo: IProfileAdditionalPayload[],
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonsLoadingAction(true, personId));

    try {
      let updatedAdditionalInfo;

      switch (module) {
        case PeakModules.FrontDesk:
          updatedAdditionalInfo = await Services.FrontDesk.updatePersonAdditionalInfo(
            personId,
            additionalInfo,
          );
          break;
        case PeakModules.Members:
          updatedAdditionalInfo = await Services.Members.updatePersonAdditionalInfo(
            personId,
            additionalInfo,
          );
          break;
        case PeakModules.Crm:
          updatedAdditionalInfo = await Services.Leads.updatePersonAdditionalInfo(
            personId,
            additionalInfo,
          );
          break;
        default:
          updatedAdditionalInfo = await Services.PTLeads.updatePersonAdditionalInfo(
            personId,
            additionalInfo,
          );
      }

      dispatch(updatePersonAdditionalInfoAction(updatedAdditionalInfo, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonsLoadingAction(false, personId));
    }
  };
};

const fetchPaymentMethodsModalDataLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PAYMENT_METHODS_MODAL_DATA_LOADING,
);
const fetchStoredCreditCardsResultAction = createPersonPrepAction<IPaymentAccount[]>(
  actionTypes.FETCH_STORED_CREDIT_CARDS,
);
const fetchPersonSubscriptionsAction = createPersonPrepAction<IPackageInstanceSubscription[]>(
  actionTypes.FETCH_PERSON_SUBSCRIPTIONS,
);
export const resetPersonSubscriptionsAction = createPersonPrepAction(
  actionTypes.RESET_PERSON_SUBSCRIPTIONS,
);
const fetchPersonPaymentMethodsAction = createPersonPrepAction<IPaymentMethodItem[]>(
  actionTypes.FETCH_PERSON_PAYMENT_METHODS,
);

export const fetchPaymentMethodsModalData = (
  memberId: number,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentMethodsModalDataLoadingAction(true, memberId));

    try {
      let storedCreditCards;
      let memberSubscriptions;
      let paymentMethods;

      switch (module) {
        case PeakModules.Members:
          storedCreditCards = await Services.Members.fetchPaymentAccounts(memberId);
          memberSubscriptions = await Services.Members.fetchPersonSubscriptions(memberId);
          paymentMethods = await Services.Members.fetchPersonPaymentMethods(memberId);
          break;
        default:
          storedCreditCards = await Services.FrontDesk.fetchPaymentAccounts(memberId);
          memberSubscriptions = await Services.FrontDesk.fetchPersonSubscriptions(memberId);
          paymentMethods = await Services.FrontDesk.fetchPersonPaymentMethods(memberId);
      }

      const creditCards = storedCreditCards.filter(
        el => el.paymentAccountType === PaymentAccountType.CREDIT_CARD,
      );
      const checkings = storedCreditCards.filter(
        el => el.paymentAccountType === PaymentAccountType.ECHECK,
      );

      batch(() => {
        dispatch(fetchCheckingSavingsData(checkings, memberId));
        dispatch(fetchStoredCreditCardsResultAction(creditCards, memberId));
        dispatch(fetchPersonSubscriptionsAction(memberSubscriptions, memberId));
        dispatch(fetchPersonPaymentMethodsAction(paymentMethods.data, memberId));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentMethodsModalDataLoadingAction(false, memberId));
    }
  };
};

export const fetchPersonStoredCreditCards = (memberId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPaymentMethodsModalDataLoadingAction(true, memberId));

    try {
      const storedCreditCards = await Services.FrontDesk.fetchPaymentAccounts(
        memberId,
        PaymentAccountType.CREDIT_CARD,
      );

      dispatch(fetchStoredCreditCardsResultAction(storedCreditCards, memberId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPaymentMethodsModalDataLoadingAction(false, memberId));
    }
  };
};

const updatePersonSubscriptionsAction = createPersonPrepAction<ActionResult>(
  actionTypes.UPDATE_PERSON_SUBSCRIPTIONS,
);
const updatePersonSubscriptionsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_SUBSCRIPTIONS_LOADING,
);
export const resetUpdatePersonSubscriptionsActionResult = createPersonPrepAction(
  actionTypes.RESET_UPDATE_PERSON_SUBSCRIPTIONS_ACTION_RESULT,
);

export const updatePersonSubscriptions = (
  personId: number,
  subscriptions: IPackageInstanceSubscriptionUpdateDTO[],
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonSubscriptionsLoadingAction(true, personId));

    try {
      switch (module) {
        case PeakModules.Members:
          await Services.Members.updateMemberSubscriptions(personId, subscriptions);
          break;
        default:
          await Services.FrontDesk.updateMemberSubscriptions(personId, subscriptions);
      }

      dispatch(updatePersonSubscriptionsAction(ActionResult.SUCCESS_ACTION, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonSubscriptionsLoadingAction(false, personId));
    }
  };
};

// TODO - not used
// export const resetPaymentMethods = createPersonPrepAction(actionTypes.RESET_PAYMENT_METHODS);
const storeCreditCardLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.ADD_CREDIT_CARD_RESULT_LOADING,
);
export const storeCreditCardResultAction = createPersonPrepAction<ActionResult>(
  actionTypes.ADD_CREDIT_CARD_RESULT,
);
export const storeCreditCard = (
  memberId: number,
  data: IAddCreditCard,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true, memberId));
    dispatch(storeCreditCardResultAction(null, memberId));

    switch (module) {
      case PeakModules.Members:
        await Services.Members.addCreditCardPaymentAccount(memberId, data);
        break;
      default:
        await Services.FrontDesk.addCreditCardPaymentAccount(memberId, data);
    }

    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION, memberId));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false, memberId));
  }
};

export const deletePaymentMethodCardResultAction = createPersonPrepAction<IActionResultData>(
  actionTypes.DELETE_CREDIT_CARD_RESULT,
);
export const deletePaymentMethodCardResultLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.DELETE_CREDIT_CARD_RESULT_LOADING,
);
export const resetDeletePaymentMethodCardResultAction = createPersonPrepAction(
  actionTypes.RESET_DELETE_CREDIT_CARD_RESULT,
);
export const deletePaymentMethodCard = (
  memberId: number,
  storedCardId: string,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  dispatch(deletePaymentMethodCardResultLoadingAction(true, memberId));

  try {
    switch (module) {
      case PeakModules.Members:
        await Services.Members.deletePaymentAccount(memberId, storedCardId);
        break;
      default:
        await Services.FrontDesk.deletePaymentAccount(memberId, storedCardId);
    }

    dispatch(
      deletePaymentMethodCardResultAction(
        {
          result: ActionResult.SUCCESS_ACTION,
          data: storedCardId,
        },
        memberId,
      ),
    );
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deletePaymentMethodCardResultLoadingAction(false, memberId));
  }
};

// Checking
const fetchCheckingSavingsData = createPersonPrepAction<IPaymentAccount[]>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA,
);

// TODO - not used
/* const fetchCheckingSavingsDataLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING,
); */
export const resetCheckingSavingsData = createPersonPrepAction(
  actionTypes.RESET_CHECKING_SAVINGS_DATA,
);

const addCheckingSavingsData = createPersonPrepAction(actionTypes.ADD_CHECKING_SAVINGS_DATA);
const addCheckingSavingsDataLoading = createPersonPrepAction(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING,
);
export const addCheckingSavingsDataActionResult = createPersonPrepAction<ActionResult | null>(
  actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT,
);

export const addCheckingSavingsDataThunk = (
  personId: number,
  formData: Omit<IPaymentAccount, 'id'>,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(addCheckingSavingsDataLoading(true, personId));

    let response: IPaymentAccount;

    switch (module) {
      case PeakModules.Members:
        response = await Services.Members.addCheckingPaymentAccount(personId, formData);
        break;
      default:
        response = await Services.FrontDesk.addCheckingPaymentAccount(personId, formData);
    }

    dispatch(addCheckingSavingsData(response, personId));
    dispatch(addCheckingSavingsDataActionResult(ActionResult.SUCCESS_ACTION, personId));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(addCheckingSavingsDataLoading(false, personId));
  }
};

const deleteCheckingSavingsItem = createPersonPrepAction<string>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM,
);
const deleteCheckingSavingsItemLoading = createPersonPrepAction<boolean>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING,
);
export const deleteCheckingSavingsItemActionResult = createPersonPrepAction<ActionResult | null>(
  actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT,
);

export const deleteCheckingSavingsItemThunk = (
  personId: number,
  checkingId: string,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(deleteCheckingSavingsItemLoading(true, personId));

    switch (module) {
      case PeakModules.Members:
        await Services.Members.deletePaymentAccount(personId, checkingId);
        break;
      default:
        await Services.FrontDesk.deletePaymentAccount(personId, checkingId);
    }

    dispatch(deleteCheckingSavingsItem(checkingId, personId));
    dispatch(deleteCheckingSavingsItemActionResult(ActionResult.SUCCESS_ACTION, personId));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(deleteCheckingSavingsItemLoading(false, personId));
  }
};

const fetchDocumentHTMLLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_DOCUMENT_HTML_LOADING,
);

export const printDocument = (
  personId: number,
  documentId: string,
  module: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    let result: string;

    dispatch(fetchDocumentHTMLLoading(true, personId));

    switch (module) {
      case PeakModules.FrontDesk:
        result = await Services.FrontDesk.printDocumentById(personId, documentId);
        break;
      case PeakModules.Members:
        result = await Services.Members.printDocumentById(personId, documentId);
        break;
      default:
        result = '';
    }

    dispatch(printHTML(result));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(fetchDocumentHTMLLoading(false, personId));
  }
};

const updatePersonRewardPointsAction = createPersonPrepAction<number>(
  actionTypes.UPDATE_PERSON_REWARDS,
);
const updatePersonRewardPointsLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_PERSON_REWARDS_LOADING,
);

export const updatePersonRewardPoints = (
  personId: number,
  data: ITopUpRewardsData,
  onClose: () => void,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonRewardPointsLoadingAction(true, personId));

    try {
      const response = await Services.FrontDesk.updatePersonRewardPoints(personId, data);

      dispatch(updatePersonRewardPointsAction(response.points, personId));
      onClose();
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonRewardPointsLoadingAction(false, personId));
    }
  };
};

export const tokenizeCommerceHubEncryptedCard = (
  memberId: number,
  sessionId: string,
  module?: PeakModules,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(storeCreditCardLoadingAction(true, memberId));
    dispatch(storeCreditCardResultAction(null, memberId));

    const body = { sessionId };
    switch (module) {
      case PeakModules.Members:
        await Services.FrontDesk.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
      default:
        await Services.Members.tokenizeCommerceHubEncryptedCard(memberId, body);
        break;
    }

    dispatch(storeCreditCardResultAction(ActionResult.SUCCESS_ACTION, memberId));
  } catch (error) {
    dispatch(enqueueErrorNotification(error));
  } finally {
    dispatch(storeCreditCardLoadingAction(false, memberId));
  }
};

const fetchPersonBalanceHistoryAction = createPersonPrepAction<IBalanceHistoryItem[]>(
  actionTypes.FETCH_PERSON_BALANCE_HISTORY_SUCCESS,
);
const fetchPersonBalanceHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_BALANCE_HISTORY_LOADING,
);
export const fetchPersonBalanceHistory = (
  personId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonBalanceHistoryLoadingAction(true, personId));

    try {
      let response: IBalanceHistoryItem[] = [];

      if (module === PeakModules.FrontDesk) {
        response = await Services.FrontDesk.getPersonBalanceHistory(personId);
      } else if (module === PeakModules.Members) {
        response = await Services.Members.getPersonBalanceHistory(personId);
      }

      dispatch(fetchPersonBalanceHistoryAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonBalanceHistoryLoadingAction(false, personId));
    }
  };
};

const fetchPersonRewardsHistoryAction = createPersonPrepAction<IRewardsHistoryItem[]>(
  actionTypes.FETCH_PERSON_REWARDS_HISTORY_SUCCESS,
);
const fetchPersonRewardsHistoryLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_REWARDS_HISTORY_LOADING,
);

export const fetchPersonRewardsHistory = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonRewardsHistoryLoadingAction(true, personId));

    try {
      const response = await Services.FrontDesk.getPersonRewardsHistory(personId);

      dispatch(fetchPersonRewardsHistoryAction(response, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonRewardsHistoryLoadingAction(false, personId));
    }
  };
};
