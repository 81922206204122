// libraries
import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormHelperText, Grid, List } from '@material-ui/core';
// custom interfaces and components
import {
  EventAttendeeType,
  IEventPersonFormValue,
  PersonAttendanceType,
} from '../../../interfaces';
import { IFormStepStyle } from '../../../../services/interfaces/packages';
import ParticipantsListSearch from './ParticipantsListSearch';
import EventParticipant from './EventParticipant';
import { PeakModules } from 'common/constants/peakModules';

interface IParticipantsListProps {
  value: IEventPersonFormValue[];
  error: boolean;
  errorMessage: JSX.Element | string;
  eventDate: string;
  module: PeakModules;
  types: EventAttendeeType[];
  repeatEvent: boolean;
  personId?: number;
  handleRemoveResourceEmployee?: (
    participantIndex: number,
    participants: IEventPersonFormValue[],
  ) => void;
  onChange: (participants: IEventPersonFormValue[]) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    flex: 1,
    minHeight: 0,
    overflow: 'hidden',
  },
  hidden: {
    display: 'none',
  },
  cardContent: {
    padding: ({ smallPaddingContent }: IFormStepStyle & IParticipantsListProps) =>
      smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    '&:last-child': {
      padding: ({ smallPaddingContent }: IFormStepStyle & IParticipantsListProps) =>
        smallPaddingContent ? theme.spacing(1, 0) : theme.spacing(1, 2),
    },
  },
  wrapFlexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  searchByNameImg: {
    height: 24,
    width: 24,
  },
  searchByNamePrice: {
    flex: 'none',
    opacity: 0.5,
  },
  searchByNameName: {
    width: '100%',
    paddingLeft: 8,
    paddingRight: 12,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
  },
  inventoryStatus: {
    marginLeft: theme.spacing(1),
  },
  amountInput: {
    width: 20,
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      // eslint-disable-next-line no-useless-computed-key
      ['-webkit-appearance']: 'none',
      margin: 0,
    },
    '& input': {
      textAlign: 'center',
      minWidth: 0,
      padding: 0,
      // eslint-disable-next-line no-useless-computed-key
      ['-moz-appearance']: 'textfield',
    },
  },
  inputButton: {
    '&.MuiIconButton-sizeSmall': {
      padding: 0,
      width: 18,
    },
  },
  select: {
    '& .MuiSelect-outlined.MuiSelect-outlined': {
      padding: '0 32px 0 0',
      textAlign: 'right',
    },
    '& fieldset': {
      border: 'unset',
    },
    minWidth: 110,
  },
  removeButton: {
    color: theme.palette.text.primary,
    opacity: 0.4,
  },
  participantsList: {
    '& .MuiListItem-container': {
      '& .participant-action': {
        visibility: 'hidden',
        color: theme.palette.text.primary,
      },
      '& .participant-action-visible': { visibility: 'visible' },

      '&:hover': {
        backgroundColor: theme.palette.background.default,

        '& .participant-action': {
          visibility: 'visible',
        },
      },
    },
  },
}));

const ParticipantsList: React.FC<IParticipantsListProps> = (
  props: IParticipantsListProps,
): JSX.Element => {
  const classes = useStyles(props);
  const {
    value: participants = [],
    module,
    types,
    personId,
    eventDate,
    onChange,
    error,
    errorMessage,
    repeatEvent,
    handleRemoveResourceEmployee,
  } = props;

  // effects

  useEffect(() => {
    const updatedParticipants = participants.map(participant => {
      const updatedParticipant = { ...participant };
      if (
        updatedParticipant.attendanceType === PersonAttendanceType.Default &&
        !updatedParticipant.joinedDate
      ) {
        updatedParticipant.joinedDate = eventDate;
      }

      if (updatedParticipant.attendanceType === PersonAttendanceType.OneTime) {
        updatedParticipant.eventDate = eventDate;
      }

      return updatedParticipant;
    });

    onChange(updatedParticipants);
    // Participants array is always changing, and we need to do this effect
    // only when the date was changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDate]);

  // handlers

  const handleUpdateParticipantAttendanceType = (index: number, type: PersonAttendanceType) => {
    const updatedParticipants = [...participants];

    updatedParticipants[index].attendanceType = type;

    if (type === PersonAttendanceType.Default) {
      updatedParticipants[index].joinedDate = eventDate;
    } else {
      updatedParticipants[index].eventDate = eventDate;
    }

    onChange(updatedParticipants);
  };

  const handleRemoveParticipant = (index: number): void => {
    if (handleRemoveResourceEmployee) {
      handleRemoveResourceEmployee(index, participants);
    }

    onChange(participants.filter((part, i) => i !== index));
  };

  const handleAddParticipant = (participant: IEventPersonFormValue) => {
    const isAlreadyAdded =
      participants.findIndex(
        p =>
          (!!p.customer?.id &&
            !!participant.customer?.id &&
            p.customer.id === participant.customer.id) ||
          (!!p.salesperson?.id &&
            !!participant.salesperson?.id &&
            p.salesperson.id === participant.salesperson.id),
      ) >= 0;

    if (!isAlreadyAdded) {
      onChange([...participants, participant]);
    }
  };

  // renders

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ParticipantsListSearch
          eventDate={eventDate}
          module={module}
          types={types}
          personId={personId}
          onAddParticipant={handleAddParticipant}
        />
      </Grid>

      {!!participants.length && (
        <Grid item xs={12}>
          <List dense className={classes.participantsList}>
            {participants.map((eventParticipant: IEventPersonFormValue, index: number) => (
              <EventParticipant
                index={index}
                repeatEvent={repeatEvent}
                key={eventParticipant.customer?.id || eventParticipant.salesperson?.id}
                participant={eventParticipant}
                onRemove={handleRemoveParticipant}
                onChangeAttendanceType={handleUpdateParticipantAttendanceType}
              />
            ))}
          </List>
        </Grid>
      )}

      {!!error && <FormHelperText error>{errorMessage}</FormHelperText>}
    </Grid>
  );
};

export default React.memo(ParticipantsList);
