import { AppStoreTypeImt } from 'store';
import { personsListPath } from 'common/components/PersonProfile/state/selectors';
import { ActionResult } from 'common/constants';

export const selectReferralData = (personId: number) => state => {
  return state.getIn([...personsListPath, personId, 'referrals', 'referrals']);
};
export const selectReferredBy = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'referrals', 'referredBy']);

export const selectAddPersonReferralActionResult = (personId: number) => (
  state: AppStoreTypeImt,
): ActionResult => {
  return state.getIn([...personsListPath, personId, 'createReferralResult']);
};
export const selectAddPersonReferralLoading = (personId: number) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...personsListPath, personId, 'createReferralLoading']);

export const selectSetReferredByActionResult = (personId: number) => (
  state: AppStoreTypeImt,
): ActionResult => {
  return state.getIn([...personsListPath, personId, 'referredByResult']);
};
export const selectSetReferredByActionLoading = (personId: number) => (
  state: AppStoreTypeImt,
): boolean => state.getIn([...personsListPath, personId, 'referredByLoading']);

export const selectReferralsError = (personId: number) => (state: AppStoreTypeImt): Error | null =>
  state.getIn([...personsListPath, personId, 'referralsError']);
