// Appointments
import {
  IBookingEvent,
  IEventAction,
  IRecentAppointmentItem,
  IRemindAppointmentDto,
  IRestoreAppointmentsParams,
  IRestoredAppointment,
} from 'modules/booking/interfaces';
import * as actionTypes from 'common/components/PersonProfile/state/constants';
import { PeakModules } from 'common/constants/peakModules';
import { GeneralThunkAction } from 'common/state/interfaces';
import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { INamedEntity } from 'common/interfaces/common';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';
import {
  ICancelAppointmentParticipation,
  IConfirmAppointmentParticipation,
} from 'common/components/PersonProfile/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';
import { IServiceItemDetails } from 'common/interfaces/service';

const fetchPersonAppointmentsHistoryAction = createPersonPrepAction<
  IPaginatedData<IRecentAppointmentItem[]>
>(actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY);
const fetchPersonAppointmentsHistoryLoadingAction = createPersonPrepAction(
  actionTypes.FETCH_PERSON_APPOINTMENTS_HISTORY_LOADING,
);
export const fetchPersonAppointmentsHistory = (
  personId: number,
  tableParams: ITableParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonAppointmentsHistoryLoadingAction(true, personId));

    let appointments;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          appointments = await Services.FrontDesk.getPersonAppointmentsHistory(
            personId,
            tableParams,
          );
          break;
        case PeakModules.Crm:
          appointments = await Services.Leads.getPersonAppointmentsHistory(personId, tableParams);
          break;
        case PeakModules.Members:
          appointments = await Services.Members.getPersonAppointmentsHistory(personId, tableParams);
          break;
        case PeakModules.PersonalTrainingCrm:
          appointments = await Services.PTLeads.getPersonAppointmentsHistory(personId, tableParams);
          break;
        default:
          appointments = [];
      }

      dispatch(fetchPersonAppointmentsHistoryAction(appointments, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonAppointmentsHistoryLoadingAction(false, personId));
    }
  };
};

const fetchRecentPersonAppointmentsAction = createPersonPrepAction<IRecentAppointmentItem[]>(
  actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS,
);
const fetchRecentPersonAppointmentsLoadingAction = createPersonPrepAction(
  actionTypes.FETCH_RECENT_PERSON_APPOINTMENTS_LOADING,
);
export const fetchRecentPersonAppointments = (
  personId: number,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentPersonAppointmentsLoadingAction(true, personId));

    let appointments;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          appointments = await Services.FrontDesk.getRecentPersonAppointments(personId);
          break;
        case PeakModules.Crm:
          appointments = await Services.Leads.getRecentLeadAppointments(personId);
          break;
        case PeakModules.Members:
          appointments = await Services.Members.getRecentMemberAppointments(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          appointments = await Services.PTLeads.getRecentLeadAppointments(personId);
          break;
        default:
          appointments = [];
      }

      dispatch(fetchRecentPersonAppointmentsAction(appointments, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentPersonAppointmentsLoadingAction(false, personId));
    }
  };
};

const fetchPersonAppointmentAction = createPersonPrepAction<IBookingEvent>(
  actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS,
);
const fetchPersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_APPOINTMENT_DETAILS_LOADING,
);
export const resetPersonAppointmentDetails = createPersonPrepAction(
  actionTypes.RESET_PERSON_APPOINTMENT_DETAILS,
);

export const fetchPersonAppointmentById = (
  appointmentId: string,
  appointmentDate: string,
  personId: number,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchPersonAppointmentLoading(true, personId));

    let appointment: IBookingEvent;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          appointment = await Services.FrontDesk.getPersonAppointmentById(
            personId,
            appointmentId,
            appointmentDate,
          );
          break;
        case PeakModules.Crm:
          appointment = await Services.Leads.getPersonAppointmentById(
            personId,
            appointmentId,
            appointmentDate,
          );
          break;
        case PeakModules.Members:
          appointment = await Services.Members.getPersonAppointmentById(
            personId,
            appointmentId,
            appointmentDate,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          appointment = await Services.PTLeads.getPersonAppointmentById(
            personId,
            appointmentId,
            appointmentDate,
          );
          break;
        default:
          appointment = null;
      }

      dispatch(fetchPersonAppointmentAction(appointment, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchPersonAppointmentLoading(false, personId));
    }
  };
};

const updatePersonAppointmentAction = createPersonPrepAction<IBookingEvent>(
  actionTypes.UPDATE_APPOINTMENT,
);
const updatePersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.UPDATE_APPOINTMENT_LOADING,
);

export const updatePersonAppointment = (
  appointmentId: string,
  appointmentDate: string,
  personId: number,
  payload: IBookingEvent,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updatePersonAppointmentLoading(true, personId));

    let updatedAppointment: IBookingEvent;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          updatedAppointment = await Services.FrontDesk.updatePersonAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Crm:
          updatedAppointment = await Services.Leads.updatePersonAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Members:
          updatedAppointment = await Services.Members.updatePersonAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          updatedAppointment = await Services.PTLeads.updatePersonAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        default:
          updatedAppointment = null;
      }

      dispatch(updatePersonAppointmentAction(updatedAppointment, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updatePersonAppointmentLoading(false, personId));
    }
  };
};

const deletePersonAppointmentAction = createPersonPrepAction<IBookingEvent>(
  actionTypes.DELETE_APPOINTMENT,
);
const deletePersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.DELETE_APPOINTMENT_LOADING,
);

export const deleteAppointment = (
  appointmentId: string,
  appointmentDate: string,
  personId: number,
  payload: IEventAction,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(deletePersonAppointmentLoading(true, personId));

    let deletedAppointment: IBookingEvent; // TODO change interface after statuses will be integrated

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          deletedAppointment = await Services.FrontDesk.deleteAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Crm:
          deletedAppointment = await Services.Leads.deleteAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Members:
          deletedAppointment = await Services.Members.deleteAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          deletedAppointment = await Services.PTLeads.deleteAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        default:
          deletedAppointment = null;
      }

      dispatch(deletePersonAppointmentAction(deletedAppointment, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(deletePersonAppointmentLoading(false, personId));
    }
  };
};

const cancelPersonAppointmentAction = createPersonPrepAction<IBookingEvent>(
  actionTypes.CANCEL_APPOINTMENT,
);
const cancelPersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.CANCEL_APPOINTMENT_LOADING,
);

export const cancelAppointment = (
  appointmentId: string,
  appointmentDate: string,
  personId: number,
  payload: IEventAction,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelPersonAppointmentLoading(true, personId));

    let canceledAppointment: IBookingEvent;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          canceledAppointment = await Services.FrontDesk.cancelPersonAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Crm:
          canceledAppointment = await Services.Leads.cancelAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.Members:
          canceledAppointment = await Services.Members.cancelAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          canceledAppointment = await Services.PTLeads.cancelAppointment(
            appointmentId,
            appointmentDate,
            personId,
            payload,
          );
          break;
        default:
          canceledAppointment = null;
      }

      dispatch(cancelPersonAppointmentAction(canceledAppointment, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelPersonAppointmentLoading(false, personId));
    }
  };
};

const cancelAppointmentParticipationAction = createPersonPrepAction<Partial<IBookingEvent>>(
  actionTypes.CANCEL_APPOINTMENT_PARTICIPATION,
);
const cancelAppointmentParticipationLoading = createPersonPrepAction<boolean>(
  actionTypes.CANCEL_APPOINTMENT_PARTICIPATION_LOADING,
);

export const cancelAppointmentParticipation = ({
  personId,
  appointmentsState,
  updateType,
  module,
}: ICancelAppointmentParticipation): GeneralThunkAction => {
  return async dispatch => {
    dispatch(cancelAppointmentParticipationLoading(true, personId));

    const appointmentId = appointmentsState.selectedAppointmentId;
    const appointmentDate = appointmentsState.selectedAppointmentDate;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.cancelAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.Crm:
          await Services.Leads.cancelAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.Members:
          await Services.Members.cancelAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.cancelAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        default:
          await Services.Members.cancelAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
      }

      const payloadWithDate = {
        ...appointmentsState,
        date: appointmentDate,
      };
      dispatch(cancelAppointmentParticipationAction(payloadWithDate, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(cancelAppointmentParticipationLoading(false, personId));
    }
  };
};

const confirmAppointmentParticipationAction = createPersonPrepAction<Partial<IBookingEvent>>(
  actionTypes.CONFIRM_APPOINTMENT_PARTICIPATION,
);
const confirmAppointmentParticipationLoading = createPersonPrepAction<boolean>(
  actionTypes.CONFIRM_APPOINTMENT_PARTICIPATION_LOADING,
);

export const confirmAppointmentParticipation = ({
  personId,
  appointmentsState,
  updateType,
  module,
}: IConfirmAppointmentParticipation): GeneralThunkAction => {
  return async dispatch => {
    dispatch(confirmAppointmentParticipationLoading(true, personId));

    const appointmentId = appointmentsState.selectedAppointmentId;
    const appointmentDate = appointmentsState.selectedAppointmentDate;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.confirmAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.Crm:
          await Services.Leads.confirmAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.Members:
          await Services.Members.confirmAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.confirmAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
          break;
        default:
          await Services.Members.confirmAppointmentParticipation(
            personId,
            appointmentId,
            appointmentDate,
            updateType,
          );
      }

      const payloadWithDate = {
        ...appointmentsState,
        date: appointmentDate,
      };
      dispatch(confirmAppointmentParticipationAction(payloadWithDate, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(confirmAppointmentParticipationLoading(false, personId));
    }
  };
};

const restorePersonAppointmentAction = createPersonPrepAction<IRestoredAppointment>(
  actionTypes.RESTORE_APPOINTMENT,
);
const restorePersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.RESTORE_APPOINTMENT_LOADING,
);

export const restorePersonAppointment = (
  data: IRestoreAppointmentsParams,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(restorePersonAppointmentLoading(true, data.personId));

    let restoredAppointmentResult = null;
    try {
      switch (module) {
        case PeakModules.FrontDesk:
          restoredAppointmentResult = await Services.FrontDesk.restorePersonAppointment(data);
          break;
        case PeakModules.Members:
          restoredAppointmentResult = await Services.Members.restorePersonAppointment(data);
          break;
        case PeakModules.Crm:
          restoredAppointmentResult = await Services.Leads.restorePersonAppointment(data);
          break;
        case PeakModules.PersonalTrainingCrm:
          restoredAppointmentResult = await Services.PTLeads.restorePersonAppointment(data);
          break;
        default:
          restoredAppointmentResult = null;
      }

      dispatch(restorePersonAppointmentAction(restoredAppointmentResult, data.personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(restorePersonAppointmentLoading(false, data.personId));
    }
  };
};

const remindPersonAppointmentAction = createPersonPrepAction<Partial<IBookingEvent>>(
  actionTypes.REMIND_APPOINTMENT,
);
const remindPersonAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.REMIND_APPOINTMENT_LOADING,
);

export const remindAppointment = (
  appointmentId: string,
  personId: number,
  payload: IRemindAppointmentDto,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(remindPersonAppointmentLoading(true, personId));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDesk.remindPersonAppointment(appointmentId, personId, payload);
          break;
        case PeakModules.Crm:
          await Services.Leads.remindPersonAppointment(appointmentId, personId, payload);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeads.remindPersonAppointment(appointmentId, personId, payload);
          break;
        default:
          await Services.Members.remindPersonAppointment(appointmentId, personId, payload);
      }

      dispatch(remindPersonAppointmentAction(payload, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(remindPersonAppointmentLoading(false, personId));
    }
  };
};

const createAppointmentLoading = createPersonPrepAction<boolean>(
  actionTypes.CREATE_APPOINTMENT_LOADING,
);
export const createAppointmentAction = createPersonPrepAction<IBookingEvent>(
  actionTypes.CREATE_APPOINTMENT,
);

export const createAppointment = (
  appointment: IBookingEvent,
  personId: number,
  module?: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createAppointmentLoading(true, personId));

    let newAppointment: IBookingEvent;

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          newAppointment = await Services.FrontDesk.createPersonAppointment(personId, appointment);
          break;
        case PeakModules.Crm:
          newAppointment = await Services.Leads.createLeadAppointment(personId, appointment);
          break;
        case PeakModules.PersonalTrainingCrm:
          newAppointment = await Services.PTLeads.createLeadAppointment(personId, appointment);
          break;
        case PeakModules.Members:
          newAppointment = await Services.Members.createMemberAppointment(personId, appointment);
          break;
        default:
          newAppointment = null;
      }

      dispatch(createAppointmentAction(newAppointment, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createAppointmentLoading(false, personId));
    }
  };
};

export const resetUpdateAppointmentResult = createPersonPrepAction(
  actionTypes.RESET_APPOINTMENT_ACTION_RESULT,
);

// Appointment services
const fetchPersonAppointmentServices = createPersonPrepAction<INamedEntity[]>(
  actionTypes.FETCH_PERSON_APPOINTMENT_SERVICES,
);
const fetchPersonAppointmentServicesLoading = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_APPOINTMENT_SERVICES_LOADING,
);

export const resetPersonAppointmentServices = createPersonPrepAction(
  actionTypes.RESET_PERSON_APPOINTMENT_SERVICES,
);

export const fetchAppointmentServices = (personId: number, search: string, module: PeakModules) => {
  return async dispatch => {
    try {
      dispatch(fetchPersonAppointmentServicesLoading(true, personId));

      let services: INamedEntity[];

      switch (module) {
        case PeakModules.FrontDesk:
          services = await Services.FrontDesk.getAppointmentServices(personId, search);
          break;
        case PeakModules.Members:
          services = await Services.Members.getAppointmentServices(personId, search);
          break;
        case PeakModules.Crm:
          services = await Services.Leads.getAppointmentServices(personId, search);
          break;
        case PeakModules.PersonalTrainingCrm:
          services = await Services.PTLeads.getAppointmentServices(personId, search);
          break;
        default:
          services = [];
      }

      dispatch(fetchPersonAppointmentServices(services, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonAppointmentServicesLoading(false, personId));
    }
  };
};

const fetchPersonServicesForEventRedeemAction = createPersonPrepAction<IServiceItemDetails[]>(
  actionTypes.FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM,
);
const fetchPersonServicesForEventRedeemLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SERVICES_FOR_EVENT_REDEEM_LOADING,
);

export const resetPersonServicesForEventRedeemAction = createPersonPrepAction(
  actionTypes.RESET_PERSON_SERVICES_FOR_EVENT_REDEEM,
);

export const fetchServicesForEventRedeem = (
  personId: number,
  appointmentId: string,
  serviceId: string,
  module: PeakModules,
) => {
  return async dispatch => {
    try {
      dispatch(fetchPersonServicesForEventRedeemLoadingAction(true, personId));

      let services: IServiceItemDetails[];

      switch (module) {
        case PeakModules.FrontDesk:
          services = await Services.FrontDesk.getServicesForEventRedeem(
            personId,
            appointmentId,
            serviceId,
          );
          break;
        case PeakModules.Members:
          services = await Services.Members.getServicesForEventRedeem(
            personId,
            appointmentId,
            serviceId,
          );
          break;
        case PeakModules.Crm:
          services = await Services.Leads.getServicesForEventRedeem(
            personId,
            appointmentId,
            serviceId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          services = await Services.PTLeads.getServicesForEventRedeem(
            personId,
            appointmentId,
            serviceId,
          );
          break;
        default:
          services = [];
      }

      dispatch(fetchPersonServicesForEventRedeemAction(services, personId));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchPersonServicesForEventRedeemLoadingAction(false, personId));
    }
  };
};
