import React, { Dispatch, SetStateAction } from 'react';
import { Divider, FormControlLabel, makeStyles, Radio, RadioGroup, Theme } from '@material-ui/core';
import { List } from 'immutable';

import { IShortPersonImt } from 'common/interfaces/common';
import MemberInfo from './MemberInfo';

interface IGuardiansProps {
  guardianList: List<IShortPersonImt>;
  selectedGuardian?: number;
  setSelectedGuardian: Dispatch<SetStateAction<number | null>>;
}

const useStyles = makeStyles((theme: Theme) => ({
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  childAvatar: {
    width: '44px',
    height: '44px',
    borderRadius: '3px',
    marginRight: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const Guardian = (props: IGuardiansProps): JSX.Element => {
  const { guardianList, selectedGuardian, setSelectedGuardian } = props;

  const classes = useStyles();

  return (
    <RadioGroup value={selectedGuardian} aria-label="select-child">
      {guardianList.map((item, i) => {
        return (
          <>
            {i === 0 && <Divider className={classes.divider} />}
            <FormControlLabel
              control={<Radio />}
              value={item.get('id')}
              onChange={() => setSelectedGuardian(item.get('id'))}
              label={
                <MemberInfo
                  width={45}
                  height={45}
                  titleVariant="subtitle2"
                  firstName={item.get('firstName')}
                  lastName={item.get('lastName')}
                  imageUrl={item.get('imageUrl')}
                />
              }
              classes={{ label: classes.radioLabel }}
            />
            <Divider className={classes.divider} />
          </>
        );
      })}
    </RadioGroup>
  );
};

export default Guardian;
