import React, { useEffect, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { DialogComponent, LoadingBackdrop, Select, TextAreaEmoji } from 'common/components';
import { IMemberPhoneItemImt, ISMSImt } from 'common/components/PersonProfile/interfaces';
import { SMSValidationSchema } from './SMSValidationSchema';
import messages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import modalTitles from 'common/messages/modalTitles';
import { INamedEntityImt } from 'common/interfaces/common';
import componentMessages from 'common/components/PersonProfile/messages';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { fetchDictionaryItem, resetDictionaryItemAction } from 'common/state/dictionary/actions';
import { DictionaryItem } from 'common/constants/dictionaryConstants';
import {
  selectDictionaryItem,
  selectDictionaryItemLoading,
} from 'common/state/dictionary/selectors';
import { ICrmCampaignDtoImt } from 'common/interfaces/dictionary';
import { useAppDispatch } from 'store/hooks';
import { Autocomplete } from '@material-ui/lab';
import { convertPhoneNumber } from 'helpers/common';
import { QuestionnaireModal } from 'common/modals';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    overflow: 'hidden',
  },
  textField: {
    position: 'relative',

    '& .draftEditorToolbar': {
      paddingBottom: '5px',
      justifyContent: 'flex-end',
    },

    '& .draftEditorInner': {
      minHeight: '160px',
      padding: theme.spacing(0, 1, 1, 1),
      overflow: 'auto',
    },
  },
  row: {
    marginTop: '6px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rowItemLeft: {
    marginRight: 'auto',
  },
  rowItemRight: {
    marginLeft: 'auto',
  },
  symbols: {
    textAlign: 'right',
    opacity: 0.4,
  },
  extraTextarea: {
    '& textarea': {
      minHeight: '100px',
      maxHeight: '200px',
    },
  },
}));

interface IProps {
  isOpen: boolean;
  campaigns?: ImmutableList<INamedEntityImt>;
  personPhones?: ImmutableList<IMemberPhoneItemImt>;
  selectedSMS?: ISMSImt;
  fromPhone: string;
  personId: number;
  onClose: () => void;
  onSubmit: (sms) => void;
}

const initialValues = {
  campaign: '',
  targetPersonPhone: '',
  text: '',
};

const NewSMSModal = ({
  isOpen,
  campaigns,
  personPhones,
  onClose,
  onSubmit,
  fromPhone,
  selectedSMS,
  personId,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isLoading = useSelector(selectDictionaryItemLoading(DictionaryItem.CAMPAIGN_SMS));
  const campaignSMS: ICrmCampaignDtoImt = useSelector(
    selectDictionaryItem(DictionaryItem.CAMPAIGN_SMS),
  );

  const [{ isOpenQuestionnaireModal, currentCampaignId }, setIsOpenQuestionnaireModal] = useState({
    isOpenQuestionnaireModal: false,
    currentCampaignId: '',
  });

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const formMethods = useForm<any>({
    defaultValues: initialValues,
    resolver: yupResolver(SMSValidationSchema),
    mode: 'onBlur',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = formMethods;

  const formSubmit = values => {
    const { campaign } = values;

    onSubmit({
      ...values,
      campaign: campaigns?.find(item => item.get('id') === campaign)?.toJS(),
    });
  };

  const fetchCampaignSMS = (campaignId: string) => {
    dispatch(fetchDictionaryItem(DictionaryItem.CAMPAIGN_SMS, { personId, campaignId }));
  };

  useEffect(() => {
    if (selectedSMS?.size) {
      reset({
        text: '',
        campaign: selectedSMS.getIn(['campaign', 'id']),
        targetPersonPhone: selectedSMS.getIn(['targetPersonPhone', 'id']),
      });
    }
  }, [selectedSMS, reset]);

  useEffect(() => {
    if (!isOpen) {
      reset(initialValues);
    }
  }, [isOpen, reset]);

  useEffect(() => {
    if (campaignSMS.size) {
      setValue('text', campaignSMS.getIn(['documentTemplate', 'content']) || '');
      dispatch(resetDictionaryItemAction({ dictionary: DictionaryItem.CAMPAIGN_SMS }));
    }
  }, [campaignSMS, setValue, dispatch]);

  const isReplyModal = Boolean(selectedSMS?.size);

  const onChangeCampaign = (v: string, onChange: (v: string) => void) => {
    const textValue = getValues('text');

    if (textValue.trim().length) {
      setIsOpenQuestionnaireModal({ currentCampaignId: v, isOpenQuestionnaireModal: true });
    } else if (v !== '') {
      fetchCampaignSMS(v);
      onChange(v);
    } else {
      onChange(v);
    }
  };

  const onCloseQuestionnaireModal = () => {
    setIsOpenQuestionnaireModal({ currentCampaignId: '', isOpenQuestionnaireModal: false });
  };

  const onConfirmQuestionnaireModal = () => {
    if (currentCampaignId) {
      fetchCampaignSMS(currentCampaignId);
    } else {
      setValue('text', '');
    }

    setValue('campaign', currentCampaignId);
    setIsOpenQuestionnaireModal({ currentCampaignId: '', isOpenQuestionnaireModal: false });
  };

  return (
    <DialogComponent
      title={
        <FormattedMessage {...(isReplyModal ? modalTitles.replyTitle : modalTitles.newSmsTitle)} />
      }
      size="xs"
      submitBtnTitle={<FormattedMessage {...messages.sendBtn} />}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit(formSubmit)}
    >
      <form className={classes.root} autoComplete="none">
        {isReplyModal && (
          <Box bgcolor="background.default" mb={3} mt={1.25} p={1.25}>
            {selectedSMS?.get('text')}
          </Box>
        )}
        <Grid className={classes.formWrapper} container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Autocomplete
                  disableClearable
                  value={fromPhone}
                  multiple={false}
                  disabled
                  getOptionLabel={option => convertPhoneNumber(option) || ''}
                  options={[fromPhone]}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.from} />}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="targetPersonPhone"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      disableClearable
                      value={field.value || {}}
                      onChange={(event, v: IMemberPhoneItemImt) => field.onChange(v)}
                      getOptionLabel={option => convertPhoneNumber(option.phone) || ''}
                      options={personPhones?.toJS() || []}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="outlined"
                          error={!!errors.targetPersonPhone}
                          helperText={renderIntlMessage(errors?.targetPersonPhone?.message)}
                          label={<FormattedMessage {...inputLabels.to} />}
                        />
                      )}
                    />
                  )}
                />

                {/* <Controller
                  control={control}
                  name="targetPersonPhone"
                  render={({ value, onChange }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.to} />}
                      variant="outlined"
                      fullWidth
                      value={value}
                      onChange={onChange}
                      error={!!errors.targetPersonPhone}
                      helperText={renderIntlMessage(errors?.targetPersonPhone?.message)}
                    >
                      {Boolean(personPhones.size) &&
                        personPhones.map(p => (
                          <MenuItem key={p.get('id')} value={p.get('id')}>
                            {p.get('phone')}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                /> */}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="campaign"
                  render={({ field }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.campaign} />}
                      variant="outlined"
                      fullWidth
                      disabled={isReplyModal}
                      value={field.value}
                      onChange={v => onChangeCampaign(v, field.onChange)}
                    >
                      <MenuItem key="empty-option" value="">
                        <Typography variant="body1">
                          <FormattedMessage {...componentMessages.noCampaign} />
                        </Typography>
                      </MenuItem>
                      {Boolean(campaigns?.size) &&
                        campaigns?.map(c => (
                          <MenuItem key={c.get('id')} value={c.get('id')}>
                            {c.get('title')}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                />
              </Grid>
              <Grid item xs={12} className={classes.textField}>
                <Controller
                  control={control}
                  name="text"
                  render={({ field }) => (
                    <TextAreaEmoji
                      maxSymbols={160}
                      className={classes.extraTextarea}
                      autoComplete="off"
                      variant="outlined"
                      fullWidth
                      value={field.value}
                      onChange={field.onChange}
                      error={Boolean(errors.text)}
                      helperText={renderIntlMessage(errors.text?.message)}
                      onBlur={field.onBlur}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <LoadingBackdrop isLoading={isLoading} />
      {isOpenQuestionnaireModal && (
        <QuestionnaireModal
          isOpen
          onClose={onCloseQuestionnaireModal}
          onSubmit={onConfirmQuestionnaireModal}
          body={
            <FormattedMessage
              {...componentMessages.confirmOverrideTextMessage}
              values={{ value: 'sms' }}
            />
          }
          submitBtnTitle={<FormattedMessage {...messages.confirmBtn} />}
          cancelBtnTitle={<FormattedMessage {...messages.cancelBtn} />}
        />
      )}
    </DialogComponent>
  );
};

export default NewSMSModal;
