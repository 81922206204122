import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
// Messages
// Constants
import { DictionaryList } from 'common/constants';
// interfaces
import { ImmutableObject } from 'common/state/interfaces';
import { ILeadProfile, ILeadProfileImt, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import {
  ICameFromIndicatorDictionaryItemImt,
  IEmployeeListItemImt,
} from 'common/interfaces/dictionary';
import {
  IImmutablePrimaryMemberInfo,
  IPersonSalesInfoImt,
} from 'common/components/PersonProfile/interfaces';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import * as actions from 'modules/crm/state/leads/actions';
import { fetchLeadActivities } from 'modules/crm/state/leads/actions';
import * as selectors from 'modules/crm/state/leads/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// Components
import { LeadPrimaryInfoStep } from 'modules/crm/components';
import { LoadingBackdrop } from 'common/components';
import { PeakModules } from 'common/constants/peakModules';

interface IEditLeadProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const EditLead = ({ isOpen, onClose }: IEditLeadProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const leadLoading: boolean = useSelector(selectors.selectLeadLoading);
  const leadProfile: ImmutableObject<ILeadProfile> = useSelector(selectors.selectLeadForm);
  const leadProfileSalesInfo: IPersonSalesInfoImt = useSelector(selectors.selectLeadFormSalesInfo);
  const leadActionResult: ILeadProfileImt = useSelector(selectors.selectLeadActionResult);
  const employees: ImmutableList<IEmployeeListItemImt> = useSelector(
    selectDictionaryList(DictionaryList.EMPLOYEES),
  );
  const cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt> = useSelector(
    selectDictionaryList(DictionaryList.CAME_FROM),
  );
  const selectedClubId: string = useSelector(selectUserSelectedClubId);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const leadId = Number(id);

  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;

  // lifecycle

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(fetchDictionaryList(DictionaryList.CAME_FROM, { module }));
    dispatch(actions.fetchLeadProfile(leadId, module, true));
    dispatch(actions.fetchLeadFormSalesInfo(leadId, module));
    dispatch(fetchLeadActivities(module));
  }, [dispatch, leadId, module]);

  useEffect(() => {
    if (leadActionResult.get('id')) {
      const isPTCRMModule = module === PeakModules.PersonalTrainingCrm;
      const isCRMModule = module === PeakModules.Crm;
      const shouldNavigateToCrm = isCRMModule && leadActionResult.get('leadMember') === false;
      const shouldNavigateToPTCrm = isPTCRMModule && leadActionResult.get('ptLeadMember') === false;

      dispatch(actions.resetLeadProfileActionResult());

      if (shouldNavigateToCrm) {
        navigate('/crm/leads');
      }

      if (shouldNavigateToPTCrm) {
        navigate('/personal-training-crm/leads');
      }

      if (!shouldNavigateToCrm && !shouldNavigateToPTCrm) {
        dispatch(actions.fetchLeadProfile(leadId, module, true));
        onClose();
      }
    }
  }, [dispatch, leadActionResult, onClose, leadId, module, navigate]);

  // handlers

  const handleOnSubmitStep = useCallback(
    (data: Partial<INewLeadProfileData>): void => {
      const transformedActivitiesIds = (data.activitiesIds as IDictionaryCommonItem[]).map(
        activityItem => activityItem.id,
      ) as string[];

      const payload = {
        ...data,
        id: leadProfile.get('id'),
        activitiesIds: transformedActivitiesIds,
      };

      const assignedSalespersonId = payload.assignedSalesperson?.id;

      payload.assignedSalesperson = assignedSalespersonId
        ? employees.find(c => c.get('id') === assignedSalespersonId)?.toJS() || null
        : null;

      dispatch(actions.updateLeadProfile(selectedClubId, payload, module));
    },
    [dispatch, employees, leadProfile, selectedClubId, module],
  );

  const handleOnExistingPerson = useCallback(
    (person: IImmutablePrimaryMemberInfo): void => {
      navigate(`/${location.pathname.split('/')[1]}/leads/edit/${person.get('id')}`);
    },
    [location.pathname, navigate],
  );

  // renders

  return (
    <>
      {!!leadProfile && (
        <LeadPrimaryInfoStep
          module={module}
          cameFromIndicators={cameFromIndicators}
          employees={employees}
          primaryInfo={leadProfile}
          salesInfo={leadProfileSalesInfo}
          setPrimaryInfo={handleOnExistingPerson}
          hideAutocomplete
          saveLeadProfile={handleOnSubmitStep}
          isOpen={isOpen}
          onClose={onClose}
        />
      )}
      <LoadingBackdrop isLoading={leadLoading} />
    </>
  );
};

export default EditLead;
