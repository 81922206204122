import { ITask } from 'modules/crm/interfaces/tasks';
import { IMultipleSelectData, ITableParams } from 'common/interfaces/table';
import ApiService from './ApiService';
import axios from 'axios';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ReassignData } from '../../modules/crm/state/tasks/actions';
import { IEmployeeAccess } from 'common/interfaces/employee';

export type IPaginatedTasks = IPaginatedData<ITask>;

export default class PtTasksService extends ApiService {
  public getTasks = (requestOptions?: ITableParams): Promise<IPaginatedTasks> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/pt-tasks`, { params: searchParams });
  };

  public completeTasks = (selectedData: IMultipleSelectData): Promise<void> => {
    return axios.put(`/api/v1/pt-tasks/complete`, selectedData);
  };

  public assignSalespersonTasks = (selectedData: ReassignData): Promise<void> => {
    return axios.put(
      `/api/v1/pt-tasks/reassign`,
      this.transformMultiselectDataToBulkDTO(selectedData),
    );
  };

  public fetchEmployeeAccessInfo = (employeeId: number): Promise<IEmployeeAccess> =>
    axios.get(`/api/v1/pt-tasks/employees/${employeeId}`);
}
