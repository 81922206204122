import React, { useMemo } from 'react';
import moment from 'moment-timezone';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import {
  Grid,
  Box,
  Button,
  Typography,
  Theme,
  SvgIcon,
  IconButton,
  makeStyles,
  FormControlLabel,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Add as AddIcon } from '@material-ui/icons';
// interfaces
import {
  EventAttendeeType,
  EventOverbookingType,
  IEventFormValues,
  IEventPersonEntity,
  IEventPersonFormValue,
  IShortResourceImt,
} from 'modules/booking/interfaces';
import { PeakModules } from 'common/constants/peakModules';
// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// components
import { ReactComponent as Times } from 'img/times.svg';
import { Checkbox, MultipleSelect, NumberTextField, Select } from 'common/components';
import ParticipantsList from '../ParticipantsList/ParticipantsList';
// constants
import { AttendeeTypes, OverbookingTypes } from 'modules/booking/constants';
// utils
import { getAvailableResources } from 'modules/booking/utils/resources';
// messages
import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  participantsWrapper: {
    marginTop: theme.spacing(1),
  },
  participantsText: {
    textAlign: 'center',
  },
  duplicatedItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',

    '& .MuiFormControl-root': {
      flex: 1,
    },
  },
  duplicatedItemNumber: {
    fontSize: '1rem',
    fontWeight: 'bold',
    color: theme.palette.text.disabled,
  },
  duplicatedSelect: {
    margin: theme.spacing(1),
    flex: 1,
  },
  duplicatedRemoveIcon: {
    fontSize: '1.1rem',
    color: theme.palette.text.disabled,
  },
}));

interface IProps {
  personId?: number;
  module: PeakModules;
  resources: ImmutableList<IShortResourceImt>;
  onAllowedParticipantsChange: (types: Array<EventAttendeeType>) => void;
}

const ParticipantsSection = ({
  module,
  personId,
  resources,
  onAllowedParticipantsChange,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const intl = useIntl();
  const renderErrorMessage = useRenderIntlMessage();

  const { control, formState, setValue, getValues } = useFormContext<IEventFormValues>();
  const { errors } = formState;

  const watchResources = useWatch({ control, name: 'resources' });
  const watchOverbookingEvents: any[] = useWatch({ control, name: 'overbookingResources' });
  const watchOverbookingType: EventOverbookingType = useWatch({ control, name: 'overbookingType' });
  const watchSpotLimited: boolean = useWatch({ control, name: 'spotLimited' });
  const watchSpotAmount: number = useWatch({ control, name: 'spotAmount' });
  const watchPersons: IEventPersonEntity[] = useWatch({ control, name: 'persons' });
  const repeat: boolean = useWatch({ control, name: 'repeated' });
  const watchAllowedParticipantTypes: EventAttendeeType[] = useWatch({
    control,
    name: 'allowedParticipantTypes',
  });
  const watchDate: string = useWatch({ control, name: 'date' });
  const watchTime: string = useWatch({ control, name: 'time' });

  const { fields: overbookingResources, append, remove } = useFieldArray({
    control,
    name: 'overbookingResources',
  });

  const selectedDate = useMemo(() => {
    if (watchTime && watchDate) {
      return moment.utc(`${watchDate} ${watchTime}`).format();
    }
    return '';
  }, [watchTime, watchDate]);

  const availableResources = getAvailableResources(
    watchResources as any, // TODO - PRM-1810 need type
    resources,
    watchOverbookingEvents,
  );

  const isParticipantsLimited =
    watchOverbookingType === EventOverbookingType.DO_NOT_ALLOW_OVERBOOKING && watchSpotLimited;
  const spotsLeft = watchSpotAmount - watchPersons.length;

  const participantsStatusText = `${
    !watchPersons || !watchPersons.length
      ? intl.formatMessage({ ...messages.noParticipantsAddedLabel })
      : ''
  } ${
    isParticipantsLimited
      ? `${
          spotsLeft > 0
            ? intl.formatMessage(
                { ...messages.availableSpotsLabel },
                { restSpots: spotsLeft, spots: watchSpotAmount },
              )
            : intl.formatMessage({ ...messages.noAvailableSpotsLabel })
        }`
      : intl.formatMessage({ ...messages.unlimitedNumberLabel })
  }`;

  const allowedParticipantOptions = [
    EventAttendeeType.MEMBER,
    EventAttendeeType.EMPLOYEE,
    EventAttendeeType.PROSPECT,
  ];

  const handleRemoveResourceEmployee = (
    participantIndex: number,
    participants: IEventPersonFormValue[],
  ) => {
    const currentResources = getValues('resources');

    if (currentResources?.length) {
      const currentParticipant = participants.find((item, i) => i === participantIndex);
      const resourceId = currentParticipant?.resourceId;
      if (resourceId) {
        const updatedResources = currentResources.filter(({ id }) => id !== resourceId);
        setValue('resources', updatedResources);
      }
    }
  };

  return (
    <Grid container spacing={2} className={classes.participantsWrapper}>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6}>
            <Controller
              name="allowSelfBooking"
              control={control}
              render={({ field }) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value}
                        onBlur={field.onBlur}
                        onChange={(e, v) => field.onChange(v)}
                      />
                    }
                    label={<FormattedMessage {...messages.allowSelfBooking} />}
                  />
                  {errors.allowSelfBooking && (
                    <FormHelperText error>
                      {renderErrorMessage(errors.allowSelfBooking?.message)}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Grid container justifyContent="flex-end">
              <Controller
                name="spotLimited"
                control={control}
                render={({ field }) => (
                  <>
                    <FormControlLabel
                      label={<FormattedMessage {...messages.limitClassSpotsLabel} />}
                      control={
                        <Checkbox
                          checked={field.value}
                          onChange={(e, v) => {
                            field.onChange(v);

                            if (!v) {
                              setValue('spotAmount', null);
                            } else {
                              setValue('spotAmount', watchPersons.length);
                            }
                          }}
                        />
                      }
                    />
                    {errors.spotLimited && (
                      <FormHelperText error>
                        {renderErrorMessage(errors.spotLimited?.message)}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={watchSpotLimited ? 9 : 12}>
            <Controller
              name="allowedParticipantTypes"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  multiple
                  options={allowedParticipantOptions}
                  getOptionLabel={option =>
                    intl.formatMessage({ ...AttendeeTypes.find(option).message })
                  }
                  value={field.value || []}
                  filterSelectedOptions
                  onChange={(e, v: EventAttendeeType[]) => {
                    onAllowedParticipantsChange(v);
                  }}
                  onBlur={field.onBlur}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label={<FormattedMessage {...inputLabels.whoCanAttend} />}
                      error={!!errors.allowedParticipantTypes}
                      helperText={renderErrorMessage(errors.allowedParticipantTypes?.message)}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={3} style={{ display: watchSpotLimited ? 'block' : 'none' }}>
            <Controller
              name="spotAmount"
              control={control}
              render={({ field }) => (
                <NumberTextField
                  label={<FormattedMessage {...inputLabels.spots} />}
                  value={field.value}
                  variant="outlined"
                  autoComplete="off"
                  onBlur={field.onBlur}
                  onChange={field.onChange}
                  numberFormatProps={{ min: 0 }}
                  error={!!errors.spotAmount}
                  helperText={renderErrorMessage(errors.spotAmount?.message)}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="persons"
          render={({ field }) => (
            <ParticipantsList
              handleRemoveResourceEmployee={handleRemoveResourceEmployee}
              eventDate={selectedDate}
              repeatEvent={repeat}
              value={field.value}
              module={module}
              personId={personId}
              types={watchAllowedParticipantTypes}
              error={!!errors.persons}
              errorMessage={errors.persons && renderErrorMessage(errors.persons.message)}
              onChange={field.onChange}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" color="textSecondary" className={classes.participantsText}>
          {participantsStatusText}
        </Typography>
      </Grid>

      {watchSpotLimited && (
        <>
          <Grid item xs={12}>
            <Controller
              name="overbookingType"
              control={control}
              render={({ field }) => (
                <Select
                  value={field.value}
                  onChange={field.onChange}
                  label={<FormattedMessage {...inputLabels.overbooking} />}
                  fullWidth
                  error={!!errors.overbookingType}
                  helperText={renderErrorMessage(errors.overbookingType?.message)}
                >
                  {OverbookingTypes.getSelectOptions()}
                </Select>
              )}
            />
          </Grid>

          {watchOverbookingType === EventOverbookingType.DUPLICATE_EVENT_FOR_EXTRA_PARTICIPANTS && (
            <Grid item xs={12}>
              {overbookingResources.map((eventItem, index) => (
                <Box key={eventItem.id} className={classes.duplicatedItem}>
                  <Typography className={classes.duplicatedItemNumber}>{index + 1}</Typography>
                  <Box className={classes.duplicatedSelect}>
                    <Controller
                      name={`overbookingResources.${index}.resources`}
                      control={control}
                      defaultValue={eventItem.resources}
                      render={({ field }) => (
                        <MultipleSelect
                          value={field.value}
                          onChange={field.onChange}
                          fullWidth
                          multiple
                          label={<FormattedMessage {...inputLabels.resourcesForDuplicatedEvent} />}
                          options={availableResources}
                        />
                      )}
                    />
                  </Box>
                  <IconButton size="small" onClick={() => remove(index)}>
                    <SvgIcon className={classes.duplicatedRemoveIcon}>
                      <Times />
                    </SvgIcon>
                  </IconButton>
                </Box>
              ))}

              <Button
                startIcon={<AddIcon />}
                color="primary"
                onClick={() => append({ resources: [] })}
              >
                <FormattedMessage {...commonMessages.duplicatedEventBtn} />
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default ParticipantsSection;
