import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { makeStyles } from '@material-ui/core';

import {
  fetchDocumentsHistory,
  resetUpdatePersonAttachmentsAction,
} from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';

import { PeakModules } from 'common/constants/peakModules';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { WaiverStatus } from 'modules/crm/constants/leads';
import { ActionResult, TableOrderByParams } from 'common/constants';

import { IDocumentsHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { makeTableParams } from 'common/utils/http';

import { ActionItem, ActionsMenu, DialogComponent, Table } from 'common/components/index';
import { ReactComponent as SignatureIcon } from 'img/icons/signature.svg';
import { ReactComponent as DownloadDocIcon } from 'img/icons/download-doc.svg';

import messages from 'common/components/PersonProfile/messages';
import tableHeaders from 'common/messages/tableHeaders';
import commonMessages from 'common/messages/messages';
import leadsMessages from 'modules/crm/messages/leads';
import { ContractStatus } from 'common/components/Steps/SignDocumentStep/components';

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.NAME,
    label: <FormattedMessage {...tableHeaders.name} />,
    sort: true,
    padding: 'none',
  },
  {
    id: TableOrderByParams.UPLOAD_DATE,
    label: <FormattedMessage {...tableHeaders.uploadDate} />,
    sort: true,
  },
  {
    id: TableOrderByParams.SIGN_DATE,
    label: <FormattedMessage {...tableHeaders.signedDate} />,
    sort: true,
  },
  {
    id: TableOrderByParams.STATUS,
    label: <FormattedMessage {...tableHeaders.status} />,
    sort: true,
  },
  {
    id: 'actions',
    label: <FormattedMessage {...tableHeaders.actions} />,
    sort: false,
    align: 'right',
  },
];

const useStyles = makeStyles(() => ({
  actionIcon: {
    width: '1rem',
    height: '1rem',
  },
}));

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  profileId: number;
  module: PeakModules;
  onSelectSignDocument: (document: IDocumentsHistoryImt) => void;
  signContractActionResult: ActionResult;
  signWaiverActionResult: ActionResult;
}

const DocumentsHistoryModal = ({
  isOpen,
  onClose,
  profileId,
  module,
  onSelectSignDocument,
  signContractActionResult,
  signWaiverActionResult,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const documentsHistory: List<IDocumentsHistoryImt> = useSelector(
    selectors.selectDocumentsHistoryList(profileId),
  );
  const isDocumentsHistoryLoading: boolean = useSelector(
    selectors.selectDocumentsHistoryLoading(profileId),
  );
  const documentsHistoryMeta: IPageMetaImt = useSelector(
    selectors.selectDocumentsHistoryMeta(profileId),
  );

  const [tableParams, setTableParams] = useState(() => makeTableParams([], null));

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDocumentsHistory(profileId, module, tableParams));
  }, [dispatch, module, profileId, tableParams]);

  useEffect(() => {
    if (
      signContractActionResult === ActionResult.SUCCESS_ACTION ||
      signWaiverActionResult === ActionResult.SUCCESS_ACTION
    ) {
      dispatch(fetchDocumentsHistory(profileId, module, tableParams));
      dispatch(resetUpdatePersonAttachmentsAction(null, profileId));
    }
  }, [dispatch, module, profileId, signContractActionResult, signWaiverActionResult, tableParams]);

  const handleChangeTableProps = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  const rows = useMemo(() => {
    return documentsHistory
      .map(
        (documentHistoryItem): ITableRow => {
          const needSighDocument = documentHistoryItem?.get('status') !== WaiverStatus.NO_SIGN;

          return {
            id: documentHistoryItem.get('id'),
            cells: [
              { label: documentHistoryItem.get('title') },
              {
                label: documentHistoryItem.get('uploadDate')
                  ? timezoneMoment(documentHistoryItem.get('uploadDate')).format(
                      DEFAULT_DATE_TIME_FORMAT,
                    )
                  : '-',
              },
              {
                label: documentHistoryItem.get('signedDate')
                  ? timezoneMoment(documentHistoryItem.get('signedDate')).format(
                      DEFAULT_DATE_TIME_FORMAT,
                    )
                  : '-',
              },
              {
                label: '',
                cellComponent: <ContractStatus status={documentHistoryItem?.get('status')} />,
              },
              {
                label: '-',
                align: 'right',
                cellComponent: (
                  <ActionsMenu horizontal tableActionsMode>
                    {documentHistoryItem.get('status') !== WaiverStatus.SIGNED && needSighDocument && (
                      <ActionItem
                        icon={<SignatureIcon className={classes.actionIcon} />}
                        onClick={() => onSelectSignDocument(documentHistoryItem)}
                      >
                        <FormattedMessage {...leadsMessages.newLeadSignWaiversStepSign} />
                      </ActionItem>
                    )}

                    <a download href={documentHistoryItem.get('url')}>
                      <ActionItem icon={<DownloadDocIcon className={classes.actionIcon} />}>
                        <FormattedMessage {...commonMessages.downloadBtn} />
                      </ActionItem>
                    </a>
                  </ActionsMenu>
                ),
              },
            ],
          };
        },
      )
      ?.toJS();
  }, [classes.actionIcon, documentsHistory, onSelectSignDocument, timezoneMoment]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.documentsBlockTitle} />}
      size="lg"
      hideFooterButtons
    >
      <Table
        rows={rows}
        headerOptions={headerOptions}
        tableParams={tableParams}
        hideSearchInput
        totalRows={documentsHistoryMeta?.get('total')}
        page={documentsHistoryMeta?.get('page')}
        isLoading={isDocumentsHistoryLoading}
        onChangeParams={handleChangeTableProps}
      />
    </DialogComponent>
  );
};

export default DocumentsHistoryModal;
