import React from 'react';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import profilePlaceholder from 'img/photo-placeholder.png';

import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';

import { ICustomerShortImt, IFamilyMemberImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';

import { getInitials } from 'helpers/common';

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-start',
    width: 200,
  },
  closeBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  closeIcon: {
    fontSize: '16px !important',
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: '52px',
    height: '52px',
    borderRadius: '3px',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '18px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
}));

const Avatar = styled.img`
  width: 100%;
  border-radius: 5px;
`;

interface IFamilyMemberProps {
  member: IFamilyMemberImt;
  personId: number;
  hideGuestType?: boolean;
  removeFamilyMember?: (id: string) => void;
}

const FamilyMember: React.FC<IFamilyMemberProps> = React.memo(
  ({ member, hideGuestType, removeFamilyMember, personId }: IFamilyMemberProps): JSX.Element => {
    const classes = useStyles();
    const currentIsLeftCustomer = member.getIn(['leftCustomer', 'id']) === personId;
    const relatedMember: ICustomerShortImt = currentIsLeftCustomer
      ? member.get('rightCustomer')
      : member.get('leftCustomer');
    const relationship: string = currentIsLeftCustomer
      ? member.get('leftRightRelation')
      : member.get('rightLeftRelation');
    const initials = getInitials(relatedMember.get('firstName'), relatedMember.get('lastName'));

    return (
      <Grid item className={classes.root}>
        {Boolean(removeFamilyMember) && (
          <Button
            onClick={() => removeFamilyMember(member.get('id'))}
            variant="text"
            type="button"
            className={`btn-text ${classes.closeBtn}`}
          >
            <Close className={classes.closeIcon} />
          </Button>
        )}
        <Box mr={1.5} className={classes.avatarWrap}>
          {initials && !relatedMember.get('imageUrl') ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar src={relatedMember.get('imageUrl') || profilePlaceholder} alt="avatar-icon" />
          )}
        </Box>
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">
            {relatedMember.get('firstName')} {relatedMember.get('lastName')}
          </Typography>
          {!hideGuestType && <GuestStatus type={relatedMember.get('type')} />}
          <Typography component="span" variant="body2" color="textSecondary">
            {relationship}
          </Typography>
        </Box>
      </Grid>
    );
  },
);

export default React.memo(FamilyMember);
