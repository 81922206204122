import axios from 'axios';

import { members } from 'modules/front-desk/mockData';

import {
  CancelMembershipData,
  FreezeMembershipData,
  IBalanceHistoryItem,
  IBilling,
  ICheckInHistory,
  ICommerceHubCredentials,
  ICustomerShort,
  ICustomerSimpleDto,
  IDocumentsHistory,
  IFallthroughCapture,
  IFallthroughCaptureForm,
  IFamilyMember,
  IFamilyMemberCreateDTO,
  IFamilyMemberEditDTO,
  IFamilyMemberSearch,
  IFreezeHistoryPackage,
  IFreezeItem,
  IMemberAutocompleteSearch,
  IMembershipActionDTO,
  IModificationHistory,
  IModificationHistoryItem,
  INote,
  INoteCodeRequestPayload,
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionUpdateDTO,
  IPastDue,
  IPayment,
  IPaymentAccount,
  IPersonDocuments,
  IPersonSalesInfo,
  IPrimaryMemberInfo,
  IProfileAdditionalPayload,
  IProfileInfo,
  ITag,
  ITicketCreateDto,
  ITicketDetailedDto,
  ITicketShortDto,
} from 'common/components/PersonProfile/interfaces';
import ApiService from './ApiService';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedLeads } from './LeadsService';
import { IAttachment, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import {
  IConfiguredPackage,
  IMembershipTransformed,
  IPackageConfiguration,
  IPackageInventoryViewDto,
  IShortPackages,
} from 'common/interfaces/membership';
import {
  IContactInfo,
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
} from 'common/interfaces/contract';
import { IAdditionalInfo } from 'modules/front-desk/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ISuccessResponse, PersonSearchParamsType } from 'common/interfaces/http';
import { INamedEntity } from 'common/interfaces/common';
import {
  EventUpdateType,
  IAppointmentHistoryItem,
  IBookingEvent,
  IEventAction,
  IRecentAppointmentItem,
  IRemindAppointmentDto,
  IRestoreAppointmentsParams,
  IRestoredAppointment,
} from 'modules/booking/interfaces';

import {
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
  IAllergy,
} from 'common/interfaces/additionalInfo';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { IPosKioskOpenRegisterDTO } from 'modules/pos-kiosk/interfaces/registers';
import {
  INotifications,
  ISearchPackagesResult,
  PaymentsType,
  IInvoiceDetailsDto,
  IBalance,
  IAddInvoiceUnitDTO,
  CartUnitTypes,
  PaymentAccountType,
  IRewards,
  ISearchGiftCard,
} from 'common/interfaces/invoices';
import { ILeadProfile, INewLeadProfileData } from 'modules/crm/interfaces/leads';
import { List } from 'immutable';
import { IPackageInstanceDetails, IServiceItemDetails } from 'common/interfaces/service';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IAllergiesFormValues } from 'modules/members/interfaces';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';

export default class MembersService extends ApiService {
  public allMembers: any = members;

  public guestsList: any = [];

  public personsList: any = [];

  // TODO remove other get members actions
  public getMembersList = (tableParams?: ITableParams): Promise<IPaginatedLeads> => {
    const requestOptions = this.getSearchFromTableParams(tableParams);
    return axios.get('/api/v1/members', { params: requestOptions });
  };

  public getMember = (memberId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/members/${memberId}`);

  public getMemberInfoView = (memberId: number): Promise<Partial<IProfileInfo>> =>
    axios.get(`/api/v1/members/${memberId}/view`);

  public getFoundGuestsList() {
    return this.guestsList;
  }

  public searchMembers = (searchStr: string): Promise<IPaginatedData<ICustomerShort>> => {
    return axios.get('/api/v1/members', { params: { search: searchStr } });
  };

  public getInsurance = (memberId: number): Promise<ICustomerInsuranceDto> => {
    return axios.get(`/api/v1/members/${memberId}/info/insurance`);
  };

  public updateInsurance = (
    memberId: number,
    requestData: ICustomerInsuranceDto,
  ): Promise<ICustomerInsuranceDto> => {
    return axios.put(`/api/v1/members/${memberId}/info/insurance`, requestData);
  };

  public getEmergencyContact = (memberId: number): Promise<ICustomerEmergencyContactDto> => {
    return axios.get(`/api/v1/members/${memberId}/info/emergency-contact`);
  };

  public updateEmergencyContact = (
    memberId: number,
    requestData: ICustomerEmergencyContactDto,
  ): Promise<ICustomerEmergencyContactDto> => {
    return axios.put(`/api/v1/members/${memberId}/info/emergency-contact`, requestData);
  };

  public getAllergies = (memberId: number): Promise<IAllergy[]> => {
    return axios.get(`/api/v1/members/${memberId}/info/allergy`);
  };

  public updateAllergies = (
    memberId: number,
    requestData: IAllergiesFormValues,
  ): Promise<IAllergy> => {
    return axios.put(`/api/v1/members/${memberId}/info/allergy`, requestData);
  };

  public getReferralMembers = (search: string): Promise<INamedEntity[]> => {
    return axios.get('/api/v1/members/referrals/list', { params: { search } });
  };

  public getFrontDeskAutocompleteSearch = (
    searchStr: string,
    field: string,
  ): Promise<IMemberAutocompleteSearch[]> => {
    const config = {
      params: {
        search: searchStr,
        memberSearchSortBy: field,
      },
    };
    return axios.get(`/api/v1/members/front-desk-autocomplete/search`, config);
  };

  public getSearchMembers = (
    searchStr: string,
    field: PersonSearchParamsType,
  ): Promise<IPrimaryMemberInfo[]> => {
    const params = {
      search: searchStr,
      customerSearchSortBy: field,
    };
    return axios.get('/api/v1/members/search', { params });
  };

  public createMember = (data: IPrimaryMemberInfo): Promise<IPrimaryMemberInfo> =>
    axios.post(`/api/v1/members`, data);

  public createMemberLead = (clubId: string, data: INewLeadProfileData): Promise<ILeadProfile> => {
    const profileData = {
      ...data,
      initialClubId: clubId,
      type: 'GUEST',
      assignedSalespersonId: data.assignedSalesperson?.id,
    };

    return axios.post('/api/v1/members/lead', profileData);
  };

  public getFallthroughCapture = (personId: number): Promise<IFallthroughCapture> => {
    return axios.get(`/api/v1/members/${personId}/fallthrough-capture`);
  };

  public setFallthroughCapture = (
    personId: number,
    payload: IFallthroughCaptureForm,
  ): Promise<IFallthroughCapture> => {
    return axios.post(`/api/v1/members/${personId}/fallthrough-capture`, payload);
  };

  public deleteFallthroughCapture = (personId: number, captureId: string): Promise<void> => {
    return axios.delete(`/api/v1/members/${personId}/fallthrough-capture/${captureId}`);
  };

  public updateCustomer = (
    memberId: number,
    data: IPrimaryMemberInfo,
  ): Promise<IPrimaryMemberInfo> => {
    return axios.put(`api/v1/members/${memberId}`, data);
  };

  public updateMembershipInfo = (memberId: number, data: IMembershipTransformed): Promise<void> => {
    return axios.put(`api/v1/members/${memberId}/membership`, data);
  };

  public getMembershipConfigurationPos = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/members/pos/package-list/${membershipId}`, { params: { clubId } });
  };

  public getMembershipConfiguration = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/members/membership-packages/${membershipId}`, { params: { clubId } });
  };

  public getPackageInventory = (
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(`api/v1/members/membership-packages/${packageId}/inventories/${inventoryId}`);
  };

  public getPersonPackageInventory = (
    personId: number,
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(
      `api/v1/members/${personId}/membership/${packageId}/inventories/${inventoryId}`,
    );
  };

  public saveAdditionalInfoForMember = (
    memberId: number,
    data: IAdditionalInfo,
  ): Promise<IAdditionalInfo> => {
    return axios.put(`/api/v1/members/${memberId}/info`, data);
  };

  public getAdditionalInfoForMember = (memberId: number): Promise<IAdditionalInfo> => {
    return axios.get(`/api/v1/members/${memberId}/info`);
  };

  public getFamilyMembers = (memberId: number): Promise<IFamilyMember[]> => {
    return axios.get(`/api/v1/members/${memberId}/family-members`);
  };

  public addFamilyMember = (
    memberId: number,
    formData: IFamilyMemberCreateDTO,
  ): Promise<IFamilyMember> =>
    axios.post(`/api/v1/members/${memberId}/family-members/add`, formData);

  public saveFamilyMembers = (
    memberId: number,
    data: IFamilyMemberEditDTO,
  ): Promise<IFamilyMember[]> => axios.put(`/api/v1/members/${memberId}/family-members`, data);

  public getPersonWaivers = (personId: number): Promise<IPersonDocumentItemDto[]> => {
    return axios.get(`/api/v1/members/${personId}/documents/waiver`);
  };

  public applyWaiverSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/members/${personId}/documents/waiver/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public signWaivers = (
    personId: number,
    payload: IPersonDocumentToSignDto[],
  ): Promise<IPersonDocumentItemDto[]> => {
    return axios.put(`/api/v1/members/${personId}/documents/waiver/sign`, payload);
  };

  // public saveWaivers = (memberId: number, data: any): Promise<any> => {
  //   return http(`/api/v1/`).put(`members/${memberId}/waivers`, data);
  // };

  // public async searchGuests(searchParams: IPersonSearchParams): Promise<any> {
  //   const res: any = await new Promise((resolve, reject) => {
  //     setTimeout(() => {
  //       const filteredMembers: any = this.allMembers.data.filter(member => {
  //         const isFilteredArray = [];
  //         if (searchParams.phone) {
  //           const phones = member.person.phones.filter(item =>
  //             item.phone.startsWith(searchParams.phone),
  //           );
  //           isFilteredArray.push(phones.length > 0);
  //         }
  //         if (searchParams.firstName) {
  //           isFilteredArray.push(
  //             member.person.firstName
  //               .toLowerCase()
  //               .startsWith(searchParams.firstName.toLowerCase()),
  //           );
  //         }
  //         if (searchParams.lastName) {
  //           isFilteredArray.push(
  //             member.person.lastName.toLowerCase().startsWith(searchParams.lastName.toLowerCase()),
  //           );
  //         }
  //         return isFilteredArray.length ? isFilteredArray.every(item => item) : false;
  //       });
  //       resolve(filteredMembers);
  //     }, 800);
  //   });
  //   this.guestsList = res;
  //   return this.guestsList;
  // }

  /**
   * Search family members by search string, returns list of all possible members,
   * and also returns list of already associated members as unavailableIds
   * @param searchStr - string, to search matches in first and last names.
   * @param personId - id of member, to return list with ids of already associated members
   */
  public searchFamilyMembers = (
    searchStr: string,
    personId: number,
  ): Promise<IFamilyMemberSearch> => {
    const config = {
      params: searchStr
        ? {
            search: searchStr,
          }
        : {},
    };
    return axios.get(`/api/v1/members/${personId}/family-members/search`, config);
  };

  public uploadPhoto = async (memberId: number, image: IStoredFileSimpleDto): Promise<void> => {
    await axios.put(`/api/v1/members/${memberId}/avatar`, image);
  };

  public deletePhoto = (memberId: number): Promise<void> => {
    return axios.delete(`/api/v1/members/${memberId}/avatar`);
  };

  public async getMembers(value: string, personId: number) {
    this.personsList = await new Promise(resolve => {
      setTimeout(() => {
        if (value) {
          const filteredMembers: any = this.allMembers.data.filter(member => {
            const fullName = `${member.person.firstName} ${member.person.lastName}`.trim();
            return (
              fullName.toLowerCase().startsWith(value.toLowerCase()) &&
              member.person.id !== personId
            );
          });
          resolve(filteredMembers);
        }
        resolve(this.allMembers.data);
      }, 800);
    });

    return this.personsList;
  }

  public async checkInMember(personId: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        const selectedMember = this.allMembers.data.find(item => item.person.id === personId);

        selectedMember.id = Math.floor(10 + Math.random() * (1000 + 1 - 10));
        selectedMember.createdAt = new Date().toISOString();
        selectedMember.checkInType = 'manual';
        resolve(this.guestsList);
      }, 800);
    });
  }

  public async checkOutMember(personId: number) {
    return new Promise(resolve => {
      setTimeout(() => {
        const selectedMember = this.allMembers.data.find(item => item.person.id === personId);

        selectedMember.createdAt = '';
        selectedMember.checkInType = '';
        resolve(this.guestsList);
      }, 800);
    });
  }

  public getPersonContract = (personId: number): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/members/${personId}/documents/contract`);
  };

  public getPersonDocumentById = (
    personId: number,
    documentId: string,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/members/${personId}/documents/${documentId}`);
  };

  public applyContractSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/members/${personId}/documents/contract/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public signContract = (
    personId: number,
    payload: IPersonDocumentToSignDto,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.put(`/api/v1/members/${personId}/documents/contract/sign`, payload);
  };

  public printDocumentById = (personId: number, documentId: string): Promise<string> => {
    return axios.get(`/api/v1/members/${personId}/documents/${documentId}/print`);
  };

  public printContract = (personId: number): Promise<string> => {
    return axios.get(`/api/v1/members/${personId}/documents/contract/print`);
  };

  public printWaiverById = (personId: number, waiverId: string): Promise<string> => {
    return axios.get(`/api/v1/members/${personId}/documents/waiver/${waiverId}/print`);
  };

  public getContactInfo = (personId: number): Promise<IContactInfo> => {
    return axios.get(`/api/v1/members/${personId}/contact-info`);
  };

  public deleteFamilyMember = (personId: number, id: string): Promise<void> => {
    return axios.delete(`/api/v1/members/${personId}/family-members/${id}`);
  };

  public getAllNotes = (
    customerId: number,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<INote>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/members/${customerId}/notes`, { params: searchParams });
  };

  public getNoteById = (personId: number, noteId: string): Promise<INote> =>
    axios.get(`/api/v1/members/${personId}/notes/${noteId}`);

  public updateNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, id, ...data } = requestData;
    return axios.put(`/api/v1/members/${customerId}/notes/${id}`, data);
  };

  public deleteNote = (personId: number, noteId: string): Promise<INote> =>
    axios.delete(`/api/v1/members/${personId}/notes/${noteId}`);

  public createNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, ...data } = requestData;
    return axios.post(`/api/v1/members/${customerId}/notes`, data);
  };

  // appointments

  public getPersonAppointmentsHistory = (
    memberId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IAppointmentHistoryItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/members/${memberId}/appointment`, { params: searchParams });
  };

  public getRecentMemberAppointments = (memberId: number): Promise<IRecentAppointmentItem[]> =>
    axios.get(`/api/v1/members/${memberId}/appointment/recent`);

  public getPersonAppointmentById = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/members/${personId}/appointment/${appointmentId}`, {
      params: { eventStartDate: appointmentDate },
    });

  public restorePersonAppointment = (
    requestParams: IRestoreAppointmentsParams,
  ): Promise<IRestoredAppointment> => {
    const { personId, appointmentId, date } = requestParams;
    return axios.post(`/api/v1/members/${personId}/appointment/${appointmentId}/restore`, null, {
      params: { eventStartDate: date },
    });
  };

  public updatePersonAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IBookingEvent,
  ): Promise<IBookingEvent> =>
    axios.put(`/api/v1/members/${personId}/appointment/${appointmentId}`, payload, {
      params: { eventStartDate: appointmentDate },
    });

  public createMemberAppointment = (
    personId: number,
    data: IBookingEvent,
  ): Promise<IBookingEvent> => axios.post(`/api/v1/members/${personId}/appointment`, data);

  public deleteAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.delete(`/api/v1/members/${personId}/appointment/${appointmentId}`, {
      data: payload,
      params: { eventStartDate: appointmentDate },
    });

  public cancelAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/members/${personId}/appointment/${appointmentId}/cancel`, payload, {
      params: { eventStartDate: appointmentDate },
    });

  public cancelAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(
      `api/v1/members/${personId}/appointment/${appointmentId}/cancel-participation`,
      null,
      { params: { eventStartDate: appointmentDate, updateType } },
    );
  };

  public confirmAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/members/${personId}/appointment/${appointmentId}/approve`, null, {
      params: { eventStartDate: appointmentDate, updateType },
    });
  };

  public remindPersonAppointment = (
    appointmentId: string,
    personId: number,
    data: IRemindAppointmentDto,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/members/${personId}/appointment/${appointmentId}/remind`, data);

  // appointment services
  public getAppointmentServices = (personId: number, search: string): Promise<INamedEntity[]> => {
    return axios.get(`/api/v1/members/${personId}/appointment/services`, {
      params: { title: search },
    });
  };

  public getServicesForEventRedeem = (
    personId: number,
    appointmentId: string,
    serviceId: string,
  ): Promise<IServiceItemDetails[]> =>
    axios.get(`/api/v1/members/${personId}/appointment/${appointmentId}/services`, {
      params: { serviceId },
    });

  public getMemberSalesInfo = (id: number): Promise<IPersonSalesInfo> => {
    return axios.get(`/api/v1/members/${id}/sales-info`);
  };

  public updateMemberSalesInfo = (
    personId: number,
    data: INewLeadProfileData,
  ): Promise<INewLeadProfileData> => {
    return axios.put(`/api/v1/members/${personId}/sales-info`, data);
  };

  // POS
  public searchPOSInventory = (
    registerId: string,
    search: string,
  ): Promise<IPaginatedData<ISimpleClubInventoryItem>> => {
    return axios.get(`/api/v1/members/pos/register/${registerId}/inventory`, {
      params: { search },
    });
  };

  public searchPOSPackages = (
    search: string,
    clubId: string,
    employeeId: number,
  ): Promise<ISearchPackagesResult> =>
    axios.get(`/api/v1/members/pos/package-list`, {
      params: { search, clubId, employeeId },
    });

  public searchPOSGiftCards = (clubIds: string[], search: string): Promise<ISearchGiftCard[]> =>
    axios.get(`/api/v1/members/pos/gift-card-list`, {
      params: { search, clubs: clubIds.join(',') },
    });

  // Open Register
  public openRegister = (registerId: string, data: IPosKioskOpenRegisterDTO): Promise<void> => {
    return axios.put(`/api/v1/members/pos/register/${registerId}/open`, JSON.stringify(data));
  };

  // Invoice Data
  public createNewInvoice = (
    registerId: string,
    customerId?: number,
  ): Promise<IInvoiceDetailsDto> => {
    const params = {
      registerId,
      ...(customerId ? { customerId } : {}),
    };

    return axios.post('/api/v1/members/pos/invoice', null, {
      params,
    });
  };

  public addInvoiceUnit = (
    invoiceId: string,
    data: Partial<IAddInvoiceUnitDTO>,
    unitType: CartUnitTypes,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(`/api/v1/members/pos/invoice/add-invoice-unit`, data, {
      params: { type: unitType, invoiceId },
    });

  public deleteInvoiceUnit = (
    invoiceId: string,
    invoiceUnitId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.delete(`/api/v1/members/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`);

  public changeInvoiceUnitNumber = (
    invoiceId: string,
    invoiceUnitId: string,
    unitNumber: number,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/members/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`, null, {
      params: {
        number: unitNumber,
      },
    });

  public changeInvoiceUnitsRegister = (
    invoiceId: string,
    customerId: number,
    employeeId: number,
    invoiceUnitIds: Array<string>,
    registerId: string,
    targetInvoiceId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/members/pos/invoice/${invoiceId}/invoice-unit/change-register`, null, {
      params: {
        customerId,
        employeeId,
        invoiceUnitIds: invoiceUnitIds.join(','),
        registerId,
        targetInvoiceId: targetInvoiceId ?? null,
      },
    });

  public sendNotifications = (invoiceId: string, data: INotifications): Promise<void> => {
    return axios.put(`/api/v1/members/pos/invoice/${invoiceId}/send`, data);
  };

  public addInvoiceDiscount = (
    invoiceId: string,
    discountPercentage: number,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/members/pos/invoice/${invoiceId}/discount`, null, {
      params: { discountPercentage },
    });
  };

  // Get Member Membership for payment step
  public getPersonMembership = (
    memberId: number,
    salespersonId: string,
  ): Promise<IConfiguredPackage> => {
    const params = {
      employeeId: salespersonId,
    };
    return axios.get(`/api/v1/members/${memberId}/membership`, { params });
  };

  // Documents

  public getPersonDocuments = (personId: number): Promise<IPersonDocuments> =>
    axios.get(`/api/v1/members/${personId}/documents`);

  public updatePersonAttachments = (
    personId: number,
    attachments: IAttachment[],
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/members/${personId}/documents/attachments`, attachments);

  public deletePersonAttachment = (
    personId: number,
    attachmentId: string,
  ): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/members/${personId}/documents/attachments/${attachmentId}`);

  public downloadPersonAttachment = (personId: number, attachmentId: string): Promise<void> =>
    axios.get(`/api/v1/members/${personId}/documents/${attachmentId}/download`);

  // Tickets
  public getPersonTickets = (personId: number): Promise<ITicketShortDto[]> => {
    return axios.get(`/api/v1/members/${personId}/tickets`);
  };

  public getPersonTicket = (personId: number, ticketId: number): Promise<ITicketDetailedDto> => {
    return axios.get(`/api/v1/members/${personId}/tickets/${ticketId}`);
  };

  public createPersonTicket = (
    personId: number,
    attachments: File[],
    dto: ITicketCreateDto<number>,
  ): Promise<ITicketShortDto[]> => {
    const formData = new FormData();

    attachments.forEach((file: File) => {
      formData.append('attachments', file);
    });

    const blob = new Blob([JSON.stringify(dto)], {
      type: 'application/json',
    });

    formData.append('dto', blob);

    return axios.post(`/api/v1/members/${personId}/tickets`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  public getCustomers = (
    tableParams: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
    personId?: number,
  ): Promise<IPaginatedData<ICustomerSimpleDto>> => {
    const { searchStr, ...params } = tableParams;
    return axios.get(`/api/v1/front-desk/${personId}/tickets/customers`, {
      params: { ...params, search: searchStr },
    });
  };

  // Modification history

  public getModificationHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IModificationHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/members/${personId}/modification-history`, { params: searchParams });
  };

  public getRecentModificationHistory = (personId: number): Promise<IModificationHistory[]> => {
    return axios.get(`/api/v1/members/${personId}/modification-history/recent`);
  };

  public getModificationHistoryItem = (
    personId: number,
    revisionId: number,
  ): Promise<IModificationHistoryItem> =>
    axios.get(`/api/v1/members/${personId}/modification-history/${revisionId}`);

  // Membership packages

  public getMembershipPackages = (clubId: string, search: string): Promise<IShortPackages> =>
    axios.get('/api/v1/members/membership-packages/list', { params: { clubId, search } });

  public cancelInvoice = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/members/pos/invoice/${invoiceId}/cancel`);

  public getPersonAdditionalInfo = (personId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/members/${personId}/additional-fields`);

  // Update additional info
  public updatePersonAdditionalInfo = (
    personId: number,
    additionalInfo: IProfileAdditionalPayload[],
  ): Promise<IProfileInfo> =>
    axios.put(`/api/v1/members/${personId}/additional-fields`, additionalInfo);

  public getRecentPersonPayments = (personId: number): Promise<IPayment[]> =>
    axios.get(`/api/v1/members/${personId}/member-payments/recent`);

  public getPersonPayments = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IPayment>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/members/${personId}/member-payments`, { params: searchParams });
  };

  public voidPersonInvoice = (
    personId: number,
    invoiceId: string,
    reason: string,
  ): Promise<void> => {
    return axios.put(`api/v1/members/${personId}/member-payments/${invoiceId}/void`, { reason });
  };

  public getPaymentDetails = (personId: number, invoiceId: string): Promise<IInvoiceDetailsDto> =>
    axios.get(`/api/v1/members/${personId}/member-payments/${invoiceId}`);

  public getRecentCheckInsHistory = (personId: number): Promise<Array<ICheckInHistory>> =>
    axios.get(`/api/v1/members/${personId}/checkin-history/recent`);

  public getCheckInsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ICheckInHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/members/${personId}/checkin-history`, { params: searchParams });
  };

  public getDocumentsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IDocumentsHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/members/${personId}/documents/history`, {
      params: searchParams,
    });
  };

  public getPackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/members/${personId}/membership/${membershipId}/billing-schedules`, {
      params: searchParams,
    });
  };

  public getServicePackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/members/${personId}/service-section/service-packages/${membershipId}/cancel/billing-schedules`,
      {
        params: searchParams,
      },
    );
  };

  public getPersonPastDues = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue[]> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/members/${personId}/past-dues`, { params: searchParams });
  };

  public getPersonPastDuesForReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/members/${personId}/membership/reactivate-package/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/members/${personId}/service-section/reactivate/past-dues`, {
      params: searchParams,
    });
  };

  public getInvoiceReceipt = (invoiceId: string): Promise<string> =>
    axios.get(`/api/v1/members/pos/invoice/${invoiceId}/receipt`);

  public fetchOpenInvoices = (registerId: string): Promise<IInvoiceDetailsDto[]> =>
    axios.get(`/api/v1/members/pos/register/${registerId}/open-invoices`);

  public getPersonPastDuesForCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/members/${personId}/membership/${membershipId}/cancel/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/members/${personId}/service-section/service-packages/${membershipId}/cancel/past-dues`,
      {
        params: searchParams,
      },
    );
  };

  public cancelPersonMembership = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(`/api/v1/members/${personId}/membership/${membershipId}/cancel`, data);

  public cancelServicePackage = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(
      `/api/v1/members/${personId}/service-section/service-packages/${membershipId}/cancel`,
      data,
    );

  public fetchPersonSubscriptions = (
    memberId: number,
  ): Promise<Array<IPackageInstanceSubscription>> =>
    axios.get(`/api/v1/members/${memberId}/subscriptions`);

  public fetchPersonPaymentMethods = (
    memberId: number,
  ): Promise<IPaginatedData<IPaymentMethodItem>> =>
    axios.get(`/api/v1/members/${memberId}/subscriptions/payment-methods`);

  public updateMemberSubscriptions = (
    memberId: number,
    data: IPackageInstanceSubscriptionUpdateDTO[],
  ): Promise<void> => axios.put(`/api/v1/members/${memberId}/subscriptions`, data);

  public fetchPaymentAccounts = (
    memberId: number,
    paymentAccountType?: PaymentAccountType,
    cardType?: PaymentsType,
  ): Promise<IPaymentAccount[]> => {
    const config = {
      params: {
        ...(cardType && { cardType }),
        ...(paymentAccountType && { paymentAccountType }),
      },
    };

    return axios.get(`/api/v1/members/${memberId}/payment-accounts`, config);
  };

  public addCheckingPaymentAccount = (
    memberId: number,
    data: Omit<IPaymentAccount, 'id'>,
  ): Promise<IPaymentAccount> => {
    return axios.post(`/api/v1/members/${memberId}/payment-accounts/add-checking-saving`, data);
  };

  public deletePaymentAccount = (memberId: number, paymentAccountId: string): Promise<void> =>
    axios.delete(`/api/v1/members/${memberId}/payment-accounts/${paymentAccountId}`);

  public addCreditCardPaymentAccount = (
    memberId: number,
    data: IAddCreditCard,
  ): Promise<IProfileInfo> => {
    const { expiredIn } = data;
    const expireSplit: string[] = expiredIn.split('/');
    const modifiedData: Omit<IAddCreditCard, 'expiredIn'> & {
      number: string;
      expirationMonth: string;
      expirationYear: string;
      expiredIn?: string;
    } = {
      ...data,
      number: data.cardNumber.replaceAll(' ', ''),
      expirationMonth: expireSplit[0],
      expirationYear: expireSplit[1],
    };

    delete modifiedData.expiredIn;
    return axios.post(`/api/v1/members/${memberId}/payment-accounts/add-credit-card`, modifiedData);
  };

  public getProfileInfo = (personId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/members/${personId}`);

  public setTags = (personId: number, profileTags: List<ITag>): Promise<{ id: string }> => {
    return axios.put(
      `/api/v1/members/${personId}/tags`,
      profileTags.map(tag => tag.id),
    );
  };

  public fetchFreezeItems = (memberId: number): Promise<IFreezeItem[]> => {
    return axios.get(`/api/v1/members/${memberId}/membership/freeze`);
  };

  public fetchServicePackageFreezeInformation = (memberId: number): Promise<IFreezeItem[]> =>
    axios.get(`/api/v1/members/${memberId}/service-section/freeze`);

  public freezeMembershipPackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/members/${personId}/membership/freeze`, data);
  };

  public unfreezeMembershipPackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/members/${personId}/membership/unfreeze/${packageId}`);

  public freezePackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> =>
    axios.post(`/api/v1/members/${personId}/service-section/freeze`, data);

  public unfreezePackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/members/${personId}/service-section/unfreeze/${packageId}`);

  public freezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> => {
    return axios.get(`/api/v1/members/${personId}/membership/freeze/history`);
  };

  public getServicePackageFreezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> =>
    axios.get(`/api/v1/members/${personId}/service-section/freeze/history`);

  public fetchReactivateMembershipItems = (
    personId: number,
  ): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/members/${personId}/membership/past-packages`);
  };

  public fetchCancelItems = (personId: number): Promise<IPackageInstanceDetails[]> =>
    axios.get(`/api/v1/members/${personId}/service-section/service-packages`);

  public fetchReactivateServiceItems = (personId: number): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/members/${personId}/service-section/past-packages`);
  };

  public setSalespersonToInvoice = (
    invoiceId: string,
    salespersonId: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/members/pos/invoice/${invoiceId}/salesperson `, null, {
      params: { employeeId: salespersonId },
    });
  };

  public setCustomerToInvoice = (
    invoiceId: string,
    customerId: number | null,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`api/v1/members/pos/invoice/${invoiceId}/customer`, null, {
      params: { customerId },
    });
  };

  public fetchBalanceById = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/members/pos/balance', { params: { customerId } });
  };

  // Activities
  public getMemberActivities = (): Promise<IDictionaryCommonItem[]> =>
    axios.get('/api/v1/members/activity');

  public createMemberActivity = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> =>
    axios.post('/api/v1/members/activity', data);

  public fetchAvailableMembershipPackageActions = (
    customerId: number,
  ): Promise<IMembershipActionDTO> => axios.get(`/api/v1/members/${customerId}/membership-actions`);

  public fetchMembership = (personId: number, packageId: string): Promise<IBundleView> => {
    return axios.get(`api/v1/members/${personId}/membership/${packageId}`);
  };

  // Rewards
  public fetchRewardsById = (customerId: number): Promise<IRewards> => {
    return axios.get('api/v1/members/pos/rewards', { params: { customerId } });
  };

  // Commerce Hub
  public fetchCommerceHubCredentials = (personId: number): Promise<ICommerceHubCredentials> => {
    return axios.get(`api/v1/members/${personId}/commerce-hub/get-security-credentials`);
  };

  public tokenizeCommerceHubEncryptedCard = (
    personId: number,
    data: any,
  ): Promise<IPaymentAccount[]> => {
    return axios.post(`api/v1/members/${personId}/commerce-hub/tokenize-credit-card`, data);
  };

  public getPersonBalanceHistory = (customerId: number): Promise<IBalanceHistoryItem[]> => {
    return axios.get(`api/v1/members/${customerId}/balance-history`);
  };
}
