import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
import { TSenderAvailabilityImt } from 'modules/booking/interfaces';
import { AppStoreTypeImt } from 'store';
import { ICorporationItemImt } from '../../../../modules/authentication/interfaces';

const profilePath = ['memberPortalProfile', 'profile'];

export const selectMemberProfile = (state): IProfileInfoImt => {
  return state.getIn([...profilePath, 'result']);
};

export const selectMemberProfileCorporation = (state): ICorporationItemImt => {
  return state.getIn([...profilePath, 'result', 'corporation']);
};

export const selectMemberProfileLoading = (state): boolean => {
  return state.getIn([...profilePath, 'isLoading']);
};

export const selectPersonAlerts = state => {
  return state.getIn([...profilePath, 'alerts']);
};

export const selectSenderAvailability = (type: SenderAvailabilityTypeList) => (
  state: AppStoreTypeImt,
): TSenderAvailabilityImt => {
  return state.getIn([...profilePath, 'sendAvailability', type, 'data']);
};

export const selectPersonBalanceHistory = state => {
  return state.getIn([...profilePath, 'balanceHistory']);
};

export const selectPersonBalanceHistoryLoading = state => {
  return state.getIn([...profilePath, 'balanceHistoryLoading']);
};

export const selectPersonRewardsHistory = state => {
  return state.getIn([...profilePath, 'rewardsHistory']);
};

export const selectPersonRewardsHistoryLoading = state => {
  return state.getIn([...profilePath, 'rewardsHistoryLoading']);
};
