// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
// components
import { Button, DialogComponent } from 'common/components';
import CheckingSavingsStep from './steps/checkingSavings/CheckingSavingsStep';
import CashPaymentStep from './steps/cash/CashPaymentStep';
import OtherTypePaymentStep from './steps/gift/OtherTypePaymentStep';
import CreditCardPaymentStep from './steps/creditCard/CreditCardPaymentStep';
import OnAccountStep from './steps/onAccount/onAccountStep';
import SelectPaymentTypeStep from './steps/SelectPaymentTypeStep';
// interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IInvoiceDetailsImt, PaymentsType } from 'common/interfaces/invoices';
// constants
import { PMethodSteps, PMethodStepsTitles } from '../constants';
import { PaymentsTypeLabels } from 'modules/pos-settings/constants/paymentMethods';
import { PeakModules } from 'common/constants/peakModules';
// messages
import messages from '../messages';
// icons
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';
// utils
import { formatPrice } from 'common/utils';
// state
import {
  fetchAvailablePaymentMethods,
  fetchCustomerBalanceThunk,
  fetchCustomerRewardsThunk,
  resetAvailablePaymentMethodsList,
  resetCustomerBalance,
  updateInvoiceWithSyncErrorAction,
} from 'common/state/invoice/actions';
import { fetchPosKioskRegisterData } from 'modules/pos-kiosk/state/register/actions';
import {
  selectAvailablePaymentMethods,
  selectAvailablePaymentMethodsLoading,
  selectUpdatedInvoiceError,
  selectCurrentInvoice,
  selectCustomerBalance,
  selectCustomerBalanceLoading,
  selectCustomerRewards,
  selectCustomerRewardsLoading,
} from 'common/state/invoice/selectors';
import { FormattedMessageByCode, ServerError } from 'common/errors/serverErrors';
import { parseErrorArgs } from '../../../utils/http';
import ClientRewardsStep from './steps/clientRewards/ClientRewardsStep';

interface IInvoicePaymentWizardProps {
  isOpen: boolean;
  leftToPay: number;
  memberId?: number;
  clubId: string;

  registerId: string;
  isSystemRegister: boolean;
  module: PeakModules;
  isPaymentStep?: boolean;

  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  leftToPayBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  leftToPayPrice: {
    fontWeight: theme.typography.fontWeightMedium as number,
  },
  backButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  backIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(1.5),
  },
}));

const InvoicePaymentWizard: React.FC<IInvoicePaymentWizardProps> = (
  props: IInvoicePaymentWizardProps,
): JSX.Element => {
  const classes = useStyles(props);
  const {
    isOpen,
    module,
    registerId,
    isSystemRegister,
    isPaymentStep,
    leftToPay,
    memberId,
    onClose,
    clubId,
  } = props;

  const [stepsStack, setStepsStack] = useState<PMethodSteps[]>([PMethodSteps.SelectMethodStep]);
  const [selectedType, setSelectedType] = useState<PaymentsType | null>(null);

  // global state

  const dispatch = useDispatch();

  const paymentMethods = useSelector(selectAvailablePaymentMethods);
  const isPaymentMethodsLoading = useSelector(selectAvailablePaymentMethodsLoading);
  const paymentError = useSelector(selectUpdatedInvoiceError);
  const selectedInvoice: IInvoiceDetailsImt = useSelector(selectCurrentInvoice);
  const selectedCustomerBalanceLoading = useSelector(selectCustomerBalanceLoading);
  const selectedCustomerBalance = useSelector(selectCustomerBalance);
  const selectedCustomerRewards = useSelector(selectCustomerRewards);
  const selectedCustomerRewardsLoading = useSelector(selectCustomerRewardsLoading);

  // constants

  const isOnHandExceededServerError = paymentError
    ?.toJS()
    .codes.find(code => code === ServerError.INVENTORY_CLUB_ON_HAND_EXCEEDED);
  const currentStep = useMemo(() => stepsStack[stepsStack.length - 1], [stepsStack]);
  const paymentTypeId = useMemo(() => {
    const pMethod = paymentMethods.find(method => method.get('type') === selectedType);
    if (pMethod) {
      return pMethod.get('id');
    }

    return null;
  }, [paymentMethods, selectedType]);

  // handlers

  const handleSelectStep = useCallback((step: PMethodSteps, type: PaymentsType): void => {
    setStepsStack(prevStack => {
      if (prevStack.includes(step)) return prevStack;
      return [...prevStack, step];
    });

    setSelectedType(type);
  }, []);

  const handleGoBack = useCallback(() => {
    const prevStep = stepsStack[stepsStack.length - 2];

    setStepsStack(prevSteps => {
      prevSteps.pop();
      return [...prevSteps];
    });

    if (!prevStep || prevStep === PMethodSteps.SelectMethodStep) {
      setSelectedType(null);
    }
  }, [stepsStack]);

  // effects

  useEffect(() => {
    if (!isOpen) {
      setStepsStack([PMethodSteps.SelectMethodStep]);
      setSelectedType(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (memberId && isOpen) {
      dispatch(fetchCustomerBalanceThunk(memberId, module, isPaymentStep));
      dispatch(fetchCustomerRewardsThunk(memberId, module, isPaymentStep));
    }
  }, [memberId, module, dispatch, isPaymentStep, isOpen]);

  useEffect(() => {
    if (isOnHandExceededServerError && !isOpen) {
      dispatch(fetchPosKioskRegisterData(registerId));
      dispatch(updateInvoiceWithSyncErrorAction(null));
    }
  }, [dispatch, isOnHandExceededServerError, isOpen, registerId, selectedInvoice]);

  // effects

  useEffect(() => {
    if (isOpen && module && registerId) {
      dispatch(fetchAvailablePaymentMethods(module, registerId, isPaymentStep));
    }

    return () => {
      batch(() => {
        dispatch(resetAvailablePaymentMethodsList());
        dispatch(resetCustomerBalance());
      });
    };
  }, [dispatch, isOpen, isPaymentStep, module, registerId]);

  // renders

  const Title: JSX.Element = useMemo(
    () => (
      <>
        {currentStep !== PMethodSteps.SelectMethodStep && (
          <Button
            size="small"
            className={classes.backButton}
            startIcon={<BackArrowIcon className={classes.backIcon} />}
            onClick={handleGoBack}
          />
        )}

        {!selectedType && <FormattedMessage {...PMethodStepsTitles.message(currentStep)} />}

        {!!selectedType && PaymentsTypeLabels[selectedType]}
      </>
    ),
    [classes, currentStep, handleGoBack, selectedType],
  );

  const LeftToPay: JSX.Element = useMemo(
    () => (
      <Box className={classes.leftToPayBlock}>
        <Typography component="span">
          <FormattedMessage {...messages.leftToPay} />
        </Typography>
        <Typography component="span" className={classes.leftToPayPrice}>
          {formatPrice(leftToPay)}
        </Typography>
      </Box>
    ),
    [classes.leftToPayBlock, classes.leftToPayPrice, leftToPay],
  );

  const SubHeader = useMemo(() => {
    const errorCode = paymentError?.getIn(['codes', 0]);
    const errorArgs = paymentError?.get('args')?.toJS();
    const errorValues = errorArgs && parseErrorArgs(errorArgs);
    return (
      <Box mt={2} p={0}>
        {paymentError && (
          <Alert className={classes.error} severity="error">
            {FormattedMessageByCode(errorCode, errorValues) || paymentError.get('message')}
          </Alert>
        )}
        {LeftToPay}
      </Box>
    );
  }, [LeftToPay, classes.error, paymentError]);

  const balance = selectedCustomerBalance?.get('balance') || 0;
  const rewards = selectedCustomerRewards?.get('points') || 0;

  return (
    <DialogComponent
      size="sm"
      isOpen={isOpen}
      title={Title}
      closeCross
      subHeader={SubHeader}
      hasCustomFooter
      supressBottomShadow
      onClose={onClose}
    >
      {currentStep === PMethodSteps.SelectMethodStep && (
        <SelectPaymentTypeStep
          paymentMethodsList={paymentMethods}
          isLoading={
            isPaymentMethodsLoading ||
            selectedCustomerBalanceLoading ||
            selectedCustomerRewardsLoading
          }
          balance={balance}
          onSelectStep={handleSelectStep}
          memberId={memberId}
          isSystemRegister={isSystemRegister}
          rewards={rewards}
        />
      )}

      {currentStep === PMethodSteps.CashPayment && (
        <CashPaymentStep
          clubId={clubId}
          module={module}
          isPaymentStep={isPaymentStep}
          memberId={memberId}
          leftToPay={leftToPay}
          paymentTypeId={paymentTypeId}
          onClose={onClose}
        />
      )}

      {currentStep === PMethodSteps.CreditCardPayment && (
        <CreditCardPaymentStep
          clubId={clubId}
          module={module}
          isPaymentStep={isPaymentStep}
          memberId={memberId}
          leftToPay={leftToPay}
          creditCardType={selectedType}
          paymentTypeId={paymentTypeId}
          onClose={onClose}
        />
      )}

      {currentStep === PMethodSteps.CheckingSavings && (
        <CheckingSavingsStep
          module={module}
          clubId={clubId}
          isPaymentStep={isPaymentStep}
          paymentTypeId={paymentTypeId}
          leftToPay={leftToPay}
          onClose={onClose}
          memberId={memberId}
        />
      )}

      {currentStep === PMethodSteps.OnAccount && (
        <OnAccountStep
          module={module}
          leftToPay={leftToPay}
          balance={balance}
          isPaymentStep={isPaymentStep}
          paymentTypeId={paymentTypeId}
          memberId={memberId}
          clubId={clubId}
          onClose={onClose}
        />
      )}

      {currentStep === PMethodSteps.OtherTypePayment && (
        <OtherTypePaymentStep
          module={module}
          isPaymentStep={isPaymentStep}
          registerId={registerId}
          leftToPay={leftToPay}
          onClose={onClose}
          clubId={clubId}
        />
      )}

      {currentStep === PMethodSteps.ClientRewards && (
        <ClientRewardsStep
          module={module}
          rewards={rewards}
          isPaymentStep={isPaymentStep}
          paymentTypeId={paymentTypeId}
          memberId={memberId}
          clubId={clubId}
          onClose={onClose}
          leftToPay={leftToPay}
        />
      )}
    </DialogComponent>
  );
};

export default InvoicePaymentWizard;
