import axios from 'axios';
import { List } from 'immutable';

import ApiService from './ApiService';
import {
  IConfiguredPackage,
  IMembershipTransformed,
  IPackageConfiguration,
  IPackageInventoryViewDto,
  IShortPackages,
} from 'common/interfaces/membership';
import {
  IContactInfo,
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
} from 'common/interfaces/contract';
import {
  CancelMembershipData,
  FreezeMembershipData,
  IBilling,
  ICheckInHistory,
  IDocumentsHistory,
  IFamilyMember,
  IFamilyMemberCreateDTO,
  IFamilyMemberEditDTO,
  IFamilyMemberSearch,
  IFamilyMemberService,
  IFreezeHistoryPackage,
  IFreezeItem,
  IMemberAutocompleteSearch,
  IModificationHistory,
  IModificationHistoryItem,
  INote,
  INoteCodeRequestPayload,
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionUpdateDTO,
  IPastDue,
  IPayment,
  IPersonDocuments,
  IPrimaryMemberInfo,
  IProfileAdditionalPayload,
  IProfileInfo,
  IPaymentAccount,
  ITag,
  ICustomerShort,
  ITicketShortDto,
  ITicketDetailedDto,
  ITicketCreateDto,
  ICustomerSimpleDto,
  IMembershipActionDTO,
  ICommerceHubCredentials,
  ITopUpRewardsData,
  IReferralShortDto,
  IReferralCreateDto,
  ISetReferredDto,
  IBalanceHistoryItem,
  IRewardsHistoryItem,
} from 'common/components/PersonProfile/interfaces';
import { IAdditionalInfo } from 'modules/front-desk/interfaces';
import {
  EventUpdateType,
  IAppointmentHistoryItem,
  IBookingEvent,
  IEventAction,
  IRecentAppointmentItem,
  IRemindAppointmentDto,
  IRestoreAppointmentsParams,
  IRestoredAppointment,
} from 'modules/booking/interfaces';
import { INamedEntity, IObject } from 'common/interfaces/common';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPosKioskOpenRegisterDTO } from 'modules/pos-kiosk/interfaces/registers';
import {
  IInvoiceDetailsDto,
  IInvoicePaymentSplit,
  INotifications,
  ISearchPackagesResult,
  PaymentsType,
  IBalance,
  CartUnitTypes,
  IAddInvoiceUnitDTO,
  PaymentAccountType,
  IRewards,
  ISearchGiftCard,
} from 'common/interfaces/invoices';
import { ISuccessResponse, PersonSearchParamsType } from 'common/interfaces/http';
import { IAttachment, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { ISignInResponse, IUnlockCredentials } from 'modules/authentication/interfaces';
import { IEntranceItem } from 'modules/clubs/interfaces';
import { IPersonSearchBarValues } from 'common/components/ManualCheckInPanel/components/PersonSearchBar/interfaces';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { IPackageInstanceDetails, IServiceItemDetails } from 'common/interfaces/service';
import { IBundleView } from 'modules/services/interfaces/packages';

export default class FrontDeskService extends ApiService {
  public fetchOpenInvoicesInMembership = (registerId: string): Promise<IInvoiceDetailsDto[]> => {
    return axios.get(`/api/v1/front-desk/customer-pos/register/${registerId}/open-invoices`);
  };

  public fetchOpenInvoicesInPOS = (registerId: string): Promise<IInvoiceDetailsDto[]> => {
    return axios.get(`/api/v1/front-desk/pos/register/${registerId}/open-invoices`);
  };

  public getMember = (memberId: number): Promise<IProfileInfo> => {
    return axios.get(`/api/v1/front-desk/${memberId}`);
  };

  public getMemberInfoView = (memberId: number): Promise<Partial<IProfileInfo>> => {
    return axios.get(`/api/v1/front-desk/${memberId}/view`);
  };

  public getFamilyMembers = (personId: number): Promise<IFamilyMember[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/family-members`);
  };

  public deleteFamilyMember = (personId: number, id: string): Promise<void> => {
    return axios.delete(`/api/v1/front-desk/${personId}/family-members/${id}`);
  };

  public getReferralMembers = (search: string): Promise<INamedEntity[]> => {
    return axios.get('/api/v1/front-desk/referrals/list', { params: { search } });
  };

  public getPersonReferralMembers = (personId: number, search: string): Promise<INamedEntity[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/referrals/list`, { params: { search } });
  };

  public getPersonPastDues = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue[]> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/front-desk/${personId}/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDueInvoice = (
    personId: number,
    alertUuid: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.get(`/api/v1/front-desk/${personId}/alerts/${alertUuid}/past-due`);
  };

  public getPersonPastDuesForReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/front-desk/${personId}/membership/reactivate-package/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/front-desk/${personId}/service-section/reactivate/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/front-desk/${personId}/membership/${membershipId}/cancel/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/front-desk/${personId}/service-section/service-packages/${membershipId}/cancel/past-dues`,
      {
        params: searchParams,
      },
    );
  };

  public setSalespersonToInvoice = (
    invoiceId: string,
    salespersonId: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/front-desk/pos/invoice/${invoiceId}/salesperson`, null, {
      params: { employeeId: salespersonId },
    });
  };

  public setSalespersonToInvoiceCustomerPos = (
    invoiceId: string,
    salespersonId: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/front-desk/customer-pos/invoice/${invoiceId}/salesperson`, null, {
      params: { employeeId: salespersonId },
    });
  };

  public setCustomerToInvoiceCustomerPos = (
    invoiceId: string,
    customerId: number | null,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`api/v1/front-desk/customer-pos/invoice/${invoiceId}/customer`, null, {
      params: { customerId },
    });
  };

  public setCustomerToInvoicePos = (
    invoiceId: string,
    customerId: number | null,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`api/v1/front-desk/pos/invoice/${invoiceId}/customer`, null, {
      params: { customerId },
    });
  };

  public addFamilyMember = (
    memberId: number,
    formData: IFamilyMemberCreateDTO,
  ): Promise<IFamilyMember> =>
    axios.post(`/api/v1/front-desk/${memberId}/family-members/add`, formData);

  public saveFamilyMembers = (
    memberId: number,
    data: IFamilyMemberEditDTO,
  ): Promise<IFamilyMember[]> => axios.put(`/api/v1/front-desk/${memberId}/family-members`, data);

  public searchFamilyMembers = (
    searchStr: string,
    personId: number,
  ): Promise<IFamilyMemberSearch> => {
    const config = {
      params: searchStr
        ? {
            search: searchStr,
          }
        : {},
    };
    return axios.get(`/api/v1/front-desk/${personId}/family-members/search`, config);
  };

  public getSearchMembers = (
    searchStr: string,
    field: PersonSearchParamsType,
  ): Promise<IPrimaryMemberInfo[]> => {
    const params = {
      search: searchStr,
      customerSearchSortBy: field,
    };
    return axios.get('/api/v1/front-desk/search', { params });
  };

  public getMemberServices = (memberId: number): Promise<IFamilyMemberService[]> =>
    axios.get(`/api/v1/front-desk/${memberId}/family-members/services`);

  public createMember = (data: IPrimaryMemberInfo): Promise<IPrimaryMemberInfo> =>
    axios.post(`/api/v1/front-desk`, data);

  public updateCustomer = (
    memberId: number,
    data: IPrimaryMemberInfo,
  ): Promise<IPrimaryMemberInfo> => {
    return axios.put(`api/v1/front-desk/${memberId}`, data);
  };

  public updateMembershipInfo = (memberId: number, data: IMembershipTransformed): Promise<void> => {
    return axios.put(`api/v1/front-desk/${memberId}/membership`, data);
  };

  public getMembershipConfigurationPos = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/front-desk/pos/package-list/${membershipId}`, {
      params: { clubId },
    });
  };

  public getMembershipConfiguration = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/front-desk/membership-packages/${membershipId}`, {
      params: { clubId },
    });
  };

  public getMembershipConfigurationCustomerPos = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/front-desk/customer-pos/package-list/${membershipId}`, {
      params: { clubId },
    });
  };

  public getFrontDeskAutocompleteSearch = (
    search: IPersonSearchBarValues,
  ): Promise<IMemberAutocompleteSearch[]> => {
    return axios.get(`/api/v1/front-desk/front-desk-autocomplete/search`, {
      params: search,
    });
  };

  public saveAdditionalInfoForMember = (
    memberId: number,
    data: IAdditionalInfo,
  ): Promise<IAdditionalInfo> => {
    return axios.put(`/api/v1/front-desk/${memberId}/info`, data);
  };

  public getAdditionalInfoForMember = (memberId: number): Promise<IAdditionalInfo> => {
    return axios.get(`/api/v1/front-desk/${memberId}/info`);
  };

  public getPersonContract = (personId: number): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/contract`);
  };

  public getPersonDocumentById = (
    personId: number,
    documentId: string,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/${documentId}`);
  };

  public applyContractSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/front-desk/${personId}/documents/contract/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public signContract = (
    personId: number,
    payload: IPersonDocumentToSignDto,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.put(`/api/v1/front-desk/${personId}/documents/contract/sign`, payload);
  };

  public getContactInfo = (personId: number): Promise<IContactInfo> => {
    return axios.get(`/api/v1/front-desk/${personId}/contact-info`);
  };

  public printDocumentById = (personId: number, documentId: string): Promise<string> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/${documentId}/print`);
  };

  public printContract = (personId: number): Promise<string> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/contract/print`);
  };

  public printWaiverById = (personId: number, waiverId: string): Promise<string> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/waiver/${waiverId}/print`);
  };

  // appointment
  public getPersonAppointmentsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IAppointmentHistoryItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/front-desk/${personId}/appointment`, {
      params: searchParams,
    });
  };

  public getRecentPersonAppointments = (personId: number): Promise<IRecentAppointmentItem[]> =>
    axios.get(`/api/v1/front-desk/${personId}/appointment/recent`);

  public getPersonAppointmentById = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/front-desk/${personId}/appointment/${appointmentId}`, {
      params: { eventStartDate: appointmentDate },
    });

  public restorePersonAppointment = (
    requestParams: IRestoreAppointmentsParams,
  ): Promise<IRestoredAppointment> => {
    const { personId, appointmentId, date } = requestParams;
    return axios.post(`/api/v1/front-desk/${personId}/appointment/${appointmentId}/restore`, null, {
      params: { eventStartDate: date },
    });
  };

  public updatePersonAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IBookingEvent,
  ): Promise<IBookingEvent> =>
    axios.put(`/api/v1/front-desk/${personId}/appointment/${appointmentId}`, payload, {
      params: { eventStartDate: appointmentDate },
    });

  public createPersonAppointment = (
    personId: number,
    data: IBookingEvent,
  ): Promise<IBookingEvent> => axios.post(`/api/v1/front-desk/${personId}/appointment`, data);

  public deleteAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.delete(`/api/v1/front-desk/${personId}/appointment/${appointmentId}`, {
      data: payload,
      params: { eventStartDate: appointmentDate },
    });

  public cancelPersonAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    data: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/front-desk/${personId}/appointment/${appointmentId}/cancel`, data, {
      params: { eventStartDate: appointmentDate },
    });

  public cancelAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(
      `api/v1/front-desk/${personId}/appointment/${appointmentId}/cancel-participation`,
      null,
      { params: { eventStartDate: appointmentDate, updateType } },
    );
  };

  public confirmAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/front-desk/${personId}/appointment/${appointmentId}/approve`, null, {
      params: { eventStartDate: appointmentDate, updateType },
    });
  };

  public remindPersonAppointment = (
    appointmentId: string,
    personId: number,
    data: IRemindAppointmentDto,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/front-desk/${personId}/appointment/${appointmentId}/remind`, data);

  // appointment services
  public getAppointmentServices = (personId: number, search: string): Promise<INamedEntity[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/appointment/services`, {
      params: { title: search },
    });
  };

  public getServicesForEventRedeem = (
    personId: number,
    appointmentId: string,
    serviceId: string,
  ): Promise<IServiceItemDetails[]> =>
    axios.get(`/api/v1/front-desk/${personId}/appointment/${appointmentId}/services`, {
      params: { serviceId },
    });

  public getPersonWaivers = (personId: number): Promise<IPersonDocumentItemDto[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/documents/waiver`);
  };

  public applyWaiverSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/front-desk/${personId}/documents/waiver/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public signWaivers = (
    personId: number,
    payload: IPersonDocumentToSignDto[],
  ): Promise<IPersonDocumentItemDto[]> => {
    return axios.put(`/api/v1/front-desk/${personId}/documents/waiver/sign`, payload);
  };

  public getAllNotes = (
    customerId: number,
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<INote>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/front-desk/${customerId}/notes`, {
      params: searchParams,
    });
  };

  /* public getNoteById = (personId: number, noteId: string): Promise<INote> =>
    axios.get(`/api/v1/front-desk/${personId}/notes/${noteId}`); */

  public updateNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, id, ...data } = requestData;
    return axios.put(`/api/v1/front-desk/${customerId}/notes/${id}`, data);
  };

  public deleteNote = (personId: number, noteId: string): Promise<INote> =>
    axios.delete(`/api/v1/front-desk/${personId}/notes/${noteId}`);

  public createNote = (requestData: INoteCodeRequestPayload): Promise<INote> => {
    const { customerId, ...data } = requestData;

    return axios.post(`/api/v1/front-desk/${customerId}/notes`, data);
  };

  // POS
  public searchPOSInventory = (
    registerId: string,
    search: string,
    isCustomer: boolean,
  ): Promise<IPaginatedData<ISimpleClubInventoryItem>> => {
    return axios.get(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/register/${registerId}/inventory`,
      {
        params: { search },
      },
    );
  };

  public searchPOSPackages = (
    search: string,
    clubId: string,
    employeeId: number,
    isCustomer: boolean,
  ): Promise<ISearchPackagesResult> =>
    axios.get(`/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/package-list`, {
      params: { search, clubId, employeeId },
    });

  public searchPOSGiftCards = (
    clubIds: string[],
    search: string,
    isCustomer: boolean,
  ): Promise<ISearchGiftCard[]> =>
    axios.get(`/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/gift-card-list`, {
      params: { search, clubs: clubIds.join(',') },
    });

  // Open Register
  public openRegister = (
    registerId: string,
    data: IPosKioskOpenRegisterDTO,
    isCustomer: boolean,
  ): Promise<void> => {
    return axios.put(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/register/${registerId}/open`,
      JSON.stringify(data),
    );
  };

  // Invoice Data
  public createNewInvoice = (
    registerId: string,
    customerId?: number,
    isCustomer: boolean = false,
  ): Promise<IInvoiceDetailsDto> => {
    const params = {
      registerId,
      ...(customerId ? { customerId } : {}),
    };

    return axios.post(`/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/invoice`, null, {
      params,
    });
  };

  public addInvoiceUnit = (
    invoiceId: string,
    data: Partial<IAddInvoiceUnitDTO>,
    unitType: CartUnitTypes,
    isCustomer: boolean,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/invoice/add-invoice-unit`,
      data,
      {
        params: { type: unitType, invoiceId },
      },
    );

  public deleteInvoiceUnit = (
    invoiceId: string,
    invoiceUnitId: string,
    isCustomer: boolean,
  ): Promise<IInvoiceDetailsDto> =>
    axios.delete(
      `/api/v1/front-desk/${
        isCustomer ? 'customer-' : ''
      }pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`,
    );

  public changeInvoiceUnitNumber = (
    invoiceId: string,
    invoiceUnitId: string,
    unitNumber: number,
    isCustomer: boolean,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(
      `/api/v1/front-desk/${
        isCustomer ? 'customer-' : ''
      }pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`,
      null,
      {
        params: {
          number: unitNumber,
        },
      },
    );

  public changeInvoiceUnitsRegister = (
    invoiceId: string,
    customerId: number,
    employeeId: number,
    invoiceUnitIds: Array<string>,
    registerId: string,
    targetInvoiceId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(
      `/api/v1/front-desk/customer-pos/invoice/${invoiceId}/invoice-unit/change-register`,
      null,
      {
        params: {
          customerId,
          employeeId,
          invoiceUnitIds: invoiceUnitIds.join(','),
          registerId,
          targetInvoiceId: targetInvoiceId ?? null,
        },
      },
    );

  public sendNotifications = (
    invoiceId: string,
    data: INotifications,
    isCustomer: boolean,
  ): Promise<void> => {
    return axios.put(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/invoice/${invoiceId}/send`,
      data,
    );
  };

  public addInvoiceDiscount = (
    invoiceId: string,
    discountPercentage: number,
    isCustomer: boolean,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/invoice/${invoiceId}/discount`,
      null,
      { params: { discountPercentage } },
    );
  };

  // Get freeze info for freeze membership modal
  public fetchFreezeItems = (memberId: number): Promise<IFreezeItem[]> => {
    return axios.get(`/api/v1/front-desk/${memberId}/membership/freeze`);
  };

  public fetchServicePackageFreezeInformation = (memberId: number): Promise<IFreezeItem[]> =>
    axios.get(`/api/v1/front-desk/${memberId}/service-section/freeze`);

  // Freeze selected package services
  public freezeMembershipPackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> =>
    axios.post(`/api/v1/front-desk/${personId}/membership/freeze`, data);

  public unfreezeMembershipPackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/membership/unfreeze/${packageId}`);

  public freezePackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> =>
    axios.post(`/api/v1/front-desk/${personId}/service-section/freeze`, data);

  public unfreezePackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/service-section/unfreeze/${packageId}`);

  // Get freeze history for freeze history modal
  public freezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> =>
    axios.get(`/api/v1/front-desk/${personId}/membership/freeze/history`);

  public getServicePackageFreezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> =>
    axios.get(`/api/v1/front-desk/${personId}/service-section/freeze/history`);

  // Get Member Membership for payment step
  public getPersonMembership = (
    memberId: number,
    salespersonId: string,
  ): Promise<IConfiguredPackage> => {
    // TODO: Temporarily does not pass the salespersonId. This value is taken from the token
    const params = salespersonId
      ? {
          employeeId: salespersonId,
        }
      : {};
    return axios.get(`/api/v1/front-desk/${memberId}/membership`, { params });
  };

  // Documents
  public getPersonDocuments = (personId: number): Promise<IPersonDocuments> =>
    axios.get(`/api/v1/front-desk/${personId}/documents`);

  public updatePersonAttachments = (
    personId: number,
    attachments: IAttachment[],
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/documents/attachments`, attachments);

  public deletePersonAttachment = (
    personId: number,
    attachmentId: string,
  ): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/front-desk/${personId}/documents/attachments/${attachmentId}`);

  // Tickets
  public getPersonTickets = (personId: number): Promise<ITicketShortDto[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/tickets`);
  };

  public getPersonTicket = (personId: number, ticketId: number): Promise<ITicketDetailedDto> => {
    return axios.get(`/api/v1/front-desk/${personId}/tickets/${ticketId}`);
  };

  public createPersonTicket = (
    personId: number,
    attachments: File[],
    dto: ITicketCreateDto<number>,
  ): Promise<ITicketShortDto[]> => {
    const formData = new FormData();

    attachments.forEach((file: File) => {
      formData.append('attachments', file);
    });

    const blob = new Blob([JSON.stringify(dto)], {
      type: 'application/json',
    });

    formData.append('dto', blob);

    return axios.post(`/api/v1/front-desk/${personId}/tickets`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  public getCustomers = (
    tableParams: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
    personId?: number,
  ): Promise<IPaginatedData<ICustomerSimpleDto>> => {
    const { searchStr, ...params } = tableParams;
    return axios.get(`/api/v1/front-desk/${personId}/tickets/customers`, {
      params: { ...params, search: searchStr },
    });
  };

  // Referrals
  public getPersonReferrals = (personId: number): Promise<IReferralShortDto> => {
    return axios.get(`/api/v1/front-desk/${personId}/referrals`);
  };

  public createPersonReferral = (
    personId: number,
    data: IReferralCreateDto,
  ): Promise<IReferralCreateDto> => axios.post(`/api/v1/front-desk/${personId}/referrals`, data);

  public setReferredBy = (personId: number, data: ISetReferredDto): Promise<IReferralCreateDto> =>
    axios.post(`/api/v1/front-desk/${personId}/referrals/referred-by`, data);

  // Unlock kiosk mode
  public unlockKioskMode = (data: IUnlockCredentials): Promise<ISignInResponse> =>
    axios.post(`/api/v1/front-desk/kiosk-mode/unlock`, data);

  public getClubEntrances = (clubId: string): Promise<IEntranceItem[]> =>
    axios.get(`/api/v1/front-desk/club/${clubId}/entrances`);

  // Book a tour

  public createPersonBookATour = (personId: number, data: IBookingEvent): Promise<IBookingEvent> =>
    axios.post(`/api/v1/front-desk/${personId}/tour`, data);

  // Modification history

  public getModificationHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IModificationHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/front-desk/${personId}/modification-history`, {
      params: searchParams,
    });
  };

  public getRecentModificationHistory = (personId: number): Promise<IModificationHistory[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/modification-history/recent`);
  };

  public getModificationHistoryItem = (
    personId: number,
    revisionId: number,
  ): Promise<IModificationHistoryItem> =>
    axios.get(`/api/v1/front-desk/${personId}/modification-history/${revisionId}`);

  public uploadPhoto = async (memberId: number, image: IStoredFileSimpleDto): Promise<void> => {
    await axios.put(`/api/v1/front-desk/${memberId}/avatar`, image);
  };

  public deletePhoto = (memberId: number): Promise<void> => {
    return axios.delete(`/api/v1/front-desk/${memberId}/avatar`);
  };

  // Membership packages

  public getMembershipPackages = (clubId: string, search: string): Promise<IShortPackages> =>
    axios.get('/api/v1/front-desk/membership-packages/list', {
      params: { clubId, search },
    });

  public getPackageInventory = (
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(
      `api/v1/front-desk/membership-packages/${packageId}/inventories/${inventoryId}`,
    );
  };

  public getPersonPackageInventory = (
    personId: number,
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(
      `api/v1/front-desk/${personId}/membership/${packageId}/inventories/${inventoryId}`,
    );
  };

  public getPOSInvoiceReceipt = (invoiceId: string): Promise<string> =>
    axios.get(`api/v1/front-desk/pos/invoice/${invoiceId}/receipt`);

  public getCustomerPOSInvoiceReceipt = (invoiceId: string): Promise<string> =>
    axios.get(`api/v1/front-desk/customer-pos/invoice/${invoiceId}/receipt`);

  // Cancel invoice

  public cancelInvoiceFromPOS = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/front-desk/pos/invoice/${invoiceId}/cancel`);

  public voidInvoiceFromPOS = (invoiceId: string, reason: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/pos/invoice/${invoiceId}/void`, { reason });

  // Voids invoice during membership buying/changing process
  public cancelMembershipInvoice = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/front-desk/customer-pos/invoice/${invoiceId}/cancel`);

  public voidMembershipInvoice = (invoiceId: string, reason: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/customer-pos/invoice/${invoiceId}/void`, {
      reason,
    });

  public fetchAvailablePaymentMethods = (registerId: string): Promise<IPaymentMethodItem[]> =>
    axios.get(`/api/v1/front-desk/pos/register/${registerId}/payment-methods/default`);

  public fetchCustomerAvailablePMethods = (registerId: string): Promise<IPaymentMethodItem[]> =>
    axios.get(`/api/v1/front-desk/customer-pos/register/${registerId}/payment-methods/default`);

  public addInvoicePaymentSplit = (
    invoiceId: string,
    invoicePaymentSplit: IInvoicePaymentSplit,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(
      `/api/v1/front-desk/pos/invoice/${invoiceId}/invoice-payment-split`,
      invoicePaymentSplit,
    );

  public addCustomerInvoicePaymentSplit = (
    invoiceId: string,
    invoicePaymentSplit: IInvoicePaymentSplit,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(
      `/api/v1/front-desk/customer-pos/invoice/${invoiceId}/invoice-payment-split`,
      invoicePaymentSplit,
    );

  public proceedFreeInvoice = (
    invoiceId: string,
    isCustomer: boolean,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(
      `/api/v1/front-desk/${isCustomer ? 'customer-' : ''}pos/invoice/${invoiceId}/proceed-free`,
    );

  public getPersonAdditionalInfo = (personId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/front-desk/${personId}/additional-fields`);

  // Update additional info
  public updatePersonAdditionalInfo = (
    personId: number,
    additionalInfo: IProfileAdditionalPayload[],
  ): Promise<IProfileInfo> =>
    axios.put(`/api/v1/front-desk/${personId}/additional-fields`, additionalInfo);

  public fetchPaymentAccounts = (
    memberId: number,
    paymentAccountType?: PaymentAccountType,
    cardType?: PaymentsType,
  ): Promise<IPaymentAccount[]> => {
    const config = {
      params: {
        ...(cardType && { cardType }),
        ...(paymentAccountType && { paymentAccountType }),
      },
    };

    return axios.get(`/api/v1/front-desk/${memberId}/payment-accounts`, config);
  };

  public deletePaymentAccount = (memberId: number, paymentAccountId: string): Promise<void> =>
    axios.delete(`/api/v1/front-desk/${memberId}/payment-accounts/${paymentAccountId}`);

  public addCreditCardPaymentAccount = (
    memberId: number,
    data: IAddCreditCard,
  ): Promise<IProfileInfo> => {
    const { expiredIn } = data;
    const expireSplit: string[] = expiredIn.split('/');
    const modifiedData: IObject = {
      ...data,
      number: data.cardNumber.replaceAll(' ', ''),
      expirationMonth: expireSplit[0],
      expirationYear: expireSplit[1],
    };
    delete modifiedData.expiredIn;
    return axios.post(
      `/api/v1/front-desk/${memberId}/payment-accounts/add-credit-card`,
      modifiedData,
    );
  };

  public addCheckingPaymentAccount = (
    memberId: number,
    data: Omit<IPaymentAccount, 'id'>,
  ): Promise<IPaymentAccount> => {
    return axios.post(`/api/v1/front-desk/${memberId}/payment-accounts/add-checking-saving`, data);
  };

  public fetchPersonSubscriptions = (
    memberId: number,
  ): Promise<Array<IPackageInstanceSubscription>> =>
    axios.get(`/api/v1/front-desk/${memberId}/subscriptions`);

  public fetchPersonPaymentMethods = (
    memberId: number,
  ): Promise<IPaginatedData<IPaymentMethodItem>> =>
    axios.get(`/api/v1/front-desk/${memberId}/subscriptions/payment-methods`);

  public updateMemberSubscriptions = (
    memberId: number,
    data: IPackageInstanceSubscriptionUpdateDTO[],
  ): Promise<void> => axios.put(`/api/v1/front-desk/${memberId}/subscriptions`, data);

  public getRecentPersonPayments = (personId: number): Promise<IPayment[]> =>
    axios.get(`/api/v1/front-desk/${personId}/member-payments/recent`);

  public getPersonPayments = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IPayment>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/front-desk/${personId}/member-payments`, {
      params: searchParams,
    });
  };

  public voidPersonInvoice = (
    personId: number,
    invoiceId: string,
    reason: string,
  ): Promise<void> => {
    return axios.put(`api/v1/front-desk/${personId}/member-payments/${invoiceId}/void`, { reason });
  };

  public getPaymentDetails = (personId: number, invoiceId: string): Promise<IInvoiceDetailsDto> =>
    axios.get(`/api/v1/front-desk/${personId}/member-payments/${invoiceId}`);

  public getRecentCheckInsHistory = (personId: number): Promise<Array<ICheckInHistory>> =>
    axios.get(`/api/v1/front-desk/${personId}/checkin-history/recent`);

  public getCheckInsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ICheckInHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/front-desk/${personId}/checkin-history`, {
      params: searchParams,
    });
  };

  public getDocumentsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IDocumentsHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/front-desk/${personId}/documents/history`, {
      params: searchParams,
    });
  };

  public getPackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/front-desk/${personId}/membership/${membershipId}/billing-schedules`,
      {
        params: searchParams,
      },
    );
  };

  public getServicePackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/front-desk/${personId}/service-section/service-packages/${membershipId}/cancel/billing-schedules`,
      {
        params: searchParams,
      },
    );
  };

  public cancelPersonMembership = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(`/api/v1/front-desk/${personId}/membership/${membershipId}/cancel`, data);

  public cancelServicePackage = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(
      `/api/v1/front-desk/${personId}/service-section/service-packages/${membershipId}/cancel`,
      data,
    );

  public fetchReactivateMembershipItems = (
    personId: number,
  ): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/membership/past-packages`);
  };

  public fetchReactivateServiceItems = (personId: number): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/front-desk/${personId}/service-section/past-packages`);
  };

  public fetchCancelItems = (personId: number): Promise<IPackageInstanceDetails[]> =>
    axios.get(`/api/v1/front-desk/${personId}/service-section/service-packages`);

  public getProfileInfo = (personId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/front-desk/${personId}`);

  public setTags = (personId: number, profileTags: List<ITag>): Promise<{ id: string }> => {
    return axios.put(
      `/api/v1/front-desk/${personId}/tags`,
      profileTags.map(tag => tag.id),
    );
  };

  public fetchBalanceByIdCustomerPOS = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/front-desk/customer-pos/balance', {
      params: { customerId },
    });
  };

  public fetchBalanceByIdPOS = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/front-desk/pos/balance', { params: { customerId } });
  };

  public searchFornkDeskPOSMembers = (searchStr: string): Promise<ICustomerShort[]> => {
    return axios.get('/api/v1/front-desk/pos/members', {
      params: { search: searchStr },
    });
  };

  public fetchAvailableMembershipPackageActions = (
    customerId: number,
  ): Promise<IMembershipActionDTO> =>
    axios.get(`/api/v1/front-desk/${customerId}/membership-actions`);

  public fetchMembership = (personId: number, packageId: string): Promise<IBundleView> => {
    return axios.get(`api/v1/front-desk/${personId}/membership/${packageId}`);
  };

  // Rewards
  public fetchRewardsByIdCustomerPOS = (customerId: number): Promise<IRewards> => {
    return axios.get('api/v1/front-desk/customer-pos/rewards', { params: { customerId } });
  };

  public fetchRewardsByIdPOS = (customerId: number): Promise<IRewards> => {
    return axios.get('api/v1/front-desk/pos/rewards', { params: { customerId } });
  };

  public updatePersonRewardPoints = (
    personId: number,
    data: ITopUpRewardsData,
  ): Promise<IRewards> => {
    return axios.put(`/api/v1/front-desk/${personId}/reward-points`, data);
  };

  // Commerce Hub
  public fetchCommerceHubCredentials = (personId: number): Promise<ICommerceHubCredentials> => {
    return axios.get(`api/v1/front-desk/${personId}/commerce-hub/get-security-credentials`);
  };

  public tokenizeCommerceHubEncryptedCard = (
    personId: number,
    data: IObject,
  ): Promise<IPaymentAccount[]> => {
    return axios.post(`api/v1/front-desk/${personId}/commerce-hub/tokenize-credit-card`, data);
  };

  public getPersonBalanceHistory = (customerId: number): Promise<IBalanceHistoryItem[]> => {
    return axios.get('api/v1/front-desk/pos/balance/history', {
      params: { customerId },
    });
  };

  public getPersonRewardsHistory = (customerId: number): Promise<IRewardsHistoryItem[]> => {
    return axios.get(`api/v1/front-desk/${customerId}/reward-points/history`, {});
  };
}
