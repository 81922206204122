import React from 'react';
import { IProfileInfoImt } from 'common/components/PersonProfile/interfaces';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectPersonFallthroughCapture } from 'common/components/PersonProfile/state/selectors';
import { Grid } from '@material-ui/core';
import {
  CheckInInfo,
  DocumentsSection,
  TicketsSection,
  FallthroughBlock,
  MembershipBlock,
  ServicesSection,
  BillingActionsBlock,
  Balance,
} from 'common/components/PersonProfile/components';
import { PeakModules } from 'common/constants/peakModules';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import ReferralSection from 'common/components/PersonProfile/components/Referrals/ReferralSection';

interface IServicesSectionBlock {
  profile: IProfileInfoImt;
  isBiggerThanXs: boolean;
  isLessThanTablet: boolean;
  classes: Partial<ClassNameMap<string>>;
  openPOSPanel: () => void;
  isOpenPOSPanel: boolean;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  disablePosBtn: boolean;
}

const ServicesSectionBlock = ({
  profile,
  isBiggerThanXs,
  isLessThanTablet,
  classes,
  openPOSPanel,
  isOpenPOSPanel,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
  disablePosBtn,
}: IServicesSectionBlock): JSX.Element => {
  const { id } = useParams();
  const memberId = Number(id);
  const clientId = '1';
  const personId = profile.get('id');
  const fallthroughCapture = useSelector(selectPersonFallthroughCapture(memberId));
  const fallthroughCaptureExists = Boolean(fallthroughCapture);
  const membershipPackageExists = Boolean(profile.get('membership'));

  return personId ? (
    <Grid container spacing={2}>
      <Grid item xs={isBiggerThanXs && isLessThanTablet ? 6 : 12}>
        <CheckInInfo homeClub={profile.get('homeClub')} barcode={profile.get('barcode')} />
        <Balance balance={profile.get('balance')} reward={profile.get('rewardPointNumber')} />
      </Grid>
      <Grid
        item
        xs={12}
        className={isBiggerThanXs && isLessThanTablet ? classes?.buttonsWrapper : ''}
      >
        <BillingActionsBlock
          openPOSPanel={openPOSPanel}
          isOpenPOSPanel={isOpenPOSPanel}
          handlePOSPanelChange={handlePOSPanelChange}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          disablePosBtn={disablePosBtn}
          profile={profile}
          module={PeakModules.Members}
          isSmallScreen={isBiggerThanXs && isLessThanTablet}
        />
      </Grid>

      {!fallthroughCaptureExists && (
        <Grid item xs={12}>
          <MembershipBlock profile={profile} module={PeakModules.Members} />
        </Grid>
      )}

      {!membershipPackageExists && (
        <Grid item xs={12}>
          <FallthroughBlock
            module={PeakModules.Members}
            clientId={clientId}
            profileId={profile.get('id')}
            fallthroughCapture={fallthroughCapture}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <ServicesSection
          readOnly
          module={PeakModules.Members}
          profileId={profile.get('id')}
          profileFirstName={profile.get('firstName')}
          profileLastName={profile.get('lastName')}
        />
      </Grid>

      <Grid item xs={12}>
        <DocumentsSection profileId={profile.get('id')} module={PeakModules.Members} />
      </Grid>

      <Grid item xs={12}>
        <TicketsSection profile={profile} module={PeakModules.Members} />
      </Grid>
      <Grid item xs={12}>
        <ReferralSection profile={profile} module={PeakModules.Members} />
      </Grid>
    </Grid>
  ) : null;
};

export default ServicesSectionBlock;
