import { batch } from 'react-redux';
import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { actionTypes } from './constants';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { PeakModules } from 'common/constants/peakModules';
import { IPersonDocumentItemDto, IPersonDocumentToSignDto } from 'common/interfaces/contract';
import { ActionResult } from 'common/constants';
import { IAppliedSignWaiverPayload } from 'common/state/newPerson/waiver/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import { printHTML } from 'common/state/printHTML/actions';

export const setIsLoading = createAction<boolean>(actionTypes.SET_IS_LOADING);
export const getWaiversAction = createAction<IPersonDocumentItemDto[]>(actionTypes.GET_WAIVERS);
export const applyWaiverAction = createAction<IAppliedSignWaiverPayload>(actionTypes.APPLY_WAIVERS);
export const signWaiversAction = createAction<IPersonDocumentItemDto[]>(actionTypes.SIGN_WAIVERS);
export const setSignWaiversActionResult = createAction<ActionResult>(
  actionTypes.SET_SIGN_WAIVERS_ACTION_RESULT,
);
export const setApplyWaiverActionResult = createAction<ActionResult>(
  actionTypes.SET_APPLY_WAIVER_ACTION_RESULT,
);
export const resetWaivers = createAction(actionTypes.RESET_WAIVERS);

export const resetApplyActionResult = createAction(actionTypes.RESET_WAIVERS);

export const getWaiversThunk = (
  personId: number,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setIsLoading(true));

    try {
      let waiverData: IPersonDocumentItemDto[];

      switch (module) {
        case PeakModules.FrontDesk:
          waiverData = await Services.FrontDesk.getPersonWaivers(personId);
          break;
        case PeakModules.Members:
          waiverData = await Services.Members.getPersonWaivers(personId);
          break;
        case PeakModules.Crm:
          waiverData = await Services.Leads.getPersonWaivers(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          waiverData = await Services.PTLeads.getPersonWaivers(personId);
          break;
        default:
      }

      dispatch(getWaiversAction(waiverData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const applyWaiverThunk = (
  personId: number,
  documentId: string,
  file: File,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));

    try {
      let waiverData: IPersonDocumentItemDto;

      switch (module) {
        case PeakModules.FrontDesk:
          waiverData = await Services.FrontDesk.applyWaiverSignature(personId, documentId, file);
          break;
        case PeakModules.Members:
          waiverData = await Services.Members.applyWaiverSignature(personId, documentId, file);
          break;
        case PeakModules.Crm:
          waiverData = await Services.Leads.applyWaiverSignature(personId, documentId, file);
          break;
        case PeakModules.PersonalTrainingCrm:
          waiverData = await Services.PTLeads.applyWaiverSignature(personId, documentId, file);
          break;
        default:
      }

      const state = getState();

      const result = state.getIn(['newPerson', 'personWaivers', 'result']);

      batch(() => {
        dispatch(
          applyWaiverAction({
            waivers: result
              ?.toJS()
              .map(item => (item.documentId === waiverData.documentId ? waiverData : item)),
            appliedSignWaiver: waiverData,
          }),
        );
        dispatch(setApplyWaiverActionResult(ActionResult.SUCCESS_ACTION));
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
};

export const signWaiversThunk = (
  personId: number,
  payload: IPersonDocumentToSignDto[],
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(setIsLoading(true));

    let waiverData: IPersonDocumentItemDto[];

    switch (module) {
      case PeakModules.FrontDesk:
        waiverData = await Services.FrontDesk.signWaivers(personId, payload);
        break;
      case PeakModules.Members:
        waiverData = await Services.Members.signWaivers(personId, payload);
        break;
      case PeakModules.Crm:
        waiverData = await Services.Leads.signWaivers(personId, payload);
        break;
      case PeakModules.PersonalTrainingCrm:
        waiverData = await Services.PTLeads.signWaivers(personId, payload);
        break;
      default:
    }

    batch(() => {
      dispatch(signWaiversAction(waiverData));
      dispatch(setSignWaiversActionResult(ActionResult.SUCCESS_ACTION));
    });
  } catch (e) {
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(setIsLoading(false));
  }
};

const fetchWaiverHTMLLoading = createAction<boolean>(actionTypes.FETCH_WAIVER_HTML_LOADING);

export const printWaiver = (
  personId: number,
  waiverId: string,
  module: PeakModuleForNewPersonType = PeakModules.FrontDesk,
): GeneralThunkAction => async dispatch => {
  try {
    dispatch(fetchWaiverHTMLLoading(true));

    let result: string;

    switch (module) {
      case PeakModules.FrontDesk:
        result = await Services.FrontDesk.printWaiverById(personId, waiverId);
        break;
      case PeakModules.Members:
        result = await Services.Members.printWaiverById(personId, waiverId);
        break;
      case PeakModules.Crm:
        result = await Services.Leads.printWaiverById(personId, waiverId);
        break;
      default:
        result = await Services.PTLeads.printWaiverById(personId, waiverId);
    }

    dispatch(printHTML(result));
  } catch (e) {
    dispatch(enqueueErrorNotification(e));
  } finally {
    dispatch(fetchWaiverHTMLLoading(false));
  }
};
