import { personsListPath } from 'common/components/PersonProfile/state/selectors';

export const selectPersonAppointmentsHistory = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentsHistory']);
export const selectPersonAppointmentsHistoryMeta = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentsHistoryMeta']);

export const selectPersonAppointmentsHistoryLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentsHistoryLoading']);
export const selectRecentPersonAppointments = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'recentAppointments']);
export const selectRecentPersonAppointmentsLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'recentAppointmentsLoading']);

export const selectPersonAppointmentsActionResult = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentActionStatus', 'result']);

export const selectPersonAppointmentsActionLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentActionStatus', 'isLoading']);

export const selectPersonAppointmentDetailsLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'isAppointmentLoading']);

export const selectPersonAppointmentDetails = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointment']);

// appointment services

export const selectAppointmentServices = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentServices']);

export const selectAppointmentServicesLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'appointmentServicesLoading']);

export const selectServicesForEventRedeem = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'servicesForEventRedeem']);

export const selectServicesForEventRedeemLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'servicesForEventRedeemLoading']);
