import { createAction } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import Services from 'services/network';
import { actionTypes } from 'memberPortalModules/authentication/state/constants';
import TokenService from 'services/auth/TokenService';
import { IUserAvatar, IUserProfileInfo } from 'modules/authentication/interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IServerError, ISuccessResponse } from 'common/interfaces/http';
import AppService from 'services/application/AppService';
import { GeneralThunkAction } from 'common/state/interfaces';
import { INamedEntity } from 'common/interfaces/common';
import { resetUserRole } from 'common/state/userRole/actions';

export const setUserAvatar = createAction<IUserAvatar>(actionTypes.SET_USER_AVATAR);
export const setUserId = createAction<number>(actionTypes.SET_USER_ID);
const signInAction = createAction<ISuccessResponse>(actionTypes.USER_SIGN_IN);
const signInLoadingAction = createAction<boolean>(actionTypes.USER_SIGN_IN_LOADING);
export const signInErrorAction = createAction<IServerError>(actionTypes.USER_SIGN_IN_ERROR);

export const signIn = (
  email: string,
  password: string,
  corporationId: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(signInLoadingAction(true));

    try {
      const signInData = await Services.MemberPortalAuthentication.signIn(
        email,
        password,
        corporationId,
      );

      TokenService.setAccessToken(signInData.accessToken);
      TokenService.setRefreshToken(signInData.refreshToken);

      dispatch(signInAction({ success: true }));
    } catch (e) {
      dispatch(signInErrorAction(e));
    } finally {
      dispatch(signInLoadingAction(false));
    }
  };
};

const resetUserProfileAction = createAction<IUserProfileInfo>(actionTypes.RESET_USER_PROFILE_DATA);
export const clearUserDataAction = createAction(actionTypes.USER_LOG_OUT);

export const memberPortalLogOut = (): GeneralThunkAction => {
  return async dispatch => {
    try {
      await Services.MemberPortalAuthentication.signOut();
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      TokenService.clearTokens();
      AppService.clearAppSelectedRegisters();
      AppService.clearAppSelectedEntrance();

      batch(() => {
        dispatch(clearUserDataAction());
        dispatch(resetUserProfileAction());
        dispatch(resetUserRole());
      });
    }
  };
};

export const setOrganizationPrimaryColorAction = createAction<string>(
  actionTypes.SET_ORGANIZATION_PRIMARY_COLOR,
);
export const resetOrganizationPrimaryColorAction = createAction(
  actionTypes.RESET_ORGANIZATION_PRIMARY_COLOR,
);

const fetchCorporationsForSignInAction = createAction<INamedEntity[]>(
  actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN,
);
const fetchCorporationsForSignInActionLoading = createAction<boolean>(
  actionTypes.FETCH_CORPORATIONS_FOR_SIGN_IN_LOADING,
);
export const resetCorporationsForSignInAction = createAction(
  actionTypes.RESET_CORPORATIONS_FOR_SIGN_IN,
);

export const fetchCorporationsForSignIn = (email: string): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchCorporationsForSignInActionLoading(true));

    try {
      const corporations = await Services.MemberPortalAuthentication.getCorporationsForSignIn(
        email,
      );

      dispatch(fetchCorporationsForSignInAction(corporations));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchCorporationsForSignInActionLoading(false));
    }
  };
};
