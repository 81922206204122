import React, { useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import MomentAdapter from '@date-io/moment';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { Box, Grid, MenuItem, Typography, makeStyles, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { resetServiceResources } from 'common/components/PersonProfile/state/servicesModals/actions';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { useAppDispatch } from 'store/hooks';

import { times } from 'common/constants/times';

import { CustomTheme } from 'common/ui/interfaces';
import {
  IRedeemResourceTag,
  IRedeemResourceTagImt,
} from 'common/components/PersonProfile/interfaces';
import { IServiceItemDetailsImt } from 'common/interfaces/service';

import { AdaptiveDatePicker, Select } from 'common/components/index';
import TimePicker from 'common/components/TimePicker/TimePicker';
import ServiceAvailabilityHoursAlert, {
  checkIsTimeInServiceAvailabilityRange,
} from 'common/components/PersonProfile/components/Services/ServiceAvailabilityHoursAlert/ServiceAvailabilityHoursAlert';

import inputLabels from 'common/messages/inputLabels';
import { getDefaultSelectMenuPosition } from 'common/ui/utils';

interface IUnscheduledRedeemFormProps {
  loadServiceResources: (date: string) => void;
  resourceTags: ImmutableList<IRedeemResourceTagImt>;
  serviceInstance: IServiceItemDetailsImt;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  formWrapper: {
    padding: theme.spacing(0, 0.5),
    margin: theme.spacing(1, 0),
  },
  durationMenu: {
    maxHeight: '300px',
    margin: theme.spacing(2, 0),
  },
  resourcesWrapper: {
    marginTop: theme.spacing(1),
  },
  resource: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  inputAvailabilityTime: {
    position: 'absolute',
    top: '50%',
    right: 32,
    transform: 'translate(0, -50%)',
    cursor: 'pointer',
  },
  availabilityOption: {
    '&[data-focus="true"]': {
      '& .MuiTypography-colorPrimary': {
        color: theme.palette.primary.contrastText,
      },
    },
  },
}));

const UnscheduledRedeemForm: React.FC<IUnscheduledRedeemFormProps> = ({
  loadServiceResources,
  resourceTags,
  serviceInstance,
}: IUnscheduledRedeemFormProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { watch, control, formState } = useFormContext();
  const { errors } = formState;
  const [timezoneMoment] = useTimezoneMoment();
  const date = watch('date');
  const startTime = watch('time');

  useEffect(() => {
    if (date && startTime) {
      loadServiceResources(date);
    }
  }, [date, loadServiceResources, startTime]);

  useEffect(() => {
    return () => {
      dispatch(resetServiceResources());
    };
  }, [dispatch]);

  const transformedServiceInstance = useMemo(() => serviceInstance.toJS(), [serviceInstance]);

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const isServiceAvailabilityHoursAlertShow =
    startTime &&
    !transformedServiceInstance.hasSameAsClubAvailability &&
    !checkIsTimeInServiceAvailabilityRange(
      transformedServiceInstance.customAvailability,
      date,
      startTime,
    );

  // renders

  return (
    <Box className={classes.formWrapper}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider utils={MomentAdapter}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <AdaptiveDatePicker
                  onBlur={field.onBlur}
                  label={<FormattedMessage {...inputLabels.startDate} />}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="time"
            control={control}
            render={({ field }) => (
              <TimePicker
                onBlur={field.onBlur}
                value={field.value}
                onChange={field.onChange}
                name={field.name}
                labelMessageDescriptor={inputLabels.startTime}
                error={!!errors.time}
                helperText={renderIntlMessage(errors.time?.message)}
              />
            )}
          />
        </Grid>

        <Grid item xs={6}>
          <Controller
            name="durationInMinutes"
            control={control}
            render={({ field }) => (
              <Select
                label={<FormattedMessage {...inputLabels.duration} />}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                renderValue={(v: string) => `${+v.split(':')[0]}h ${v.split(':')[1]}m`}
                MenuProps={{
                  classes: { paper: classes.durationMenu },
                  ...getDefaultSelectMenuPosition(),
                }}
                fullWidth
                error={!!errors.durationInMinutes}
                helperText={renderIntlMessage(errors.durationInMinutes?.message)}
              >
                {times.map(timeItem => (
                  <MenuItem key={timeItem} value={timeItem}>
                    {timeItem}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>

        {isServiceAvailabilityHoursAlertShow && (
          <Grid item xs={12}>
            <ServiceAvailabilityHoursAlert
              availability={transformedServiceInstance.customAvailability}
            />
          </Grid>
        )}
      </Grid>

      {!!resourceTags?.size && (
        <Grid
          container
          spacing={2}
          className={classes.resourcesWrapper}
          key={`${date} ${startTime}`}
        >
          {resourceTags.toJS().map((resourceTag: IRedeemResourceTag, index) => (
            <Grid item xs={12}>
              <Controller
                name={`redeemResourceCreateDtoList.${index}`}
                control={control}
                render={({ field }) => (
                  <MuiAutocomplete
                    value={field.value}
                    fullWidth
                    onBlur={field.onBlur}
                    multiple={false}
                    disableClearable
                    onChange={(_, option) =>
                      field.onChange({ ...option.availability, id: option.resource.id })
                    }
                    classes={{ option: classes.availabilityOption }}
                    options={resourceTag.resources}
                    getOptionLabel={option => option.resource.title}
                    renderOption={option => (
                      <Box className={classes.resource}>
                        <Typography color="textPrimary">{option.resource.title}</Typography>

                        <Typography color="primary">
                          {`${timezoneMoment(option.availability.startTime, 'HH:mm').format(
                            'LT',
                          )} - ${timezoneMoment(option.availability.endTime, 'HH:mm').format(
                            'LT',
                          )}`}
                        </Typography>
                      </Box>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={resourceTag.tag.title}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {field.value && (
                                <Typography
                                  color="primary"
                                  className={classes.inputAvailabilityTime}
                                >
                                  {`${timezoneMoment(field.value.startTime, 'HH:mm')
                                    .utc(false)
                                    .format('HH:mmA')} - ${timezoneMoment(
                                    field.value.endTime,
                                    'HH:mm',
                                  )
                                    .utc(false)
                                    .format('HH:mmA')}`}
                                </Typography>
                              )}

                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default UnscheduledRedeemForm;
