import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@material-ui/core';

import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';

import { PeakModules } from 'common/constants/peakModules';

import { IInvoiceDetailsImt } from 'common/interfaces/invoices';

import { DialogComponent, LoadingBackdrop } from 'common/components/index';
import {
  CheckOutResponseNamesInfo,
  CheckOutResponsePaymentInfo,
} from 'common/components/InvoiceOperating/InvoiceModals';

import messages from 'common/messages/messages';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  personId: number;
  invoiceId: string;
  module: PeakModules;
}

const InvoiceDetailsModal = ({
  isOpen,
  onClose,
  personId,
  invoiceId,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const invoiceDetails: IInvoiceDetailsImt = useSelector(selectors.selectPaymentDetails(personId));
  const invoiceDetailsLoading: boolean = useSelector(
    selectors.selectPaymentDetailsLoading(personId),
  );

  useEffect(() => {
    dispatch(actions.fetchPaymentDetails(personId, invoiceId, module));
  }, [dispatch, invoiceId, module, personId]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={
        <Typography variant="h4">
          <FormattedMessage
            {...messages.invoiceNumber}
            values={{ number: invoiceDetails?.get('number') }}
          />
        </Typography>
      }
      hideFooterButtons
    >
      {!!invoiceDetails?.size && (
        <Box>
          <CheckOutResponseNamesInfo checkOutData={invoiceDetails} />

          <CheckOutResponsePaymentInfo checkOutData={invoiceDetails} />

          <LoadingBackdrop isLoading={invoiceDetailsLoading} />
        </Box>
      )}
    </DialogComponent>
  );
};

export default InvoiceDetailsModal;
