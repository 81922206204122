import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Card, makeStyles, Typography } from '@material-ui/core';

import { CustomTheme } from 'common/ui/interfaces';

import { KidProfile } from 'modules/kid-zone/containers/index';

import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: CustomTheme) => ({
  noPersonSelected: {
    height: '100%',
  },
  noPersonSelectedText: {
    margin: theme.spacing(12.5, 'auto'),
    textAlign: 'center',
  },
}));

interface IProps {
  personId: number;
  clientId: string;
  onCloseManualCheckinPanel: () => void;
}

const CheckInProfilePanel = ({
  personId,
  clientId,
  onCloseManualCheckinPanel,
}: IProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" height="100%" flex={1}>
      {personId ? (
        <KidProfile
          onCloseManualCheckinPanel={onCloseManualCheckinPanel}
          personId={personId}
          clientId={clientId}
        />
      ) : (
        <Card className={classes.noPersonSelected}>
          <Typography
            color="textSecondary"
            variant="button"
            component="p"
            className={classes.noPersonSelectedText}
          >
            <FormattedMessage {...commonMessages.noRecentCheckInsTitle} />
          </Typography>
        </Card>
      )}
    </Box>
  );
};

export default CheckInProfilePanel;
