import axios from 'axios';

import ApiService from 'services/network/ApiService';

import { ITableParams } from 'common/interfaces/table';
import { IBilling } from 'common/components/PersonProfile/interfaces';

export default class BillingScheduleService extends ApiService {
  public getPersonBillingSchedule = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/front-desk/${personId}/billing-schedules`, { params: searchParams });
  };

  public updateBillingScheduleAmount = (
    personId: number,
    billingItemId: string,
    amount: number,
  ): Promise<IBilling> =>
    axios.patch(`/api/v1/front-desk/${personId}/billing-schedules/${billingItemId}`, {
      amount,
    });
}
