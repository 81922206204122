import React from 'react';
import cn from 'classnames';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  Theme,
} from '@material-ui/core';

import { ReactComponent as ReplayIcon } from 'img/icons/reset.svg';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

import { BULK_STRING } from 'common/constants/bulkConstants';
import { times } from 'common/constants/times';
import { InputMultipleValues, ResetValueType } from 'common/constants/enums';
import { debounce } from 'common/utils';
import { getDefaultSelectMenuPosition } from 'common/ui/utils';

const useStyles = makeStyles((theme: Theme) => ({
  disabledOption: {
    display: 'none',
  },
  mainContainer: {
    position: 'relative',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '& :hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  replayContainer: {
    backgroundColor: theme.palette.primary.main,
    position: 'absolute',
    zIndex: 1,
    right: 0,
    borderRadius: 3,
    cursor: 'pointer',
    height: 18,
    width: 20,
    textAlign: 'center',
  },
  iconReplay: {
    fill: theme.palette.background.paper,
    height: 16,
    width: 16,
    padding: '2px 2px 0px',
  },
  menuPaper: {
    maxHeight: 440,
  },
}));

interface SelectFieldProps extends SelectProps {
  id?: string;
  label?: string | JSX.Element;
  fullWidth?: boolean;
  helperText?: string;
  isDuration?: boolean;
  canReset?: boolean;
  resetValue?: ResetValueType;
  onSelect?: (e) => void;
  onChange?: (value: any) => void;
  menuClassName?: string;
  size?: 'small' | 'medium';
}

const SelectField = (props: SelectFieldProps): JSX.Element => {
  const {
    label,
    name,
    fullWidth,
    error,
    helperText,
    isDuration,
    children,
    onSelect,
    onChange,
    canReset,
    size = 'medium',
    menuClassName,
    resetValue = InputMultipleValues.SingleSelect,
    ...selectProps
  } = props;

  const classes = useStyles();

  const handleOnChange: SelectInputProps['onChange'] = (event): void => {
    const result: any = event && event.target && event.target.value;

    if (
      selectProps.multiple &&
      result &&
      result.length > 1 &&
      result.includes(InputMultipleValues.SingleSelect)
    ) {
      const multipleIndex = result.findIndex(val => val === InputMultipleValues.SingleSelect);
      result.splice(multipleIndex, 1);
    }

    onChange(result);
  };

  let isNotEqualValues: boolean;

  try {
    isNotEqualValues =
      typeof resetValue === 'object'
        ? JSON.stringify(resetValue) !== JSON.stringify(selectProps.value)
        : selectProps.value !== resetValue;
  } catch (e) {
    isNotEqualValues = false;
  }

  return (
    <FormControl
      size={size}
      variant="outlined"
      fullWidth={fullWidth}
      error={!!error}
      className={`${canReset && isNotEqualValues ? classes.mainContainer : ''}`}
    >
      {!!canReset && isNotEqualValues && (
        <Box className={classes.replayContainer} onClick={() => onChange(resetValue)}>
          <ReplayIcon className={classes.iconReplay} />
        </Box>
      )}
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        MenuProps={{
          classes: { paper: cn(classes.menuPaper, menuClassName) },
          ...getDefaultSelectMenuPosition(),
        }}
        id={name}
        label={label}
        fullWidth={fullWidth}
        {...selectProps}
        onChange={debounce(handleOnChange)}
      >
        {/* If multiple resettable values are provided - this is a hidden option for that */}
        {canReset && (
          <MenuItem disabled value={BULK_STRING} className={classes.disabledOption}>
            Multiple
          </MenuItem>
        )}

        {isDuration
          ? times.map(timeVal => (
              <MenuItem key={timeVal} value={timeVal}>
                {timeVal}
              </MenuItem>
            ))
          : children}
      </Select>

      {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default SelectField;
