import axios from 'axios';
import ApiService from 'services/network/ApiService';
import {
  InventoryRedeemHistoryDto,
  InventoryInstanceDetailsDto,
  IServiceRedeemHistoryImt,
  IServiceSectionData,
  IFamilyMemberServiceInstanceDetailsDto,
  IFamilyMemberToRedeem,
  IRedeemFamilyMemberServicePayload,
} from 'common/interfaces/service';
import {
  IFamilyMemberCreateDTO,
  IScheduledServiceRedeemDTO,
} from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IBookingEvent } from 'modules/booking/interfaces';

export default class LeadRedeemService extends ApiService {
  public getPersonServicesData = async (personId: number): Promise<IServiceSectionData> => {
    return axios.get(`/api/v1/leads/${personId}/service-section`);
  };

  public getRedeemedInventoryDetails = (
    personId: number,
    inventoryId: string,
  ): Promise<InventoryInstanceDetailsDto> => {
    return axios.get(`api/v1/leads/${personId}/service-section/inventories/${inventoryId}`);
  };

  public getRedeemedFamilyMemberServiceDetails = (
    personId: number,
    serviceInstanceId: string,
  ): Promise<IFamilyMemberServiceInstanceDetailsDto> => {
    return axios.get(
      `/api/v1/leads/${personId}/service-section/family-members/services/${serviceInstanceId}`,
    );
  };

  public getFamilyMembersToRedeem = (
    personId: number,
    serviceInstanceId: string,
  ): Promise<IFamilyMemberToRedeem[]> => {
    return axios.get(
      `/api/v1/leads/${personId}/service-section/family-members/services/${serviceInstanceId}/redeem/members`,
    );
  };

  public getRedeemedInventoryRecentHistory = (
    personId: number,
    inventoryId: string,
  ): Promise<InventoryRedeemHistoryDto[]> => {
    return axios.get(
      `api/v1/leads/${personId}/service-section/inventories/${inventoryId}/redeem/history/recent`,
    );
  };

  public getRedeemedInventoryHistory = (
    personId: number,
    inventoryId: string,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<InventoryRedeemHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(
      `api/v1/leads/${personId}/service-section/inventories/${inventoryId}/redeem/history`,
      { params: searchParams },
    );
  };

  public getServiceRedeemRecentHistory = (
    personId: number,
    serviceId: string,
  ): Promise<IServiceRedeemHistoryImt> => {
    return axios.get(
      `api/v1/leads/${personId}/service-section/services/${serviceId}/redeem/history/recent`,
    );
  };

  public getServiceRedeemHistory = (
    personId: number,
    serviceId: string,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<InventoryRedeemHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(
      `api/v1/leads/${personId}/service-section/services/${serviceId}/redeem/history`,
      { params: searchParams },
    );
  };

  public addFamilyMember = (personId: number, data: IFamilyMemberCreateDTO): Promise<void> =>
    axios.post(`/api/v1/leads/${personId}/service-section/family-members/add`, data);

  public getPersonServiceEventDetails = (
    personId: number,
    eventId: string,
    eventStartDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`api/v1/leads/${personId}/service-section/events/${eventId}`, {
      params: { eventStartDate },
    });

  public getPersonAppointmentDetails = (
    personId: number,
    appointmentId: string,
    eventStartDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/leads/${personId}/appointment/${appointmentId}`, {
      params: { eventStartDate },
    });

  public redeemScheduledService = (
    personId: number,
    serviceId: string,
    redeemData: IScheduledServiceRedeemDTO,
  ): Promise<void> =>
    axios.put(
      `api/v1/leads/${personId}/service-section/services/${serviceId}/redeem/scheduled`,
      redeemData,
    );

  public redeemAppointmentScheduledService = (
    personId: number,
    serviceId: string,
    appointmentId: string,
    redeemData: IScheduledServiceRedeemDTO,
  ): Promise<void> =>
    axios.put(
      `api/v1/leads/${personId}/appointment/${appointmentId}/services/${serviceId}/redeem/scheduled`,
      redeemData,
    );

  public redeemInventory = (
    personId: number,
    inventoryId: string,
    redeemedNumber: number,
    pinCode: string,
    clubId: string,
  ): Promise<void> => {
    return axios.put(`api/v1/leads/${personId}/service-section/inventories/${inventoryId}/redeem`, {
      redeemedNumber,
      pinCode,
      clubId,
    });
  };

  public redeemFamilyMembers = (
    personId: number,
    serviceInstanceId: string,
    payload: IRedeemFamilyMemberServicePayload,
  ): Promise<void> => {
    return axios.put(
      `api/v1/leads/${personId}/service-section/family-members/services/${serviceInstanceId}/redeem`,
      payload,
    );
  };
}
