import ApiService from './ApiService';
import {
  IPosKioskReconcileDTO,
  IPosKioskOpenRegisterDTO,
  IPosKioskRegisterData,
  IPosKioskRegisterTotals,
  ICloseRegisterInvoices,
  TCashDeskInfo,
} from 'modules/pos-kiosk/interfaces/registers';
import axios from 'axios';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { ISuccessResponse } from 'common/interfaces/http';
import {
  INotifications,
  IInvoicePaymentSplit,
  IInvoiceDetailsDto,
  ISearchPackagesResult,
  PaymentsType,
  IBalance,
  IAddInvoiceUnitDTO,
  CartUnitTypes,
  PaymentAccountType,
  IRewards,
  ISearchGiftCard,
} from 'common/interfaces/invoices';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import {
  ICommerceHubCredentials,
  ICustomerShort,
  IPaymentAccount,
  IPrimaryMemberInfo,
} from 'common/components/PersonProfile/interfaces';
import { ISignInResponse, IUnlockCredentials } from 'modules/authentication/interfaces';
import { IPaymentMethodItem } from '../../modules/pos-settings/interfaces/paymentMethods';
import { IPackageConfiguration } from 'common/interfaces/membership';
import { IObject } from 'common/interfaces/common';

export default class PosSettingsService extends ApiService {
  public fetchOpenInvoices = (registerId: string): Promise<IInvoiceDetailsDto[]> => {
    return axios.get(`/api/v1/pos/register/${registerId}/open-invoices`);
  };

  public searchPOSMembers = (searchStr: string): Promise<ICustomerShort[]> => {
    return axios.get('/api/v1/pos/members', { params: { search: searchStr } });
  };

  // create member from invoice
  public createMember = (
    data: IPrimaryMemberInfo,
    initialFamilyMemberId: string | null = null,
  ): Promise<IPrimaryMemberInfo> => {
    const body = {
      ...data,
      initialFamilyMemberId,
      type: CustomerStatus.Guest,
    };
    return axios.post(`/api/v1/pos/guests`, body);
  };

  // Register Data
  public getRegisterData = (registerId: string): Promise<IPosKioskRegisterData> => {
    return axios.get(`/api/v1/register/${registerId}`);
  };

  // Register Totals
  public getRegisterTotals = (
    registerId: string,
    cashAmount: number,
    checkAmount: number,
  ): Promise<IPosKioskRegisterTotals> =>
    axios.get(`/api/v1/register/${registerId}/total-sales`, {
      params: { cashAmount, checkAmount },
    });

  // Cash desk info
  public getCashDeskInfo = (registerId: string): Promise<TCashDeskInfo> =>
    axios.get(`/api/v1/register/${registerId}/cash-desk`);

  // Open/close/reconcile Register
  public openRegister = (registerId: string, data: IPosKioskOpenRegisterDTO): Promise<void> => {
    return axios.put(`/api/v1/register/${registerId}/open`, JSON.stringify(data));
  };

  public getRegisterInvoices = (registerId: string): Promise<ICloseRegisterInvoices> => {
    return axios.get(`/api/v1/pos/register/${registerId}/close/invoices`);
  };

  public closeRegister = (registerId: string, data: IPosKioskReconcileDTO): Promise<string> => {
    return axios.put(`/api/v1/register/${registerId}/close`, data);
  };

  public reconcileRegister = (
    registerId: string,
    data: Partial<IPosKioskReconcileDTO>,
  ): Promise<void> => {
    return axios.put(`/api/v1/register/${registerId}/reconcile-sales`, data);
  };

  // POS
  public searchPOSInventory = (
    registerId: string,
    search: string,
  ): Promise<IPaginatedData<ISimpleClubInventoryItem>> => {
    return axios.get(`/api/v1/pos/register/${registerId}/inventory`, {
      params: { search },
    });
  };

  public searchPOSPackages = (
    search: string,
    clubId: string,
    employeeId: number,
  ): Promise<ISearchPackagesResult> =>
    axios.get(`/api/v1/pos/package-list`, {
      params: { search, clubId, employeeId },
    });

  public searchPOSGiftCards = (clubIds: string[], search: string): Promise<ISearchGiftCard[]> =>
    axios.get(`/api/v1/pos/gift-card-list`, {
      params: { search, clubs: clubIds.join(',') },
    });

  public setSalespersonToInvoice = (
    invoiceId: string,
    salespersonId: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/pos/invoice/${invoiceId}/salesperson`, null, {
      params: { employeeId: salespersonId },
    });
  };

  // Invoice Data
  public createNewInvoice = (
    registerId: string,
    customerId?: number,
  ): Promise<IInvoiceDetailsDto> => {
    const params = {
      registerId,
      ...(customerId ? { customerId } : {}),
    };

    return axios.post('/api/v1/pos/invoice', null, { params });
  };

  public addInvoiceUnit = (
    invoiceId: string,
    data: Partial<IAddInvoiceUnitDTO>,
    unitType: CartUnitTypes,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(`/api/v1/pos/invoice/add-invoice-unit`, data, {
      params: { type: unitType, invoiceId },
    });

  public deleteInvoiceUnit = (
    invoiceId: string,
    invoiceUnitId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.delete(`/api/v1/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`);

  public changeInvoiceUnitNumber = (
    invoiceId: string,
    invoiceUnitId: string,
    unitNumber: number,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`, null, {
      params: {
        number: unitNumber,
      },
    });

  public getInvoiceReceipt = (invoiceId: string): Promise<string> => {
    return axios.get(`api/v1/pos/invoice/${invoiceId}/receipt`);
  };

  public cancelInvoice = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/pos/invoice/${invoiceId}/cancel`);

  public voidInvoice = (invoiceId: string, reason: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/pos/invoice/${invoiceId}/void`, { reason });

  public setCustomerToInvoice = (
    invoiceId: string,
    customerId: number | null,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`api/v1/pos/invoice/${invoiceId}/customer`, null, {
      params: {
        customerId,
      },
    });
  };

  public sendNotifications = (invoiceId: string, data: INotifications): Promise<void> => {
    return axios.put(`/api/v1/pos/invoice/${invoiceId}/send`, data);
  };

  public addInvoiceDiscount = (
    invoiceId: string,
    discountPercentage: number,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/pos/invoice/${invoiceId}/discount`, null, {
      params: { discountPercentage },
    });
  };

  // Unlock kiosk mode
  public unlockKioskMode = (data: IUnlockCredentials): Promise<ISignInResponse> =>
    axios.post(`/api/v1/pos/kiosk-mode/unlock`, data);

  public fetchAvailablePaymentMethods = (registerId: string): Promise<IPaymentMethodItem[]> =>
    axios.get(`/api/v1/pos/register/${registerId}/payment-methods/default`);

  public addInvoicePaymentSplit = (
    invoiceId: string,
    invoicePaymentSplit: IInvoicePaymentSplit,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(`/api/v1/pos/invoice/${invoiceId}/invoice-payment-split`, invoicePaymentSplit);

  public proceedFreeInvoice = (invoiceId: string): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/pos/invoice/${invoiceId}/proceed-free`);

  public fetchPaymentAccounts = (
    memberId: number,
    paymentAccountType: PaymentAccountType,
    cardType?: PaymentsType,
  ): Promise<IPaymentAccount[]> => {
    const config = {
      params: {
        ...(cardType && { cardType }),
        ...(paymentAccountType && { paymentAccountType }),
      },
    };

    return axios.get(`/api/v1/pos/${memberId}/payment-accounts`, config);
  };

  public addCheckingPaymentAccount = (
    memberId: number,
    data: Omit<IPaymentAccount, 'id'>,
  ): Promise<IPaymentAccount> => {
    return axios.post(`/api/v1/pos/${memberId}/payment-accounts/add-checking-saving`, data);
  };

  public fetchBalanceById = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/pos/balance', { params: { customerId } });
  };

  public getMembershipConfiguration = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/pos/package-list/${membershipId}`, { params: { clubId } });
  };

  // Rewards
  public fetchRewardsById = (customerId: number): Promise<IRewards> => {
    return axios.get('api/v1/pos/rewards', { params: { customerId } });
  };

  // Commerce Hub
  public fetchCommerceHubCredentials = (personId: number): Promise<ICommerceHubCredentials> => {
    return axios.get(`api/v1/pos/${personId}/commerce-hub/get-security-credentials`);
  };

  public tokenizeCommerceHubEncryptedCard = (
    personId: number,
    data: IObject,
  ): Promise<IPaymentAccount[]> => {
    return axios.post(`api/v1/pos/${personId}/commerce-hub/tokenize-credit-card`, data);
  };
}
