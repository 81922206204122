import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete as MuiAutocomplete, AutocompleteProps } from '@material-ui/lab';
import { Avatar, Box, TextField, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import Services from 'services/network';
import { debounce } from 'common/utils';
import { getInitials } from 'helpers/common';
import { ListboxComponent } from 'common/components';
import { PeakModules } from 'common/constants/peakModules';
import profilePlaceholder from 'img/photo-placeholder.png';
import { ICustomerSimpleDto } from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPageMeta, IPaginatedData } from 'common/interfaces/pagination';

interface IMemberAutocompleteProps
  extends Omit<AutocompleteProps<any, any, any, any>, 'renderInput' | 'options'> {
  label: string | JSX.Element;
  fullWidth: boolean;
  error?: string;
  refresh?: number;
  personId?: number;
  module?: PeakModules;
}

const useStyles = makeStyles((theme: Theme) => ({
  optionAvatar: {
    width: '100%',
    height: '100%',
  },
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },

  avatarWrap: {
    position: 'relative',
    flex: 'none',
    width: '36px',
    height: '36px',
    backgroundColor: theme.palette.secondary.light,
    borderRadius: '50%',
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '16px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
}));

const CustomersAutocompleteField = (props: IMemberAutocompleteProps): JSX.Element => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<ICustomerSimpleDto[]>([]);
  const [meta, setMeta] = useState<IPageMeta & { search: string }>({
    page: 0,
    perPage: 25,
    total: 0,
    search: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const metaRef = useRef(meta);
  metaRef.current = meta;

  const {
    label,
    fullWidth,
    error,
    getOptionDisabled,
    personId,
    refresh = 0,
    module,
    ...autocompleteProps
  } = props;

  const loadCustomers = useMemo(() => {
    return debounce(async ({ page, perPage, searchStr }) => {
      setIsLoading(true);

      let getMembers: (
        tableParams: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
        id?: number,
      ) => Promise<IPaginatedData<ICustomerSimpleDto>>;

      switch (module) {
        case PeakModules.FrontDesk:
          getMembers = Services.FrontDesk.getCustomers;
          break;
        case PeakModules.Members:
          getMembers = Services.Members.getCustomers;
          break;
        default:
          getMembers = Services.PeakContacts.getCustomers;
      }

      const { data, meta: metaData } = await getMembers({ page, perPage, searchStr }, personId);

      setOptions(prev => [
        ...prev.filter(item => !data.find(newItem => newItem.id === item.id)),
        ...data,
      ]);
      setMeta({ ...metaData, search: searchStr });

      setIsLoading(false);
    }, 500);
  }, [module, personId]);

  useEffect(() => {
    if (refresh !== 0) {
      loadCustomers({ page: 0, perPage: 25, searchStr: '' });
    }
  }, [refresh, loadCustomers]);

  useEffect(() => {
    if (!open) {
      setMeta({
        page: 0,
        perPage: 25,
        total: 0,
        search: '',
      });
      setOptions([]);
    } else {
      loadCustomers(metaRef.current);
    }
  }, [open, loadCustomers]);

  const renderUserOption = ({ imageUrl, firstName, lastName, id }: ICustomerSimpleDto) => {
    const initials = getInitials(firstName, lastName);

    return (
      <Box display="flex" alignItems="center" key={id}>
        <Box className={classes.avatarWrap}>
          {initials && !imageUrl ? (
            <Box className={classes.avatarInitial}>{initials}</Box>
          ) : (
            <Avatar
              src={imageUrl || profilePlaceholder}
              className={classes.optionAvatar}
              alt={firstName}
            />
          )}
        </Box>
        <Box ml={1}>
          <Typography className={classes.optionName}>{`${firstName} ${lastName}`}</Typography>
        </Box>
      </Box>
    );
  };

  const onScroll = useCallback(
    (event: React.BaseSyntheticEvent) => {
      const listBoxNode = event.currentTarget;
      const currentScrollHeight = listBoxNode.scrollTop + listBoxNode.clientHeight;

      if (currentScrollHeight === listBoxNode.scrollHeight) {
        const pageCount = meta.total / meta.perPage;
        const remainderOnDividing = meta.total % meta.perPage;
        const isLastPage = remainderOnDividing
          ? Math.trunc(pageCount) === meta.page
          : pageCount - 1 === meta.page;

        if (!isLastPage) {
          loadCustomers({ page: meta.page + 1, perPage: meta.perPage, searchStr: meta.search });
        }
      }
    },
    [loadCustomers, meta.page, meta.perPage, meta.search, meta.total],
  );

  const ListboxProps = {
    itemSize: 50,
    limitCount: 6,
    isLoading,
    onScroll,
  };

  const onSearch = (e: React.BaseSyntheticEvent) => {
    loadCustomers({ page: 0, perPage: 25, searchStr: e.target.value });
  };

  return (
    <MuiAutocomplete
      {...autocompleteProps}
      open={open}
      multiple
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      getOptionSelected={(option, value) => option?.id === value?.id}
      getOptionLabel={option => (option ? `${option.firstName} ${option.lastName}` : '')}
      renderOption={renderUserOption}
      options={options}
      loading={isLoading}
      ListboxComponent={ListboxComponent}
      ListboxProps={ListboxProps}
      renderInput={params => {
        return (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth={fullWidth}
            onChange={onSearch}
            error={!!error}
            helperText={error}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
              form: {
                autoComplete: 'off',
              },
            }}
          />
        );
      }}
    />
  );
};

export default CustomersAutocompleteField;
