// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { batch, useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
// custom interfaces
import {
  IInventoryListInfoImt,
  IRedeemInventoryForm,
  IServiceListInfoImt,
} from 'common/interfaces/service';
import { IInvoiceDetailsDto, IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
// custom components
import CheckInBlock from '../CheckInBlock/CheckInBlock';
import { Button, LoadingBackdrop } from 'common/components';
import ServiceListItem from './ServiceListItem/ServiceListItem';
import ServiceSectionActions from './ServiceSectionActions/ServiceSectionActions';
import ServiceDetailsModal from './modals/ServiceDetailsModal/ServiceDetailsModal';
import InventoryDetailsModal from './modals/InventoryDetailsModal/InventoryDetailsModal';
import RedeemModal from './modals/RedeemModal/RedeemModal';
import RedeemInventoryModal from './modals/RedeemInventoryModal/RedeemInventoryModal';
import FamilyMemberServiceDetailsModal from './modals/FamilyMemberServiceDetailsModal/FamilyMemberServiceDetailsModal';
import RedeemFamilyMembersModal from './modals/RedeemFamilyMembersModal/RedeemFamilyMembersModal';
// icons
import { ReactComponent as AngleDownIcon } from 'img/icons/angle-down.svg';
import { ReactComponent as AngleUpIcon } from 'img/icons/angle-up.svg';
// constants
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { CREATE_INVOICE } from 'common/constants/delayedActionsKeys';
import { ServiceType } from 'common/constants/service';
import StorageServices from 'services/storage';
// state
import {
  fetchServices,
  resetPersonServices,
} from 'common/components/PersonProfile/state/services/actions';
import { createInvoice, selectInvoice } from 'common/state/invoice/actions';
import { fetchPersonNotes } from 'common/components/PersonProfile/state/actions';
import * as actions from 'common/components/PersonProfile/state/servicesModals/actions';
import {
  selectExternalInventories,
  selectExternalServices,
  selectFamilyMembersServices,
  selectMembershipInventories,
  selectMembershipServices,
  selectServicesSectionLoading,
} from 'common/components/PersonProfile/state/services/selectors';
import * as selectors from 'common/state/invoice/selectors';
import { selectCurrentInvoice } from 'common/state/invoice/selectors';
import { selectMainPanelPersonId } from 'modules/front-desk/state/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import {
  selectInventoryRedeemActionLoading,
  selectInventoryRedeemActionResult,
  selectIsFamilyMemberServiceRedeemShown,
  selectIsInventoryRedeemShown,
  selectIsRedeemShown,
  selectRedeemFamilyMembersActionResult,
  selectRedeemFamilyMembersLoading,
  selectServiceRedeemActionResult,
} from 'common/components/PersonProfile/state/servicesModals/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
// messages
import messages from 'common/components/PersonProfile/messages';
import { fetchPersonFamilyMembers } from 'common/components/PersonProfile/state/familyMembers/actions';
import { selectAddFamilyMemberResult } from 'common/state/newPerson/familyMembers/selectors';
import { redeemFamilyMembers } from 'common/components/PersonProfile/state/servicesModals/actions';
import useFetchInvoicesList from 'common/httpHooks/useFetchInvoicesList';
import { snackbar } from 'common/utils/snackbarUtils';
import commonMessages from 'common/messages/messages';
import {
  CancelMembershipModal,
  FreezeMembershipModal,
  ReactivateMembershipModal,
} from 'common/components/PersonProfile/modals';

interface IServicesSectionProps {
  profileId: number;
  profileFirstName: string;
  profileLastName: string;
  module: PeakModuleForNewPersonType;
  readOnly?: boolean;
  photoUrl?: string;

  onAddNewService?: () => void;
  isOpenPOSPanel?: boolean;
  handlePOSPanelChange?: (isOpen: boolean) => void;
  handleNewMemberPanelChange?: (isOpen: boolean) => void;
  handleChangePackagePlanChange?: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    marginTop: theme.spacing(1),
    '& > span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  icon: {
    width: 16,
    height: 16,
  },
  showMoreButton: {
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
  btnTitle: {
    marginRight: theme.spacing(0.75),
  },
}));

const { addDelayedAction } = StorageServices.delayedActions;

const ServicesSection: React.FC<IServicesSectionProps> = ({
  profileId,
  module,
  readOnly,
  onAddNewService,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
  isOpenPOSPanel,
}) => {
  const classes = useStyles();

  // state
  const dispatch = useAppDispatch();

  const usePersonSelector = usePersonSelectorTemplate(profileId);

  const membershipServices: ImmutableList<IServiceListInfoImt> = usePersonSelector(
    selectMembershipServices,
  );
  const membershipInventory: ImmutableList<IInventoryListInfoImt> = usePersonSelector(
    selectMembershipInventories,
  );
  const externalServices: ImmutableList<IServiceListInfoImt> = usePersonSelector(
    selectExternalServices,
  );
  const externalInventory: ImmutableList<IInventoryListInfoImt> = usePersonSelector(
    selectExternalInventories,
  );
  const familyMembersServices: ImmutableList<IServiceListInfoImt> = usePersonSelector(
    selectFamilyMembersServices,
  );
  const isServicesLoading: boolean = usePersonSelector(selectServicesSectionLoading);
  const redeemActionResult: ActionResult = useSelector(selectServiceRedeemActionResult);
  const inventoryRedeemActionResult: ActionResult = useSelector(selectInventoryRedeemActionResult);
  const isInventoryRedeemActionLoading: boolean = useSelector(selectInventoryRedeemActionLoading);

  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const currentInvoice: IInvoiceDetailsImt = useSelector(selectCurrentInvoice);
  const checkOutResponse: IInvoiceDetailsImt = useSelector(selectors.selectInvoiceCheckoutResult);
  const mainPanelPersonId: string = useSelector(selectMainPanelPersonId);
  const isRedeemModalOpen: boolean = useSelector(selectIsRedeemShown);
  const isInventoryRedeemModalOpen = useSelector(selectIsInventoryRedeemShown);
  const isFamilyMemberServiceRedeemModalOpen = useSelector(selectIsFamilyMemberServiceRedeemShown);

  const redeemFamilyMemberActionResult = useSelector(selectRedeemFamilyMembersActionResult);
  const redeemFamilyMemberLoading = useSelector(selectRedeemFamilyMembersLoading);
  const addFamilyMemberFromStepActionResult = useSelector(selectAddFamilyMemberResult);
  const currentUserId = useSelector(selectCurrentUserId);

  const [isInvoicesListLoading, setIsInvoicesListLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isFreezeModalOpen, setIsFreezeModalOpen] = useState<boolean>(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState<boolean>(false);

  const isCrmModule = module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  const fetchInvoicesList = useFetchInvoicesList({
    module: PeakModules.FrontDesk,
    registerId: selectedRegisterId,
    isMembershipStep: false,
  });

  // effects

  useEffect(() => {
    dispatch(fetchServices(profileId, module));

    return () => {
      dispatch(resetPersonServices(null, profileId));
    };
  }, [dispatch, module, profileId]);

  useEffect(() => {
    if (redeemActionResult === ActionResult.SUCCESS_ACTION) {
      batch(() => {
        dispatch(actions.setIsDetailsShown(false));
        dispatch(actions.setIsRedeemShown(false));
        dispatch(actions.resetRedeemService());
      });
      dispatch(fetchServices(profileId, module));

      if (!isCrmModule) {
        dispatch(fetchPersonNotes(profileId, module));
      }
    }
  }, [dispatch, module, profileId, redeemActionResult, isCrmModule]);

  useEffect(() => {
    if (inventoryRedeemActionResult === ActionResult.SUCCESS_ACTION) {
      batch(() => {
        dispatch(actions.setIsInventoryDetailsShown(false));
        dispatch(actions.setIsInventoryRedeemShown(false));
        dispatch(actions.resetRedeemInventory());
      });
      dispatch(fetchServices(profileId, module));

      if (!isCrmModule) {
        dispatch(fetchPersonNotes(profileId, module));
      }
    }
  }, [dispatch, inventoryRedeemActionResult, module, profileId, isCrmModule]);

  useEffect(() => {
    if (
      redeemFamilyMemberActionResult === ActionResult.SUCCESS_ACTION ||
      addFamilyMemberFromStepActionResult === ActionResult.SUCCESS_ACTION
    ) {
      dispatch(fetchServices(profileId, module));
      dispatch(fetchPersonFamilyMembers(profileId, module));

      batch(() => {
        dispatch(actions.setIsFamilyMemberServiceDetailsShown(false));
        dispatch(actions.setIsFamilyMemberServiceRedeemShown(false));
        dispatch(actions.resetRedeemFamilyMembers());
      });
    }
  }, [
    addFamilyMemberFromStepActionResult,
    dispatch,
    module,
    profileId,
    redeemFamilyMemberActionResult,
  ]);

  useEffect(() => {
    // update service section after check out

    const checkOutCustomerId = checkOutResponse?.getIn(['customer', 'id']);

    if (checkOutCustomerId === mainPanelPersonId) {
      dispatch(fetchServices(profileId, module));
    }
  }, [checkOutResponse, dispatch, mainPanelPersonId, module, profileId]);

  // handlers

  const handleServiceClick = useCallback(
    (item: IServiceListInfoImt) => {
      if (item.get('packageServiceInstanceId')) {
        batch(() => {
          if ((item as IServiceListInfoImt).get('type') === ServiceType.FamilyMember) {
            dispatch(actions.selectFamilyMemberServiceId(item.get('packageServiceInstanceId')));
            dispatch(actions.setIsFamilyMemberServiceDetailsShown(true));
          } else {
            dispatch(actions.setIsDetailsShown(true));
            dispatch(actions.selectServiceItemId(item.get('packageServiceInstanceId')));
          }
        });
      }
    },
    [dispatch],
  );

  const handleInventoryClick = useCallback(
    (item: IInventoryListInfoImt) => {
      batch(() => {
        dispatch(actions.selectInventoryId(item.get('id')));
        dispatch(actions.setIsInventoryDetailsShown(true));
      });
    },
    [dispatch],
  );

  const handleHistoryClick = useCallback(() => {
    snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />);
  }, []);

  const handleAddServiceClick = useCallback(async () => {
    const handleCreateInvoice = (invoicesList: IInvoiceDetailsDto[]) => {
      if (onAddNewService) {
        if (currentInvoice?.getIn(['customer', 'id']) === profileId) {
          onAddNewService();
          return;
        }

        const openedInvoiceForSelectedCustomer: IInvoiceDetailsDto | void = invoicesList?.find(
          (invoiceItem: IInvoiceDetailsDto) => {
            return (
              invoiceItem.status === InvoiceStatus.OPEN &&
              invoiceItem.customer?.id === profileId &&
              invoiceItem.salesperson?.id === currentUserId
            );
          },
        );
        const doAction = (registerId: string) => {
          dispatch(
            createInvoice({
              module,
              registerId,
              customerId: profileId,
              shouldFocus: true,
              profileId,
            }),
          );
        };

        if (openedInvoiceForSelectedCustomer) {
          dispatch(selectInvoice(openedInvoiceForSelectedCustomer.id));
          onAddNewService();
        } else if (selectedRegisterId && isOpenPOSPanel) {
          doAction(selectedRegisterId);
        } else {
          addDelayedAction(CREATE_INVOICE, doAction);
          onAddNewService();
        }
      }
    };

    setIsInvoicesListLoading(true);
    await fetchInvoicesList(handleCreateInvoice);
    setIsInvoicesListLoading(false);
  }, [
    fetchInvoicesList,
    setIsInvoicesListLoading,
    onAddNewService,
    currentInvoice,
    profileId,
    selectedRegisterId,
    isOpenPOSPanel,
    currentUserId,
    dispatch,
    module,
  ]);

  const onRedeemInventory = ({
    redeemedNumber,
    pinCode,
    inventoryId,
    club,
  }: IRedeemInventoryForm & { inventoryId: string }) => {
    dispatch(
      actions.redeemInventoryItem(profileId, inventoryId, redeemedNumber, pinCode, club.id, module),
    );
  };

  const onCloseRedeemInventoryModal = () => {
    batch(() => {
      dispatch(actions.setIsInventoryRedeemShown(false));
      dispatch(actions.setIsInventoryDetailsShown(true));
    });
  };

  const onCloseAddFamilyMemberModal = () => {
    batch(() => {
      dispatch(actions.setIsFamilyMemberServiceRedeemShown(false));
      dispatch(actions.setIsFamilyMemberServiceDetailsShown(true));
    });
  };

  const onRedeemFamilyMemberService = useCallback(
    (serviceInstanceId: string, familyMemberIds: string[], clubId: string) => {
      dispatch(
        redeemFamilyMembers(profileId, serviceInstanceId, { familyMemberIds, clubId }, module),
      );
    },
    [dispatch, module, profileId],
  );

  const onToggleCancelMembershipModal = useCallback(() => {
    setIsCancelModalOpen(prevState => !prevState);
  }, []);

  const onToggleFreezeMembershipModal = useCallback(
    () => setIsFreezeModalOpen(prevState => !prevState),
    [],
  );

  const onToggleReactivateMembershipModal = useCallback(
    () => setIsReactivateModalOpen(prevState => !prevState),
    [],
  );

  let servicesSize = 0;
  let fullServicesSize = 0;

  let membershipServicesRenderData = membershipServices || ImmutableList();
  let membershipInventoryRenderData = membershipInventory || ImmutableList();
  let externalServicesRenderData = externalServices || ImmutableList();
  let externalInventoryRenderData = externalInventory || ImmutableList();
  let familyMembersServicesRenderData = familyMembersServices || ImmutableList();

  fullServicesSize += membershipServicesRenderData.size;
  fullServicesSize += membershipInventoryRenderData.size;
  fullServicesSize += externalServicesRenderData.size;
  fullServicesSize += externalInventoryRenderData.size;
  fullServicesSize += familyMembersServicesRenderData.size;

  if (!open) {
    membershipServicesRenderData = membershipServicesRenderData.slice(0, 5);
    servicesSize += membershipServicesRenderData.size;

    if (servicesSize < 5) {
      membershipInventoryRenderData = membershipInventoryRenderData.slice(0, 5 - servicesSize);
      servicesSize += membershipInventoryRenderData.size;
    } else {
      membershipInventoryRenderData = ImmutableList();
    }

    if (servicesSize < 5) {
      externalServicesRenderData = externalServicesRenderData.slice(0, 5 - servicesSize);
      servicesSize += externalServicesRenderData.size;
    } else {
      externalServicesRenderData = ImmutableList();
    }

    if (servicesSize < 5) {
      externalInventoryRenderData = externalInventoryRenderData.slice(0, 5 - servicesSize);
      servicesSize += externalInventoryRenderData.size;
    } else {
      externalInventoryRenderData = ImmutableList();
    }

    if (servicesSize < 5) {
      familyMembersServicesRenderData = familyMembersServicesRenderData.slice(0, 5 - servicesSize);
    } else {
      familyMembersServicesRenderData = ImmutableList();
    }
  }

  const services = (
    <Box>
      {(!!membershipServicesRenderData.size || !!membershipInventoryRenderData.size) && (
        <>
          <Box className={classes.subHeader}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage {...messages.membershipSubLabel} />
            </Typography>
          </Box>

          {membershipServicesRenderData.map((service: IServiceListInfoImt) => (
            <ServiceListItem
              key={service.get('serviceId')}
              service={service}
              onClick={handleServiceClick}
            />
          ))}

          {membershipInventoryRenderData.map((inventory: IInventoryListInfoImt) => (
            <ServiceListItem
              key={inventory.get('id')}
              item={inventory}
              onClick={handleInventoryClick}
            />
          ))}
        </>
      )}

      {(!!externalServicesRenderData.size || !!externalInventoryRenderData.size) && (
        <>
          <Box className={classes.subHeader}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage {...messages.additionalSubLabel} />
            </Typography>
          </Box>

          {externalServicesRenderData.map((service: IServiceListInfoImt) => {
            return (
              <ServiceListItem
                key={service.get('serviceId')}
                service={service}
                onClick={handleServiceClick}
              />
            );
          })}

          {externalInventoryRenderData.map((inventory: IInventoryListInfoImt) => {
            return (
              <ServiceListItem
                key={inventory.get('id')}
                item={inventory}
                onClick={handleInventoryClick}
              />
            );
          })}
        </>
      )}

      {!!familyMembersServicesRenderData.size && (
        <>
          <Box className={classes.subHeader}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage {...messages.inheritedSubLabel} />
            </Typography>
          </Box>

          {familyMembersServicesRenderData.map((service: IServiceListInfoImt) => {
            return (
              <ServiceListItem
                key={service.get('serviceId')}
                service={service}
                onClick={handleServiceClick}
              />
            );
          })}
        </>
      )}
    </Box>
  );

  const showMore = () => {
    setOpen(true);
  };

  const showLess = () => {
    setOpen(false);
  };

  // renders
  // TODO: Need to fix the key match
  return (
    <CheckInBlock
      headerDivider
      title={<FormattedMessage {...messages.servicesBlockTitle} />}
      buttonGroup={
        <ServiceSectionActions
          readOnly={readOnly}
          onAddNewClick={handleAddServiceClick}
          onHistoryClick={handleHistoryClick}
          onCancelClick={onToggleCancelMembershipModal}
          onFreezeClick={onToggleFreezeMembershipModal}
          onReactivateClick={onToggleReactivateMembershipModal}
        />
      }
    >
      {services}
      {fullServicesSize > 5 && (
        <Button
          type="button"
          onClick={open ? showLess : showMore}
          fullWidth
          size="medium"
          color="primary"
          variant="outlined"
          className={classes.showMoreButton}
        >
          <Typography color="inherit" variant="inherit" className={classes.btnTitle}>
            <FormattedMessage
              {...(open ? commonMessages.showLessBtn : commonMessages.showMoreBtn)}
            />
          </Typography>
          {open ? (
            <AngleUpIcon className={classes.icon} />
          ) : (
            <AngleDownIcon className={classes.icon} />
          )}
        </Button>
      )}

      {!membershipServices?.size &&
        !membershipInventory?.size &&
        !externalServices?.size &&
        !externalInventory?.size &&
        !familyMembersServices?.size && (
          <Box className="empty-section-placeholder" p={2}>
            <Typography className="empty-text">
              <FormattedMessage {...messages.emptyServiceListMessage} />
            </Typography>
          </Box>
        )}

      <LoadingBackdrop isLoading={isInvoicesListLoading || isServicesLoading} />

      <ServiceDetailsModal profileId={profileId} module={module} />

      <InventoryDetailsModal profileId={profileId} module={module} />

      <FamilyMemberServiceDetailsModal profileId={profileId} module={module} />

      {isRedeemModalOpen && (
        <RedeemModal profileId={profileId} module={module} isOpen={isRedeemModalOpen} />
      )}

      {isInventoryRedeemModalOpen && (
        <RedeemInventoryModal
          onClose={onCloseRedeemInventoryModal}
          isOpen={isInventoryRedeemModalOpen}
          onSubmit={onRedeemInventory}
          isLoading={isInventoryRedeemActionLoading}
        />
      )}

      {isFamilyMemberServiceRedeemModalOpen && (
        <RedeemFamilyMembersModal
          isLoading={redeemFamilyMemberLoading}
          isOpen={isFamilyMemberServiceRedeemModalOpen}
          personId={profileId}
          module={module}
          onSubmit={onRedeemFamilyMemberService}
          onClose={onCloseAddFamilyMemberModal}
        />
      )}

      {isCancelModalOpen && (
        <CancelMembershipModal
          isOpen
          personId={profileId}
          membershipId={null}
          onCancel={onToggleCancelMembershipModal}
          module={module}
          handlePOSPanelChange={handlePOSPanelChange}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          isOpenPOSPanel={isOpenPOSPanel}
          disablePosBtn={false}
        />
      )}

      {isFreezeModalOpen && (
        <FreezeMembershipModal
          module={module}
          isOpen={isFreezeModalOpen}
          personId={profileId}
          onSubmit={onToggleFreezeMembershipModal}
          onCancel={onToggleFreezeMembershipModal}
        />
      )}

      {isReactivateModalOpen && (
        <ReactivateMembershipModal
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          disablePosBtn={false}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handlePOSPanelChange={handlePOSPanelChange}
          isOpenPOSPanel={isOpenPOSPanel}
          isOpen={isReactivateModalOpen}
          personId={profileId}
          onSubmit={onToggleReactivateMembershipModal}
          onCancel={onToggleReactivateMembershipModal}
          module={module}
        />
      )}
    </CheckInBlock>
  );
};

export default React.memo(ServicesSection);
