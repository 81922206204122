import IntlService from 'services/intl/IntlService';
import { RewardsType } from './constants';
import messages from 'common/components/PersonProfile/messages';

export const getRewardsTypeLabel = (type: RewardsType) => {
  switch (type) {
    case RewardsType.Prospect:
      return IntlService.formatMessage(messages.prospect);

    case RewardsType.Join:
      return IntlService.formatMessage(messages.join);

    case RewardsType.Redemption:
      return IntlService.formatMessage(messages.redemption);

    case RewardsType.Adjustmen:
      return IntlService.formatMessage(messages.adjustmen);
    default:
      return '';
  }
};
