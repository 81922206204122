import React, { useEffect, useMemo, useState } from 'react';
import { Box, debounce, Grid, MenuItem, TextField, Typography } from '@material-ui/core';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Autocomplete as MuiAutocomplete } from '@material-ui/lab';
import { GridSize } from '@material-ui/core/Grid/Grid';
import { List as ImmutableList } from 'immutable';

import { Select, ListboxComponent, AvatarWithInitials } from 'common/components';
import inputLabels from 'common/messages/inputLabels';
import { CameFromType } from 'common/constants';
import { ICameFromIndicatorDictionaryItemImt } from 'common/interfaces/dictionary';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { INamedEntity, INamedEntityImt } from 'common/interfaces/common';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { getInitials } from 'helpers/common';
import { getString } from 'common/utils/typeUtils';

type TLayout = { xs?: GridSize; sm?: GridSize };

interface ILayoutConfig {
  cameFromField: TLayout;
  referralListField: TLayout;
  dropdownSelectField: TLayout;
  singleInputField: TLayout;
}

interface ICameFromFieldsProps {
  cameFromIndicators: ImmutableList<ICameFromIndicatorDictionaryItemImt>;
  persons: ImmutableList<INamedEntityImt>;
  getReferralMembersByValue: (search: string) => void;
  clearSearchResults: () => void;
  isPersonSearchLoading: boolean;
  isMultipleSelect?: boolean;
  layoutConfig?: ILayoutConfig;
  cameFromIndicator?: any;
  referralMember?: any;
  fieldName?: string;
}

const useStyles = makeStyles(() => ({
  optionAvatar: {
    width: '36px',
    height: '36px',
  },
  optionName: {
    fontSize: '0.9rem',
    fontWeight: 500,
  },
}));

const CameFromFields = (props: ICameFromFieldsProps): JSX.Element => {
  const {
    cameFromIndicators,
    isPersonSearchLoading,
    persons,
    clearSearchResults,
    getReferralMembersByValue,
    layoutConfig = {},
    referralMember,
    cameFromIndicator,
    fieldName,
    isMultipleSelect,
  } = props;

  const {
    cameFromField,
    referralListField,
    dropdownSelectField,
    singleInputField,
  } = layoutConfig as ILayoutConfig;

  const [currentCameFrom, setCurrentCameFrom] = useState<ICameFromIndicatorDictionaryItemImt>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const { control, formState, setValue } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();

  const { cameFromTypeFieldName, optionalFieldName } = useMemo(
    () => ({
      cameFromTypeFieldName: fieldName ? `${fieldName}.cameFromType` : 'cameFromType',
      optionalFieldName: fieldName ? `${fieldName}.optional` : 'optional',
    }),
    [fieldName],
  );

  useEffect(() => {
    if (currentCameFrom) {
      setValue(optionalFieldName, currentCameFrom.get('optional'));
      setValue(cameFromTypeFieldName, currentCameFrom.get('type'));
    }
  }, [cameFromTypeFieldName, currentCameFrom, optionalFieldName, setValue]);

  const cameFromType = useWatch({
    control,
    name: cameFromTypeFieldName,
  });

  const convertedPersons = useMemo(() => (persons ? persons.toJS() : []), [persons]);

  const onChangeSearch = useMemo(() => {
    return debounce((value: string) => {
      getReferralMembersByValue(value);
    }, 500);
  }, [getReferralMembersByValue]);

  const renderUserOption = ({ title, imageUrl }: INamedEntity) => {
    const [firstName, lastName] = title.split(' ');

    const initials = getInitials(firstName, lastName);

    return (
      <Box display="flex">
        <AvatarWithInitials
          imageUrl={imageUrl}
          width="36px"
          height="36px"
          fontSize="1rem"
          initials={initials}
        />

        <Box display="flex" alignItems="center">
          <Typography className={classes.optionName}>{title}</Typography>
        </Box>
      </Box>
    );
  };

  const existReferralMember = referralMember
    ? [
        {
          id: referralMember.get('id'),
          title: `${referralMember.get('firstName')} ${referralMember.get('lastName')}`,
          imageUrl: referralMember.get('imageUrl'),
        },
      ]
    : [];

  const options = convertedPersons.length ? convertedPersons : existReferralMember;

  const ListboxProps = {
    itemSize: 45,
    limitCount: 8,
  };

  return (
    <>
      <Grid item xs={12} {...cameFromField}>
        <Controller
          name={fieldName ? `${fieldName}.cameFromIndicatorId` : 'cameFromIndicatorId'}
          control={control}
          render={({ field }) => (
            <Select
              label={<FormattedMessage {...inputLabels.cameFrom} />}
              value={field.value}
              onChange={field.onChange}
              onBlur={field.onBlur}
              fullWidth
              variant="outlined"
              error={
                !!(fieldName
                  ? getAccessByPropPath(errors, `${fieldName}.cameFromIndicatorId`)
                  : errors.cameFromIndicatorId)
              }
              helperText={renderIntlMessage(
                fieldName
                  ? getString(get(errors, `${fieldName}.cameFromIndicatorId.message`))
                  : getString(errors.cameFromIndicatorId?.message),
              )}
            >
              {cameFromIndicators.map(item => (
                <MenuItem
                  onClick={() => setCurrentCameFrom(item)}
                  key={item.get('id')}
                  value={item.get('id')}
                >
                  {item.get('title')}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </Grid>

      {cameFromType === CameFromType.ReferralList && (
        <Grid item xs={12} {...referralListField}>
          <Controller
            control={control}
            name={fieldName ? `${fieldName}.referralMember` : 'referralMember'}
            render={({ field }) => {
              return (
                <MuiAutocomplete
                  open={isOpenDropdown}
                  onOpen={() => setIsOpenDropdown(true)}
                  onClose={() => setIsOpenDropdown(false)}
                  onChange={(e, val) => {
                    field.onChange(val);
                  }}
                  multiple={isMultipleSelect}
                  value={field.value}
                  getOptionLabel={option =>
                    option.title || `${option.firstName} ${option.lastName}`
                  }
                  renderOption={renderUserOption}
                  onInputChange={(e, val) => {
                    onChangeSearch(val || '');
                  }}
                  onFocus={(e: React.BaseSyntheticEvent) => {
                    onChangeSearch(e.target.value || '');
                  }}
                  onBlur={() => {
                    field.onBlur();
                    clearSearchResults();
                  }}
                  ListboxComponent={ListboxComponent}
                  ListboxProps={ListboxProps}
                  options={options}
                  loading={isPersonSearchLoading}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        label={
                          <FormattedMessage
                            {...(currentCameFrom?.get('optional')
                              ? inputLabels.referralOptional
                              : inputLabels.referral)}
                          />
                        }
                        variant="outlined"
                        fullWidth
                        error={
                          !!(fieldName
                            ? getAccessByPropPath(errors, `${fieldName}.referralMember`)
                            : errors.cameFromIndicatorId)
                        }
                        helperText={renderIntlMessage(
                          fieldName
                            ? getString(get(errors, `${fieldName}.referralMember.message`))
                            : getString(errors.cameFromIndicatorId?.message),
                        )}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'off',
                        }}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </Grid>
      )}

      {cameFromType === CameFromType.DropdownSelect && (
        <Grid item xs={12} {...dropdownSelectField}>
          <Controller
            control={control}
            name={fieldName ? `${fieldName}.cameFromValue` : 'cameFromValue'}
            render={({ field }) => (
              <Select
                fullWidth
                variant="outlined"
                label={
                  currentCameFrom?.get('inputPlaceholder') ||
                  cameFromIndicator?.get('inputPlaceholder')
                }
                multiple={isMultipleSelect}
                name={field.name}
                value={!field.value && isMultipleSelect ? [] : field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={
                  !!(fieldName
                    ? getAccessByPropPath(errors, `${fieldName}.cameFromValue`)
                    : errors.cameFromValue)
                }
                helperText={renderIntlMessage(
                  fieldName
                    ? getString(get(errors, `${fieldName}.cameFromValue.message`))
                    : getString(errors.cameFromValue?.message),
                )}
              >
                {(currentCameFrom?.get('options') || cameFromIndicator?.get('options'))?.map(
                  (option: string, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ),
                )}
              </Select>
            )}
          />
        </Grid>
      )}

      {cameFromType === CameFromType.SingleInput && (
        <Grid item xs={12} {...singleInputField}>
          <Controller
            control={control}
            name={fieldName ? `${fieldName}.cameFromValue` : 'cameFromValue'}
            render={({ field }) => (
              <TextField
                fullWidth
                label={
                  currentCameFrom?.get('inputPlaceholder') ||
                  cameFromIndicator?.get('inputPlaceholder')
                }
                variant="outlined"
                inputProps={{ maxLength: 1000 }}
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                inputRef={field.ref}
                error={
                  !!(fieldName
                    ? getAccessByPropPath(errors, `${fieldName}.cameFromValue`)
                    : errors.cameFromValue)
                }
                helperText={renderIntlMessage(
                  fieldName
                    ? getString(get(errors, `${fieldName}.cameFromValue.message`))
                    : getString(errors.cameFromValue?.message),
                )}
              />
            )}
          />
        </Grid>
      )}

      <Controller render={() => null} control={control} name={optionalFieldName} />
      <Controller render={() => null} control={control} name={cameFromTypeFieldName} />
    </>
  );
};

export default CameFromFields;
