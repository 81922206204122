import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import {
  Box,
  FormControlLabel,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import { getAccessByPropPath } from 'common/utils/errorObject';
import { sortAvailabilities } from '../../../modules/services/utils/servicePackage';

import { FullWeekdays, Weekday } from 'common/interfaces/common';

import { ReactComponent as DeleteIcon } from 'img/icons/trash_deprecated.svg';
import { Checkbox } from 'common/components/index';
import TimeAvailabilitySelector from './TimeAvailabilitySelector';

import inputLabels from 'common/messages/inputLabels';

const useStyles = makeStyles((theme: Theme) => ({
  checkboxLabel: {
    '& .MuiFormControlLabel-label': {
      marginLeft: theme.spacing(1),
    },
  },
  disabledColor: {
    '& .MuiInputBase-input.Mui-disabled': {
      opacity: 0.4,
    },
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
  addIcon: {
    width: 16,
    height: 16,
  },
}));

const options = Object.values(Weekday).map(weekdayItem => ({
  weekday: weekdayItem,
  allDay: false,
  timeRanges: [{ startTime: null, endTime: null }],
  editableRangeIndex: null,
}));

interface IProps {
  weekdaysFieldName?: string;
  rangesFieldName?: string;
}

const DayTimeAvailabilitySection = ({
  rangesFieldName,
  weekdaysFieldName,
}: IProps): JSX.Element => {
  const { control, formState, setValue, getValues } = useFormContext();
  const { errors } = formState;
  const renderIntlMessage = useRenderIntlMessage();
  const classes = useStyles();
  const fieldName = rangesFieldName || 'ranges';
  const weekdaysName = weekdaysFieldName ? `${weekdaysFieldName}.weekdays` : 'weekdays';

  const handleRemoveWeekday = (weekday: Weekday) => {
    const weekdays = getValues(weekdaysName);

    const restWeekdays = weekdays.filter(weekdayItem => weekdayItem.weekday !== weekday);
    setValue(weekdaysName, restWeekdays);
  };

  const { fields: ranges, remove, update } = useFieldArray({ control, name: fieldName });

  return (
    <Box display="flex" flexDirection="column" gridGap={16} overflow="hidden">
      <Controller
        name={weekdaysName}
        control={control}
        render={({ field }) => (
          <Autocomplete
            value={field.value}
            onBlur={field.onBlur}
            multiple
            disableCloseOnSelect
            onChange={(_, values, action, details) => {
              field.onChange(sortAvailabilities(values));

              if (action === 'select-option' && details?.option) {
                setValue(fieldName, sortAvailabilities([...ranges, details.option]));
              }

              if (action === 'remove-option') {
                const index = ranges.findIndex(
                  rangeItem => (rangeItem as any).weekday === details?.option.weekday, // TODO - PRM-1810 need type (dynamic array name)
                );

                remove(index);
              }

              if (action === 'clear') {
                setValue(fieldName, []);
              }
            }}
            getOptionSelected={(option, selectedOption) =>
              option?.weekday === selectedOption?.weekday
            }
            getOptionLabel={option => renderIntlMessage(FullWeekdays.message(option.weekday)) || ''}
            options={options}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                error={!!getAccessByPropPath(errors, weekdaysName)}
                helperText={renderIntlMessage(getAccessByPropPath(errors, weekdaysName)?.message)}
                label={<FormattedMessage {...inputLabels.dayOfTheWeek} />}
              />
            )}
          />
        )}
      />

      {ranges.map((rangeItem, index) => {
        const isAllDay = getValues(`${fieldName}.${index}.allDay`);

        return (
          <Box key={rangeItem.id}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="button" color="textSecondary">
                {/* // TODO - PRM-1810 need type (dynamic array name) */}
                {renderIntlMessage((rangeItem as any).weekday)}
              </Typography>

              <Box display="flex" alignItems="center">
                <Controller
                  control={control}
                  name={`${fieldName}.${index}.allDay`}
                  defaultValue={(rangeItem as any).allDay} // TODO - PRM-1810 need type (dynamic array name)
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={field.value}
                          onChange={e => {
                            const isChecked = e.target.checked;

                            if (isChecked) {
                              update(index, {
                                ...rangeItem,
                                allDay: isChecked,
                                editableRangeIndex: null,
                                timeRanges: [],
                              });
                            } else {
                              update(index, {
                                ...rangeItem,
                                allDay: isChecked,
                                timeRanges: [{ startTime: null, endTime: null }],
                              });
                            }
                          }}
                          onBlur={field.onBlur}
                        />
                      }
                      label={<FormattedMessage {...inputLabels.allDay} />}
                      className={classes.checkboxLabel}
                    />
                  )}
                />

                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => {
                    remove(index);
                    handleRemoveWeekday((rangeItem as any).weekday); // TODO - PRM-1810 need type (dynamic array name)
                  }}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>

            {!isAllDay && <TimeAvailabilitySelector name={`${fieldName}.${index}`} />}
          </Box>
        );
      })}
    </Box>
  );
};

export default DayTimeAvailabilitySection;
