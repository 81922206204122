import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import cx from 'classnames';
import { Box, createStyles, makeStyles, Paper, Theme, Typography } from '@material-ui/core';

import { selectAlertsList } from 'common/state/alerts/selectors';

import { getInitials } from 'helpers/common';
import { getAlertColor } from 'common/utils/alerts';

import { ICheckInImt } from 'modules/front-desk/interfaces';
import { IAlertSettingsImt } from 'common/interfaces/alerts';

import { TimeFromCheckIn } from 'modules/kid-zone/components/index';
import { AvatarWithInitials } from 'common/components';

import messages from 'modules/front-desk/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkInWrapper: {
      position: 'relative',
      width: '100%',
      height: 305,
      cursor: 'pointer',
      borderRadius: '3px',
      overflow: 'hidden',
    },
    checkInInfoWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'absolute',
      top: 0,
      zIndex: 2,
      width: '100%',
      height: '100%',
    },
    checkingInNowTitle: {
      padding: theme.spacing(1.75, 0, 0, 1.75),
      color: theme.palette.common.white,
      textShadow: '0px 1px 2px rgba(0, 0, 0, 0.3);',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '.875rem',
    },
    checkInInfoBlock: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5, 1, 1.5, 1.5),
      color: theme.palette.primary.contrastText,

      '&.inactive': {
        background: 'rgba(50, 50, 50, 0.9)',
        backdropFilter: 'blur(4px)',
      },
    },
    kidNameTitle: {
      fontSize: '1rem',
      fontWeight: 700,
    },
  }),
);

interface IProps {
  checkIn: ICheckInImt;
  selectedCheckInId: number;
  onClick: (personId: number) => void;
}
const KidCurrentCheckIn = ({ checkIn, selectedCheckInId, onClick }: IProps): JSX.Element => {
  const alertList: ImmutableList<IAlertSettingsImt> = useSelector(selectAlertsList);

  const classes = useStyles();

  const initials = getInitials(checkIn.get('firstName'), checkIn.get('lastName'));

  const alerts = checkIn?.get('alerts').toJS();

  const itemColor = getAlertColor(alertList, alerts);

  const handleOnClick = () => {
    onClick(checkIn.get('personId'));
  };

  return (
    <Paper className={classes.checkInWrapper} onClick={handleOnClick}>
      <Box className={classes.checkInInfoWrapper}>
        <Typography component="p" className={classes.checkingInNowTitle}>
          <FormattedMessage {...messages.checkingInNow} />
        </Typography>

        <Box
          bgcolor={selectedCheckInId === checkIn.get('personId') ? itemColor : null}
          className={cx(classes.checkInInfoBlock, {
            inactive: selectedCheckInId !== checkIn.get('personId'),
          })}
        >
          <Typography color="inherit" className={classes.kidNameTitle}>
            {`${checkIn.get('firstName')} ${checkIn.get('lastName')}`}
          </Typography>

          <TimeFromCheckIn checkIn={checkIn} />
        </Box>
      </Box>

      <AvatarWithInitials
        initials={initials}
        imageUrl={checkIn.get('imageUrl')}
        width="100%"
        height="100%"
        borderRadius="initial"
        variant="square"
        marginRight={0}
        fontSize="1.75rem"
      />
    </Paper>
  );
};

export default KidCurrentCheckIn;
