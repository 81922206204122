import axios from 'axios';
import { List } from 'immutable';

import {
  ICampaign,
  ILead,
  ILeadBulkSalespersonDto,
  ILeadBulkSalesStatusDto,
  ILeadEmailHistoryDto,
  ILeadProfile,
  INewLeadProfileData,
} from 'modules/crm/interfaces/leads';
import { ITableParams } from 'common/interfaces/table';
import ApiService from './ApiService';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  IContactInfo,
  IPersonDocumentItemDto,
  IPersonDocumentToSignDto,
} from 'common/interfaces/contract';
import {
  EventUpdateType,
  IAppointmentHistoryItem,
  IBookingEvent,
  IEventAction,
  IRecentAppointmentItem,
  IRemindAppointmentDto,
  IRestoreAppointmentsParams,
  IRestoredAppointment,
} from 'modules/booking/interfaces';
import {
  IEmail,
  ISMS,
  ICallNoteDto,
  IFallthroughCapture,
  IFallthroughCaptureForm,
  IPersonSalesInfo,
  ISendEmailRequestPayload,
  IFamilyMemberSearch,
  IFamilyMember,
  ISendSmsRequestPayload,
  IReminderRecent,
  IPrimaryMemberInfo,
  IModificationHistory,
  IModificationHistoryItem,
  ICampaigns,
  IBilling,
  CancelMembershipData,
  IProfileInfo,
  IProfileAdditionalPayload,
  IFamilyMemberService,
  ITag,
  FreezeMembershipData,
  IPastDue,
  IFamilyMemberEditDTO,
  IFreezeItem,
  IFreezeHistoryPackage,
  IPaymentAccount,
  IPersonDocuments,
  IMembershipActionDTO,
  IReminderDto,
  ILeadCallHistoryDto,
  ICommerceHubCredentials,
} from 'common/components/PersonProfile/interfaces';
import { ISuccessResponse, PersonSearchParamsType } from 'common/interfaces/http';
import {
  IConfiguredPackage,
  IMembershipTransformed,
  IPackageConfiguration,
  IPackageInventoryViewDto,
  IShortPackages,
} from 'common/interfaces/membership';
import { IAdditionalInfo } from 'modules/front-desk/interfaces';
import { IAttachment, IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { INamedEntity } from 'common/interfaces/common';
import { ISimpleClubInventoryItem } from 'modules/pos-settings/interfaces/inventoryItems';
import { IPosKioskOpenRegisterDTO } from 'modules/pos-kiosk/interfaces/registers';
import {
  IInvoiceDetailsDto,
  INotifications,
  ISearchPackagesResult,
  PaymentsType,
  IInvoicePaymentSplit,
  IBalance,
  IAddInvoiceUnitDTO,
  CartUnitTypes,
  PaymentAccountType,
  IRewards,
  ISearchGiftCard,
} from 'common/interfaces/invoices';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IPackageInstanceDetails, IServiceItemDetails } from 'common/interfaces/service';
import { IEmployeeAccess } from 'common/interfaces/employee';
import { IBundleView } from 'modules/services/interfaces/packages';

export type IPaginatedLeads = IPaginatedData<ILead>;

export default class LeadsService extends ApiService {
  public getLeads = (requestOptions: ITableParams, isTable: boolean): Promise<IPaginatedLeads> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/leads${isTable ? '/table' : ''}`, { params: searchParams });
  };

  public getLead = (leadId: number): Promise<ILeadProfile> => axios.get(`/api/v1/leads/${leadId}`);

  public getLeadInfoView = (leadId: number): Promise<Partial<ILeadProfile>> =>
    axios.get(`/api/v1/leads/${leadId}/view`);

  public createLead = (clubId: string, profile: INewLeadProfileData): Promise<ILeadProfile> => {
    const profileData = {
      ...profile,
      initialClubId: clubId,
      type: 'GUEST',
      assignedSalespersonId: profile.assignedSalesperson?.id,
    };

    return axios.post('/api/v1/leads', profileData);
  };

  public getAdditionalInfoForLead = (personId: number): Promise<IAdditionalInfo> => {
    return axios.get(`/api/v1/leads/${personId}/info`);
  };

  public getInvoiceReceipt = (invoiceId: string): Promise<string> => {
    return axios.get(`api/v1/leads/pos/invoice/${invoiceId}/receipt`);
  };

  public saveAdditionalInfoForLead = (
    personId: number,
    data: IAdditionalInfo,
  ): Promise<IAdditionalInfo> => {
    return axios.put(`/api/v1/leads/${personId}/info`, data);
  };

  public updateLead = (clubId: string, profile: INewLeadProfileData): Promise<ILeadProfile> => {
    const profileData = {
      ...profile,
      clubId,
      type: 'GUEST',
      assignedSalespersonId: profile.assignedSalesperson?.id,
      salesType: profile.salesType,
    };

    return axios.post(`/api/v1/leads`, profileData);
  };

  public getReferralLeads = (search: string): Promise<INamedEntity[]> => {
    return axios.get('/api/v1/leads/referrals/list', { params: { search } });
  };

  public updateLeadAvatar = (leadId: number, image: IStoredFileSimpleDto): Promise<void> => {
    return axios.put(`/api/v1/leads/${leadId}/avatar`, image);
  };

  public deleteLeadAvatar = (leadId: number): Promise<void> => {
    return axios.delete(`/api/v1/leads/${leadId}/avatar`);
  };

  public updateMembershipInfo = (personId: number, data: IMembershipTransformed): Promise<void> => {
    return axios.put(`api/v1/leads/${personId}/membership`, data);
  };

  public getMembershipConfiguration = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/leads/membership-packages/${membershipId}`, { params: { clubId } });
  };

  public getPackageInventory = (
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(`api/v1/leads/membership-packages/${packageId}/inventories/${inventoryId}`);
  };

  public getPersonPackageInventory = (
    personId: number,
    packageId: string,
    inventoryId: string,
  ): Promise<IPackageInventoryViewDto> => {
    return axios.get(`api/v1/leads/${personId}/membership/${packageId}/inventories/${inventoryId}`);
  };

  public getMembershipConfigurationPos = (
    membershipId: string,
    clubId: string,
  ): Promise<IPackageConfiguration> => {
    return axios.get(`/api/v1/leads/pos/package-list/${membershipId}`, { params: { clubId } });
  };

  public assignSalespersonLeads = (data: ILeadBulkSalespersonDto): Promise<void> => {
    return axios.put(`/api/v1/leads/salesperson`, data);
  };

  public setCustomerToInvoice = (
    invoiceId: string,
    customerId: number | null,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`api/v1/leads/pos/invoice/${invoiceId}/customer`, null, {
      params: { customerId },
    });
  };

  public changeLeadsStatus = (data: ILeadBulkSalesStatusDto): Promise<void> => {
    return axios.put('/api/v1/leads/sales-status', data);
  };

  public changeLeadStatus = (leadId: number, newStatus: string): Promise<void> => {
    return axios.put(`/api/v1/leads/${leadId}/sales-status`, {
      salesStatus: newStatus,
    });
  };

  public getPersonContract = (personId: number): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/leads/${personId}/documents/contract`);
  };

  public getPersonDocumentById = (
    personId: number,
    documentId: string,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.get(`/api/v1/leads/${personId}/documents/${documentId}`);
  };

  public getPersonServiceDetails = (
    personId: number,
    serviceId: string,
  ): Promise<IServiceItemDetails> =>
    axios.get(`api/v1/leads/${personId}/service-section/services/${serviceId}`);

  public applyContractSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/leads/${personId}/documents/contract/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public printContract = (personId: number): Promise<string> => {
    return axios.get(`/api/v1/leads/${personId}/documents/contract/print`);
  };

  public printWaiverById = (personId: number, waiverId: string): Promise<string> => {
    return axios.get(`/api/v1/leads/${personId}/documents/waiver/${waiverId}/print`);
  };

  public searchFamilyMembers = (
    searchStr: string,
    personId: number,
  ): Promise<IFamilyMemberSearch> => {
    const config = {
      params: searchStr
        ? {
            search: searchStr,
          }
        : {},
    };
    return axios.get(`/api/v1/leads/${personId}/family-members/search`, config);
  };

  public getFamilyMembers = (memberId: number): Promise<IFamilyMember[]> => {
    return axios.get(`/api/v1/leads/${memberId}/family-members`);
  };

  /* public createFamilyMembers = (memberId: number): Promise<IFamilyMember[]> => {
    return axios.get(`/api/v1/leads/${memberId}/family-members`);
  }; */

  public saveFamilyMembers = (
    memberId: number,
    data: IFamilyMemberEditDTO,
  ): Promise<IFamilyMember[]> => axios.put(`/api/v1/leads/${memberId}/family-members`, data);

  public getMemberServices = (memberId: number): Promise<IFamilyMemberService[]> =>
    axios.get(`/api/v1/leads/${memberId}/family-members/services`);

  public signContract = (
    personId: number,
    payload: IPersonDocumentToSignDto,
  ): Promise<IPersonDocumentItemDto> => {
    return axios.put(`/api/v1/leads/${personId}/documents/contract/sign`, payload);
  };

  public getContactInfo = (personId: number): Promise<IContactInfo> => {
    return axios.get(`/api/v1/leads/${personId}/contact-info`);
  };

  // Recent calls

  public getLeadRecentCalls = (personId: number): Promise<Array<ICallNoteDto>> =>
    axios.get(`/api/v1/leads/${personId}/messaging/call/recent`);

  // Calls
  public getLeadCalls = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ILeadCallHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/messaging/call`, { params: searchParams });
  };

  public getPersonCallById = (personId: number, callId: string): Promise<ICallNoteDto> =>
    axios.get(`/api/v1/leads/${personId}/messaging/call/${callId}`);

  public addLeadCall = (personId: number, callData: ICallNoteDto): Promise<ICallNoteDto> =>
    axios.post(`/api/v1/leads/${personId}/messaging/call`, callData);

  // Reminders
  public getReminders = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IReminderDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/reminders`, { params: searchParams });
  };

  public getRecentReminders = (personId: number): Promise<Array<IReminderRecent>> => {
    return axios.get(`/api/v1/leads/${personId}/reminders/recent`);
  };

  public getReminder = (personId: number, reminderId: string): Promise<IReminderDto> => {
    return axios.get(`/api/v1/leads/${personId}/reminders/${reminderId}`);
  };

  public addReminder = (
    personId: number,
    reminderData: IReminderRecent,
  ): Promise<IReminderRecent> => axios.put(`/api/v1/leads/${personId}/reminders`, reminderData);

  public getLeadRecentEmails = (personId: number): Promise<Array<IEmail>> => {
    return axios.get(`/api/v1/leads/${personId}/messaging/email/recent`);
  };

  public getLeadEmails = (
    personId: number,
    tableParams: ITableParams<ILeadEmailHistoryDto>,
  ): Promise<IPaginatedData<ILeadEmailHistoryDto>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/messaging/email`, { params: searchParams });
  };

  public getLeadEmail = (personId: number, emailId: string): Promise<IEmail> => {
    return axios.get(`/api/v1/leads/${personId}/messaging/email/${emailId}`);
  };

  public getLeadSMS = (personId: number): Promise<Array<ISMS>> => {
    return axios.get(`/api/v1/leads/${personId}/messaging/sms/recent`);
  };

  public getLeadSMSById = (personId: number, smsId: string): Promise<ISMS> => {
    return axios.get(`/api/v1/leads/${personId}/messaging/sms/${smsId}`);
  };

  public getLeadSMSHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ISMS>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/messaging/sms`, { params: searchParams });
  };

  public addLeadEmail = (
    personId: number,
    emailData: ISendEmailRequestPayload,
  ): Promise<IEmail> => {
    return axios.post(`/api/v1/leads/${personId}/messaging/email`, emailData);
  };

  public addLeadSMS = (personId: number, smsData: ISendSmsRequestPayload): Promise<ISMS> => {
    return axios.post(`/api/v1/leads/${personId}/messaging/sms`, smsData);
  };

  // The endpoint has not yet been added. Not needed for now.
  public deleteLeadEmail = (personId: number, emailId: string): Promise<void> => {
    return axios.delete(`/api/v1/leads/${personId}/messaging/email/${emailId}`);
  };

  // appointment

  public getPersonAppointmentsHistory = (
    leadId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IAppointmentHistoryItem>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${leadId}/appointment`, { params: searchParams });
  };

  public getRecentLeadAppointments = (leadId: number): Promise<IRecentAppointmentItem[]> =>
    axios.get(`/api/v1/leads/${leadId}/appointment/recent`);

  public getPersonAppointmentById = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/leads/${personId}/appointment/${appointmentId}`, {
      params: { eventStartDate: appointmentDate },
    });

  public restorePersonAppointment = (
    requestParams: IRestoreAppointmentsParams,
  ): Promise<IRestoredAppointment> => {
    const { personId, appointmentId, date } = requestParams;
    return axios.post(`/api/v1/leads/${personId}/appointment/${appointmentId}/restore`, null, {
      params: { eventStartDate: date },
    });
  };

  public updatePersonAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IBookingEvent,
  ): Promise<IBookingEvent> =>
    axios.put(`/api/v1/leads/${personId}/appointment/${appointmentId}`, payload, {
      params: { eventStartDate: appointmentDate },
    });

  public createLeadAppointment = (personId: number, data: IBookingEvent): Promise<IBookingEvent> =>
    axios.post(`/api/v1/leads/${personId}/appointment`, data);

  public deleteAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.delete(`/api/v1/leads/${personId}/appointment/${appointmentId}`, {
      data: payload,
      params: { eventStartDate: appointmentDate },
    });

  public cancelAppointment = (
    appointmentId: string,
    appointmentDate: string,
    personId: number,
    payload: IEventAction,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/leads/${personId}/appointment/${appointmentId}/cancel`, payload, {
      params: { eventStartDate: appointmentDate },
    });

  public cancelAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(
      `api/v1/leads/${personId}/appointment/${appointmentId}/cancel-participation`,
      null,
      { params: { eventStartDate: appointmentDate, updateType } },
    );
  };

  public confirmAppointmentParticipation = (
    personId: number,
    appointmentId: string,
    appointmentDate: string,
    updateType?: EventUpdateType,
  ): Promise<IBookingEvent> => {
    return axios.post(`/api/v1/leads/${personId}/appointment/${appointmentId}/approve`, null, {
      params: { eventStartDate: appointmentDate, updateType },
    });
  };

  public remindPersonAppointment = (
    appointmentId: string,
    personId: number,
    data: IRemindAppointmentDto,
  ): Promise<IBookingEvent> =>
    axios.post(`/api/v1/leads/${personId}/appointment/${appointmentId}/remind`, data);

  // appointment services
  public getAppointmentServices = (personId: number, search: string): Promise<INamedEntity[]> => {
    return axios.get(`/api/v1/leads/${personId}/appointment/services`, {
      params: { title: search },
    });
  };

  public getServicesForEventRedeem = (
    personId: number,
    appointmentId: string,
    serviceId: string,
  ): Promise<IServiceItemDetails[]> =>
    axios.get(`/api/v1/leads/${personId}/appointment/${appointmentId}/services`, {
      params: { serviceId },
    });

  public getPersonWaivers = (personId: number): Promise<IPersonDocumentItemDto[]> => {
    return axios.get(`/api/v1/leads/${personId}/documents/waiver`);
  };

  public applyWaiverSignature = (
    personId: number,
    documentId: string,
    file: File,
  ): Promise<IPersonDocumentItemDto> => {
    const data = new FormData();
    data.append('file', file);

    return axios.post(
      `/api/v1/leads/${personId}/documents/waiver/apply-sign?documentId=${documentId}`,
      data,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );
  };

  public signWaivers = (
    personId: number,
    payload: IPersonDocumentToSignDto[],
  ): Promise<IPersonDocumentItemDto[]> => {
    return axios.put(`/api/v1/leads/${personId}/documents/waiver/sign`, payload);
  };

  // Fallthrough Capture

  public setFallthroughCapture = (
    personId: number,
    payload: IFallthroughCaptureForm,
  ): Promise<IFallthroughCapture> => {
    return axios.post(`/api/v1/leads/${personId}/fallthrough-lead-capture`, payload);
  };

  public getFallthroughCapture = (personId: number): Promise<IFallthroughCapture> => {
    return axios.get(`/api/v1/leads/${personId}/fallthrough-lead-capture`);
  };

  /* public updateFallthroughCapture = (
    personId: number,
    captureId: string,
    payload: IFallthroughCaptureForm,
  ): Promise<IFallthroughCapture> => {
    return axios.put(`/api/v1/leads/${personId}/fallthrough-lead-capture/${captureId}`, payload);
  }; */

  public deleteFallthroughCapture = (
    personId: number,
    captureId: string,
  ): Promise<ISuccessResponse> => {
    return axios.delete(`/api/v1/leads/${personId}/fallthrough-lead-capture/${captureId}`);
  };

  public getSearchLeads = (
    searchStr: string,
    field: PersonSearchParamsType,
  ): Promise<IPrimaryMemberInfo[]> => {
    const params = {
      search: searchStr,
      customerSearchSortBy: field,
    };

    return axios.get('/api/v1/leads/search', { params });
  };

  public getLeadSalesInfo = (personId: number): Promise<IPersonSalesInfo> => {
    return axios.get(`/api/v1/leads/${personId}/sales-info`);
  };

  public updateLeadSalesInfo = (
    personId: number,
    data: INewLeadProfileData,
  ): Promise<INewLeadProfileData> => {
    return axios.put(`/api/v1/leads/${personId}/sales-info`, data);
  };

  // POS
  public searchPOSInventory = (
    registerId: string,
    search: string,
  ): Promise<IPaginatedData<ISimpleClubInventoryItem>> => {
    return axios.get(`/api/v1/leads/pos/register/${registerId}/inventory`, {
      params: { search },
    });
  };

  public searchPOSPackages = (
    search: string,
    clubId: string,
    employeeId: number,
  ): Promise<ISearchPackagesResult> =>
    axios.get(`/api/v1/leads/pos/package-list`, {
      params: { search, clubId, employeeId },
    });

  public searchPOSGiftCards = (clubIds: string[], search: string): Promise<ISearchGiftCard[]> =>
    axios.get(`/api/v1/leads/pos/gift-card-list`, {
      params: { search, clubs: clubIds.join(',') },
    });

  public setSalespersonToInvoice = (
    invoiceId: string,
    salespersonId: string,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/salesperson `, null, {
      params: { employeeId: salespersonId },
    });
  };

  // Open Register
  public openRegister = (registerId: string, data: IPosKioskOpenRegisterDTO): Promise<void> => {
    return axios.put(`/api/v1/leads/pos/register/${registerId}/open`, JSON.stringify(data));
  };

  // Invoice Data
  public createNewInvoice = (
    registerId: string,
    customerId?: number,
  ): Promise<IInvoiceDetailsDto> => {
    const params = {
      registerId,
      ...(customerId ? { customerId } : {}),
    };

    return axios.post('/api/v1/leads/pos/invoice', null, { params });
  };

  public addInvoiceUnit = (
    invoiceId: string,
    data: Partial<IAddInvoiceUnitDTO>,
    unitType: CartUnitTypes,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(`/api/v1/leads/pos/invoice/add-invoice-unit`, data, {
      params: { type: unitType, invoiceId },
    });

  public deleteInvoiceUnit = (
    invoiceId: string,
    invoiceUnitId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.delete(`/api/v1/leads/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`);

  public changeInvoiceUnitNumber = (
    invoiceId: string,
    invoiceUnitId: string,
    unitNumber: number,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`, null, {
      params: {
        number: unitNumber,
      },
    });

  public changeInvoiceUnitsRegister = (
    invoiceId: string,
    customerId: number,
    employeeId: number,
    invoiceUnitIds: Array<string>,
    registerId: string,
    targetInvoiceId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/invoice-unit/change-register`, null, {
      params: {
        customerId,
        employeeId,
        invoiceUnitIds: invoiceUnitIds.join(','),
        registerId,
        targetInvoiceId: targetInvoiceId ?? null,
      },
    });

  public sendNotifications = (invoiceId: string, data: INotifications): Promise<void> => {
    return axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/send`, data);
  };

  public addInvoiceDiscount = (
    invoiceId: string,
    discountPercentage: number,
  ): Promise<IInvoiceDetailsDto> => {
    return axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/discount`, null, {
      params: { discountPercentage },
    });
  };

  public getPersonMembership = (
    memberId: number,
    salespersonId: string,
  ): Promise<IConfiguredPackage> => {
    const params = {
      employeeId: salespersonId,
    };
    return axios.get(`/api/v1/leads/${memberId}/membership`, { params });
  };

  // Activities
  public getLeadActivities = (): Promise<IDictionaryCommonItem[]> =>
    axios.get('/api/v1/leads/activity');

  public createLeadActivity = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> =>
    axios.post('/api/v1/leads/activity', data);

  // Documents

  public updatePersonAttachments = (
    personId: number,
    attachments: IAttachment[],
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/leads/${personId}/documents/attachments`, attachments);

  public getPersonDocuments = (personId: number): Promise<IPersonDocuments> =>
    axios.get(`/api/v1/leads/${personId}/documents`);

  public deletePersonAttachment = (
    personId: number,
    attachmentId: string,
  ): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/leads/${personId}/documents/attachments/${attachmentId}`);

  // Modification history

  public getModificationHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IModificationHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/modification-history`, { params: searchParams });
  };

  public getRecentModificationHistory = (personId: number): Promise<IModificationHistory[]> => {
    return axios.get(`/api/v1/leads/${personId}/modification-history/recent`);
  };

  public getModificationHistoryItem = (
    personId: number,
    revisionId: number,
  ): Promise<IModificationHistoryItem> =>
    axios.get(`/api/v1/leads/${personId}/modification-history/${revisionId}`);

  // Membership packages

  public getMembershipPackages = (clubId: string, search: string): Promise<IShortPackages> =>
    axios.get('/api/v1/leads/membership-packages/list', { params: { clubId, search } });

  public getPersonAdditionalInfo = (personId: number): Promise<IProfileInfo> =>
    axios.get(`/api/v1/leads/${personId}/additional-fields`);

  // Update additional info
  public updatePersonAdditionalInfo = (
    personId: number,
    additionalInfo: IProfileAdditionalPayload[],
  ): Promise<IProfileInfo> =>
    axios.put(`/api/v1/leads/${personId}/additional-fields`, additionalInfo);

  public cancelInvoice = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/leads/pos/invoice/${invoiceId}/cancel`);

  public voidInvoice = (invoiceId: string, reason: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/void`, { reason });

  public fetchOpenInvoices = (registerId: string): Promise<IInvoiceDetailsDto[]> =>
    axios.get(`/api/v1/leads/pos/register/${registerId}/open-invoices`);

  // Campaigns

  public fetchCampaigns = (personId: number): Promise<ICampaigns> =>
    axios.get(`/api/v1/leads/${personId}/campaigns/recent`);

  public getLeadCampaignsHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ICampaign>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/leads/${personId}/campaigns`, { params: searchParams });
  };

  public fetchAvailablePaymentMethods = (registerId: string): Promise<IPaymentMethodItem[]> =>
    axios.get(`/api/v1/leads/pos/register/${registerId}/payment-methods/default`);

  public addInvoicePaymentSplit = (
    invoiceId: string,
    invoicePaymentSplit: IInvoicePaymentSplit,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(`/api/v1/leads/pos/invoice/${invoiceId}/invoice-payment-split`, invoicePaymentSplit);

  public proceedFreeInvoice = (invoiceId: string): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/leads/pos/invoice/${invoiceId}/proceed-free`);

  public getPackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/leads/${personId}/membership/${membershipId}/billing-schedules`, {
      params: searchParams,
    });
  };

  public getServicePackageBillingSchedule = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/leads/${personId}/service-section/service-packages/${membershipId}/cancel/billing-schedules`,
      {
        params: searchParams,
      },
    );
  };

  public getPersonPastDues = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue[]> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/leads/${personId}/past-dues`, { params: searchParams });
  };

  public getPersonPastDuesForCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/leads/${personId}/membership/${membershipId}/cancel/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageCancel = (
    personId: number,
    membershipId: string,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(
      `/api/v1/leads/${personId}/service-section/service-packages/${membershipId}/cancel/past-dues`,
      {
        params: searchParams,
      },
    );
  };

  public getPersonPastDuesForReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/leads/${personId}/membership/reactivate-package/past-dues`, {
      params: searchParams,
    });
  };

  public getPersonPastDuesForServicePackageReactivate = (
    personId: number,
    requestOptions: ITableParams,
  ): Promise<IPastDue> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get(`/api/v1/leads/${personId}/service-section/reactivate/past-dues`, {
      params: searchParams,
    });
  };

  public cancelPersonMembership = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(`/api/v1/leads/${personId}/membership/${membershipId}/cancel`, data);

  public cancelServicePackage = (
    personId: number,
    membershipId: string,
    data: CancelMembershipData,
  ): Promise<void> =>
    axios.put(
      `/api/v1/leads/${personId}/service-section/service-packages/${membershipId}/cancel`,
      data,
    );

  public fetchPaymentAccounts = (
    memberId: number,
    paymentAccountType?: PaymentAccountType,
    cardType?: PaymentsType,
  ): Promise<IPaymentAccount[]> => {
    const config = {
      params: {
        ...(cardType && { cardType }),
        ...(paymentAccountType && { paymentAccountType }),
      },
    };

    return axios.get(`/api/v1/leads/${memberId}/payment-accounts`, config);
  };

  public addCheckingPaymentAccount = (
    memberId: number,
    data: Omit<IPaymentAccount, 'id'>,
  ): Promise<IPaymentAccount> => {
    return axios.post(`/api/v1/leads/${memberId}/payment-accounts/add-checking-saving`, data);
  };

  public setTags = (personId: number, profileTags: List<ITag>): Promise<{ id: string }> => {
    return axios.put(
      `/api/v1/leads/${personId}/tags`,
      profileTags.map(tag => tag.id),
    );
  };

  public fetchFreezeItems = (memberId: number): Promise<IFreezeItem[]> => {
    return axios.get(`/api/v1/leads/${memberId}/membership/freeze`);
  };

  public fetchServicePackageFreezeInformation = (memberId: number): Promise<IFreezeItem[]> =>
    axios.get(`/api/v1/leads/${memberId}/service-section/freeze`);

  public freezeMembershipPackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> => {
    return axios.post(`/api/v1/leads/${personId}/membership/freeze`, data);
  };

  public unfreezeMembershipPackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/leads/${personId}/membership/unfreeze/${packageId}`);

  public freezePackageServices = (
    personId: number,
    data: FreezeMembershipData,
  ): Promise<ISuccessResponse> =>
    axios.post(`/api/v1/leads/${personId}/service-section/freeze`, data);

  public unfreezePackageServices = (
    personId: number,
    packageId: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/leads/${personId}/service-section/unfreeze/${packageId}`);

  public freezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> => {
    return axios.get(`/api/v1/leads/${personId}/membership/freeze/history`);
  };

  public getServicePackageFreezeHistory = (personId: number): Promise<IFreezeHistoryPackage[]> =>
    axios.get(`/api/v1/leads/${personId}/service-section/freeze/history`);

  public fetchReactivateMembershipItems = (
    personId: number,
  ): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/leads/${personId}/membership/past-packages`);
  };

  public fetchReactivateServiceItems = (personId: number): Promise<IPackageInstanceDetails[]> => {
    return axios.get(`/api/v1/leads/${personId}/service-section/past-packages`);
  };

  public fetchCancelItems = (personId: number): Promise<IPackageInstanceDetails[]> =>
    axios.get(`/api/v1/leads/${personId}/service-section/service-packages`);

  public fetchBalanceById = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/leads/pos/balance', { params: { customerId } });
  };

  public fetchEmployeeAccessInfo = (employeeId: number): Promise<IEmployeeAccess> =>
    axios.get(`/api/v1/leads/employees/${employeeId}`);

  public fetchAvailableMembershipPackageActions = (
    customerId: number,
  ): Promise<IMembershipActionDTO> => axios.get(`/api/v1/leads/${customerId}/membership-actions`);

  public fetchMembership = (personId: number, packageId: string): Promise<IBundleView> => {
    return axios.get(`api/v1/leads/${personId}/membership/${packageId}`);
  };

  // Rewards
  public fetchRewardsById = (customerId: number): Promise<IRewards> => {
    return axios.get('api/v1/leads/pos/rewards', { params: { customerId } });
  };

  // Commerce Hub
  public fetchCommerceHubCredentials = (personId: number): Promise<ICommerceHubCredentials> => {
    return axios.get(`api/v1/leads/${personId}/commerce-hub/get-security-credentials`);
  };

  public tokenizeCommerceHubEncryptedCard = (
    personId: number,
    data: any,
  ): Promise<IPaymentAccount[]> => {
    return axios.post(`api/v1/leads/${personId}/commerce-hub/tokenize-credit-card`, data);
  };
}
