import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';

import * as selectors from 'modules/kid-zone/state/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import * as actions from 'modules/kid-zone/state/actions';

import { useAppDispatch } from 'store/hooks';

import { ActionResult } from 'common/constants';

import { IKidProfileImt, IKidZoneAutocompleteCustomerImt } from 'modules/kid-zone/interfaces';

import { DaycareRedeemModal, GuardiansBlock } from 'modules/kid-zone/components';
import AddDateOfBirthdayModal from 'modules/kid-zone/containers/ManualCheckIn/components/AddDateOfBirthdayModal/AddDateOfBirthdayModal';
import { fetchKidProfileInfo } from 'modules/kid-zone/state/actions';

interface IProps {
  profile: IKidProfileImt;
  isSmallScreen: boolean;
  onCloseManualCheckinPanel: () => void;
}

const Guardians = ({ profile, isSmallScreen, onCloseManualCheckinPanel }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const rightPanelChildId = useSelector(selectors.selectKidZoneRightPanelPersonId);
  const selectedClubId: string = useSelector(selectUserSelectedClubId);
  const isCheckInActionLoading: boolean = useSelector(selectors.selectCheckInChildActionLoading);
  const checkInActionResult: ActionResult = useSelector(selectors.selectCheckInChildActionResult);
  const addDOBActionResult = useSelector(selectors.selectAddDOBActionResult);
  const isAddDOBActionLoading: boolean = useSelector(selectors.selectAddDOBActionLoading);

  const [addBirthdayModalData, setAddBirthdayModalData] = useState<{ id: number | null }>({
    id: null,
  });

  const isCheckInPanel = rightPanelChildId === profile.get('id') && rightPanelChildId;

  const guardians: ImmutableList<IKidZoneAutocompleteCustomerImt> = profile.get('guardians');
  const other: ImmutableList<IKidZoneAutocompleteCustomerImt> = profile.get('other');
  const profileId = profile.get('id');

  const [guardianId, setGuardianId] = useState(null);

  useEffect(() => {
    if (checkInActionResult === ActionResult.SUCCESS_ACTION) {
      setGuardianId(null);
    }
  }, [checkInActionResult]);

  useEffect(() => {
    if (addDOBActionResult === ActionResult.SUCCESS_ACTION) {
      if (onCloseManualCheckinPanel && addBirthdayModalData.id) {
        onCloseManualCheckinPanel();
      }

      setAddBirthdayModalData({ id: null });
      dispatch(fetchKidProfileInfo(profileId));

      dispatch(actions.resetAddDateOfBirthActionResult());
    }
  }, [
    dispatch,
    addDOBActionResult,
    setAddBirthdayModalData,
    onCloseManualCheckinPanel,
    addBirthdayModalData.id,
    other,
    profileId,
  ]);

  const handleOpenRedeemModal = (personId: number) => {
    setGuardianId(personId);
  };

  const handleCheckInKid = (serviceId: string) => {
    dispatch(actions.checkInKid(profile.get('id'), guardianId, serviceId, selectedClubId));
  };

  const handleCheckOutKid = (personId: number) => {
    dispatch(actions.checkOutKid(profile.get('id'), personId));
  };

  const addDateOfBirth = (date: string) => {
    dispatch(actions.addDateOfBirth(addBirthdayModalData.id, null, date));
  };

  const onAddBirthday = (id: number) => {
    setAddBirthdayModalData({ id });
  };

  return (
    <>
      <GuardiansBlock
        onAddBirthday={onAddBirthday}
        guardians={guardians}
        otherMembers={other}
        isSmallScreen={isSmallScreen}
        isCheckInPanel={!!isCheckInPanel}
        isActionLoading={isCheckInActionLoading}
        onCheckInKid={handleOpenRedeemModal}
        onCheckOutKid={handleCheckOutKid}
      />

      {!!guardianId && (
        <DaycareRedeemModal
          isOpen={!!guardianId}
          onClose={() => setGuardianId(null)}
          guardianId={guardianId}
          isRedeemActionLoading={isCheckInActionLoading}
          onSubmit={handleCheckInKid}
        />
      )}
      {addBirthdayModalData.id && (
        <AddDateOfBirthdayModal
          isOpen
          onClose={() => setAddBirthdayModalData({ id: null })}
          onSubmit={addDateOfBirth}
          isAddDOBActionLoading={isAddDOBActionLoading}
        />
      )}
    </>
  );
};

export default Guardians;
