import axios from 'axios';

import ApiService from './ApiService';
import {
  ICustomerSimpleDto,
  ITicketCreateDto,
  ITicketShortDto,
} from 'common/components/PersonProfile/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';

export default class PeakContactsService extends ApiService {
  public createTicket = (
    attachments: File[],
    dto: ITicketCreateDto<number>,
  ): Promise<ITicketShortDto[]> => {
    const formData = new FormData();

    attachments.forEach((file: File) => {
      formData.append('attachments', file);
    });

    const blob = new Blob([JSON.stringify(dto)], {
      type: 'application/json',
    });

    formData.append('dto', blob);

    return axios.post(`/api/v1/peak-contacts/tickets`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  public getCustomers = (
    tableParams: Omit<ITableParams, 'order' | 'orderBy' | 'filters' | 'calendarView'>,
  ): Promise<IPaginatedData<ICustomerSimpleDto>> => {
    const { searchStr, ...params } = tableParams;
    return axios.get(`/api/v1/peak-contacts/tickets/customers`, {
      params: { ...params, search: searchStr },
    });
  };
}
