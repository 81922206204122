import React from 'react';
import { Box, createStyles, Typography, WithStyles, Divider, Button } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';

interface BlockProps {
  width?: string;
  tableContainer?: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    block: {
      position: 'relative',
      height: '100%',
      width: (props: BlockProps) => props.width || '100%',
      border: '1px solid #ededed',
      borderRadius: '3px',
      padding: ({ tableContainer = false }: BlockProps) => {
        return `8px 16px ${tableContainer ? '3px' : '16px'} 16px`;
      },
      display: 'block',

      '& .empty-section-placeholder': {
        height: 'calc(100% - 30px)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',

        '& .empty-text': {
          textAlign: 'center',
          fontSize: '0.9rem',
          color: theme.palette.text.secondary,
          lineHeight: '19px',
        },
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '36px',
      paddingBottom: theme.spacing(1),
    },
    title: {
      fontSize: '.875rem',
      fontWeight: 'bold',
      lineHeight: 1.2,
      textTransform: 'uppercase',
    },
  });

interface Props extends BlockProps, WithStyles<typeof styles> {
  children: React.ReactNode;
  title?: string | JSX.Element;
  buttonTitle?: string | JSX.Element;
  className?: string;
  onClickButton?: () => void;
  headerDivider?: boolean;
  buttonGroup?: JSX.Element;
}

const CheckInBlock = withStyles(styles)(
  ({
    title,
    buttonTitle,
    children,
    width,
    onClickButton,
    className = '',
    classes,
    headerDivider,
    buttonGroup,
  }: Props) => {
    return (
      <Box width={width} className={`${classes.block} ${className}`}>
        <Box display="flex" alignItems="space-between" className={classes.header}>
          {title && (
            <Typography color="textSecondary" className={classes.title}>
              {title}
            </Typography>
          )}

          {buttonGroup}

          {buttonTitle && (
            <Button
              variant="text"
              color="primary"
              type="button"
              className="btn-text"
              onClick={onClickButton}
            >
              {buttonTitle}
            </Button>
          )}
        </Box>
        {headerDivider && <Divider />}
        {children}
      </Box>
    );
  },
);

export default CheckInBlock;
