import { MenuProps } from '@material-ui/core';

export function getDefaultSelectMenuPosition(): Partial<MenuProps> {
  return {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: -4,
      horizontal: 'left',
    },
  };
}
