import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { List as ImmutableList } from 'immutable';

import { ITaskImt } from 'modules/crm/interfaces/tasks';
import { ImmutableObject } from 'common/state/interfaces';
import { ITableParams } from 'common/interfaces/table';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import { TCampaignsDictionaryImt } from 'common/interfaces/dictionary';

import { RouteBackground } from 'components';
import { TasksTable } from 'modules/crm/components';

import { makeTableParams, pushQueryToUrl } from 'common/utils/http';
import { DictionaryList, QueryPageList } from 'common/constants';

import * as actions from 'modules/crm/state/tasks/actions';
import * as selectors from 'modules/crm/state/tasks/selectors';
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { fetchDictionaryList } from 'common/state/dictionary/actions';
import { updateQueryParams } from 'common/state/queryPage-lists/actions';
import { TableOrderByParams } from 'common/constants/table';
import tableFilters from 'common/messages/tableFilters';
import messages from 'common/messages/inputLabels';
import { FormattedMessage } from 'react-intl';
import tasksMessages from '../../messages/tasks';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import { fetchLeads } from '../../state/leads/actions';
import { ILeadProfile } from '../../interfaces/leads';
import { tasksTypesConstOptions } from '../../constants/tasks';
import { selectCurrentUserCorporation } from 'modules/authentication/state/selectors';
import { INamedEntityImt } from 'common/interfaces/common';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { PeakModules } from 'common/constants/peakModules';
import tableHeaders from 'common/messages/tableHeaders';
import Services from 'services/network';

const Tasks = (): JSX.Element => {
  // state

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;
  const isCrmModule = module === PeakModules.Crm;

  const tasks: ImmutableList<ITaskImt> = useSelector(selectors.selectTasks());
  const tasksMeta: IPageMetaImt = useSelector(selectors.selectTasksMeta());
  const isTasksLoading: boolean = useSelector(selectors.selectTasksLoading());
  const isTaskAssignLoading: boolean = useSelector(selectors.selectTaskAssigning());
  const isTaskCompleteLoading: boolean = useSelector(selectors.selectTaskCompleting());
  const tasksActionResult: ImmutableObject<Record<string, boolean>> = useSelector(
    selectors.selectTasksActionResult(),
  );
  const corporation: INamedEntityImt = useSelector(selectCurrentUserCorporation);
  const campaigns: ImmutableList<TCampaignsDictionaryImt> = useSelector(
    selectDictionaryList(isCrmModule ? DictionaryList.CAMPAIGNS : DictionaryList.PT_CAMPAIGNS),
  );

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'types',
        title: <FormattedMessage {...tableFilters.type} />,
        type: FilterTypes.MULTIPLE,
        options: tasksTypesConstOptions.getFilterOptions(),
      },
      {
        name: 'leads',
        title: <FormattedMessage {...(isCrmModule ? messages.lead : tableHeaders.ptLead)} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: number) => {
          try {
            let lead: ILeadProfile;

            if (module === PeakModules.Crm) {
              lead = await Services.Leads.getLead(id);
            }

            lead = await Services.Leads.getLead(id);

            return `${lead.firstName} ${lead.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search, page) => {
          try {
            const { meta, data } = await Services.DictionaryLists.getLeadList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
      {
        name: 'campaigns',
        title: (
          <FormattedMessage
            {...(isCrmModule ? tableFilters.campaign : ptCrmMessages.ptCampaigns)}
          />
        ),
        type: FilterTypes.MULTIPLE,
        options: campaigns?.toJS().map(campaign => ({
          key: campaign.id,
          label: campaign.title,
          value: campaign.id,
        })),
      },
      {
        name: 'salespersons',
        title: <FormattedMessage {...tableFilters.salesperson} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: number) => {
          try {
            const employee = await Services.Employees.getEmployeeById(id);
            return `${employee.firstName} ${employee.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search, page) => {
          try {
            const { meta, data } = await Services.DictionaryLists.getTasksEmployeesList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
      {
        name: 'completed',
        title: <FormattedMessage {...tableFilters.status} />,
        type: FilterTypes.SINGLE,
        options: [
          {
            key: 'COMPLETED',
            label: <FormattedMessage {...tasksMessages.completed} />,
            value: true,
          },
          {
            key: 'NOT_COMPLETED',
            label: <FormattedMessage {...tasksMessages.notCompleted} />,
            value: false,
          },
        ],
      },
      {
        name: '',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
    ],
    [isCrmModule, campaigns, module],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, location.search, {
      orderBy: TableOrderByParams.TYPE,
    }),
  );

  // lifecycle

  useEffect(() => {
    dispatch(fetchDictionaryList(DictionaryList.EMPLOYEES, { module }));
    dispatch(
      fetchDictionaryList(isCrmModule ? DictionaryList.CAMPAIGNS : DictionaryList.PT_CAMPAIGNS),
    );
    dispatch(fetchLeads(module));
  }, [dispatch, module, isCrmModule]);

  useEffect(() => {
    dispatch(actions.fetchTasks(tableParams, module));
  }, [corporation, dispatch, module, tableParams]);

  useEffect(() => {
    if (tasksActionResult.get('success')) {
      dispatch(actions.fetchTasks(tableParams, module));
    }
  }, [dispatch, module, tableParams, tasksActionResult]);

  // handlers

  const updateQueryFunction = useCallback(
    query => dispatch(updateQueryParams({ page: QueryPageList.TASKS, query })),
    [dispatch],
  );

  const handleChangeTableProps = useCallback(
    (tableProps: ITableParams): void => {
      setTableParams(tableProps);
      pushQueryToUrl(navigate, tableProps, updateQueryFunction);
    },
    [navigate, updateQueryFunction],
  );

  const onAssignSalespersonTasks = useCallback(
    data => {
      dispatch(actions.assignSalespersonTasks(data, module));
    },
    [dispatch, module],
  );

  const onCompleteTasks = useCallback(
    data => {
      dispatch(actions.completeTasks(data, module));
    },
    [dispatch, module],
  );

  const onResetTasksActionResult = useCallback(() => {
    dispatch(actions.resetTasksActionResult());
  }, [dispatch]);

  return (
    <RouteBackground>
      <TasksTable
        module={module}
        items={tasks}
        totalRows={tasksMeta.get('total')}
        isLoading={isTasksLoading || isTaskCompleteLoading}
        isTaskAssignLoading={isTaskAssignLoading}
        onChangeParams={handleChangeTableProps}
        onAssignSalespersonTasks={onAssignSalespersonTasks}
        onCompleteTasks={onCompleteTasks}
        tasksActionResult={tasksActionResult.toJS()}
        resetTasksActionResult={onResetTasksActionResult}
        tableFilterSettings={tableFilterSettings}
        tableParams={tableParams}
      />
    </RouteBackground>
  );
};

export default Tasks;
