import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, makeStyles, Theme, useTheme, IconButton } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';
import cx from 'classnames';

import { selectAlertsList } from 'common/state/alerts/selectors';

import { ICheckInImt } from 'modules/front-desk/interfaces';
import { IAlertSettingsImt } from 'common/interfaces/alerts';

import {
  getAlertColor,
  hasCelebrationAlert,
  hasErrorAlert,
  hasWarningAlert,
} from 'common/utils/alerts';
import { formatPersonalCode } from 'common/utils/format';
import { getInitials } from 'helpers/common';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
import { AvatarWithInitials, TooltipTypography } from 'common/components';

import { ReactComponent as WarningIcon } from 'img/icons/warning.svg';
import { ReactComponent as CakeIcon } from 'img/icons/cake.svg';
import { ReactComponent as StoreIcon } from 'img/icons/cash-register.svg';
import { ReactComponent as TimeIndicatorIcon } from 'img/icons/time-indicator.svg';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';

import messages from 'modules/front-desk/messages';

const useStyles = makeStyles((theme: Theme) => ({
  currentCheckIn: {
    position: 'relative',
    width: '100%',
    minWidth: '305px',
    height: '305px',
    overflow: 'hidden',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '3px',
    boxShadow:
      '0px 1px 5px rgba(153, 153, 153, 0.12), 0px 1px 3px -2px rgba(153, 153, 153, 0.2), 0px 2px 2px rgba(153, 153, 153, 0.14)',
    cursor: 'pointer',

    '&:hover': {
      '&:not(.isActive)': {
        boxShadow: '0 6px 12px 0 rgba(0,0,0,0.16)',
      },
    },

    '&.isActive': {
      borderWidth: '1px',
    },
  },
  checkInInfo: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  checkingInNow: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    padding: theme.spacing(1, 2),
    color: theme.palette.background.paper,

    backgroundColor: 'rgba(87, 87, 87, 0.35)',
    borderRadius: '4px',

    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  checkingInNowTitle: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.75, 1.5),

    '&.isActive': {
      opacity: 1,
    },
  },
  personName: {
    maxWidth: '155px',
    color: theme.palette.background.paper,
    fontWeight: 700,
    fontSize: '1rem',
  },
  leftFooterPart: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
  },
  checkInTime: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: theme.spacing(0.5),
    color: theme.palette.background.paper,
    opacity: 0.6,

    '& > div': {
      color: theme.palette.background.paper,
    },
    '& .check-in-time': {
      opacity: 1,
      color: theme.palette.background.paper,
    },
  },
  alert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    backgroundColor: 'rgba(87, 87, 87, 0.35)',

    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  isSelected: {
    color: theme.palette.background.paper,
  },
  posButton: {
    color: theme.palette.background.paper,

    '&:hover': {
      background: 'rgba(255, 255, 255, 0.4)',
    },

    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  barcodeIcon: {
    width: '16px',
    height: '16px',
    marginLeft: theme.spacing(1),
  },
}));

interface ICurrentCheckInProps {
  checkIn: ICheckInImt;
  selectedCheckInId: number;
  onClick: (personId: number) => void;
  onClickPOSBtn?: (personId: number) => void;
}

const CurrentCheckIn = ({
  checkIn,
  selectedCheckInId,
  onClick,
  onClickPOSBtn,
}: ICurrentCheckInProps): JSX.Element => {
  const classes = useStyles();

  const alertList: ImmutableList<IAlertSettingsImt> = useSelector(selectAlertsList);

  const theme = useTheme();

  const [timezoneMoment] = useTimezoneMoment();

  const alerts = checkIn?.get('alerts').toJS();

  const itemBorder = getAlertColor(alertList, alerts);
  const handleOnClick = () => {
    onClick(checkIn.get('personId'));
  };

  const handleClickPOSBtn = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClickPOSBtn(checkIn.get('personId'));
  };

  const initials = getInitials(checkIn?.get('firstName'), checkIn?.get('lastName'));

  const isSelected = selectedCheckInId === checkIn?.get('id');

  const renderAlert = () => {
    if (hasErrorAlert(alertList, alerts)) {
      return (
        <Box color="error.main" className={classes.alert}>
          <WarningIcon />
        </Box>
      );
    }

    if (hasWarningAlert(alertList, alerts)) {
      return (
        <Box color="warning.main" className={classes.alert}>
          <WarningIcon />
        </Box>
      );
    }

    if (hasCelebrationAlert(alerts)) {
      return (
        <Box color="celebration.main" className={classes.alert}>
          <CakeIcon />
        </Box>
      );
    }

    return null;
  };

  return (
    <Box
      borderColor={isSelected ? itemBorder : 'background.paper'}
      className={cx(classes.currentCheckIn, { isActive: isSelected })}
      onClick={handleOnClick}
    >
      <AvatarWithInitials
        initials={initials}
        imageUrl={checkIn?.get('imageUrl')}
        variant="square"
        width="100%"
        height="100%"
        fontSize="3rem"
        marginRight={0}
        borderRadius="3px"
      />

      <Box className={classes.checkInInfo}>
        <Box display="flex" justifyContent="space-between" p={1.5}>
          <Typography component="p" className={classes.checkingInNow}>
            <TimeIndicatorIcon />

            <Typography component="span" color="inherit" className={classes.checkingInNowTitle}>
              <FormattedMessage {...messages.checkingInNow} />
            </Typography>
          </Typography>

          {renderAlert()}
        </Box>

        <Box
          className={cx(classes.footer, { isActive: isSelected })}
          bgcolor={isSelected ? itemBorder : 'rgba(50, 50, 50, 0.90)'}
        >
          <Box className={classes.leftFooterPart}>
            <TooltipTypography ellipsized className={classes.personName}>
              {`${checkIn?.get('firstName')} ${checkIn?.get('lastName')}`}
            </TooltipTypography>

            <Typography component="span">
              <GuestStatus
                type={checkIn?.get('personType')}
                color={isSelected ? theme.palette.background.paper : null}
              />
            </Typography>
          </Box>

          <Box display="flex" gridGap={8}>
            <Typography className={classes.checkInTime}>
              <Typography component="span" color="inherit">
                <FormattedMessage
                  {...messages.ID}
                  values={{ personId: formatPersonalCode(checkIn?.get('personalCode')) }}
                />
              </Typography>

              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Typography color="inherit">
                  {timezoneMoment(checkIn?.get('checkInTime')).format('h:mm A')}
                </Typography>

                <BarcodeIcon className={classes.barcodeIcon} />
              </Box>
            </Typography>

            {onClickPOSBtn && (
              <IconButton
                color="default"
                size="small"
                onClick={handleClickPOSBtn}
                className={classes.posButton}
              >
                <StoreIcon />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CurrentCheckIn;
