import ApiService from './ApiService';
import axios from 'axios';

import { DictionariesTypes, TagType } from 'modules/dictionaries/constants/constants';

import {
  IDictionaryCommonItem,
  IDictionaryRequestParams,
  IPaginatedAlertSounds,
  IPaginatedDictionaries,
  ITagItem,
} from 'modules/dictionaries/interfaces/interfaces';
import { ISuccessResponse } from 'common/interfaces/http';
import { IDictionaryOptions } from 'common/interfaces/dictionary';
import { ITableParams } from 'common/interfaces/table';
import { INotification } from 'modules/dictionaries/interfaces/notifications';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IClubGroupItem } from 'modules/dictionaries/interfaces/clubGroups';
import { IAccessHoursItem } from 'modules/dictionaries/interfaces/accessHours';
import { IHelpInfoItem } from 'modules/dictionaries/interfaces/helpInfo';
import { PeakModules } from 'common/constants/peakModules';
import { IPaginatedEmployees } from 'modules/employees/interfaces';

export default class DictionariesService extends ApiService {
  // Dictionary List
  public getDictionaryList = (
    requestOptions?: IDictionaryRequestParams,
    url?: string,
  ): Promise<IPaginatedDictionaries> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get(`/api/v1/dictionary${url ? `/${url}` : ''}`, {
      params: {
        dictionaryType: requestOptions?.dictionaryType,
        ...searchParams,
        excludedConditions: '',
        includedConditions: '',
      },
    });
  };

  public getAlertSoundsList = (requestOptions?: ITableParams): Promise<IPaginatedAlertSounds> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/alert-sound', { params: searchParams });
  };

  public deleteDictionaryItem = (id: string): Promise<ISuccessResponse> => {
    return axios.delete(`/api/v1/dictionary/${id}`);
  };

  public editDictionaryItem = (
    dictionaryType: DictionariesTypes,
    id: string,
    data: IDictionaryCommonItem,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    const json = JSON.stringify({
      ...data,
      dictionaryType,
    });
    return axios.put(`/api/v1/dictionary/${id}`, json);
  };

  public changeStatusDictionaryItem = (
    id: string,
    active: boolean,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.patch(`/api/v1/dictionary/${id}/change-status`, { active: active.toString() });
  };

  public createDictionaryItem = (
    dictionaryType: DictionariesTypes,
    data: IDictionaryCommonItem,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    const json = JSON.stringify({
      ...data,
      dictionaryType,
    });
    return axios.post('/api/v1/dictionary', json);
  };

  public uploadSoundItem = (id: string, file: File): Promise<ISuccessResponse> => {
    const data = new FormData();
    data.append('file', file);

    return axios.put(`/api/v1/alert-sound/${id}`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  public createTag = (tagType: TagType, data: ITagItem): Promise<Partial<ISuccessResponse>> => {
    const json = JSON.stringify({
      ...data,
      tagType,
    });
    return axios.post('/api/v1/tag', json);
  };

  // Event tag

  public getEventTags = (options?: IDictionaryOptions): Promise<IPaginatedDictionaries> => {
    const searchParams = this.getSearchFromTableParams(options);

    return axios.get('/api/v1/event-tag', { params: searchParams });
  };

  public changeStatusEventTagItem = (
    eventId: string,
    isActive: boolean,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.patch(`/api/v1/event-tag/${eventId}/change-status`, { active: isActive });
  };

  public createEventTagItem = (data: ITagItem): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.post('/api/v1/event-tag', data);
  };

  public editEventTagItem = (
    eventId: string,
    data: ITagItem,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.put(`/api/v1/event-tag/${eventId}`, data);
  };

  // Resource tag
  public getResourceTags = (options?: IDictionaryOptions): Promise<IPaginatedDictionaries> => {
    const searchParams = this.getSearchFromTableParams(options);

    return axios.get('/api/v1/resource-tag', { params: searchParams });
  };

  public changeStatusResourceTagItem = (
    resourceId: string,
    isActive: boolean,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.patch(`/api/v1/resource-tag/${resourceId}/change-status`, { active: isActive });
  };

  public createResourceTagItem = (
    data: IDictionaryCommonItem,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.post('/api/v1/resource-tag', data);
  };

  public editResourceTagItem = (
    eventId: string,
    data: IDictionaryCommonItem,
  ): Promise<Partial<IDictionaryCommonItem>> => {
    return axios.put(`/api/v1/resource-tag/${eventId}`, data);
  };

  // notifications

  public getNotificationsList = (): Promise<INotification[]> => {
    return axios.get('/api/v1/notification');
  };

  public getNotificationById = (notificationId: string): Promise<INotification> => {
    return axios.get(`/api/v1/notification/${notificationId}`);
  };

  public updateNotification = (
    notificationId: string,
    notificationData: INotification,
  ): Promise<INotification> => {
    return axios.put(`/api/v1/notification/${notificationId}`, notificationData);
  };

  // insurance companies

  public getInsuranceCompanies = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedDictionaries> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/insurance-company', { params: searchParams });
  };

  public createInsuranceCompany = (data: IDictionaryCommonItem): Promise<IDictionaryCommonItem> =>
    axios.post('/api/v1/insurance-company', data);

  public updateInsuranceCompany = (
    companyId: string,
    data: IDictionaryCommonItem,
  ): Promise<IDictionaryCommonItem> => axios.put(`/api/v1/insurance-company/${companyId}`, data);

  public changeStatusInsuranceCompany = (
    companyId: string,
    isActive: boolean,
  ): Promise<IDictionaryCommonItem> =>
    axios.patch(`/api/v1/insurance-company/${companyId}/change-status`, { active: isActive });

  // club groups

  public getClubGroups = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IClubGroupItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/club-group', { params: searchParams });
  };

  public createClubGroup = (data: IClubGroupItem): Promise<IClubGroupItem> =>
    axios.post('/api/v1/club-group', data);

  public updateClubGroup = (clubGroupId: string, data: IClubGroupItem): Promise<IClubGroupItem> =>
    axios.put(`/api/v1/club-group/${clubGroupId}`, data);

  public changeClubGroupStatus = (
    clubGroupId: string,
    isActive: boolean,
  ): Promise<IClubGroupItem> =>
    axios.patch(`/api/v1/club-group/${clubGroupId}/change-status`, { active: isActive });

  // access hours

  public getAccessHours = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IAccessHoursItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/time-access', { params: searchParams });
  };

  public createAccessHours = (data: IAccessHoursItem): Promise<IAccessHoursItem> =>
    axios.post('/api/v1/time-access', data);

  public updateAccessHours = (
    accessHoursId: string,
    data: IAccessHoursItem,
  ): Promise<IAccessHoursItem> => axios.put(`/api/v1/time-access/${accessHoursId}`, data);

  public changeAccessHoursStatus = (
    accessHoursId: string,
    isActive: boolean,
  ): Promise<IAccessHoursItem> =>
    axios.patch(`/api/v1/time-access/${accessHoursId}/change-status`, { active: isActive });

  // help information

  public getHelpInfoList = (
    requestOptions?: ITableParams,
  ): Promise<IPaginatedData<IHelpInfoItem>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/help-info', { params: searchParams });
  };

  public getHelpInfoItem = (itemId: string): Promise<IHelpInfoItem> =>
    axios.get(`/api/v1/help-info/${itemId}`);

  public createHelpInfoItem = (data: IHelpInfoItem): Promise<IHelpInfoItem> =>
    axios.post('/api/v1/help-info', data);

  public updateHelpInfoItem = (itemId: string, data: IHelpInfoItem): Promise<IHelpInfoItem> =>
    axios.put(`/api/v1/help-info/${itemId}`, data);

  public changeHelpInfoItemStatus = (itemId: string, isActive: boolean): Promise<IHelpInfoItem> =>
    axios.patch(`/api/v1/help-info/${itemId}/change-status`, { active: isActive });

  public getEmployeesDictionary = (
    search: string,
    module: PeakModules,
    isCustomer: boolean,
  ): Promise<IPaginatedEmployees> => {
    const params = {
      search,
    };

    switch (module) {
      case PeakModules.FrontDesk:
        return isCustomer
          ? axios.get('/api/v1/front-desk/customer-pos/dictionary/employees')
          : axios.get('/api/v1/front-desk/dictionary/employees');
      case PeakModules.Crm:
        return axios.get('/api/v1/leads/pos/dictionary/employees', { params });
      case PeakModules.PersonalTrainingCrm:
        return axios.get('/api/v1/pt-leads/dictionary/employees', { params });
      case PeakModules.Members:
        return axios.get('/api/v1/members/dictionary/employees', { params });
      default:
        return axios.get('/api/v1/pos/dictionary/employees');
    }
  };
}
