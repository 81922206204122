import { createPersonSelectorFactory, personsListPath } from '../selectors';

export const selectMembershipServices = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'services', 'membershipInfo', 'services']);
export const selectMembershipInventories = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'services', 'membershipInfo', 'inventories']);

export const selectExternalServices = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'services', 'posInfo', 'services']);
export const selectExternalInventories = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'services', 'posInfo', 'inventories']);

export const selectFamilyMembersServices = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'services', 'familyMembershipServices']);

export const selectServicesSectionLoading = (personId: number) => state =>
  state.getIn([...personsListPath, personId, 'servicesLoading']);

// export const makeSelectPersonServicesLoading = createPersonSelectorFactory('servicesLoading');

export const makeSelectPersonServiceItem = createPersonSelectorFactory('serviceItem');
export const makeSelectPersonServiceItemLoading = createPersonSelectorFactory('serviceItemLoading');

export const makeSelectPersonServiceRedeem = createPersonSelectorFactory('serviceRedeem');
export const makeSelectPersonServiceFreeze = createPersonSelectorFactory('serviceFreeze');

export const makeSelectPersonServiceHistory = createPersonSelectorFactory('serviceHistory');
export const makeSelectPersonServiceHistoryLoading = createPersonSelectorFactory(
  'serviceHistoryLoading',
);

export const makeSelectPersonServiceHistoryList = createPersonSelectorFactory('serviceHistoryList');
export const makeSelectPersonServiceHistoryListLoading = createPersonSelectorFactory(
  'serviceHistoryListLoading',
);
