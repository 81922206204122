export const actionTypes = {
  FETCH_MEMBER_REFERRALS_SUCCESS: 'MEMBER_PORTAL/FETCH_MEMBER_REFERRALS_SUCCESS',
  FETCH_MEMBER_REFERRALS_LOADING: 'MEMBER_PORTAL/FETCH_MEMBER_REFERRALS_LOADING',
  RESET_MEMBER_REFERRALS: 'MEMBER_PORTAL/RESET_MEMBER_REFERRALS',

  ADD_MEMBER_REFERRAL: 'MEMBER_PORTAL/ADD_MEMBER_REFERRAL',
  ADD_MEMBER_REFERRAL_LOADING: 'MEMBER_PORTAL/ADD_MEMBER_REFERRAL_LOADING',
  RESET_ADD_MEMBER_REFERRAL_ACTION_RESULT: 'MEMBER_PORTAL/RESET_ADD_MEMBER_REFERRAL_ACTION_RESULT',

  SET_REFERRED_BY: 'MEMBER_PORTAL/SET_REFERRED_BY',
  SET_REFERRED_BY_LOADING: 'MEMBER_PORTAL/SET_REFERRED_BY_LOADING',
  SET_MEMBER_REFERRALS_ERROR: 'MEMBER_PORTAL/SET_MEMBER_REFERRALS_ERROR',

  FETCH_REFERRAL_PERSONS_LOADING: 'MEMBER_PORTAL/FETCH_REFERRAL_PERSONS_LOADING',
  FETCH_REFERRAL_PERSONS_SUCCESS: 'MEMBER_PORTAL/FETCH_REFERRAL_PERSONS_SUCCESS',
  RESET_REFERRAL_PERSONS_FOUND: 'MEMBER_PORTAL/RESET_REFERRAL_PERSONS_FOUND',
};
