// Libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Box, List, Paper, WithStyles, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';
// Custom components
import { LoadingBackdrop } from 'common/components';
import CheckInItem from '../CheckInItem/CheckInItem';
import KidCheckInItem from '../KidCheckInItem/KidCheckInItem';

import { CustomTheme } from 'common/ui/interfaces';
import { ICheckInImt } from 'modules/front-desk/interfaces';

import { selectAlertsList } from 'common/state/alerts/selectors';

import { PeakModules } from 'common/constants/peakModules';

const styles = (theme: CustomTheme) =>
  createStyles({
    list: {
      position: 'relative',
    },
    padding: {
      padding: theme.spacing(0),
      [theme.breakpoints.down('md')]: {
        paddingBottom: theme.spacing(13),
      },
    },
    emptyListPaper: {
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    emptyListMessage: {
      opacity: 0.3,
      textAlign: 'center',
    },
  });

interface ICheckInListProps extends Partial<WithStyles<typeof styles>> {
  selectedCheckInId: number;
  recentCheckInsList: ImmutableList<ICheckInImt>;
  currentCheckIn: ICheckInImt;
  loading: boolean;
  selectCheckInPerson: (personId: number) => void;
  module: PeakModules;
  onClickPOSBtn?: (personId: number) => void;
}

const CheckIns = React.memo(
  withStyles(styles)(
    ({
      recentCheckInsList,
      loading,
      selectedCheckInId,
      currentCheckIn,
      selectCheckInPerson,
      classes,
      module,
      onClickPOSBtn,
    }: ICheckInListProps) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const alertsList = useSelector(selectAlertsList);

      return (
        <Box
          className={classes?.list}
          height={currentCheckIn?.size ? 'calc(100% - 313px)' : '100%'}
        >
          <LoadingBackdrop isLoading={loading} />

          {recentCheckInsList.size > 0 ? (
            <List className={classes?.padding}>
              {recentCheckInsList.map((checkIn: ICheckInImt, index: number) => (
                <>
                  {module === PeakModules.FrontDesk ? (
                    <CheckInItem
                      index={index}
                      alertsList={alertsList}
                      key={checkIn.get('id')}
                      checkIn={checkIn}
                      isActive={checkIn.get('id') === selectedCheckInId}
                      onClick={selectCheckInPerson}
                      onClickPOSBtn={onClickPOSBtn}
                    />
                  ) : (
                    <KidCheckInItem
                      checkIn={checkIn}
                      isActive={checkIn.get('id') === selectedCheckInId}
                      onClick={selectCheckInPerson}
                      alertsList={alertsList}
                    />
                  )}
                </>
              ))}
            </List>
          ) : (
            <Paper className={classes?.emptyListPaper} />
          )}
        </Box>
      );
    },
  ),
);

export default CheckIns;
