import React, { useCallback, useEffect, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { List as ImmutableList } from 'immutable';
import { Box } from '@material-ui/core';
// Interfaces
import {
  IBilling,
  INoteCodeRequestPayload,
  INoteImt,
  IPersonDocumentGroupItemImt,
  IProfileInfo,
  IProfileInfoImt,
} from 'common/components/PersonProfile/interfaces';
import {
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
} from 'common/interfaces/additionalInfo';
import { IMemberAlert, IMemberAlertImt } from 'modules/front-desk/interfaces';
import { INoteCodeDictionaryItemImt } from 'common/interfaces/dictionary';
// state
import * as actions from 'common/components/PersonProfile/state/actions';
import {
  fetchPersonNotes,
  setRemindLaterActionResult,
} from 'common/components/PersonProfile/state/actions';
import { selectAlertsList } from 'common/state/alerts/selectors';
import {
  selectCreatedNote,
  selectCreateNoteLoading,
  selectNoteCodes,
  selectNoteCodesLoading,
  selectPersonAlerts,
  selectPersonAlertsLoading,
  selectPersonUpdateProfileActionLoading,
  selectRemindLaterActionResult,
} from 'common/components/PersonProfile/state/selectors';
import {
  selectCurrentUserId,
  selectUserSelectedClubId,
} from 'modules/authentication/state/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// utils
import { getAlertType } from 'common/utils/alerts';
// Components
import { Alert, LoadingBackdrop } from 'common/components';
import { ModalAlerts } from './ModalAlerts';
import { ViewNoteModal } from 'modules/front-desk/components/Modals';
import NoteModal from 'common/components/PersonProfile/components/Notes/modals/NoteModal';
import SignDocumentModal from 'common/components/PersonProfile/components/Documents/SignDocumentModal/SignDocumentModal';
import { PaymentMethodsModal } from 'common/components/PersonProfile/modals';
import { CakeIcon, BellIcon } from 'img/icons';
// constants
import { AlertCondition, AlertTypes, IAlertSettingsImt } from 'common/interfaces/alerts';
import { AlertConditions } from 'common/constants/alerts';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
// messages
import commonMessages from 'common/messages/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IInvoiceDetailsDto, InvoiceStatus } from 'common/interfaces/invoices';
import { createInvoice, selectInvoice } from 'common/state/invoice/actions';
import { CREATE_INVOICE } from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import useFetchInvoicesList from 'common/httpHooks/useFetchInvoicesList';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { IAllergiesFormValues } from 'modules/members/interfaces';

export interface IAlertsSectionProps {
  profile: IProfileInfoImt;
  onChangeAvatarModalToggle?: () => void;
  onOpenChangePackagePlan?: (personId: number) => void;
  small: boolean;
  medium: boolean;
  openPOSPanel: () => void;
  isOpenPOSPanel: boolean;
  module: PeakModuleForNewPersonType;
}

export interface IAlertsSectionState {
  resolvingAlert: AlertCondition;
  isOpenUploadModal: boolean;
  isOpenAddressModal: boolean;
  isOpenPersonalModal: boolean;
  isOpenBirthdayModal: boolean;
  isOpenLastNameModal: boolean;
  isOpenEmailModal: boolean;
  isOpenEmergencyContactInfoModal: boolean;
  isOpenInsuranceInfoModal: boolean;
  isOpenSalespersonModal: boolean;
  isOpenAllergiesModal: boolean;
  isOpenPhoneModal: boolean;
  isOpenWaiverModal: boolean;
  isOpenNoteModal: boolean;
  isOpenPastDuesModal: boolean;
  isOpenFirstNameModal: boolean;
  isOpenCreditCardModal: boolean;
}

const { addDelayedAction } = StorageServices.delayedActions;

const MemberAlertsSection = ({
  profile,
  onChangeAvatarModalToggle,
  onOpenChangePackagePlan,
  small,
  medium,
  module,
  openPOSPanel,
  isOpenPOSPanel,
}: IAlertsSectionProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const remindLaterActionResult: string = useSelector(
    selectRemindLaterActionResult(profile.get('id')),
  );
  const selectedClubId: string = useSelector(selectUserSelectedClubId);
  const alertsList: ImmutableList<IAlertSettingsImt> = useSelector(selectAlertsList);
  const memberAlertsList: ImmutableList<IMemberAlertImt> = useSelector(
    selectPersonAlerts(profile.get('id')),
  );
  const isMemberAlertsListLoading: boolean = useSelector(
    selectPersonAlertsLoading(profile.get('id')),
  );
  const isRemindLaterLoading: boolean = useSelector(
    selectPersonUpdateProfileActionLoading(profile.get('id')),
  );
  const isCreateNoteLoading: boolean = useSelector(selectCreateNoteLoading(profile.get('id')));
  const createdNote: INoteImt = useSelector(selectCreatedNote(profile.get('id')));
  const isNoteCodesLoading: boolean = useSelector(selectNoteCodesLoading(profile.get('id')));
  const noteCodes: ImmutableList<INoteCodeDictionaryItemImt> = useSelector(
    selectNoteCodes(profile.get('id')),
  );
  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const currentUserId = useSelector(selectCurrentUserId);

  const [modalsState, setModalsState] = useState<Partial<IAlertsSectionState>>({
    resolvingAlert: null,
    isOpenUploadModal: false,
    isOpenAddressModal: false,
    isOpenPersonalModal: false,
    isOpenFirstNameModal: false,
    isOpenBirthdayModal: false,
    isOpenLastNameModal: false,
    isOpenEmailModal: false,
    isOpenEmergencyContactInfoModal: false,
    isOpenInsuranceInfoModal: false,
    isOpenSalespersonModal: false,
    isOpenAllergiesModal: false,
    isOpenPhoneModal: false,
    isOpenWaiverModal: false,
    isOpenNoteModal: false,
    isOpenPastDuesModal: false,
    isOpenCreditCardModal: false,
  });
  const [noteDetails, setNoteDetails] = useState<INoteImt>(null);
  const [loadingData, setLoadingData] = useState<Partial<Record<AlertCondition, boolean>>>({});
  const [documentDetails, setDocumentDetails] = useState<IPersonDocumentGroupItemImt>(null);

  const renderIntlMessage = useRenderIntlMessage();

  const [timezoneMoment] = useTimezoneMoment();

  const { resolvingAlert, isOpenNoteModal, isOpenWaiverModal, isOpenCreditCardModal } = modalsState;

  const fetchInvoicesList = useFetchInvoicesList({
    module: PeakModules.FrontDesk,
    registerId: selectedRegisterId,
    isMembershipStep: false,
  });

  useEffect(() => {
    dispatch(actions.fetchMemberAlerts(profile.get('id')));
  }, [dispatch, profile]);

  const handleCloseAddNote = useCallback((): void => {
    setModalsState({
      resolvingAlert: null,
      isOpenNoteModal: false,
    });
  }, [setModalsState]);

  const handleCloseWaiverModal = useCallback((): void => {
    setDocumentDetails(null);
    setModalsState({
      resolvingAlert: null,
      isOpenWaiverModal: false,
    });
  }, [setModalsState, setDocumentDetails]);

  const handleCloseCreditCardModal = useCallback((): void => {
    setModalsState({
      resolvingAlert: null,
      isOpenCreditCardModal: false,
    } as Pick<IAlertsSectionState, keyof IAlertsSectionState>);
  }, [setModalsState]);

  const getAlertDetails = useCallback(
    (alertCondition: AlertCondition): IMemberAlertImt => {
      return memberAlertsList.find(alertItem => alertItem.get('condition') === alertCondition);
    },
    [memberAlertsList],
  );

  const getUnsignedDocumentAlertDetails = (
    alertCondition: AlertCondition,
    documentId: string,
  ): IMemberAlertImt => {
    return memberAlertsList.find(alertItem => {
      return (
        alertItem.get('condition') === alertCondition &&
        alertItem.getIn(['document', 'id']) === documentId
      );
    });
  };

  useEffect(() => {
    if (createdNote && resolvingAlert) {
      const alertId = getAlertDetails(resolvingAlert).get('id');

      batch(() => {
        dispatch(actions.resolvePersonNote(profile.get('id'), resolvingAlert, alertId));
        dispatch(actions.resetPersonCreatedNoteAction(null, profile.get('id')));
      });

      handleCloseAddNote();
    }
  }, [createdNote, dispatch, getAlertDetails, handleCloseAddNote, profile, resolvingAlert]);

  useEffect(() => {
    if (remindLaterActionResult === ActionResult.SUCCESS_ACTION) {
      handleCloseAddNote();
      dispatch(fetchPersonNotes(profile.get('id'), module));
      dispatch(actions.fetchMemberAlerts(profile.get('id')));
      dispatch(setRemindLaterActionResult(null, profile.get('id')));
    }
  }, [dispatch, remindLaterActionResult, profile, module, handleCloseAddNote]);

  const onToggleModal = (alertCondition: AlertCondition): void => {
    const alert: AlertCondition = getAlertDetails(alertCondition)?.get('condition');

    if (alert === AlertCondition.MissingPhoto && onChangeAvatarModalToggle) {
      onChangeAvatarModalToggle();
      return;
    }

    if (
      alert === AlertCondition.InactiveMember ||
      alert === AlertCondition.MemberWillExpireSoon ||
      alert === AlertCondition.MemberIsFrozen
    ) {
      onOpenChangePackagePlan(profile.get('id'));

      return;
    }

    const alertSettings = ModalAlerts[alert];
    const { [alertSettings.isOpenName as keyof IAlertsSectionState]: modal } = modalsState;

    setModalsState({
      resolvingAlert: alert,
      [alertSettings.isOpenName as keyof IAlertsSectionState]: !modal,
    });
  };

  const onToggleSignDocumentModal = (documentId: string) => (alertCondition: AlertCondition) => {
    const alert = getUnsignedDocumentAlertDetails(alertCondition, documentId);

    const alertSettings = ModalAlerts[alertCondition];
    const { [alertSettings.isOpenName as keyof IAlertsSectionState]: modal } = modalsState;

    setDocumentDetails(alert?.get('document'));
    setModalsState({
      resolvingAlert: alertCondition,
      [alertSettings.isOpenName as keyof IAlertsSectionState]: !modal,
    });
  };

  const onRemindLaterUnsignedWaiver = (documentId: string) => (
    alertCondition: AlertCondition,
  ): void => {
    const alert = getUnsignedDocumentAlertDetails(alertCondition, documentId);

    dispatch(
      actions.remindLaterAlert(profile.get('id'), alertCondition, alert.get('id'), documentId),
    );
  };

  const onIgnoreUnsignedWaiver = (documentId: string) => (alertCondition: AlertCondition): void => {
    const alert = getUnsignedDocumentAlertDetails(alertCondition, documentId);

    dispatch(actions.ignoreAlert(profile.get('id'), alertCondition, alert?.get('id'), documentId));
  };

  // profile update functions

  const updatePersonInfo = (info: IProfileInfo, alertCondition: AlertCondition): void => {
    dispatch(actions.updatePersonsProfileInfo(profile.get('id'), info, alertCondition));
  };

  const ignorePersonAppointmentAlert = (alert: IMemberAlert): void => {
    const ingoreDTO = {
      appointmentId: alert.appointment.id,
      condition: alert.condition,
      date: alert.appointment.date,
      time: alert.appointment.time,
    };

    dispatch(actions.ignorePersonAppointmentAlert(profile.get('id'), ingoreDTO));
  };

  const remindLaterPersonAppointmentAlert = (alert: IMemberAlert): void => {
    const remindDTO = {
      appointmentId: alert.appointment.id,
      condition: alert.condition,
      date: alert.appointment.date,
      time: alert.appointment.time,
    };

    dispatch(actions.remindLaterPersonAppointmentAlert(profile.get('id'), remindDTO));
  };

  const updatePersonEmergencyContactInfo = (
    emergencyInfo: ICustomerEmergencyContactDto,
    alertCondition: AlertCondition,
  ): void => {
    dispatch(actions.updateEmergencyContactInfo(profile.get('id'), emergencyInfo, alertCondition));
  };

  const updatePersonInsuranceInfo = (
    insuranceInfo: ICustomerInsuranceDto,
    alertCondition: AlertCondition,
  ): void => {
    dispatch(actions.updateInsuranceInfo(profile.get('id'), insuranceInfo, alertCondition));
  };

  const updatePersonSalesperson = (salespersonId: string, alertCondition: AlertCondition): void => {
    dispatch(actions.updateSalesperson(profile.get('id'), salespersonId, alertCondition));
  };

  const updatePersonAllergies = (
    allergies: IAllergiesFormValues,
    alertCondition: AlertCondition,
  ): void => {
    dispatch(actions.updateAllergies(profile.get('id'), allergies, alertCondition));
  };

  const handlePastDuesSubmit = (selectedDues: IBilling[], alertId: AlertCondition) => {
    // ToDo need add action for payment
    onToggleModal(alertId);
  };

  const pickSubmitFunction = (condition: AlertCondition): ((...params: any) => void) => {
    switch (condition) {
      case AlertCondition.MissingFirstName:
      case AlertCondition.MissingLastName:
      case AlertCondition.MissingBirthDate:
      case AlertCondition.MissingSSN:
      case AlertCondition.MissingEmail:
      case AlertCondition.MissingAddress:
      case AlertCondition.MissingPhone:
        return updatePersonInfo;
      case AlertCondition.PastDuePayments:
        return handlePastDuesSubmit;
      case AlertCondition.Note:
      case AlertCondition.MemberIsFrozen:
      case AlertCondition.CreditCardExpired:
      case AlertCondition.MissingBillingInfo:
        return onToggleModal;
      case AlertCondition.MissingEmergencyContactInfo:
        return updatePersonEmergencyContactInfo;
      case AlertCondition.MissingInsuranceInfo:
        return updatePersonInsuranceInfo;
      case AlertCondition.MissingSalesperson:
        return updatePersonSalesperson;
      case AlertCondition.MissingAllergies:
        return updatePersonAllergies;
      default:
        return null;
    }
  };

  // handlers

  const handleCloseViewNoteModal = useCallback((): void => {
    setNoteDetails(null);
  }, []);

  const onToggleNoteModal = (alertCondition: AlertCondition): void => {
    // values: INoteCodeRequestPayload
    setModalsState({
      resolvingAlert: alertCondition,
      isOpenNoteModal: !isOpenNoteModal,
    });
  };

  const handleOpenViewNoteModal = (note: INoteImt): void => {
    setNoteDetails(note);
  };

  const onIgnore = (alertCondition: AlertCondition): void => {
    const alertId = getAlertDetails(alertCondition).get('id');

    dispatch(actions.ignoreAlert(profile.get('id'), alertCondition, alertId));
  };

  const onRemindLaterDangerAlert = (
    alertCondition: AlertCondition,
    values: INoteCodeRequestPayload,
  ) => {
    const alertDetails = getAlertDetails(alertCondition);
    const { noteCodeId, text } = values;

    dispatch(
      actions.remindLaterDangerAlert({
        personId: profile.get('id'),
        alertCondition,
        alertId: alertDetails.get('id'),
        noteCodeId,
        text,
      }),
    );
  };

  const handleCreateNote = (values: INoteCodeRequestPayload) => {
    dispatch(
      actions.createPersonNote({ ...values, customerId: profile.get('id') }, PeakModules.FrontDesk),
    );
  };

  const onRemindLater = (alertCondition: AlertCondition): void => {
    const alertDetails = getAlertDetails(alertCondition);
    const isDangerAlert = alertDetails.get('type') === AlertTypes.Danger;

    if (isDangerAlert) {
      onToggleNoteModal(alertCondition);
    } else {
      dispatch(actions.remindLaterAlert(profile.get('id'), alertCondition, alertDetails.get('id')));
    }
  };

  const clearNoteCodesResult = () => {
    dispatch(actions.resetNoteCodes(null, profile.get('id')));
  };

  const searchNoteCodes = (search: string) => {
    dispatch(actions.searchNoteCodesThunk(search, profile.get('id')));
  };

  const onSubmitNoteModal =
    resolvingAlert === AlertCondition.Note
      ? handleCreateNote
      : (values: INoteCodeRequestPayload) => {
          onRemindLaterDangerAlert(resolvingAlert, values);
        };

  const onResolveNotAllowedAlert = async (alertCondition: AlertCondition) => {
    const loadingValues = Object.values(loadingData);
    const hasLoading = loadingValues.some(value => value);

    if (hasLoading) {
      return;
    }

    const handleCreateInvoice = (invoicesList: IInvoiceDetailsDto[]) => {
      const openedInvoiceForSelectedCustomer: IInvoiceDetailsDto = invoicesList?.find(
        (invoiceItem: IInvoiceDetailsDto) => {
          return (
            invoiceItem.status === InvoiceStatus.OPEN &&
            invoiceItem.customer?.id === profile.get('id') &&
            invoiceItem.salesperson?.id === currentUserId
          );
        },
      );
      const doAction = (registerId: string) => {
        dispatch(
          createInvoice({
            module,
            registerId,
            customerId: profile.get('id'),
            shouldFocus: true,
          }),
        );
      };

      if (openedInvoiceForSelectedCustomer) {
        dispatch(selectInvoice(openedInvoiceForSelectedCustomer.id));
        openPOSPanel();
      } else if (selectedRegisterId && isOpenPOSPanel) {
        doAction(selectedRegisterId);
      } else {
        addDelayedAction(CREATE_INVOICE, doAction);
        openPOSPanel();
      }
    };

    setLoadingData({ [alertCondition]: true });
    await fetchInvoicesList(handleCreateInvoice);
    setLoadingData({ [alertCondition]: false });
  };

  return (
    <Box pb={medium ? 1 : 2.125}>
      {memberAlertsList?.map((alert: IMemberAlertImt) => {
        const alertCondition = alert.get('condition');
        const alertId = alert.get('id');

        const alertSettings = ModalAlerts[alertCondition];

        const {
          [alertSettings.isOpenName as keyof IAlertsSectionState]: isOpenModal,
        } = modalsState;

        const isShowAlert = !!alertsList
          .find(alertItem => alertItem.get('condition') === alertCondition)
          ?.get('active');

        if (
          (alertCondition === AlertCondition.Birthday ||
            alertCondition === AlertCondition.Anniversary) &&
          isShowAlert
        ) {
          return (
            <Alert
              key={`member-alert-${alertId}`}
              title={renderIntlMessage(AlertConditions.message(alertCondition), {
                firstName: profile.get('firstName'),
              })}
              severity={AlertTypes.Info}
              small={small}
              icon={alertCondition === AlertCondition.Birthday ? CakeIcon : BellIcon}
            />
          );
        }

        if (
          (alertCondition === AlertCondition.MissedAppointment ||
            alertCondition === AlertCondition.UpcomingAppointment) &&
          isShowAlert
        ) {
          const dateTime = `${alert.getIn(['appointment', 'date'])} ${alert.getIn([
            'appointment',
            'time',
          ])}`;

          const alertTitle = `${renderIntlMessage(
            AlertConditions.message(alertCondition),
          )}${alert.getIn(['appointment', 'title'])}: ${timezoneMoment(
            dateTime,
            'YYYY-MM-DD HH:mm',
            true,
          ).format(DEFAULT_DATE_TIME_FORMAT)}`;

          return (
            <Alert
              key={`member-alert-${alert.getIn(['appointment', 'id'])}${dateTime}`}
              alertId={alertCondition}
              title={alertTitle}
              severity={getAlertType(alertCondition, alertsList)}
              small={small}
              onIgnore={() => ignorePersonAppointmentAlert(alert.toJS())}
              onRemindLater={() => remindLaterPersonAppointmentAlert(alert.toJS())}
            />
          );
        }

        if (alertCondition === AlertCondition.PastDuePayments && isShowAlert) {
          const { pastDue } = alert.toJS();

          return (
            <React.Fragment key={`member-alert-${alertId}`}>
              <Alert
                key={`member-alert-${alertId}`}
                alertId={alertCondition}
                title={AlertConditions.translate(alertCondition, {
                  price: pastDue?.amount?.toFixed(2),
                  days: pastDue?.daysSinceOldestPaymentDate,
                })}
                severity={getAlertType(alertCondition, alertsList)}
                small={small}
                onResolve={onToggleModal}
              />
              {alertSettings.component && isOpenModal ? (
                <alertSettings.component
                  module={module}
                  isOpen={isOpenModal && alertCondition === resolvingAlert}
                  clientId={selectedClubId}
                  personId={profile.get('id')}
                  alertId={alertCondition}
                  alertUuid={alertId}
                  onSubmit={pickSubmitFunction(alertCondition)}
                  onCancel={onToggleModal}
                />
              ) : null}
            </React.Fragment>
          );
        }

        if (alertCondition === AlertCondition.Note && isShowAlert) {
          return (
            <Alert
              key={`member-alert-${alertId}`}
              alertId={alertCondition}
              title={alert.getIn(['note', 'noteCode', 'title'])}
              severity={getAlertType(alertCondition, alertsList)}
              small={small}
              onResolve={onToggleModal}
              onViewNote={() => handleOpenViewNoteModal(alert.get('note'))}
            />
          );
        }

        if (alertCondition === AlertCondition.UnsignedWaiver && isShowAlert) {
          const alertType = getAlertType(alertCondition, alertsList);
          const isDangerAlert = alertType === AlertTypes.Danger;
          const documentId = alert.getIn(['document', 'id']);

          return (
            <Alert
              key={`member-alert-${alertId}`}
              alertId={alertCondition}
              title={`${renderIntlMessage(
                AlertConditions.message(alertCondition),
              )}: ${renderIntlMessage(commonMessages.membership)} #${alert.getIn([
                'document',
                'code',
              ])}`}
              severity={getAlertType(alertCondition, alertsList)}
              small={small}
              onIgnore={isDangerAlert ? null : onIgnoreUnsignedWaiver(documentId)}
              onRemindLater={onRemindLaterUnsignedWaiver(documentId)}
              onResolve={onToggleSignDocumentModal(documentId)}
            />
          );
        }

        const alertType = getAlertType(alertCondition, alertsList);
        const isDangerAlert = alertType === AlertTypes.Danger;
        const hasResolveBtn = alertCondition !== AlertCondition.NotRegisteredForClubLife;
        const isNotAllowedAlertConditionType =
          alertCondition === AlertCondition.NotAllowedAtThisDayTime ||
          alertCondition === AlertCondition.NotAllowedAtThisLocation;

        const onResolveAlert = isNotAllowedAlertConditionType
          ? onResolveNotAllowedAlert
          : onToggleModal;

        return (
          <React.Fragment key={`member-alert-${alertId}`}>
            {isShowAlert ? (
              <>
                <Alert
                  key={`member-alert-${alertId}`}
                  isDisabled={isNotAllowedAlertConditionType && loadingData[alertCondition]}
                  alertId={alertCondition}
                  title={
                    alertCondition !== AlertCondition.MemberIsFrozen
                      ? AlertConditions.translate(alertCondition)
                      : renderIntlMessage(AlertConditions.message(alertCondition), {
                          date: timezoneMoment(alert.getIn(['freezePackage', 'endDate'])).format(
                            'MMM D, YYYY',
                          ),
                        })
                  }
                  severity={getAlertType(alertCondition, alertsList)}
                  small={small}
                  onIgnore={isDangerAlert ? null : onIgnore}
                  onRemindLater={
                    alertCondition !== AlertCondition.MemberIsFrozen ? onRemindLater : null
                  }
                  onResolve={hasResolveBtn ? onResolveAlert : null}
                />

                {alertSettings.component && resolvingAlert && isOpenModal ? (
                  <alertSettings.component
                    profile={profile}
                    alert={resolvingAlert}
                    isOpen={isOpenModal && alertCondition === resolvingAlert}
                    switchingModal={onToggleModal}
                    onSubmit={pickSubmitFunction(alertCondition)}
                  />
                ) : null}
              </>
            ) : null}
          </React.Fragment>
        );
      })}

      {isOpenNoteModal && (
        <NoteModal
          editNote={null}
          isNoteModalOpen={isOpenNoteModal}
          loading={isRemindLaterLoading || isCreateNoteLoading}
          noteCodesIsLoading={isNoteCodesLoading}
          customerId={profile.get('id')}
          noteCodes={noteCodes}
          clearSearchResults={clearNoteCodesResult}
          searchNoteCodes={searchNoteCodes}
          onSubmit={onSubmitNoteModal}
          onCloseModal={handleCloseAddNote}
          isAlertResolve
        />
      )}

      {isOpenWaiverModal && (
        <SignDocumentModal
          personId={profile.get('id')}
          isOpen={isOpenWaiverModal}
          document={documentDetails}
          onClose={handleCloseWaiverModal}
          module={PeakModules.FrontDesk}
        />
      )}

      {!!noteDetails?.size && (
        <ViewNoteModal
          isOpen={!!noteDetails?.size}
          onClose={handleCloseViewNoteModal}
          note={noteDetails}
        />
      )}

      {isOpenCreditCardModal && (
        <PaymentMethodsModal
          isOpen={isOpenCreditCardModal}
          personId={profile.get('id')}
          onSuccess={handleCloseCreditCardModal}
          onCancel={handleCloseCreditCardModal}
          alert={resolvingAlert}
          module={module}
        />
      )}

      <LoadingBackdrop isLoading={isMemberAlertsListLoading} />
    </Box>
  );
};

export default MemberAlertsSection;
