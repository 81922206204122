import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Grid, makeStyles, Typography, Theme, Box } from '@material-ui/core';
import { Button } from 'common/components/index';
import { ChevronRight } from '@material-ui/icons';

import { PeakModules } from 'common/constants/peakModules';

import { ReactComponent as EditIcon } from 'img/icons/edit.svg';
import { EditMoreInfo } from 'common/components/PersonProfile/modals';

import { IAdditionalFieldsImt } from 'common/components/PersonProfile/interfaces';

import messages from 'common/components/PersonProfile/messages';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'default',
    fontWeight: 700,

    '&.clickable': {
      cursor: 'pointer',
    },
  },
  chevronRight: {
    transition: 'all 0.2s',
    transform: isOpen => (isOpen ? 'rotate(90deg)' : 'none'),
  },
  editIcon: {
    width: 20,
    height: 20,
  },
  infoWrapper: {
    paddingLeft: theme.spacing(1),
    transition: 'max-height 0.4s',
    maxHeight: isOpen => (isOpen ? '300px' : 0),
    paddingTop: isOpen => (isOpen ? theme.spacing(2) : 0),
    overflow: 'hidden',
  },
  infoItem: {
    width: 'auto',
  },
  fieldTitle: {
    marginBottom: theme.spacing(0.5),
    fontSize: '0.75rem',
  },
}));

interface Props {
  profileId: number;
  additionalFields: IAdditionalFieldsImt;
  module: PeakModules;
}

const AdditionalInfo: React.FC<Props> = ({ profileId, module, additionalFields }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isEditAdditionalInfoModalOpen, setEditAdditionalInfoModalOpen] = useState(false);

  const classes = useStyles(isOpen);

  const isAdditionalFieldsExists = useMemo(() => {
    return additionalFields?.some(item => Boolean(item.get('value')?.trim()));
  }, [additionalFields]);

  const handleOpen = () => {
    if (isAdditionalFieldsExists) {
      setIsOpen(!isOpen);
    }
  };

  const handleCloseEditMoreInfoModal = useCallback(() => {
    setEditAdditionalInfoModalOpen(false);
  }, []);

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          onClick={handleOpen}
          color="textSecondary"
          className={cx(classes.title, { clickable: isAdditionalFieldsExists })}
        >
          {isAdditionalFieldsExists && <ChevronRight className={classes.chevronRight} />}
          <FormattedMessage {...messages.moreInfo} />
        </Typography>

        <Button
          color="primary"
          size="small"
          hasHoverStyle
          onClick={() => setEditAdditionalInfoModalOpen(true)}
        >
          <EditIcon className={classes.editIcon} />
        </Button>
      </Box>

      {isOpen && (
        <Grid container spacing={3} className={classes.infoWrapper}>
          {additionalFields.reduce((acc, optionalField) => {
            if (!optionalField.get('value')?.trim()) {
              return acc;
            }

            return [
              ...acc,
              <Grid
                item
                key={optionalField.getIn(['field', 'id'])}
                component="div"
                direction="column"
                alignItems="flex-start"
                className={classes.infoItem}
              >
                <Typography component="span" color="textSecondary" className={classes.fieldTitle}>
                  {optionalField.getIn(['field', 'title'])}
                </Typography>
                <Typography component="p">{optionalField.get('value')}</Typography>
              </Grid>,
            ];
          }, [])}
        </Grid>
      )}

      {isEditAdditionalInfoModalOpen && (
        <EditMoreInfo
          isOpen={isEditAdditionalInfoModalOpen}
          onClose={handleCloseEditMoreInfoModal}
          profileId={profileId}
          additionalFields={additionalFields}
          module={module}
        />
      )}
    </Box>
  );
};

export default AdditionalInfo;
