import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core';

import { PeakModules } from 'common/constants/peakModules';

import { DialogComponent } from 'common/components';
import BillingScheduleStep from './BillingScheduleStep/BillingScheduleStep';
import PaymentStep from 'common/components/PersonProfile/components/PaymentStep/PaymentStep';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { CartUnitTypes, IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
import { addInvoiceUnit } from 'common/state/invoice/actions';
import { EXECUTE_MEMBERSHIP_ACTIONS } from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';
import { useAppDispatch } from 'store/hooks';
import { useSelector } from 'react-redux';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import { selectCancelResult } from 'common/state/invoice/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import { IBilling } from 'common/components/PersonProfile/interfaces';

interface IProps {
  isOpen: boolean;
  personId: number;
  onSuccess: () => void;
  onCancel: () => void;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  isOpenPOSPanel: boolean;
  disablePosBtn: boolean;
  module: PeakModules;
}

const useStyles = makeStyles(() => ({
  customPaper: {
    '& .MuiDialog-container>.MuiDialog-paperScrollPaper': {
      overflowY: 'hidden',
    },
  },
}));

enum BillingScheduleSteps {
  BillingSchedules,
  Payment,
}

const { addDelayedAction } = StorageServices.delayedActions;

const BillingScheduleModal = ({
  isOpen,
  onCancel,
  onSuccess,
  personId,
  module,
  disablePosBtn,
  isOpenPOSPanel,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const cancelInvoiceResult = useSelector(selectCancelResult());
  const currentUserId = useSelector(selectCurrentUserId);

  const [currentStep, setCurrentStep] = useState<BillingScheduleSteps>(
    BillingScheduleSteps.BillingSchedules,
  );

  useEffect(() => {
    if (cancelInvoiceResult?.get('success') && cancelInvoiceResult.get('shouldGoBack')) {
      setCurrentStep(BillingScheduleSteps.BillingSchedules);
    }
  }, [cancelInvoiceResult, setCurrentStep]);

  const handleClosePaymentStep = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleSubmitBillingsStep = async (billingSchedules: IBilling[]) => {
    if (disablePosBtn || isOpenPOSPanel) {
      if (isOpenPOSPanel) {
        handlePOSPanelChange(false);
      }

      handleNewMemberPanelChange(false);
      handleChangePackagePlanChange(false);
    }

    const billingScheduleIds = billingSchedules.map(billingItem => billingItem.id);

    const handleCreateInvoice = (invoicesList: IInvoiceDetailsImt[], registerId: string) => {
      const openedInvoiceForSelectedCustomer = invoicesList?.find(
        (invoiceItem: IInvoiceDetailsImt) => {
          return (
            invoiceItem.get('status') === InvoiceStatus.OPEN &&
            invoiceItem.getIn(['customer', 'id']) === personId &&
            invoiceItem.getIn(['salesperson', 'id']) === currentUserId
          );
        },
      );

      if (openedInvoiceForSelectedCustomer) {
        dispatch(
          addInvoiceUnit(
            openedInvoiceForSelectedCustomer.get('id'),
            {
              billingSchedules: billingScheduleIds,
              memberId: personId,
              registerId: selectedRegisterId,
            },
            CartUnitTypes.BILLING_SCHEDULE,
            module,
            true,
            true,
          ),
        );
      } else {
        dispatch(
          addInvoiceUnit(
            null,
            {
              createNewInvoice: true,
              billingSchedules: billingScheduleIds,
              memberId: personId,
              registerId,
            },
            CartUnitTypes.BILLING_SCHEDULE,
            module,
            true,
            true,
          ),
        );
      }
    };

    addDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS, handleCreateInvoice);

    setCurrentStep(BillingScheduleSteps.Payment);
  };

  const classes = useStyles();

  return (
    <DialogComponent
      size="lg"
      isOpen={isOpen}
      title={<FormattedMessage {...messages.billingScheduleModalTitle} />}
      submitBtnTitle={<FormattedMessage {...commonMessages.doneBtn} />}
      onSubmit={onSuccess}
      onClose={onCancel}
      hasCustomFooter
      disableBodyPadding={currentStep === BillingScheduleSteps.Payment}
      className={classes.customPaper}
    >
      {currentStep === BillingScheduleSteps.BillingSchedules && (
        <BillingScheduleStep
          personId={personId}
          onSubmit={handleSubmitBillingsStep}
          onCancel={onCancel}
        />
      )}

      {currentStep === BillingScheduleSteps.Payment && (
        <PaymentStep
          personId={personId}
          disableInvoiceCreation
          disableInvoiceSelection
          onCancel={handleClosePaymentStep}
          module={module}
          onNext={handleClosePaymentStep}
        />
      )}
    </DialogComponent>
  );
};

export default BillingScheduleModal;
