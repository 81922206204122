import { createPersonPrepAction } from '../actions';
import * as actionTypes from './constants';
import Services from 'services/network';
import { GeneralThunkAction } from 'common/state/interfaces';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { IServiceSectionData } from 'common/interfaces/service';
import { PeakModules } from 'common/constants/peakModules';

const fetchServicesSectionAction = createPersonPrepAction<IServiceSectionData>(
  actionTypes.FETCH_PERSON_SERVICES_SUCCESS,
);
const fetchServicesLoadingAction = createPersonPrepAction<boolean>(
  actionTypes.FETCH_PERSON_SERVICES_LOADING,
);
export const resetPersonServices = createPersonPrepAction(actionTypes.RESET_PERSON_SERVICES);

export const fetchServices = (personId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServicesLoadingAction(true, personId));

    try {
      let servicesData;

      switch (module) {
        case PeakModules.FrontDesk:
          servicesData = await Services.FrontDeskRedeem.getPersonServicesData(personId);
          break;
        case PeakModules.Members:
          servicesData = await Services.MembersRedeem.getPersonServicesData(personId);
          break;
        case PeakModules.Crm:
          servicesData = await Services.LeadsRedeem.getPersonServicesData(personId);
          break;
        case PeakModules.PersonalTrainingCrm:
          servicesData = await Services.PTLeadsRedeem.getPersonServicesData(personId);
          break;
        default:
      }

      dispatch(fetchServicesSectionAction(servicesData, personId));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServicesLoadingAction(false, personId));
    }
  };
};
