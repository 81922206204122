import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchKidProfileInfo } from 'modules/kid-zone/state/actions';
import * as memberActions from 'common/components/PersonProfile/state/actions';
import { selectPersonProfile } from 'common/components/PersonProfile/state/selectors';

import { PeakModules } from 'common/constants/peakModules';

import { IKidProfileImt } from 'modules/kid-zone/interfaces';
import { IStoredFileSimpleDto } from 'common/interfaces/uploadFile';

import { useAppDispatch } from 'store/hooks';

import PersonProfile from 'common/components/PersonProfile/PersonProfile';
import { BlocksSection, ServicesSection } from 'modules/kid-zone/containers/index';
import { EditKidProfileModal, KidProfileGeneralInfo } from 'modules/kid-zone/components';
import { getMember } from 'common/state/newPerson/primaryInfo/actions';

interface IProps {
  personId: number;
  clientId: string;
  closable?: boolean;
  onCloseManualCheckinPanel: () => void;
  onClose?: () => void;
}

const KidProfile = (props: IProps): JSX.Element => {
  const { personId, clientId, onCloseManualCheckinPanel } = props;

  // state
  const dispatch = useAppDispatch();

  const kidProfile: IKidProfileImt = useSelector(selectPersonProfile(personId));

  const [isEditProfileModalOpen, setIsEditProfileModalOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchKidProfileInfo(personId));
  }, [dispatch, personId]);

  const onOpenEditProfile = useCallback(() => {
    dispatch(getMember(personId, PeakModules.KidZone));
    setIsEditProfileModalOpen(true);
  }, [dispatch, personId]);

  const updatePersonPhoto = (id: number, image: IStoredFileSimpleDto) => {
    dispatch(memberActions.updatePersonPhoto(id, image, PeakModules.KidZone));
  };

  const deletePersonPhoto = (id: number) => {
    dispatch(memberActions.deletePersonPhoto(id, PeakModules.KidZone));
  };

  return (
    <>
      <PersonProfile
        clientId={clientId}
        personId={personId}
        profile={kidProfile}
        updatePersonPhoto={updatePersonPhoto}
        deletePersonPhoto={deletePersonPhoto}
        openEditProfile={onOpenEditProfile}
        ServicesSection={servicesSectionProps => (
          <ServicesSection
            {...servicesSectionProps}
            onCloseManualCheckinPanel={onCloseManualCheckinPanel}
          />
        )}
        BlocksSection={BlocksSection}
        ProfileDataSection={KidProfileGeneralInfo}
        module={PeakModules.KidZone}
        {...props}
      />

      {isEditProfileModalOpen && (
        <EditKidProfileModal
          isOpen={isEditProfileModalOpen}
          onClose={() => setIsEditProfileModalOpen(false)}
          module={PeakModules.KidZone}
        />
      )}
    </>
  );
};

export default KidProfile;
