import { List as ImmutableList } from 'immutable';

import { AppStoreTypeImt } from 'store';
import { ICheckInImt } from 'modules/front-desk/interfaces';
import {
  ICheckInManuallySearchImt,
  IFoundMembersToCheckinByBarcodeErrorImt,
  IGuardianDayCareServiceImt,
  IKidZoneBarcodeCheckinDtoImt,
  IKidZoneStatusImt,
} from 'modules/kid-zone/interfaces';
import { IKidZoneCheckInHistoryImt } from 'common/components/PersonProfile/interfaces';
import { IPageMetaImt } from 'common/interfaces/pagination';
import { ActionResult } from 'common/constants';

// status

const kidZoneStatusPath = ['kidZone', 'kidZoneStatus'];
const selectKidZoneStatus = (state: AppStoreTypeImt): IKidZoneStatusImt =>
  state.getIn([...kidZoneStatusPath, 'status']);
const selectKidZoneStatusLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...kidZoneStatusPath, 'isLoading']);

// manual check in

const manualCheckInPath = ['kidZone', 'manualCheckIn'];

const selectManualCheckInMembers = (
  state: AppStoreTypeImt,
): ImmutableList<ICheckInManuallySearchImt> => state.getIn([...manualCheckInPath, 'members']);
const selectManualCheckInMembersLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...manualCheckInPath, 'isLoading']);

const selectAddDOBActionResult = (state: AppStoreTypeImt): ActionResult | null =>
  state.getIn([...manualCheckInPath, 'addDOBAction', 'result']);

const selectAddDOBActionLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...manualCheckInPath, 'addDOBAction', 'isLoading']);

// checkIns

const checkInsListPath = ['kidZone', 'checkIns'];

const selectRecentCheckInMembers = (state: AppStoreTypeImt): ImmutableList<ICheckInImt> =>
  state.getIn([...checkInsListPath, 'list']);

const selectRecentCheckInMembersLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...checkInsListPath, 'isLoading']);

const selectSelectedCheckIn = (state: AppStoreTypeImt): ICheckInImt =>
  state.getIn([...checkInsListPath, 'selectedCheckIn']);

const selectCurrentCheckIn = (state: AppStoreTypeImt): ICheckInImt =>
  state.getIn([...checkInsListPath, 'currentCheckIn']);

const selectCheckInAllChildrenActionResult = (state: AppStoreTypeImt): ImmutableList<ICheckInImt> =>
  state.getIn([...checkInsListPath, 'checkInAllActionStatus', 'result']);

const selectCheckInAllChildrenActionLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...checkInsListPath, 'checkInAllActionStatus', 'isLoading']);

const selectCheckInChildActionResult = (state: AppStoreTypeImt): ActionResult =>
  state.getIn([...checkInsListPath, 'checkInActionStatus', 'result']);

const selectCheckInChildActionLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...checkInsListPath, 'checkInActionStatus', 'isLoading']);

const selectDaycareServices = (state: AppStoreTypeImt): ImmutableList<IGuardianDayCareServiceImt> =>
  state.getIn([...checkInsListPath, 'daycareServices']);

const selectDaycareServicesLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...checkInsListPath, 'isDaycareServicesLoading']);

const selectFoundMembersToCheckinByBarcode = (
  state: AppStoreTypeImt,
): IKidZoneBarcodeCheckinDtoImt | null => {
  return state.getIn([...checkInsListPath, 'foundMembersToCheckInByBarcode', 'result']);
};

const selectFoundMembersToCheckinByBarcodeLoading = (state: AppStoreTypeImt): boolean => {
  return state.getIn([...checkInsListPath, 'foundMembersToCheckInByBarcode', 'isLoading']);
};

const selectFoundMembersToCheckinByBarcodeError = (
  state: AppStoreTypeImt,
): IFoundMembersToCheckinByBarcodeErrorImt | null => {
  return state.getIn([...checkInsListPath, 'foundMembersToCheckInByBarcode', 'error']);
};

// panels

const kidZonePanelsPath = ['kidZone', 'panels'];

const selectKidZoneMainPanelPersonId = (state: AppStoreTypeImt): number =>
  state.getIn([...kidZonePanelsPath, 'mainPanelPersonId']);

const selectKidZoneRightPanelPersonId = (state: AppStoreTypeImt): number =>
  state.getIn([...kidZonePanelsPath, 'rightPanelPersonId']);

// checkIns history

const checkInsHistoryPath = ['kidZone', 'checkInsHistory'];

const selectCheckInsHistory = (state: AppStoreTypeImt): ImmutableList<IKidZoneCheckInHistoryImt> =>
  state.getIn([...checkInsHistoryPath, 'list']);

const selectCheckInsHistoryMeta = (state: AppStoreTypeImt): IPageMetaImt =>
  state.getIn([...checkInsHistoryPath, 'meta']);

const selectCheckInsHistoryLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...checkInsHistoryPath, 'isLoading']);

// Allergies

const allergiesPath = ['kidZone', 'allergies'];

const selectAllergiesIsLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...allergiesPath, 'isLoading']);

const selectAllergies = (state: AppStoreTypeImt) => state.getIn([...allergiesPath, 'list']);

// Emergency contact
const emergencyContactPath = ['kidZone', 'emergencyContact'];

const selectEmergencyContactLoading = (state: AppStoreTypeImt): boolean =>
  state.getIn([...emergencyContactPath, 'isLoading']);

const selectEmergencyContact = (state: AppStoreTypeImt) =>
  state.getIn([...emergencyContactPath, 'result']);

export {
  selectKidZoneStatus,
  selectKidZoneStatusLoading,
  selectManualCheckInMembers,
  selectManualCheckInMembersLoading,
  selectRecentCheckInMembers,
  selectRecentCheckInMembersLoading,
  selectSelectedCheckIn,
  selectCurrentCheckIn,
  selectCheckInAllChildrenActionResult,
  selectCheckInAllChildrenActionLoading,
  selectCheckInChildActionResult,
  selectCheckInChildActionLoading,
  selectDaycareServices,
  selectDaycareServicesLoading,
  selectKidZoneMainPanelPersonId,
  selectKidZoneRightPanelPersonId,
  selectCheckInsHistory,
  selectCheckInsHistoryMeta,
  selectCheckInsHistoryLoading,
  selectAllergiesIsLoading,
  selectAllergies,
  selectEmergencyContactLoading,
  selectEmergencyContact,
  selectAddDOBActionResult,
  selectAddDOBActionLoading,
  selectFoundMembersToCheckinByBarcode,
  selectFoundMembersToCheckinByBarcodeLoading,
  selectFoundMembersToCheckinByBarcodeError,
};
