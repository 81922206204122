import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';

import { actionTypes } from 'common/constants/invoices';
import { initReqStateImt } from 'common/constants/initialState';
import { InvoiceStatus } from 'common/interfaces/invoices';

const initStateInvoiceUsersList = fromJS({
  isLoading: false,
  customerList: [],
  salespersonList: [],
});

function invoiceUsersListReducer(state = initStateInvoiceUsersList, action) {
  switch (action.type) {
    case actionTypes.FETCH_SALESPERSON_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.FETCH_SALESPERSON:
      return state.set('salespersonList', fromJS(action.payload.data));
    case actionTypes.RESET_SALESPERSON:
      return state.set('salespersonList', fromJS([]));
    case actionTypes.FETCH_CUSTOMERS_LIST:
      return state.set('customerList', fromJS(action.payload));
    case actionTypes.RESET_CUSTOMERS_LIST:
      return state.set('customerList', fromJS([]));
    default:
      return state;
  }
}

// search inventory
const initStateInvoiceSearchInventory = fromJS({
  isLoading: false,
  data: [],
});

function invoiceSearchInventoryReducer(state = initStateInvoiceSearchInventory, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_INVENTORY_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.FETCH_SEARCH_INVENTORY:
      return state.set('data', fromJS(action.payload.data));
    case actionTypes.RESET_SEARCH_INVENTORY_RESULT:
      return state.set('data', fromJS([]));
    default:
      return state;
  }
}

// search packages
const initStateInvoiceSearchPackages = fromJS({
  isLoading: false,
  data: [],
});

function invoiceSearchPackagesReducer(state = initStateInvoiceSearchPackages, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_PACKAGES_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.FETCH_SEARCH_PACKAGES:
      return state.set('data', fromJS(action.payload));
    case actionTypes.RESET_SEARCH_PACKAGES_RESULT:
      return state.set('data', fromJS([]));
    default:
      return state;
  }
}

// search gift cards
const initInvoiceSearchGiftCards = fromJS({
  isLoading: false,
  data: [],
});

function invoiceSearchGiftCardsReducer(state = initInvoiceSearchGiftCards, action) {
  switch (action.type) {
    case actionTypes.FETCH_SEARCH_GIFT_CARDS:
      return state.set('data', fromJS(action.payload));
    case actionTypes.FETCH_SEARCH_GIFT_CARDS_LOADING:
      return state.set('isLoading', fromJS(action.payload));
    case actionTypes.RESET_SEARCH_GIFT_CARDS:
      return state.set('data', fromJS([]));
    default:
      return state;
  }
}

const initInvoicesState = fromJS({
  list: null,
  isListLoading: false,
  selectedInvoiceId: null,

  balance: null,
  isFetchBalanceLoading: false,

  isCreateInvoiceLoading: false,
  isUpdateInvoiceLoading: false,

  actionCancelResult: null,
  isCancelResultLoading: false,

  isFetchReceiptLoading: false,

  isUpdateInvoiceUnitLoading: false,

  isAddInvoiceUnitActionLoading: false,

  rewards: null,
  isFetchRewardsLoading: false,
});

function invoiceOperatingReducer(state = initInvoicesState, action) {
  switch (action.type) {
    case actionTypes.FETCH_INVOICES_LIST:
      return state.set('list', fromJS(action.payload));
    case actionTypes.FETCH_INVOICES_LIST_LOADING:
      return state.set('isListLoading', action.payload);
    case actionTypes.FETCH_RECEIPT_LOADING:
      return state.set('isFetchReceiptLoading', action.payload);
    case actionTypes.RESET_INVOICES_LIST:
      return state.set('list', null);

    case actionTypes.SELECT_CURRENT_INVOICE:
      return state.set('selectedInvoiceId', action.payload);

    case actionTypes.CREATE_INVOICE_LOADING:
      return state.set('isCreateInvoiceLoading', action.payload);

    case actionTypes.CHANGE_INVOICE_UNIT_NUMBER_LOADING:
      return state.set('isUpdateInvoiceUnitLoading', action.payload);

    case actionTypes.ADD_INVOICE_UNIT_LOADING:
      return state.set('isAddInvoiceUnitActionLoading', action.payload);

    case actionTypes.CREATE_INVOICE:
    case actionTypes.ADD_INVOICE_UNIT:
    case actionTypes.DELETE_INVOICE_UNIT:
    case actionTypes.CHANGE_INVOICE_UNIT_NUMBER:
    case actionTypes.UPDATE_SOCKET_INVOICES_LIST:
    case actionTypes.ADD_INVOICE_DISCOUNT:
      if (action.payload.status === InvoiceStatus.PAID) {
        return removeInvoiceFromList(action.payload.id, state);
      }

      const updatedInvoiceIndex = state
        .get('list')
        ?.findIndex(invoice => invoice.get('id') === action.payload.id);
      const isNewInvoiceData =
        action.payload.lastModifiedDateLong >
        state.getIn(['list', updatedInvoiceIndex, 'lastModifiedDateLong']);

      if (updatedInvoiceIndex >= 0 && isNewInvoiceData) {
        return state.updateIn(['list', updatedInvoiceIndex], () => fromJS(action.payload));
      }

      if (updatedInvoiceIndex < 0) {
        // if new invoice created via socket
        return state
          .update('list', list => list.unshift(fromJS(action.payload)))
          .set('selectedInvoiceId', state.get('selectedInvoiceId') || action.payload.id);
      }

      return state;
    case actionTypes.REMOVE_SOCKET_INVOICE:
      return removeInvoiceFromList(action.payload.invoiceId, state);
    case actionTypes.SET_CUSTOMER_TO_INVOICE_SUCCESS:
      return state.updateIn(
        [
          'list',
          state.get('list').findIndex(invoice => invoice.get('id') === action.payload.invoiceId),
        ],
        invoice => invoice.set('customer', fromJS(action.payload.person)),
      );
    case actionTypes.SET_SALESPERSON_TO_INVOICE_SUCCESS:
      return state.updateIn(
        [
          'list',
          state.get('list').findIndex(invoice => invoice.get('id') === action.payload.invoiceId),
        ],
        invoice => invoice.set('salesperson', fromJS(action.payload.person)),
      );
    case actionTypes.UPDATE_INVOICE_LOADING:
      return state.set('isUpdateInvoiceLoading', action.payload);

    case actionTypes.CANCEL_INVOICE:
      return state.set('actionCancelResult', fromJS(action.payload));
    case actionTypes.CANCEL_INVOICE_LOADING:
      return state.set('isCancelResultLoading', action.payload);
    case actionTypes.RESET_CANCEL_INVOICE:
      return state.set('actionCancelResult', null);

    case actionTypes.REMOVE_INVOICE:
      return removeInvoiceFromList(action.payload, state);
    case actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID_LOADING:
      return state.set('isFetchBalanceLoading', action.payload);
    case actionTypes.FETCH_BALANCE_BY_CUSTOMER_ID:
      return state.set('balance', fromJS(action.payload));
    case actionTypes.RESET_BALANCE:
      return state.set('balance', null);

    case actionTypes.FETCH_REWARDS_BY_CUSTOMER_ID_LOADING:
      return state.set('isFetchRewardsLoading', action.payload);
    case actionTypes.FETCH_REWARDS_BY_CUSTOMER_ID:
      return state.set('rewards', fromJS(action.payload));
    case actionTypes.RESET_REWARDS:
      return state.set('rewards', null);
    case actionTypes.RESET_INVOICE_OPERATING_STATE:
      return initInvoicesState;
    default:
      return state;
  }
}

const removeInvoiceFromList = (invoiceId: string, state: any) => {
  // if socket came outside the payment pages
  if (!state.get('list')?.size) {
    return state;
  }

  const deletingIndex = state.get('list').findIndex(inv => inv.get('id') === invoiceId);

  if (deletingIndex < 0) {
    return state;
  }

  return state
    .deleteIn(['list', deletingIndex])
    .update(uState =>
      uState.set(
        'selectedInvoiceId',
        invoiceId === uState.get('selectedInvoiceId')
          ? uState.getIn(['list', 0, 'id'])
          : uState.get('selectedInvoiceId'),
      ),
    );
};

function invoiceCustomPaymentMethodReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_RESULT:
      return state.set('result', fromJS(action.payload)).set('error', null);
    case actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_PAYMENT_METHOD_BY_BARCODE_ERROR:
      return state.set('error', fromJS(action.payload));
    case actionTypes.RESET_FETCH_PAYMENT_METHOD_BY_BARCODE:
      return initReqStateImt;
    default:
      return state;
  }
}

const initStateInvoicePaymentMethods = fromJS({
  data: [],
  isLoading: false,
});

function invoicePaymentMethodsReducer(state = initStateInvoicePaymentMethods, action) {
  switch (action.type) {
    case actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_AVAILABLE_PAYMENT_METHODS_RESULT:
      return state.set('data', fromJS(action.payload.data));
    case actionTypes.RESET_AVAILABLE_PAYMENT_METHODS:
      return initStateInvoicePaymentMethods;
    default:
      return state;
  }
}

const invoiceStoredCreditCardsState = fromJS({
  creditCards: [],
  isLoading: false,
  addCreditCardResult: null,
  addCreditCardLoading: false,
  commerceHubCredentials: null,
  commerceHubCredentialsLoading: false,
});

function invoiceStoredCreditCardsReducer(state = invoiceStoredCreditCardsState, action) {
  switch (action.type) {
    case actionTypes.FETCH_STORED_CREDIT_CARDS_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.FETCH_STORED_CREDIT_CARDS_RESULT:
      return state.set('creditCards', fromJS(action.payload));
    case actionTypes.STORE_CREDIT_CARD_RESULT:
      return state.set('addCreditCardResult', action.payload);
    case actionTypes.STORE_CREDIT_CARD_LOADING:
      return state.set('addCreditCardLoading', action.payload);
    case actionTypes.RESET_STORED_CREDIT_CARD_RESULT:
      return state.set('addCreditCardResult', null);
    case actionTypes.RESET_STORED_CREDIT_CARDS_REDUCER:
      return invoiceStoredCreditCardsState;
    case actionTypes.FETCH_COMMERCEHUB_CREDENTIALS:
      return state.set('commerceHubCredentials', action.payload);
    case actionTypes.FETCH_COMMERCEHUB_CREDENTIALS_LOADING:
      return state.set('commerceHubCredentialsLoading', action.payload);
    case actionTypes.RESET_COMMERCEHUB_CREDENTIALS:
      return state.set('commerceHubCredentialsLoading', false).set('commerceHubCredentials', null);
    default:
      return state;
  }
}

function invoiceSyncActionReducer(state = initReqStateImt, action) {
  switch (action.type) {
    case actionTypes.UPDATE_INVOICE_WITH_SYNC_RESULT:
      return state.set('data', fromJS(action.payload));
    case actionTypes.UPDATE_INVOICE_WITH_SYNC_ERROR:
      return state.set('error', fromJS(action.payload));
    case actionTypes.UPDATE_INVOICE_WITH_SYNC_LOADING:
      return state.set('isLoading', action.payload);
    case actionTypes.RESET_UPDATE_INVOICE_WITH_SYNC:
      return initReqStateImt;
    default:
      return state;
  }
}

function invoiceCheckoutResultReducer(state = null, action) {
  switch (action.type) {
    case actionTypes.SET_CHECKOUT_RESULT:
      return fromJS(action.payload);
    case actionTypes.RESET_CHECKOUT_RESULT:
      return null;
    default:
      return state;
  }
}

const initCheckingSavingsState = fromJS({
  list: [],
  fetchCheckingSavingsLoading: false,
  addCheckingSavingsLoading: false,
  addCheckingSavingsActionResult: null,
  deleteCheckingSavingsLoading: false,
  deleteCheckingSavingsActionResult: null,
});

function invoiceCheckingSavingsReducer(state = initCheckingSavingsState, action) {
  switch (action.type) {
    case actionTypes.FETCH_CHECKING_SAVINGS_DATA:
      return state.set('list', fromJS(action.payload));
    case actionTypes.FETCH_CHECKING_SAVINGS_DATA_LOADING:
      return state.set('fetchCheckingSavingsLoading', action.payload);
    case actionTypes.ADD_CHECKING_SAVINGS_DATA:
      return state.update('list', data => {
        const routingNumberExists = data
          .toJS()
          .some(el => el.routingNumber === action.payload.routingNumber);
        if (routingNumberExists) {
          return data;
        }
        return fromJS([...(data || []), action.payload]);
      });
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_LOADING:
      return state.set('addCheckingSavingsLoading', action.payload);
    case actionTypes.ADD_CHECKING_SAVINGS_DATA_ACTION_RESULT:
      return state.set('addCheckingSavingsActionResult', action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM:
      return state.update('list', data => {
        const filteredData = data.filter(item => item.get('id') !== action.payload);
        return fromJS(filteredData);
      });
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_LOADING:
      return state.set('deleteCheckingSavingsLoading', action.payload);
    case actionTypes.DELETE_CHECKING_SAVINGS_ITEM_ACTION_RESULT:
      return state.set('deleteCheckingSavingsActionResult', action.payload);
    case actionTypes.RESET_CHECKING_SAVINGS_DATA:
      return initCheckingSavingsState;
    default:
      return state;
  }
}

export default combineReducers({
  invoicesOperating: invoiceOperatingReducer,
  invoiceUsersList: invoiceUsersListReducer,
  invoiceSearchInventory: invoiceSearchInventoryReducer,
  invoiceSearchPackages: invoiceSearchPackagesReducer,
  invoiceSearchGiftCards: invoiceSearchGiftCardsReducer,

  invoicePaymentMethods: invoicePaymentMethodsReducer,
  invoiceSyncAction: invoiceSyncActionReducer,
  invoiceCustomPaymentMethod: invoiceCustomPaymentMethodReducer,
  invoiceStoredCreditCards: invoiceStoredCreditCardsReducer,
  invoiceCheckoutResult: invoiceCheckoutResultReducer,
  invoiceCheckingSavings: invoiceCheckingSavingsReducer,
});
