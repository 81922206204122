import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, ListItem, Typography, makeStyles, Grid, SvgIcon } from '@material-ui/core';

import { convertPhoneNumber, getInitials } from 'helpers/common';

import { ICheckInManuallySearchImt } from 'modules/kid-zone/interfaces';
import { CustomTheme } from 'common/ui/interfaces';

import { ReactComponent as AddIcon } from 'img/icons/add_deprecated.svg';
import { ReactComponent as CakeIcon } from 'img/icons/cake.svg';
import { ReactComponent as BarcodeIcon } from 'img/icons/barcode.svg';
import { ActionItem, ActionsMenu, AvatarWithInitials, Button } from 'common/components';
import { ChildCard } from 'modules/kid-zone/containers/index';

import commonMessages from 'common/messages/messages';
import kidZoneMessages from 'modules/kid-zone/messages';
import menuItems from 'common/messages/menuItems';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { snackbar } from 'common/utils/snackbarUtils';

const useStyles = makeStyles((theme: CustomTheme) => ({
  memberItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.borderColor.main}`,

    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  sectionTitle: {
    color: theme.palette.darkBackground.light,
  },
  guardianPhone: {
    color: theme.palette.secondary.dark,
  },
  withoutDOBLabel: {
    color: theme.palette.secondary.dark,
    fontSize: '0.625rem',
    lineHeight: '0.875rem',
  },
  guardianItem: {
    display: 'flex',
    alignItems: 'center',
  },
  childName: {
    marginBottom: theme.spacing(1),
  },
  childCard: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing(1),
    },
  },
  cakeIcon: {
    width: '16px',
    height: '16px',
  },
  checkInTime: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),

    '& > svg': {
      width: '28px',
      height: '28px',
    },
  },
}));

interface IProps {
  member: ICheckInManuallySearchImt;
  index: number;
  onCheckInKid: (childId: number) => void;
  onCheckInAll: (cardIndex: number) => void;
  onAddBirthday: (personId: number, cardIndex: number) => void;
}

const ManualCheckInCardItem = ({
  member,
  onCheckInKid,
  onCheckInAll,
  onAddBirthday,
  index,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const [timezoneMoment] = useTimezoneMoment();

  const isCheckInAllBtnDisabled = useMemo(
    () => member.get('children').every(childItem => childItem.get('checkedIn')),
    [member],
  );

  return (
    <ListItem className={classes.memberItem}>
      <Box width="inherit">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="button" className={classes.sectionTitle}>
            <FormattedMessage {...kidZoneMessages.guardiansTitle} />
          </Typography>

          <Button
            color="primary"
            startIcon={<SvgIcon component={AddIcon} />}
            onClick={() =>
              snackbar.warning(<FormattedMessage {...commonMessages.notImplementedYet} />)
            }
          >
            <FormattedMessage {...commonMessages.addBtn} />
          </Button>
        </Box>

        <Box mt={1.5}>
          {member.get('guardians').size ? (
            <Grid container spacing={1}>
              {member.get('guardians').map(guardianItem => {
                const initials = getInitials(
                  guardianItem.get('firstName'),
                  guardianItem.get('lastName'),
                );

                return (
                  <Grid item className={classes.guardianItem}>
                    <AvatarWithInitials
                      initials={initials}
                      imageUrl={guardianItem.get('imageUrl')}
                      borderRadius="3px"
                      width="32px"
                      height="32px"
                    />

                    <Box>
                      <Typography variant="body2">
                        {`${guardianItem.get('firstName')} ${guardianItem.get('lastName')}`}
                      </Typography>

                      <Typography variant="body2" className={classes.guardianPhone}>
                        {convertPhoneNumber(guardianItem.getIn(['phones', 0, 'phone']))}
                      </Typography>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ) : (
            <Typography className={classes.sectionTitle}>
              <FormattedMessage {...kidZoneMessages.noGuardiansText} />
            </Typography>
          )}
        </Box>
      </Box>

      <Box width="inherit" mt={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="button" className={classes.sectionTitle}>
            <FormattedMessage {...kidZoneMessages.childrenTitle} />
          </Typography>

          {member.get('children').size > 1 && (
            <Button
              color="primary"
              disabled={isCheckInAllBtnDisabled}
              onClick={() => onCheckInAll(index)}
            >
              <FormattedMessage {...commonMessages.checkInAllBtn} />
            </Button>
          )}
        </Box>

        <Box mt={1.5}>
          {member.get('children').size ? (
            member.get('children').map(child => {
              const isCheckedIn = child.get('lastCheckinDto')
                ? !child.getIn(['lastCheckinDto', 'pickedUpCustomer'])
                : false;
              return (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.childCard}
                >
                  <ChildCard child={child} />

                  {isCheckedIn ? (
                    <Typography color="textSecondary" className={classes.checkInTime}>
                      <Typography variant="h5" component="span" color="inherit">
                        {timezoneMoment(child.getIn(['lastCheckinDto', 'date'])).format('h:mm A')}
                      </Typography>

                      <BarcodeIcon />
                    </Typography>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onCheckInKid(child.get('id'))}
                    >
                      <FormattedMessage {...commonMessages.checkInBtn} />
                    </Button>
                  )}
                </Box>
              );
            })
          ) : (
            <Typography className={classes.sectionTitle}>
              <FormattedMessage {...kidZoneMessages.noChildrenText} />
            </Typography>
          )}
        </Box>
      </Box>

      {!!member.get('other')?.size && (
        <Box mt={3} width="100%">
          <Typography variant="button" component="p" className={classes.sectionTitle}>
            <FormattedMessage {...menuItems.other} />
          </Typography>

          <Box>
            {member.get('other').map(otherItem => (
              <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                <Box display="flex" alignItems="center">
                  <AvatarWithInitials
                    initials={getInitials(otherItem.get('firstName'), otherItem.get('lastName'))}
                    imageUrl={otherItem.get('imageUrl')}
                    variant="square"
                    borderRadius="3px"
                    width="32px"
                    height="32px"
                    marginRight={0.75}
                  />

                  <Typography component="p">
                    <Typography component="span" variant="body2">
                      {`${otherItem.get('firstName')} ${otherItem.get('lastName')}`}
                    </Typography>

                    <Typography
                      display="block"
                      component="span"
                      className={classes.withoutDOBLabel}
                    >
                      <FormattedMessage {...commonMessages.withoutDOBLabel} />
                    </Typography>
                  </Typography>
                </Box>

                <ActionsMenu horizontal>
                  <ActionItem
                    icon={<CakeIcon className={classes.cakeIcon} />}
                    onClick={() => onAddBirthday(otherItem.get('id'), index)}
                  >
                    <FormattedMessage {...menuItems.addDOB} />
                  </ActionItem>
                </ActionsMenu>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </ListItem>
  );
};

export default ManualCheckInCardItem;
