import { DialogComponent, Table } from 'common/components/index';
import { FormattedMessage } from 'react-intl';
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import { Box, Chip, Grid, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';
import { List } from 'immutable';
import {
  IFreezeHistoryPackage,
  IFreezeHistoryPackageImt,
} from 'common/components/PersonProfile/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectFreezeHistory,
  selectFreezeHistoryLoading,
} from 'common/components/PersonProfile/state/membership/selectors';
import { fetchFreezeHistory } from 'common/components/PersonProfile/state/membership/actions';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import { MenuItemTypes } from 'common/interfaces/invoices';
import { TableOrderByParams } from 'common/constants';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableHeader: {
      padding: theme.spacing(2, 2, 1, 2),
      borderBottom: `1px solid ${theme.palette.secondary.light}`,
    },
    tableWrapper: {
      '& .MuiTableCell-head': {
        textTransform: 'inherit',
        '& .MuiTableSortLabel-root': {
          color: theme.palette.text.primary,
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight,
        },
      },
    },
  }),
);

interface IFreezeMembershipHistoryModal {
  personId: number;
  isOpen: boolean;
  module: PeakModuleForNewPersonType;
  onClose: () => void;
  isMembershipCard: boolean;
}

const FreezeMembershipHistoryModal = ({
  personId,
  isOpen,
  onClose,
  module,
  isMembershipCard,
}: IFreezeMembershipHistoryModal): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const freezeHistoryList: List<IFreezeHistoryPackageImt> = useSelector(selectFreezeHistory());
  const freezeHistoryLoading: boolean = useSelector(selectFreezeHistoryLoading());

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchFreezeHistory(personId, module, isMembershipCard));
    }
  }, [dispatch, isOpen, personId, module, isMembershipCard]);

  const headerOptions: IHeadCell[] = [
    {
      id: TableOrderByParams.NAME,
      label: <FormattedMessage {...messages.freezeMembershipModalHistoryModalTableNameLabel} />,
      sort: false,
    },
    {
      id: TableOrderByParams.NAME,
      label: (
        <FormattedMessage {...messages.freezeMembershipModalHistoryModalTableFreezeReasonLabel} />
      ),
      sort: false,
    },
    {
      id: TableOrderByParams.NAME,
      label: (
        <FormattedMessage {...messages.freezeMembershipModalHistoryModalTableLastFreezeLabel} />
      ),
      sort: false,
    },
    {
      id: TableOrderByParams.NAME,
      label: (
        <FormattedMessage
          {...messages.freezeMembershipModalHistoryModalTablePricePerBillingLabel}
        />
      ),
      sort: false,
    },
  ];

  const rows: ITableRow[] = freezeHistoryList?.toJS().map(
    (item: IFreezeHistoryPackage): ITableRow => {
      const lastFreeze = item.items?.find(el => moment(el.startDate).unix());

      const strStartDate = moment(lastFreeze?.startDate).format('ll');
      const strEndDate = moment(lastFreeze?.endDate).format('ll');
      const freezePeriod =
        strStartDate === strEndDate ? strStartDate : `${strStartDate} - ${strEndDate}`;

      return {
        id: item.packageInstanceId,
        disabled: true,
        cells: [
          {
            align: 'left',
            label: 'freezing-item',
            cellComponent: (
              <Typography variant="h5">
                {item.type === MenuItemTypes.Membership ? (
                  <Grid item>
                    {item.title}&nbsp;&nbsp;
                    <Chip size="small" label="Membership" />
                  </Grid>
                ) : (
                  item.title
                )}
              </Typography>
            ),
          },
          {
            align: 'left',
            label: 'freezing-item',
            cellComponent: <Typography variant="h5">{lastFreeze?.reason}</Typography>,
          },
          {
            align: 'left',
            label: 'freezing-item',
            cellComponent: <Typography variant="h5">{freezePeriod}</Typography>,
          },
          {
            align: 'left',
            label: 'freezing-item',
            cellComponent: (
              <Typography variant="h5">
                {lastFreeze?.pricePerBilling && `$${lastFreeze?.pricePerBilling.toFixed(2)}`}
              </Typography>
            ),
          },
        ],
      };
    },
  );

  return (
    <DialogComponent
      isOpen={isOpen}
      title={<FormattedMessage {...messages.freezeMembershipModalHistoryModalTitleLabel} />}
      cancelBtnTitle={<FormattedMessage {...commonMessages.closeBtn} />}
      submitBtn={false}
      size="xl"
      scroll="paper"
      onClose={onClose}
    >
      <Box className={classes.tableWrapper}>
        <Table
          hidePagination
          hideSearchInput
          headerOptions={headerOptions}
          rows={rows}
          isLoading={freezeHistoryLoading}
        />
      </Box>
    </DialogComponent>
  );
};

export default React.memo(FreezeMembershipHistoryModal);
