import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'common/messages/messages';
import invoiceMessages from 'common/components/InvoiceOperating/messages';
import { DialogComponent, NumberTextField } from 'common/components';
import { Controller, useForm } from 'react-hook-form';
import IntlService from 'services/intl/IntlService';
import { defaultNumberFormatProps } from 'common/components/NumberController/NumberController';

interface IAddDiscountModalProps {
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (values: { discount: number }) => void;
  salespersonDiscount: number;
  discountValue: number | null;
}

const AddDiscountModal: FC<IAddDiscountModalProps> = (props: IAddDiscountModalProps) => {
  const { onClose, onSubmit, isLoading, salespersonDiscount, discountValue } = props;

  const formMethods = useForm<{ discount: number }>({
    defaultValues: {
      discount: discountValue !== null ? discountValue : salespersonDiscount,
    },
    mode: 'onBlur',
  });

  const { handleSubmit, control } = formMethods;

  return (
    <DialogComponent
      size="xs"
      title={<FormattedMessage {...messages.addDiscount} />}
      cancelBtnTitle={<FormattedMessage {...messages.cancelBtn} />}
      submitBtnTitle={<FormattedMessage {...messages.addDiscount} />}
      isOpen
      onClose={onClose}
      disableFullScreenBehavior
      loading={isLoading}
      onSubmit={handleSubmit(onSubmit)}
    >
      <form>
        <Controller
          name="discount"
          control={control}
          render={({ field }) => (
            <NumberTextField
              value={field.value}
              onChange={v => field.onChange(v || 0)}
              onBlur={field.onBlur}
              label={IntlService.formatMessage(invoiceMessages.discount)}
              variant="outlined"
              fullWidth
              numberFormatProps={{
                ...defaultNumberFormatProps,
                decimalScale: 4,
                maxLength: 15,
                isAllowed: ({ floatValue }) => {
                  return !floatValue || floatValue <= salespersonDiscount;
                },
              }}
            />
          )}
        />
      </form>
    </DialogComponent>
  );
};

export default AddDiscountModal;
