import axios from 'axios';

import ApiService from 'services/network/ApiService';

import { IProfileInfo } from 'common/components/PersonProfile/interfaces';
import { ISuccessResponse } from 'common/interfaces/http';
import {
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
} from 'common/interfaces/additionalInfo';
import { INamedEntity } from 'common/interfaces/common';
import { IAppointmentAlertActionDTO, IMemberAlert } from 'modules/front-desk/interfaces';
import { IAllergiesFormValues } from 'modules/members/interfaces';

export default class FrontDeskAlertsService extends ApiService {
  public getMemberAlerts = (personId: number): Promise<IMemberAlert[]> =>
    axios.get(`/api/v1/front-desk/${personId}/alerts`);

  public updatePartialMemberInfo = (
    memberId: number,
    profileInfo: Partial<IProfileInfo>,
  ): Promise<IProfileInfo> =>
    axios.put(`/api/v1/front-desk/${memberId}/alerts/resolve/partial-info`, profileInfo);

  public ignoreAlert = (personId: number, alertId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/front-desk/${personId}/alerts/${alertId}`);

  public ignoreAppointmentAlert = (
    personId: number,
    data: IAppointmentAlertActionDTO,
  ): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/front-desk/${personId}/alerts/appointment`, { data });

  public remindLaterAppointmentAlert = (
    personId: number,
    data: IAppointmentAlertActionDTO,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/appointment`, data);

  public remindLaterAlert = (personId: number, alertId: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/${alertId}`);

  public remindLaterDangerAlert = (
    personId: number,
    alertId: string,
    noteCodeId: string,
    text: string,
  ): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/${alertId}/danger/remind`, {
      noteCodeId,
      text,
    });

  public updateEmergencyContactInfo = (
    personId: number,
    emergencyInfo: ICustomerEmergencyContactDto,
  ): Promise<ICustomerEmergencyContactDto> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/resolve/emergency-contact`, emergencyInfo);

  public updateInsuranceInfo = (
    personId: number,
    insuranceData: ICustomerInsuranceDto,
  ): Promise<ICustomerInsuranceDto> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/resolve/insurance`, insuranceData);

  public updateSalesperson = (
    personId: number,
    salesPersonId: string,
  ): Promise<ICustomerInsuranceDto> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/resolve/salesperson`, { id: salesPersonId });

  public updateAllergies = (
    personId: number,
    allergies: IAllergiesFormValues,
  ): Promise<INamedEntity[]> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/resolve/allergy`, allergies);

  public resolveNote = (personId: number, alertId: string): Promise<INamedEntity[]> =>
    axios.put(`/api/v1/front-desk/${personId}/alerts/resolve/note`, null, {
      params: { alertId },
    });
}
