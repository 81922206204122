import { initPersonsFoundState } from 'common/state/newPerson/primaryInfo/reducer';
import { actionTypes } from './constants';
import { fromJS } from 'immutable';

const defaultReferralSectionState = fromJS({
  referredBy: null,
  referrals: [],
});

const initState = fromJS({
  referralsData: defaultReferralSectionState,
  referralsLoading: false,
  referalsPersons: initPersonsFoundState,
});

const referralsReducer = (state = initState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEMBER_REFERRALS_SUCCESS:
      return state.set('referralsData', fromJS(action.payload));
    case actionTypes.FETCH_MEMBER_REFERRALS_LOADING:
      return state.set('referralsLoading', action.payload);
    case actionTypes.RESET_MEMBER_REFERRALS:
      return state.set('referralsData', defaultReferralSectionState);
    case actionTypes.ADD_MEMBER_REFERRAL_LOADING:
      return state.set('createReferralLoading', action.payload);
    case actionTypes.ADD_MEMBER_REFERRAL:
      return state.set('createReferralResult', action.payload);
    case actionTypes.SET_REFERRED_BY_LOADING:
      return state.set('referredByLoading', action.payload);
    case actionTypes.SET_REFERRED_BY:
      return state.set('referredByResult', action.payload);
    case actionTypes.FETCH_REFERRAL_PERSONS_LOADING:
      return state.setIn(['referalsPersons', 'isLoading'], action.payload);
    case actionTypes.FETCH_REFERRAL_PERSONS_SUCCESS:
      return state.setIn(['referalsPersons', 'result'], fromJS(action.payload)).set('error', null);
    case actionTypes.RESET_REFERRAL_PERSONS_FOUND:
      return state.set('referalsPersons', initPersonsFoundState);
    default:
      return state;
  }
};

export default referralsReducer;
