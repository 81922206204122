import { defineMessages } from 'react-intl';

export default defineMessages({
  twilioServiceLabel: {
    id: 'app.modules.corporate-settings.integrations.service.twilioServiceLabel',
    defaultMessage: 'Twilio Sms Service',
  },
  sendgridEmailsLabel: {
    id: 'app.modules.corporate-settings.integrations.service.sendgridEmailsLabel',
    defaultMessage: 'Sendgrid Emails',
  },
  commerceHub: {
    id: 'app.modules.corporate-settings.integrations.service.commerceHub',
    defaultMessage: 'Commercehub',
  },
  payrollDaysTitle: {
    id: 'app.modules.corporate-settings.payPeriod.payrollDaysTitle',
    defaultMessage: 'Default payroll processing days after payroll period ends',
  },
  payrollFrequency: {
    id: 'app.modules.corporate-settings.payPeriod.payrollFrequency',
    defaultMessage: 'Payroll Frequency',
  },
  everyWeek: {
    id: 'app.modules.corporate-settings.payPeriod.everyWeek',
    defaultMessage: 'Every Week',
  },
  everyWeekDescription: {
    id: 'app.modules.corporate-settings.payPeriod.everyWeekDescription',
    defaultMessage: 'Employees get paid once every week. That equals to 52 or 53 paydays a year',
  },
  everyOtherWeek: {
    id: 'app.modules.corporate-settings.payPeriod.everyOtherWeek',
    defaultMessage: 'Every Other Week',
  },
  everyOtherWeekDescription: {
    id: 'app.modules.corporate-settings.payPeriod.everyOtherWeekDescription',
    defaultMessage: 'Employees get paid every other week. That equals to 26 or 27 paydays a year',
  },
  twiceAMonth: {
    id: 'app.modules.corporate-settings.payPeriod.twiceAMonth',
    defaultMessage: 'Twice A Month',
  },
  twiceAMonthDescription: {
    id: 'app.modules.corporate-settings.payPeriod.twiceAMonthDescription',
    defaultMessage: 'Employees get paid twice a month. That equals to 24 paydays a year',
  },
  onceAMonth: {
    id: 'app.modules.corporate-settings.payPeriod.onceAMonth',
    defaultMessage: 'Once A Month',
  },
  onceAMonthDescription: {
    id: 'app.modules.corporate-settings.payPeriod.onceAMonthDescription',
    defaultMessage: 'Employees get paid once month. That equals to 12 paydays a year',
  },
  weekDay: {
    id: 'app.modules.corporate-settings.payPeriod.weekDay',
    defaultMessage: 'Week Day',
  },
  dayNumber: {
    id: 'app.modules.corporate-settings.payPeriod.dayNumber',
    defaultMessage: 'Day Number',
  },
  lockedStatus: {
    id: 'app.modules.corporate-settings.payPeriod.status.lockedStatus',
    defaultMessage: 'Locked',
  },
  unlockedStatus: {
    id: 'app.modules.corporate-settings.payPeriod.status.lockedStatus',
    defaultMessage: 'Unlocked',
  },
  commerceHubLabel: {
    id: 'app.modules.corporate-settings.integrations.service.commerceHubLabel',
    defaultMessage: 'CommerceHub',
  },
  iTransactLabel: {
    id: 'app.modules.corporate-settings.integrations.service.iTransactLabel',
    defaultMessage: 'iTransact',
  },
  ZionsLabel: {
    id: 'app.modules.corporate-settings.integrations.service.ZionsLabel',
    defaultMessage: 'Zions',
  },
  usedPeakSettings: {
    id: 'app.modules.corporate-settings.integrations.service.usedPeakSettings',
    defaultMessage: 'Used Peak settings',
  },
  systemSettingsLabel: {
    id: 'app.modules.corporate-settings.integrations.systemSettingsLabel',
    defaultMessage: 'System Settings',
  },
  signInAsSupportUserLabel: {
    id:
      'app.modules.corporate-settings.corporateSettings.generalInfoSection.signInAsSupportUserLabel',
    defaultMessage: 'Sign in as support user',
  },
  transferOwnershipLabel: {
    id:
      'app.modules.corporate-settings.corporateSettings.generalInfoSection.transferOwnershipLabel',
    defaultMessage: 'Transfer Ownership',
  },

  configureSendgridText: {
    id: 'app.modules.corporate-settings.integrations.alert.configureSendgridText',
    defaultMessage: 'In order to send emails you need to configure Sendgrid',
  },

  configureTwilioText: {
    id: 'app.modules.corporate-settings.integrations.alert.configureTwilioText',
    defaultMessage: 'In order to send sms you need to configure Twilio',
  },

  configurePayeezyText: {
    id: 'app.modules.corporate-settings.integrations.alert.configurePayeezyText',
    defaultMessage: 'In order to make payments via Payeezy you need to set parameters',
  },

  configureITransactText: {
    id: 'app.modules.corporate-settings.integrations.alert.configureITransactText',
    defaultMessage: 'In order to make payments via iTransact you need to set parameters',
  },
  configureCommercehubText: {
    id: 'app.modules.corporate-settings.integrations.alert.configureCommercehubText',
    defaultMessage: 'You need to configure Commercehub',
  },

  configureSendgridTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.configureSendgridTitle',
    defaultMessage: 'Configure Sendgrid',
  },
  configureTwilioTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.configureTwilioTitle',
    defaultMessage: 'Configure Twilio',
  },
  configurePayeezyTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.configurePayeezyTitle',
    defaultMessage: 'Configure Payeezy',
  },
  configureITransactTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.configureITransactTitle',
    defaultMessage: 'Configure iTransact',
  },
  configureCommercehubTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.configureCommercehubTitle',
    defaultMessage: 'Configure Commercehub',
  },

  editGeneralInfoTitle: {
    id: 'app.modules.corporate-settings.integrations.modal.editGeneralInfoTitle',
    defaultMessage: 'Edit General Information',
  },
  usePeakSettings: {
    id: 'app.modules.corporate-settings.integrations.modal.usePeakSettings',
    defaultMessage: 'Use Peak Settings',
  },
  // Campaign settings
  triggeredLabel: {
    id: 'app.modules.corporate-settings.integrations.campaignSettings.triggeredLabel',
    defaultMessage: 'Triggered',
  },
  scheduledLabel: {
    id: 'app.modules.corporate-settings.integrations.campaignSettings.scheduledLabel',
    defaultMessage: 'Scheduled',
  },
  requiredFieldsLabel: {
    id: 'app.modules.corporate-settings.requiredFields.requiredFieldsLabel',
    defaultMessage: 'Required Fields',
  },
  requiredProfileInfoTitle: {
    id: 'app.modules.corporate-settings.requiredFields.requiredProfileInfo',
    defaultMessage: 'Required Profile Information',
  },
  // Taxpayer Classification Options
  proprietorOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.proprietorOption',
    defaultMessage: 'Proprietor',
  },
  cCorporationOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.cCorporationOption',
    defaultMessage: 'C Corporation',
  },
  sCorporationOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.sCorporationOption',
    defaultMessage: 'S Corporation',
  },
  partnershipOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.partnershipOption',
    defaultMessage: 'Partnership',
  },
  trustOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.trustOption',
    defaultMessage: 'Trust',
  },
  liabilityCompanyOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.liabilityCompanyOption',
    defaultMessage: 'Liability Company',
  },
  otherOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.otherOption',
    defaultMessage: 'Other',
  },
  ssnOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.ssnOption',
    defaultMessage: 'SSN',
  },
  einOptionLabel: {
    id: 'app.modules.corporate-settings.taxpayer.einOption',
    defaultMessage: 'EIN',
  },
  checkingOptionLabel: {
    id: 'app.modules.corporate-settings.ach.checkingOption',
    defaultMessage: 'CHECKING',
  },
  savingsOptionLabel: {
    id: 'app.modules.corporate-settings.ach.savingsOption',
    defaultMessage: 'SAVINGS',
  },
  commercehubOptionLabel: {
    id: 'app.modules.corporate-settings.bank.commercehubOption',
    defaultMessage: 'COMMERCEHUB',
  },
  itransactOptionLabel: {
    id: 'app.modules.corporate-settings.bank.itransactOption',
    defaultMessage: 'ITRANSACT',
  },
  zionsOptionLabel: {
    id: 'app.modules.corporate-settings.bank.zionsOption',
    defaultMessage: 'ZIONS',
  },
  usdOptionLabel: {
    id: 'app.modules.corporate-settings.bank.usdOption',
    defaultMessage: 'USD',
  },
  cadOptionLabel: {
    id: 'app.modules.corporate-settings.bank.cadOption',
    defaultMessage: 'CAD',
  },
  internalOptionLabel: {
    id: 'app.modules.corporate-settings.bank.internalOption',
    defaultMessage: 'INTERNAL',
  },
  clientOptionLabel: {
    id: 'app.modules.corporate-settings.bank.clientOption',
    defaultMessage: 'CLIENT',
  },
  discountLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.discountLabel',
    defaultMessage: 'Discount',
  },
  visaLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.visaLabel',
    defaultMessage: 'Visa',
  },
  mastercardLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.mastercardLabel',
    defaultMessage: 'Mastercard',
  },
  discoverLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.discoverLabel',
    defaultMessage: 'Discover',
  },
  amexLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.amexLabel',
    defaultMessage: 'Amex',
  },
  echeckLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.echeckLabel',
    defaultMessage: 'Echeck',
  },
  echeckPercentageLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.echeckPercentageLabel',
    defaultMessage: 'Echeck %',
  },
  echeckFlatLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.echeckFlatLabel',
    defaultMessage: 'Echeck $',
  },
  creditCardPercentageLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.creditCardPercentageLabel',
    defaultMessage: 'Credit Card %',
  },
  creditCardFlatLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.creditCardFlatLabel',
    defaultMessage: 'Credit Card $',
  },
  cashLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.cashLabel',
    defaultMessage: 'Cash',
  },
  checkLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.checkLabel',
    defaultMessage: 'Check',
  },
  moneyOrderLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.moneyOrderLabel',
    defaultMessage: 'Money order',
  },
  collectionsTargetLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.collectionsTargetLabel',
    defaultMessage: 'Collections Target',
  },
  collectionsAdditionLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.collectionsAdditionLabel',
    defaultMessage: 'Collections Addition',
  },
  feeProcessingLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.feeProcessingLabel',
    defaultMessage: 'Fee Processing',
  },
  feeSingleLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.feeSingleLabel',
    defaultMessage: 'Fee Single',
  },
  chargebackRepresentmentLabel: {
    id: 'app.modules.corporate-settings.fee-single-rates.chargebackRepresentmentLabel',
    defaultMessage: 'Chargeback Representment',
  },
  refundIssueLabel: {
    id: 'app.modules.corporate-settings.fee-single-rates.refundIssueLabel',
    defaultMessage: 'Refund Issue',
  },
  convenienceFeeLabel: {
    id: 'app.modules.corporate-settings.fee-single-rates.convenienceFeeLabel',
    defaultMessage: 'Convenience Fee',
  },
  waiveFeeLabel: {
    id: 'app.modules.corporate-settings.fee-single-rates.waiveFeeLabel',
    defaultMessage: 'Waive Fee',
  },
  graceDaysLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.graceDaysLabel',
    defaultMessage: 'Grace Days',
  },
  feeLateLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.feeLateLabel',
    defaultMessage: 'Fee Late',
  },
  flatLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.flatLabel',
    defaultMessage: 'Flat',
  },
  percentageLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.percentageLabel',
    defaultMessage: 'Percentage',
  },
  amountFlatLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.amountFlatLabel',
    defaultMessage: 'Amount $',
  },
  amountPercentageLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.amountPercentageLabel',
    defaultMessage: 'Amount %',
  },
  whicheverLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.whicheverLabel',
    defaultMessage: 'Whichever',
  },
  greaterLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.greaterLabel',
    defaultMessage: 'Greater',
  },
  lessLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.lessLabel',
    defaultMessage: 'Less',
  },
  feeReturnLabel: {
    id: 'app.modules.corporate-settings.fee-return-rates.feeReturnLabel',
    defaultMessage: 'Fee Return',
  },
  feeMonthlyLabel: {
    id: 'app.modules.corporate-settings.processing-fee-rates.feeMonthlyLabel',
    defaultMessage: 'Fee Monthly',
  },

  softwareLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.softwareLabel',
    defaultMessage: 'Software',
  },
  resubmitsLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.resubmitsLabel',
    defaultMessage: 'Resubmits',
  },
  clearOwnCreditCardsLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.clearOwnCreditCardsLabel',
    defaultMessage: 'Clear Own Credit Cards',
  },
  dataStorageLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.dataStorageLabel',
    defaultMessage: 'Data Storage',
  },
  pifStorageLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.pifStorageLabel',
    defaultMessage: 'PIF Storage',
  },
  fundingLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.fundingLabel',
    defaultMessage: 'Funding',
  },
  splitsLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.splitsLabel',
    defaultMessage: 'Splits',
  },
  turnstileDoorOpenLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.turnstileDoorOpenLabel',
    defaultMessage: 'Turnstile-Door Open',
  },
  pciComplianceLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.pciComplianceLabel',
    defaultMessage: 'PCI Compliance',
  },
  pciTokenizationLabel: {
    id: 'app.modules.corporate-settings.fee-monthly-rates.pciTokenizationLabel',
    defaultMessage: 'PCI Tokenization',
  },
  feeTransactionLabel: {
    id: 'app.modules.corporate-settings.transaction-fee-rates.feeTransactionLabel',
    defaultMessage: 'Fee Transaction',
  },
  tokenizerLabel: {
    id: 'app.modules.corporate-settings.fee-transaction-rates.tokenizerLabel',
    defaultMessage: 'Tokenizer',
  },
  cardAccountUpdaterLabel: {
    id: 'app.modules.corporate-settings.fee-transaction-rates.cardAccountUpdaterLabel',
    defaultMessage: 'Card Account Updater',
  },
  rateLabel: {
    id: 'app.modules.corporate-settings.fee-late-rates.rateLabel',
    defaultMessage: 'Rate %',
  },
});
