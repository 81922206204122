export enum FilterTypes {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
  MULTIPLE_WITH_PAGINATE = 'MULTIPLE_WITH_PAGINATE',
  DATE_RANGE = 'DATE_RANGE',
}

export enum FilterViewTypes {
  DROPDOWN = 'DROPDOWN',
  HOVER_MENU = 'HOVER_MENU',
}

/* Filter value */
export interface IFilterOption {
  key: string;
  label: string | number | JSX.Element;
  value: any;
}

export type ISingleFilterValue = IFilterOption | null;

export type IMultipleFilterValue = Array<IFilterOption>;

export interface IDateRangeFilterValue {
  startDate: null | Date | string;
  endDate?: null | Date | string;
  maxDate?: null | Date;
  minDate?: null | Date;
}

/* Filter state */
interface IFilterName {
  name: string;
  type: FilterTypes;
}

export interface ISingleFilter extends IFilterName {
  value: ISingleFilterValue;
}

export interface IMultipleFilter extends IFilterName {
  value: IMultipleFilterValue;
}

export interface IDateRangeFilter extends IFilterName {
  value: IDateRangeFilterValue;
  defaultValue?: IDateRangeFilterValue;
}

/* Filter component settings */

export interface IFilterSettingsName extends IFilterName {
  title: string | JSX.Element;
  // Setting only for 'DATE_RANGE' filter type. It is useless for other filters.
  shouldClearToDefault?: boolean;
  hidden?: boolean;
}

export interface ISingleFilterSettings extends IFilterSettingsName {
  type: FilterTypes.SINGLE;
  options: IMultipleFilterValue;
  defaultValue?: ISingleFilterValue;
  isLoading?: boolean;
}

export interface IMultipleFilterSettings extends IFilterSettingsName {
  type: FilterTypes.MULTIPLE;
  options: IMultipleFilterValue;
  defaultValue?: IMultipleFilterValue;
  isLoading?: boolean;
}

export interface IPaginateFilterReturnType {
  pageElements: IFilterOption[];
  itemCount: number;
}

export interface IMultipleFilterWithPaginateSettings extends IFilterSettingsName {
  type: FilterTypes.MULTIPLE_WITH_PAGINATE;
  defaultValue?: IMultipleFilterValue;
  fetchList: (
    search: string,
    page: number,
    params: Record<string, string | null>,
  ) => Promise<IPaginateFilterReturnType>;
  fetchItemTitle: (id: string | number) => Promise<string | number | null>;
  perPage?: number;
  isLoading?: boolean;
  params?: Record<string, string | null>;
}

export interface IDateRangeFilterSettings extends IFilterSettingsName {
  type: FilterTypes.DATE_RANGE;
  options: IDateRangeFilterValue;
  defaultValue?: IDateRangeFilterValue;
}

/* Filter component props */
interface IFilterComponentProps {
  viewType: FilterViewTypes;
  dialog?: boolean;
}

export interface ISingleFilterProps extends IFilterComponentProps {
  settings: ISingleFilterSettings;
  value: ISingleFilterValue;
  onChange: (filter: ISingleFilter, changedFilterName?: string) => void;
}

export interface IMultipleFilterProps extends IFilterComponentProps {
  settings: IMultipleFilterSettings;
  value: IMultipleFilterValue;
  onChange: (filter: IMultipleFilter, changedFilterName?: string) => void;
}

export interface IMultipleWithPaginateFilterProps extends IFilterComponentProps {
  settings: IMultipleFilterWithPaginateSettings;
  value: IMultipleFilterValue;
  onChange: (filter: IMultipleFilter, changedFilterName?: string) => void;
}

export interface IDateRangeFilterProps extends IFilterComponentProps {
  settings: IDateRangeFilterSettings;
  value: IDateRangeFilterValue;
  onChange: (filter: IDateRangeFilter, changedFilterName?: string) => void;
}

/* Common types */

export type IFilterValue = ISingleFilterValue | IMultipleFilterValue | IDateRangeFilterValue;

export type IFilter = ISingleFilter | IMultipleFilter | IDateRangeFilter;

export type IFilterSettings =
  | ISingleFilterSettings
  | IMultipleFilterSettings
  | IDateRangeFilterSettings
  | IMultipleFilterWithPaginateSettings;

export type ChangedFilterName = string | 'clearAll';

export type IFilterProps = ISingleFilterProps | IMultipleFilterProps | IDateRangeFilterProps;
