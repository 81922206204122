// Libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

// state
import {
  selectCancelMembershipActionLoading,
  selectCancelMembershipActionResult,
} from 'common/components/PersonProfile/state/membership/selectors';
import * as membershipActions from 'common/components/PersonProfile/state/membership/actions';
import * as memberActions from 'common/components/PersonProfile/state/actions';
import { updatePersonStatus } from 'modules/front-desk/state/checkins/actions';
// utils
import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// interfaces
import { CancelMembershipData } from 'common/components/PersonProfile/interfaces';
// Components
import { DialogComponent } from 'common/components';
import Stepper from 'common/components/Stepper/Stepper';
import ReasonStep, { initialCancelReasonValues } from './ReasonStep/ReasonStep';
import BillingStep from './BillingStep/BillingStep';
import PastDuesStep from './PastDuesStep/PastDuesStep';
import Footer from './Footer';
import PaymentStep from 'common/components/PersonProfile/components/PaymentStep/PaymentStep';
// Constants
import { CancelMembershipSteps, CancelMembershipStepsLabels } from './constants';
import { PeakModules } from 'common/constants/peakModules';
import { ActionResult } from 'common/constants';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import { StepContext } from 'common/createContext/stepContext';
// Messages
import messages from 'common/components/PersonProfile/messages';
import { addInvoiceUnit } from 'common/state/invoice/actions';
import { CartUnitTypes, IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
import { EXECUTE_MEMBERSHIP_ACTIONS } from 'common/constants/delayedActionsKeys';
import StorageServices from 'services/storage';
import { selectSelectedRegisterId } from 'modules/pos-kiosk/state/register/selectors';
import { fetchServices } from 'common/components/PersonProfile/state/services/actions';
import { selectCancelResult } from 'common/state/invoice/selectors';
import { selectCurrentUserId } from 'modules/authentication/state/selectors';
import PackageSelectionStep from 'common/components/PersonProfile/modals/ReactivateMembershipModal/PackageSelectionStep/PackageSelectionStep';
import { PackageSelectionType } from '../../constants';
import { IObject } from 'common/interfaces/common';

const useStyles = makeStyles((theme: Theme) => ({
  stepper: {
    padding: theme.spacing(2, 0, 3, 0),

    '&.noPadding': {
      padding: theme.spacing(0, 2),
    },
  },
  customPaper: {
    '& .MuiDialog-container>.MuiDialog-paperScrollPaper': {
      overflowY: 'hidden',
    },
  },
}));

interface IProps {
  isOpen: boolean;
  personId: number;
  membershipId: string;
  onCancel: () => void;
  module: PeakModules;
  handlePOSPanelChange: (isOpen: boolean) => void;
  handleNewMemberPanelChange: (isOpen: boolean) => void;
  handleChangePackagePlanChange: (isOpen: boolean) => void;
  isOpenPOSPanel: boolean;
  disablePosBtn: boolean;
  isMembershipCard?: boolean;
}

const { Provider: StepProvider } = StepContext;
const { addDelayedAction } = StorageServices.delayedActions;

const CancelMembershipModal = ({
  isOpen,
  onCancel,
  personId,
  membershipId,
  module,
  disablePosBtn,
  isOpenPOSPanel,
  handlePOSPanelChange,
  handleNewMemberPanelChange,
  handleChangePackagePlanChange,
  isMembershipCard,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const cancelMembershipActionResult: ActionResult = useSelector(
    selectCancelMembershipActionResult,
  );
  const isCancelMembershipActionLoading: boolean = useSelector(selectCancelMembershipActionLoading);
  const selectedRegisterId: string = useSelector(selectSelectedRegisterId);
  const cancelInvoiceResult = useSelector(selectCancelResult());
  const currentUserId = useSelector(selectCurrentUserId);

  const [currentStep, setCurrentStep] = useState(CancelMembershipSteps.Reason);
  const [cancelData, setCancelData] = useState<CancelMembershipData | IObject>({});

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const isPaymentStep = currentStep === CancelMembershipSteps.Payment;

  const currentSteps = useMemo(() => {
    const steps: CancelMembershipSteps[] = [
      CancelMembershipSteps.Reason,
      CancelMembershipSteps.Billing,
      CancelMembershipSteps.PastDues,
      CancelMembershipSteps.Payment,
    ];

    if (!isMembershipCard) {
      steps.splice(1, 0, CancelMembershipSteps.PackagesSelection);
    }

    return steps;
  }, [isMembershipCard]);

  useEffect(() => {
    if (cancelMembershipActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(memberActions.fetchProfileInfoView(personId, module));
      dispatch(fetchServices(personId, module));

      batch(() => {
        dispatch(membershipActions.resetCancelMembership());

        if (
          module === PeakModules.FrontDesk &&
          timezoneMoment(cancelData.cancellationDate).isSame(timezoneMoment(), 'day')
        ) {
          // update status in check ins list after cancellation
          dispatch(updatePersonStatus(CustomerStatus.Guest, personId));
        }
      });

      onCancel();
    }
  }, [
    cancelData,
    cancelMembershipActionResult,
    dispatch,
    module,
    onCancel,
    personId,
    timezoneMoment,
  ]);

  useEffect(() => {
    if (cancelInvoiceResult?.get('success') && cancelInvoiceResult.get('shouldGoBack')) {
      setCurrentStep(CancelMembershipSteps.PastDues);
    }
  }, [cancelInvoiceResult, setCurrentStep]);

  const currentStepIndex: number = currentSteps.indexOf(currentStep);
  const nextStep: CancelMembershipSteps =
    currentStepIndex + 1 < currentSteps.length ? currentSteps[currentStepIndex + 1] : null;
  const prevStep: CancelMembershipSteps =
    currentStepIndex - 1 < 0 ? null : currentSteps[currentStepIndex - 1];
  const packageId = membershipId || cancelData.packageInstanceId;

  const handleOnSubmitStep = async (data: CancelMembershipData): Promise<void> => {
    const updatedCancelData = { ...initialCancelReasonValues, ...cancelData, ...data };

    if (
      nextStep === CancelMembershipSteps.Payment &&
      (updatedCancelData.billingSchedules?.length || updatedCancelData.pastDues?.length)
    ) {
      const handleCreateInvoice = (invoicesList: IInvoiceDetailsImt[], registerId: string) => {
        const openedInvoiceForSelectedCustomer = invoicesList?.find(
          (invoiceItem: IInvoiceDetailsImt) => {
            return (
              invoiceItem.get('status') === InvoiceStatus.OPEN &&
              invoiceItem.getIn(['customer', 'id']) === personId &&
              invoiceItem.getIn(['salesperson', 'id']) === currentUserId
            );
          },
        );

        if (openedInvoiceForSelectedCustomer) {
          dispatch(
            addInvoiceUnit(
              openedInvoiceForSelectedCustomer.get('id'),
              {
                cancelBundleDto: {
                  ...updatedCancelData,
                  packageId,
                },
                memberId: personId,
                registerId: selectedRegisterId,
              },
              isMembershipCard
                ? CartUnitTypes.CANCEL_MEMBERSHIP
                : CartUnitTypes.CANCEL_NON_MEMBERSHIP,
              module,
              true,
              true,
            ),
          );
        } else {
          dispatch(
            addInvoiceUnit(
              null,
              {
                createNewInvoice: true,
                cancelBundleDto: {
                  ...updatedCancelData,
                  packageId,
                },
                memberId: personId,
                registerId,
              },
              isMembershipCard
                ? CartUnitTypes.CANCEL_MEMBERSHIP
                : CartUnitTypes.CANCEL_NON_MEMBERSHIP,
              module,
              true,
              true,
            ),
          );
        }
      };

      addDelayedAction(EXECUTE_MEMBERSHIP_ACTIONS, handleCreateInvoice);

      if (disablePosBtn || isOpenPOSPanel) {
        if (isOpenPOSPanel && handlePOSPanelChange) {
          handlePOSPanelChange(false);
        }

        if (handleNewMemberPanelChange) {
          handleNewMemberPanelChange(false);
        }

        if (handleChangePackagePlanChange) {
          handleChangePackagePlanChange(false);
        }
      }

      setCurrentStep(nextStep);
    } else if (nextStep === CancelMembershipSteps.Payment) {
      dispatch(
        membershipActions.cancelMembership(
          personId,
          packageId,
          { ...updatedCancelData, packageId },
          module,
          isMembershipCard,
        ),
      );
    } else {
      setCancelData(prevState => ({ ...prevState, ...data }));
      setCurrentStep(nextStep);
    }
  };

  const handleOnCancelStep = (): void => {
    if (prevStep === null) {
      dispatch(membershipActions.resetCancelMembership());
      onCancel();
    } else {
      setCurrentStep(prevStep);
    }
  };

  const handleClosePaymentStep = useCallback(() => {
    setCurrentStep(prevStep);
  }, [prevStep]);

  const onPaymentStepSubmit = useCallback(() => {
    dispatch(memberActions.fetchProfileInfoView(personId, module));

    onCancel();
  }, [dispatch, module, personId, onCancel]);

  const renderFooter = (onBack, onNext, isPastDuesSelected) => {
    const isLastStep = !(cancelData.billingSchedules?.length || isPastDuesSelected);

    return (
      <Footer
        currentStep={currentStep}
        onBack={onBack}
        goBack={onCancel}
        onNext={onNext}
        isLoading={isCancelMembershipActionLoading}
        isLastStep={isLastStep}
        disabled={isPastDuesSelected}
      />
    );
  };

  return (
    <DialogComponent
      isOpen={isOpen}
      title={
        <FormattedMessage
          {...(isMembershipCard ? messages.cancelMembershipModalTitle : messages.cancelBundleTitle)}
        />
      }
      cancelBtn={false}
      submitBtn={false}
      hasCustomFooter
      size="lg"
      onClose={onCancel}
      disableBodyPadding={isPaymentStep}
      className={classes.customPaper}
    >
      <Stepper
        className={cx(classes.stepper, { noPadding: isPaymentStep })}
        currentStep={currentStepIndex}
        steps={currentSteps.map(step => ({ title: CancelMembershipStepsLabels[step] }))}
      />

      <StepProvider
        value={{
          onBack: handleOnCancelStep,
          onNext: handleOnSubmitStep,
          renderFooter,
        }}
      >
        {currentStep === CancelMembershipSteps.Reason && <ReasonStep />}

        {currentStep === CancelMembershipSteps.PackagesSelection && (
          <PackageSelectionStep
            personId={personId}
            module={module}
            packageSelectionType={PackageSelectionType.CANCEL_SERVICE}
          />
        )}

        {currentStep === CancelMembershipSteps.Billing && (
          <BillingStep
            personId={personId}
            membershipId={packageId}
            module={module}
            isMembershipCard={isMembershipCard}
          />
        )}

        {currentStep === CancelMembershipSteps.PastDues && (
          <PastDuesStep
            personId={personId}
            membershipId={packageId}
            module={module}
            isMembershipCard={isMembershipCard}
          />
        )}

        {currentStep === CancelMembershipSteps.Payment && (
          <PaymentStep
            personId={personId}
            disableInvoiceCreation
            disableInvoiceSelection
            module={module}
            onCancel={handleClosePaymentStep}
            onNext={onPaymentStepSubmit}
          />
        )}
      </StepProvider>
    </DialogComponent>
  );
};

export default CancelMembershipModal;
