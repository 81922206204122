import React, { FC, ReactNode } from 'react';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import useSmallScreen from 'common/hooks/useSmallScreen';
import ScrollBox from 'common/components/ScrollBox/ScrollBox';

interface IProps {
  fullScreenView?: boolean;
  children: ReactNode;
  // eslint-disable-next-line react/no-unused-prop-types
  className?: string;
  hasMobilePadding?: boolean;
  hasNoPadding?: boolean;
  suppressScrollY?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  style?: Record<string, unknown>;
}

const useStyles = makeStyles(theme =>
  createStyles({
    page: {
      backgroundColor: theme.palette.background.default,
    },
    childWrapper: {
      '&.zeroMobilePadding': {
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(2, 0, 2, 0),
        },
      },
    },
    noPadding: {
      padding: '0px',
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    heightAuto: {
      height: 'auto',
    },
  }),
);

const ContentWrapper: FC<IProps> = ({
  children,
  fullScreenView,
  hasMobilePadding,
  hasNoPadding,
  suppressScrollY = false,
}) => {
  const classes = useStyles();
  const isSmallScreen = useSmallScreen(1023);

  return (
    <Box className={cx({ [classes.heightAuto]: isSmallScreen && !fullScreenView })} height="100%">
      <ScrollBox suppressScrollY={suppressScrollY} suppressScrollX>
        <Box
          height="100%"
          p={2}
          className={cx('childWrapper', classes.childWrapper, {
            zeroMobilePadding: !hasMobilePadding,
            [classes.noPadding]: hasNoPadding,
          })}
        >
          {children}
        </Box>
      </ScrollBox>
    </Box>
  );
};

const PaperSheet: FC<IProps> = ({
  children,
  className,
  fullScreenView,
  hasMobilePadding,
  style,
  hasNoPadding,
  suppressScrollY,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={cx(className, classes.page, {
        [classes.overflowHidden]: fullScreenView,
      })}
      style={style}
      height="100%"
      minHeight={0}
      flex={1}
      overflow="auto"
    >
      <ContentWrapper
        fullScreenView={fullScreenView}
        hasMobilePadding={hasMobilePadding}
        hasNoPadding={hasNoPadding}
        suppressScrollY={suppressScrollY}
      >
        {children}
      </ContentWrapper>
    </Box>
  );
};

export default PaperSheet;
