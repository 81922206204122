import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import * as selectors from 'common/components/PersonProfile/state/selectors';
import * as actions from 'common/components/PersonProfile/state/actions';

import {
  getBillingItemDescription,
  getCurrentPageList,
} from 'common/components/PersonProfile/modals/CancelMembershipModal/utils';
import { usePersonSelectorTemplate } from 'common/components/PersonProfile/hooks/usePersonSelector';
import { makeTableParams } from 'common/utils/http';
import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import useMultipleSelectTableControl, {
  IMultipleSelectTableControlProps,
} from 'common/hooks/useMultipleSelectTableControl';

import { PeakModules } from 'common/constants/peakModules';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { TableOrderByParams } from 'common/constants';

import { LoadingBackdrop, Table } from 'common/components/index';
import { formatNumberToPrice, formatPrice } from 'common/utils';

import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { IPastDue, IPastDueImt } from 'common/components/PersonProfile/interfaces';
import { CustomTheme } from 'common/ui/interfaces';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';

import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import tableFilters from 'common/messages/tableFilters';
import tableHeaders from 'common/messages/tableHeaders';
import { removeTaxAmount } from 'modules/services/utils/billingUtils';
import useInitial from 'common/hooks/useInitial';
import { PastDuesSteps } from '../PastDuesResolutionModal';

const useStyles = makeStyles((theme: CustomTheme) => ({
  tableWrapper: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.borderColor?.main}`,
  },
  noPastDuesBox: {
    height: '200px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  totalLabel: {
    fontSize: '1.25rem',
    textAlign: 'right',
    marginTop: theme.spacing(1.5),
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2, 0),
  },
}));

const tableFilterSettings: IFilterSettings[] = [
  {
    name: 'range',
    title: <FormattedMessage {...tableFilters.period} />,
    type: FilterTypes.DATE_RANGE,
    options: {
      startDate: null,
      endDate: null,
    },
  },
];

const headerOptions: IHeadCell[] = [
  { id: TableOrderByParams.DATE, label: <FormattedMessage {...tableHeaders.date} />, sort: true },
  { id: 'packageBillingInfo', label: <FormattedMessage {...tableHeaders.name} />, sort: false },
  { id: 'price', label: <FormattedMessage {...tableHeaders.price} />, align: 'right', sort: false },
];

interface IProps {
  personId: number;
  alertUuid?: string;
  setCurrentStep?: Dispatch<SetStateAction<PastDuesSteps>>;
  setAutoSelectRegister?: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  onSubmit: (pastDueIds: string[]) => void;
  module: PeakModules;
}

const PastDuesStep = ({
  personId,
  alertUuid,
  setCurrentStep,
  setAutoSelectRegister,
  onSubmit,
  onCancel,
  module,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const usePersonSelector = usePersonSelectorTemplate(personId);

  const pastDueBillings: List<IPastDueImt> = usePersonSelector(selectors.selectPastDueBillings);
  const isPastDueBillingsLoading: boolean = usePersonSelector(
    selectors.selectPastDueBillingsLoading,
  );

  const initialTableParams = useInitial(() => {
    return makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    });
  });

  const [tableParams, setTableParams] = useState(initialTableParams);
  const [pastDuesData, setPastDuesData] = useState<Partial<IMultipleSelectTableControlProps>>({
    isAllSelected: false,
    selected: [],
  });

  const isInitialPastDuesExist = useRef(false);
  const prevTableParams = useRef(tableParams);
  const [timezoneMoment] = useTimezoneMoment();
  const classes = useStyles();

  useEffect(() => {
    const onGetPastDueInvoiceSuccess = (): void => {
      if (setCurrentStep) {
        setAutoSelectRegister(true);
        setCurrentStep(PastDuesSteps.Payment);
      }
    };

    dispatch(
      actions.fetchPastDues({
        personId,
        requestOptions: initialTableParams,
        module,
        alertUuid,
        onGetPastDueInvoiceSuccess,
      }),
    );
  }, [
    dispatch,
    module,
    personId,
    alertUuid,
    setAutoSelectRegister,
    setCurrentStep,
    initialTableParams,
  ]);

  useEffect(() => {
    if (!isInitialPastDuesExist.current && pastDueBillings?.size) {
      isInitialPastDuesExist.current = true;
    }
  }, [pastDueBillings]);

  const onChangeTableParamsSubscriber = (rowsData: IMultipleSelectTableControlProps) => {
    setPastDuesData(rowsData);
  };

  const handleChangeTableParams = useCallback(
    (tableProps: ITableParams): void => {
      const { page: prevPage, perPage: prevPerPage } = prevTableParams.current;
      const { page, perPage } = tableProps;

      setTableParams(tableProps);

      prevTableParams.current = tableProps;

      if (prevPage !== page || prevPerPage !== perPage) {
        return;
      }

      dispatch(actions.fetchPastDues({ personId, requestOptions: tableProps, module }));
    },
    [dispatch, module, personId],
  );

  const handleSubmit = () => {
    let pastDueIds;

    if (pastDuesData.isAllSelected && !pastDuesData.excluded?.length) {
      pastDueIds = convertedPastDues.map(pastDueItem => pastDueItem.id);

      onSubmit(pastDueIds);

      return;
    }

    if (pastDuesData.excluded?.length) {
      pastDueIds = convertedPastDues.reduce(
        (acc, pastDueItem) =>
          pastDuesData.excluded?.includes(pastDueItem.id) ? acc : [...acc, pastDueItem.id],
        [],
      );
    } else {
      pastDueIds = pastDuesData.selected;
    }

    onSubmit(pastDueIds);
  };

  const { ref } = useMultipleSelectTableControl({
    onChangeTableParamsSubscriber,
  });

  const convertedPastDues = useMemo(() => pastDueBillings.toJS(), [pastDueBillings]);

  const currentPagePastDues = useMemo(
    () => getCurrentPageList(pastDueBillings.toJS(), tableParams.page, tableParams.perPage),
    [pastDueBillings, tableParams.page, tableParams.perPage],
  );

  const tableRows: ITableRow[] = useMemo(
    () =>
      currentPagePastDues.map((pastDue: IPastDue) => ({
        id: pastDue.id,
        cells: [
          {
            align: 'left',
            label: '',
            cellComponent: (
              <Typography variant="h5">
                {timezoneMoment(pastDue.paymentDate).format(DEFAULT_DATE_FORMAT)}
              </Typography>
            ),
          },
          {
            padding: 'normal',
            align: 'left',
            label: getBillingItemDescription(pastDue.package),
          },
          {
            align: 'right',
            label: `$${formatNumberToPrice(
              pastDue.revenueCode?.totalTax
                ? removeTaxAmount(pastDue.amount, pastDue.revenueCode.totalTax)
                : pastDue.amount,
            )}`,
          },
        ],
      })),
    [currentPagePastDues, timezoneMoment],
  );

  const isSubmitBtnDisabled =
    (!pastDuesData.selected?.length && !pastDuesData.isAllSelected) ||
    (pastDuesData.isAllSelected && !pastDuesData.numSelected);

  const pastDuesTotal = useMemo(() => {
    if (pastDuesData.isAllSelected && !pastDuesData.excluded?.length) {
      return convertedPastDues.reduce((totalAmount, pastDueItem) => {
        const { amount, revenueCode } = pastDueItem;

        const pastDueAmount = revenueCode?.totalTax
          ? removeTaxAmount(amount, revenueCode.totalTax)
          : amount;

        return totalAmount + pastDueAmount;
      }, 0);
    }

    if (pastDuesData.excluded?.length) {
      return convertedPastDues.reduce((totalAmount, pastDueItem) => {
        const { id, amount, revenueCode } = pastDueItem;

        const pastDueAmount = revenueCode?.totalTax
          ? removeTaxAmount(amount, revenueCode.totalTax)
          : amount;

        return totalAmount + (pastDuesData.excluded?.includes(id) ? 0 : pastDueAmount);
      }, 0);
    }

    return pastDuesData.selected?.reduce((totalAmount, pastDueItemId) => {
      const { amount, revenueCode } = convertedPastDues.find(
        pastDueItem => pastDueItem.id === pastDueItemId,
      );

      const pastDueAmount = revenueCode?.totalTax
        ? removeTaxAmount(amount, revenueCode.totalTax)
        : amount;

      return totalAmount + pastDueAmount;
    }, 0);
  }, [convertedPastDues, pastDuesData]);

  return (
    <>
      {isPastDueBillingsLoading && <LoadingBackdrop isLoading />}
      {pastDueBillings.size || isInitialPastDuesExist.current ? (
        <>
          <Box className={classes.tableWrapper}>
            <Table
              activeSelect
              showPerPageSelect
              onChangeParams={handleChangeTableParams}
              isLoading={false}
              totalRows={pastDueBillings?.size}
              rows={tableRows}
              headerOptions={headerOptions}
              refMultipleSelectParams={ref}
              filters={tableFilterSettings}
              tableParams={tableParams}
            />
          </Box>

          <Typography component="p" className={classes.totalLabel}>
            <FormattedMessage
              {...messages.pastDuesModalTotalLabel}
              values={{
                price: (
                  <Typography component="span" variant="h3">
                    {formatPrice(pastDuesTotal)}
                  </Typography>
                ),
              }}
            />
          </Typography>
        </>
      ) : (
        <Box className={classes.noPastDuesBox}>
          <Typography variant="h2" color="textSecondary">
            <FormattedMessage {...messages.pastDuesModalNoPastDues} />
          </Typography>
        </Box>
      )}

      <Grid container spacing={2} className={classes.footer}>
        <Grid item>
          <Button color="primary" onClick={onCancel}>
            <FormattedMessage {...commonMessages.cancelBtn} />
          </Button>
        </Grid>

        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitBtnDisabled}
          >
            <FormattedMessage {...commonMessages.proceedToPaymentBtn} />
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PastDuesStep;
