import Services from 'services/network';
import { nosMemberElement } from 'modules/reports/constants/invoiceHistory';

import { IMultipleFilter, IPaginateFilterReturnType } from 'common/interfaces/filter';
import { ITableParams } from 'common/interfaces/table';

export const reportsEmployeeCommonFilter = {
  perPage: 20,
  fetchItemTitle: async (id: number): Promise<string | null> => {
    try {
      const employee = await Services.Employees.getEmployeeById(id);
      return `${employee.firstName} ${employee.lastName}`;
    } catch (e) {
      return null;
    }
  },
  fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
    try {
      const { meta, data } = await Services.DictionaryLists.getReportsEmployeeList({
        search,
        page,
        perPage: 20,
      });

      return {
        pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
        itemCount: meta.total,
      };
    } catch (e) {
      return { pageElements: [], itemCount: 0 };
    }
  },
};

export const reportsMemberWithNosFilter = {
  perPage: 20,
  fetchItemTitle: async (id: string | number) => {
    try {
      if (id === nosMemberElement.label) {
        return nosMemberElement.label;
      }

      if (typeof id !== 'number') {
        throw new Error('Invalid member id');
      }

      const member = await Services.Members.getMember(id);
      return `${member.firstName} ${member.lastName}`;
    } catch (e) {
      return null;
    }
  },
  fetchList: async (search, page) => {
    try {
      const { meta, data } = await Services.DictionaryLists.getReportsMembersList({
        search,
        page,
        perPage: 20,
      });

      const pageElements = data.map(item => ({
        key: item.id,
        label: item.title,
        value: item.id,
      }));

      const containsPartOf = nosMemberElement.label
        .toLowerCase()
        .includes(search?.toLowerCase() || '');

      const shouldBeNOSOption = page === 0 && containsPartOf;

      if (shouldBeNOSOption) {
        pageElements.unshift(nosMemberElement);
      }

      const itemCount = containsPartOf ? meta.total + 1 : meta.total;

      return {
        pageElements,
        itemCount,
      };
    } catch (e) {
      return { pageElements: [], itemCount: 0 };
    }
  },
};

export const reportsMemberFilter = {
  perPage: 20,
  fetchItemTitle: async (id: number) => {
    try {
      const member = await Services.Members.getMember(id);
      return `${member.firstName} ${member.lastName}`;
    } catch (e) {
      return null;
    }
  },
  fetchList: async (search: string, page: number, params: Record<string, string | null>) => {
    try {
      const { meta, data } = await Services.DictionaryLists.getReportsMembersList({
        search,
        page,
        perPage: 20,
        ...params,
      });

      const pageElements = data.map(item => ({
        key: item.id,
        label: item.title,
        value: item.id,
      }));

      return {
        pageElements,
        itemCount: meta.total,
      };
    } catch (e) {
      return { pageElements: [], itemCount: 0 };
    }
  },
};

export const getClubFilterIds = (
  tableParams: ITableParams,
  clubFilterName: string,
): { isAllSelected: boolean; selectedClubIds: string } => {
  const clubFilter = tableParams.filters?.find(
    filter => filter.name === clubFilterName,
  ) as IMultipleFilter;

  if (!clubFilter) {
    return { isAllSelected: true, selectedClubIds: '' };
  }

  return {
    isAllSelected: false,
    selectedClubIds: clubFilter.value
      .filter(club => !!club.key)
      .map(club => club.value)
      .join(','),
  };
};
