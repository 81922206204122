import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { List } from 'immutable';
// interfaces
import { IPaymentImt } from 'common/components/PersonProfile/interfaces';
// Components
import {
  CheckInBlock,
  InvoicesModal,
  InvoicesTable,
} from 'common/components/PersonProfile/components';
import { Button } from 'common/components/index';
// actions
import * as actions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/components/PersonProfile/state/selectors';

import { useAppDispatch } from 'store/hooks';

import { PeakModules } from 'common/constants/peakModules';

import commonMessages from 'common/messages/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

interface IProps {
  personId: number;
  module: PeakModules;
}

const Payments = ({ personId, module }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const payments: List<IPaymentImt> = useSelector(selectors.selectRecentPayments(personId));
  const paymentsLoading: boolean = useSelector(selectors.selectRecentPaymentsLoading(personId));

  const [isPaymentsModalOpen, setIsPaymentsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(actions.fetchRecentPersonPayments(personId, module));
  }, [dispatch, module, personId]);

  return (
    <>
      <CheckInBlock
        headerDivider
        title={<FormattedMessage {...commonMessages.invoicesTitle} />}
        buttonTitle={
          <Button color="primary" hasHoverStyle>
            <FormattedMessage {...permissionsMessages.viewAll} />
          </Button>
        }
        onClickButton={() => setIsPaymentsModalOpen(true)}
        tableContainer
      >
        <InvoicesTable
          disableSorting
          payments={payments}
          paymentsLoading={paymentsLoading}
          hidePagination
          hideSearchInput
          module={module}
          personId={personId}
          borderShadow={false}
          hidePadding
          suppressFiltersMobilePadding
        />
      </CheckInBlock>

      {isPaymentsModalOpen && (
        <InvoicesModal
          isOpen={isPaymentsModalOpen}
          onClose={() => setIsPaymentsModalOpen(false)}
          personId={personId}
          module={module}
        />
      )}
    </>
  );
};

export default Payments;
