import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@material-ui/core';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import * as selectors from 'common/components/PersonProfile/state/selectors';
import { FilterTypes, IFilterSettings, IPaginateFilterReturnType } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { makeTableParams } from 'common/utils/http';
import { TableOrderByParams } from 'common/constants';
import * as actions from 'common/components/PersonProfile/state/actions';
import { DialogComponent, Table } from 'common/components';
import modalMessages from 'common/messages/modalTitles';
import Services from 'services/network';
import { PeakModules } from 'common/constants/peakModules';
import tableHeaders from 'common/messages/tableHeaders';
import inputLabels from 'common/messages/inputLabels';
import { automaticManualOptions } from 'common/constants/historyModal';
import commonMessages from 'common/messages/messages';

interface IRemindersHistoryModalProps {
  personId: number;
  isOpen: boolean;
  module: PeakModules;
  onOpenCallDetails: (id: string) => void;
  onClose: () => void;
}

const headerOptions: IHeadCell[] = [
  {
    id: TableOrderByParams.DATE,
    label: <FormattedMessage {...tableHeaders.date} />,
    sort: true,
  },
  {
    id: 'salesperson',
    label: <FormattedMessage {...tableHeaders.salesperson} />,
    sort: false,
  },
  {
    id: 'from',
    label: <FormattedMessage {...inputLabels.from} />,
    sort: false,
  },
  {
    id: 'to',
    label: <FormattedMessage {...inputLabels.to} />,
    sort: false,
  },
  {
    id: 'campaign',
    label: <FormattedMessage {...inputLabels.campaign} />,
    sort: false,
  },
  {
    id: 'type',
    label: <FormattedMessage {...inputLabels.type} />,
    sort: false,
  },
];

const CallsHistoryModal = (props: IRemindersHistoryModalProps): JSX.Element => {
  const { personId, isOpen, onClose, module, onOpenCallDetails } = props;

  const dispatch = useAppDispatch();

  const calls = useSelector(selectors.selectPersonCalls(personId));
  const callsLoading = useSelector(selectors.selectPersonCallsLoading(personId));
  const callsMeta = useSelector(selectors.selectPersonCallsMeta(personId));

  const [timezoneMoment] = useTimezoneMoment();

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: 'range',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
      {
        name: 'employeeIds',
        title: <FormattedMessage {...tableFilters.salesperson} />,
        type: FilterTypes.MULTIPLE_WITH_PAGINATE,
        perPage: 20,
        fetchItemTitle: async (id: number): Promise<string | null> => {
          try {
            const employee = await Services.Employees.getEmployeeById(id);
            return `${employee.firstName} ${employee.lastName}`;
          } catch (e) {
            return null;
          }
        },
        fetchList: async (search: string, page: number): Promise<IPaginateFilterReturnType> => {
          try {
            const { meta, data } = await Services.DictionaryLists.getEmployeesList({
              search,
              page,
              perPage: 20,
              module,
            });

            return {
              pageElements: data.map(item => ({ key: item.id, label: item.title, value: item.id })),
              itemCount: meta.total,
            };
          } catch (e) {
            return { pageElements: [], itemCount: 0 };
          }
        },
      },
      {
        name: 'automatic',
        title: <FormattedMessage {...tableFilters.type} />,
        type: FilterTypes.SINGLE,
        options: automaticManualOptions,
      },
    ],
    [module],
  );

  const rows: ITableRow[] = useMemo(() => {
    return calls
      ?.map(item => {
        const firstName = item.getIn(['salesperson', 'firstName']);
        const lastName = item.getIn(['salesperson', 'lastName']);

        return {
          id: item.get('id'),
          cells: [
            {
              label: '',
              width: '17%',
              cellComponent: (
                <Button
                  variant="text"
                  className="btn-text"
                  type="button"
                  onClick={() => onOpenCallDetails(item.get('id'))}
                >
                  <Typography variant="h6" color="primary">
                    {timezoneMoment(item.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}
                  </Typography>
                </Button>
              ),
            },
            {
              width: '17%',
              label: firstName ? `${firstName} ${lastName}` : '-',
            },
            {
              width: '17%',
              label: item.get('salespersonPhone') || '-',
            },
            {
              width: '17%',
              label: item.get('leadPhone') || '-',
            },
            {
              width: '17%',
              label: item.getIn(['campaign', 'title']) || '-',
            },
            {
              width: '17%',
              label: (
                <FormattedMessage
                  {...(item.get('automatic')
                    ? commonMessages.automaticLabel
                    : commonMessages.manualLabel)}
                />
              ),
            },
          ],
        };
      })
      .toJS();
  }, [calls, timezoneMoment, onOpenCallDetails]);

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  const onChangeTableParams = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  useEffect(() => {
    dispatch(actions.fetchPersonCalls(personId, tableParams, module));
  }, [dispatch, module, personId, tableParams]);

  useEffect(() => {
    return () => {
      dispatch(actions.resetPersonCallsAction(null, personId));
    };
  }, [dispatch, personId]);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...modalMessages.callHistory} />}
      size="xl"
      submitBtn={null}
      cancelBtn={null}
    >
      <Table
        rows={rows}
        tableParams={tableParams}
        headerOptions={headerOptions}
        filters={tableFilterSettings}
        onChangeParams={onChangeTableParams}
        totalRows={callsMeta?.get('total')}
        page={callsMeta?.get('page')}
        isLoading={callsLoading}
        hideToolbar
        showPerPageSelect
        hideSearchInput
      />
    </DialogComponent>
  );
};

export default CallsHistoryModal;
