import React from 'react';
import { Typography, Box, ListItem, Theme, makeStyles } from '@material-ui/core';

import { IRecentAppointmentItemImt } from 'modules/booking/interfaces';

import AppointmentMenu from '../AppointmentMenu/AppointmentMenu';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import {
  AppointmentStatusColors,
  AppointmentStatuses,
} from 'common/components/PersonProfile/constants';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';

import commonMessages from 'common/messages/messages';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 1.5, 0, 0),
  },
  wrapper: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.secondary.light}`,
  },
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  status: {
    textTransform: 'uppercase',
    fontSize: '0.625rem',
    fontWeight: 500,
  },
  actionIcon: {
    padding: 0,
    marginLeft: theme.spacing(1),
  },
  canceledStatus: {
    textTransform: 'uppercase',
    marginLeft: theme.spacing(0.5),
  },
}));

interface IProps {
  appointment: IRecentAppointmentItemImt;
  isDisabled?: boolean;
  onClick?: (event: IRecentAppointmentItemImt) => void;
  onEdit?: (event: IRecentAppointmentItemImt) => void;
  onRedeem?: (event: IRecentAppointmentItemImt) => void;
  onCancel?: (event: IRecentAppointmentItemImt) => void;
  onCancelParticipation?: (event: IRecentAppointmentItemImt) => void;
  onConfirmParticipation?: (event: IRecentAppointmentItemImt) => void;
  onDelete?: (event: IRecentAppointmentItemImt) => void;
  onRemind?: (event: IRecentAppointmentItemImt) => void;
  onRestore?: (event: IRecentAppointmentItemImt) => void;
}

const AppointmentsItem = ({
  appointment,
  isDisabled,
  onClick,
  onEdit,
  onRedeem,
  onCancel,
  onCancelParticipation,
  onConfirmParticipation,
  onDelete,
  onRemind,
  onRestore,
}: IProps): JSX.Element => {
  const classes = useStyles();

  const [timezoneMoment] = useTimezoneMoment();

  const isPastAppointment = timezoneMoment(appointment.get('date')).isSameOrBefore(
    timezoneMoment(),
    'minutes',
  );

  const onAppointmentClick = () => {
    if (!isDisabled) {
      onClick(appointment);
    }
  };

  return (
    <ListItem className={classes.root}>
      <Box className={classes.wrapper} paddingY={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" mb={1}>
          <Typography component="p" onClick={onAppointmentClick}>
            <Typography component="span" variant="h5">
              {appointment.get('title')}
            </Typography>

            {appointment.get('canceled') && (
              <Typography component="span" color="textSecondary" className={classes.canceledStatus}>
                <FormattedMessage {...commonMessages.canceled} />
              </Typography>
            )}
          </Typography>

          {!isDisabled && !isPastAppointment && (
            <AppointmentMenu
              appointment={appointment}
              onEdit={onEdit}
              onRedeem={onRedeem}
              onCancel={onCancel}
              onCancelParticipation={onCancelParticipation}
              onConfirmParticipation={onConfirmParticipation}
              onDelete={onDelete}
              onRemind={onRemind}
              onRestore={onRestore}
            />
          )}
        </Box>

        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          <Typography variant="body2" color="textSecondary">
            {timezoneMoment(appointment.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}
          </Typography>

          <Box display="flex" alignItems="center">
            <Typography
              component="span"
              variant="body2"
              style={{ color: AppointmentStatusColors[appointment.get('appointmentStatus')] }}
              className={classes.status}
            >
              {AppointmentStatuses.translate(appointment.get('appointmentStatus'))}
            </Typography>
          </Box>
        </Box>
      </Box>
    </ListItem>
  );
};

export default AppointmentsItem;
