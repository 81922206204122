import React, { useMemo } from 'react';
import { IBillingOption, IBillingRCode } from 'modules/services/interfaces/packages';
import { FieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultBillingOption } from 'modules/services/constants/packages';
import { FormattedMessage } from 'react-intl';

import commonMessages from 'common/messages/messages';
import { DialogComponent } from 'common/components';
import EditBillingOptionForm from 'modules/services/components/EditPackageForm/BillingStep/EditBillingOptionModal/EditBillingOptionForm';
import messages from 'modules/services/messages/packages';
import { IDurationOptions } from 'modules/services/utils/billingUtils';
import { billingOptionSchema } from 'modules/services/components/EditPackageForm/BillingStep/billingValidationSchema';

interface IEditBillingOptionModal {
  billingOption: FieldArray<{ billingOptions: IBillingOption[] }, 'billingOptions'>;
  revenueCode: IBillingRCode;
  durationOption: IDurationOptions;
  onSubmit: (billingOption: IBillingOption) => void;
  onClose: () => void;
  isOutOfTerm?: boolean;
}

const EditBillingOptionModal = ({
  billingOption,
  revenueCode,
  durationOption,
  onSubmit,
  onClose,
  isOutOfTerm,
}: IEditBillingOptionModal): JSX.Element => {
  const defaultValues = useMemo(
    () => ({
      ...defaultBillingOption,
      ...billingOption,
    }),
    [billingOption],
  );
  const formMethods = useForm<IBillingOption>({
    defaultValues,
    resolver: yupResolver(billingOptionSchema) as any, // TODO - PRM-1810 need resolver type
    mode: 'all',
  });

  const { handleSubmit } = formMethods;

  const handleDialogSubmitAction = handleSubmit(data => {
    return onSubmit({ ...data, isOutOfTerm, id: billingOption?.id });
  });

  const titleMsg = billingOption
    ? messages.editPaymentOptionModalTitle
    : messages.newPaymentOptionModalTitle;

  return (
    <DialogComponent
      isOpen
      size="md"
      title={
        <FormattedMessage {...(isOutOfTerm ? messages.editOutOfTermBillingModalTitle : titleMsg)} />
      }
      submitBtnTitle={<FormattedMessage {...commonMessages.doneBtn} />}
      onSubmit={handleDialogSubmitAction}
      onClose={onClose}
    >
      <EditBillingOptionForm
        formMethods={formMethods}
        defaultValues={defaultValues}
        revenueCode={revenueCode}
        durationOption={durationOption}
        isOutOfTerm={isOutOfTerm}
      />
    </DialogComponent>
  );
};

export default EditBillingOptionModal;
