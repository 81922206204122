import { FieldError } from 'react-hook-form';

// interfaces
import { ImmutableObject } from 'common/state/interfaces';
import { ICountryImt, IStateImt } from 'common/components/PersonProfile/interfaces';
import { IStoredFileSimpleDto, IStoredFileSimpleDtoImt } from 'common/interfaces/uploadFile';
// constants
import { ConstOptions } from 'common/constants/classes';
// messages
import messages from 'modules/services/messages/services';
import dateAndTimeMessages from 'common/messages/dateAndTime';

export interface IObject<T = any> {
  [field: string]: T;
}

export interface INamedEntity<ID = string> {
  id: ID;
  imageUrl?: string;
  active?: boolean;
  title: string;
}

export type INamedEntityImt = ImmutableObject<INamedEntity>;

export interface IOwnerAccount {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  image: IStoredFileSimpleDto;
  // Peak level only
  terminationDate?: string;
}

export interface IOwnerAccountImt extends ImmutableObject<IOwnerAccount> {
  get<K extends keyof IOwnerAccount>(key: K): IOwnerAccount[K];

  get(key: 'image'): IStoredFileSimpleDtoImt;
}

// addresses
export interface IAddress {
  id?: string;
  line1: string;
  line2?: string;
  zipCode: string;
  city: string;
  country?: INamedEntity;
  state?: INamedEntity;
}

export interface IAddressImt extends ImmutableObject<IAddress> {
  get<K extends keyof IAddress>(key: K): IAddress[K];

  get(key: 'state'): IStateImt;

  get(key: 'country'): ICountryImt;
}

export interface IStateItem {
  id: string;
  imageUrl: string;
  title: string;
}

export type IStateItemImt = ImmutableObject<IStateItem>;

export interface IClubIds {
  clubIds: Array<string | number>;
}

export interface IKeyed {
  key: string;
}

export type IRequestState = {
  result: string;
  error: string;
  isLoading: boolean;
};

export type IRequestStateImt = ImmutableObject<IRequestState>;

export interface Message {
  id: string;
  defaultMessage: string;
  value?: any;
}

export interface ValidationError extends Omit<FieldError, 'message'> {
  message: Message;
}

export enum DurationUnit {
  Mins = 'MINS',
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS',
}

export const DurationUnits = new ConstOptions([
  { value: DurationUnit.Mins, message: messages.editServiceFormDurationUnitMins },
  { value: DurationUnit.Hours, message: messages.editServiceFormDurationUnitHours },
  { value: DurationUnit.Days, message: messages.editServiceFormDurationUnitDays },
  { value: DurationUnit.Weeks, message: messages.editServiceFormDurationUnitWeeks },
]);

export enum ReminderPeriodType {
  Minutes = 'MINUTES',
  Hours = 'HOURS',
  Days = 'DAYS',
  Weeks = 'WEEKS',
}

export const ReminderPeriodTypes = new ConstOptions([
  { value: ReminderPeriodType.Minutes, message: messages.editServiceFormDurationUnitMins },
  ...DurationUnits.values.slice(1),
]);

export interface IBaseStepProps {
  onBack?: (data?) => void;
  renderFooter?: (onBack?: () => void, onNext?: () => void, isLoading?: boolean) => JSX.Element;
  onNext?: (data?) => void;
  done?: () => void;
  helperData?: any;
}

// days and time

export enum Weekday {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export const weekdayOptions = [
  {
    key: Weekday.SUNDAY,
    value: Weekday.SUNDAY,
    shortMessage: dateAndTimeMessages.sundayShortLabel,
    message: dateAndTimeMessages.sundayLabel,
    fullMessage: dateAndTimeMessages.sundayFullLabel,
  },
  {
    key: Weekday.MONDAY,
    value: Weekday.MONDAY,
    shortMessage: dateAndTimeMessages.mondayShortLabel,
    message: dateAndTimeMessages.mondayLabel,
    fullMessage: dateAndTimeMessages.mondayFullLabel,
  },
  {
    key: Weekday.TUESDAY,
    value: Weekday.TUESDAY,
    shortMessage: dateAndTimeMessages.tuesdayShortLabel,
    message: dateAndTimeMessages.tuesdayLabel,
    fullMessage: dateAndTimeMessages.tuesdayFullLabel,
  },
  {
    key: Weekday.WEDNESDAY,
    value: Weekday.WEDNESDAY,
    shortMessage: dateAndTimeMessages.wednesdayShortLabel,
    message: dateAndTimeMessages.wednesdayLabel,
    fullMessage: dateAndTimeMessages.wednesdayFullLabel,
  },
  {
    key: Weekday.THURSDAY,
    value: Weekday.THURSDAY,
    shortMessage: dateAndTimeMessages.thursdayShortLabel,
    message: dateAndTimeMessages.thursdayLabel,
    fullMessage: dateAndTimeMessages.thursdayFullLabel,
  },
  {
    key: Weekday.FRIDAY,
    value: Weekday.FRIDAY,
    shortMessage: dateAndTimeMessages.fridayShortLabel,
    message: dateAndTimeMessages.fridayLabel,
    fullMessage: dateAndTimeMessages.fridayFullLabel,
  },
  {
    key: Weekday.SATURDAY,
    value: Weekday.SATURDAY,
    shortMessage: dateAndTimeMessages.saturdayShortLabel,
    message: dateAndTimeMessages.saturdayLabel,
    fullMessage: dateAndTimeMessages.saturdayFullLabel,
  },
];

export const ShortWeekdays = new ConstOptions(
  weekdayOptions.map(option => ({ ...option, message: option.shortMessage })),
);

export const Weekdays = new ConstOptions(weekdayOptions);

export const FullWeekdays = new ConstOptions(
  weekdayOptions.map(option => ({ ...option, message: option.fullMessage })),
);

export interface ITimeRange {
  startTime: string;
  endTime: string;
}

export interface IDayTimeAvailabilityDto {
  id?: string;
  allDay?: boolean;
  weekday?: Weekday;
  timeRanges?: Array<ITimeRange>;
}

export interface IDayTimeAvailabilityDtoImt extends ImmutableObject<IDayTimeAvailabilityDto> {
  get<K extends keyof IDayTimeAvailabilityDto>(key: K): IDayTimeAvailabilityDto[K];
}

export interface ITagOption {
  id?: string;
  inputValue?: string;
  title: string;
}

export interface IShortPerson {
  id: number;
  imageUrl?: string;
  firstName: string;
  lastName: string;
  active?: boolean;
}

export type IShortPersonImt = ImmutableObject<IShortPerson>;

export enum EAutomaticManualType {
  Automatic,
  Manual,
}

export type UserRole = 'client' | 'member';
