import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from 'store/hooks';
import { InventoryViewModal } from 'common/components/index';
import { IPackageInventoryViewDtoImt } from 'common/interfaces/membership';
import { PeakModules } from 'common/constants/peakModules';
import {
  fetchMembershipPackageInventory,
  resetPersonMembershipPackageInventoryAction,
} from 'common/components/PersonProfile/state/membership/actions';
import {
  selectPersonMembershipPackageInventory,
  selectPersonMembershipPackageInventoryLoading,
} from 'common/components/PersonProfile/state/membership/selectors';

interface IProps {
  isOpen: boolean;
  module: PeakModules;
  onClose: () => void;
  inventoryId: string;
  personId: number;
  packageId: string;
}

const PackageInventoryViewInfoModal = ({
  personId,
  inventoryId,
  module,
  packageId,
  isOpen,
  onClose,
}: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const isInventoryInfoLoading = useSelector(selectPersonMembershipPackageInventoryLoading);
  const inventoryInfo = useSelector(selectPersonMembershipPackageInventory);

  useEffect(() => {
    dispatch(fetchMembershipPackageInventory(personId, packageId, inventoryId, module));
  }, [dispatch, inventoryId, packageId, module, personId]);

  useEffect(() => {
    return () => {
      dispatch(resetPersonMembershipPackageInventoryAction());
    };
  }, [dispatch]);

  return (
    <InventoryViewModal<IPackageInventoryViewDtoImt>
      inventoryInfo={inventoryInfo}
      onClose={onClose}
      isOpen={isOpen}
      isLoading={isInventoryInfoLoading}
    />
  );
};

export default PackageInventoryViewInfoModal;
