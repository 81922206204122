import React from 'react';
import { Typography, Card, Box, makeStyles, Theme, Divider } from '@material-ui/core';
import { List as ImmutableList } from 'immutable';

import { ILeadImt } from 'modules/crm/interfaces/leads';
import { ScrollBox } from 'common/components';
import LeadCard from './LeadCard';
import { IConstOption } from 'common/constants/classes';
import { FormattedMessage } from 'react-intl';
import { PeakModules } from 'common/constants/peakModules';

interface IStyleProps {
  color: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '100%',
    width: '230px',
  },
  header: {
    height: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    borderBottom: (props: IStyleProps) => `2px solid ${props.color}`,
  },
  headerTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: (props: IStyleProps) => props.color,
  },
  content: {
    height: 'calc(100% - 40px)',
    position: 'relative',
    overflow: 'hidden',
  },
  divider: {
    backgroundColor: theme.palette.secondary.light,
  },
  emptyBox: {
    height: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
}));

interface IProps extends IStyleProps {
  items: ImmutableList<ILeadImt>;
  status: IConstOption;
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm;
  onScrollEnd: (status: IConstOption, offset: number) => void;
  onChangeStatus: (leadId: number) => void;
  onChangeSalesperson: (leadId: number) => void;
}

const LeadsColumn = (props: IProps): JSX.Element => {
  const classes = useStyles(props);
  const { status, items, onScrollEnd, onChangeStatus, onChangeSalesperson, module } = props;

  // debounce
  const onYReachEnd = () => {
    onScrollEnd(status, items.size);
  };

  return (
    <Box className={classes.container}>
      <Card className={classes.header}>
        <Typography className={classes.headerTitle} variant="body1">
          <FormattedMessage {...status.message} />
        </Typography>
      </Card>
      <Box className={classes.content}>
        {!!items && !!items.size ? (
          <ScrollBox onYReachEnd={onYReachEnd}>
            <Box>
              {items.map((lead, i) => (
                <div key={lead.get('id')}>
                  {!!i && <Divider className={classes.divider} />}
                  <LeadCard
                    lead={lead}
                    isLast={i === items.size - 1}
                    onChangeStatus={onChangeStatus}
                    module={module}
                    onChangeSalesperson={onChangeSalesperson}
                  />
                </div>
              ))}
            </Box>
          </ScrollBox>
        ) : (
          <Card className={classes.emptyBox}>
            <Typography color="textSecondary" variant="body2">
              No leads
            </Typography>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(LeadsColumn);
