import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isImmutable } from 'immutable';
import { FormattedMessage } from 'react-intl';
import { FileRejection, useDropzone } from 'react-dropzone';
import { Box, Button, Divider, makeStyles, Theme, Typography } from '@material-ui/core';

// state
import {
  deletePersonAttachment,
  fetchPersonDocuments,
  resetPersonDocuments,
  resetUpdatePersonAttachmentsAction,
  updatePersonAttachments,
} from 'common/components/PersonProfile/state/actions';
import {
  selectPersonDocumentActionLoading,
  selectPersonDocumentActionResult,
  selectPersonDocuments,
  selectPersonDocumentsLoading,
} from 'common/components/PersonProfile/state/selectors';
import { selectSignContractActionResult } from 'common/state/newPerson/contract/selectors';
import { selectSetSignWaiversActionResult } from 'common/state/newPerson/waiver/selectors';
import {
  selectUploadedFileById,
  selectUploadedFileDialogId,
} from 'common/state/uploadFile/selectors';
import { resetUploadFile, uploadFile } from 'common/state/uploadFile/actions';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
// components
import { CheckInBlock, SignDocumentModal } from 'common/components/PersonProfile/components/index';
import { LoadingBackdrop } from 'common/components/index';
import DocumentsSectionActions from './DocumentsSectionActions/DocumentsSectionActions';
import DocumentListItem from './DocumentListItem/DocumentListItem';
import DocumentsViewModal from './DocumentsViewModal/DocumentsViewModal';
import { WebcamImageModal } from 'common/modals';
import DocumentsHistoryModal from './DocumentsHistoryModal/DocumentsHistoryModal';
// hooks
import { useAppDispatch } from 'store/hooks';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
// interfaces
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import {
  IPersonDocumentGroupImt,
  IPersonDocumentGroupItemImt,
} from 'common/components/PersonProfile/interfaces';
import { IAttachment, IAttachmentImt } from 'common/interfaces/uploadFile';
// constants
import { ActionResult, MAX_FILE_SIZE } from 'common/constants';
import { FileErrorTypes } from 'common/components/FilesUploader/constants';
// messages
import messages from 'common/components/PersonProfile/messages';
import commonMessages from 'common/messages/messages';
import permissionsMessages from 'modules/permissions/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  subHeader: {
    marginTop: theme.spacing(1),
    '& > span': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  viewAllButton: {
    margin: theme.spacing(1, 0, -1, 0),
  },
}));

interface IProps {
  profileId: number;
  module: PeakModuleForNewPersonType;
}

interface IModalsState {
  isDocumentsViewModalOpen: boolean;
  isOpenWebcamModal: boolean;
  isOpenDocumentsHistoryModal: boolean;
}

const initialModalsState: IModalsState = {
  isDocumentsViewModalOpen: false,
  isOpenWebcamModal: false,
  isOpenDocumentsHistoryModal: false,
};

const fieldId = 'upload-file';

const DocumentsSection = ({ profileId, module }: IProps): JSX.Element => {
  const dispatch = useAppDispatch();

  const documents = useSelector(selectPersonDocuments(profileId));
  const isDocumentsLoading: boolean = useSelector(selectPersonDocumentsLoading(profileId));
  const documentActionResult: ActionResult = useSelector(
    selectPersonDocumentActionResult(profileId),
  );
  const isDocumentActionLoading: boolean = useSelector(
    selectPersonDocumentActionLoading(profileId),
  );
  const signContractActionResult = useSelector(selectSignContractActionResult);
  const signWaiverActionResult = useSelector(selectSetSignWaiversActionResult);

  const file = useSelector(selectUploadedFileById(fieldId));
  const uploadId = useSelector(selectUploadedFileDialogId);

  const [modalsState, setModalsState] = useState<IModalsState>(initialModalsState);
  const [selectedDocument, setSelectedDocument] = useState<IPersonDocumentGroupItemImt | null>(
    null,
  );

  const renderIntlMessage = useRenderIntlMessage();

  const onDropAccepted = (uploadFiles: File[]) => {
    dispatch(uploadFile(fieldId, uploadFiles[0]));
  };

  const onDropRejected = (uploadFiles: FileRejection[]) => {
    const errorCode = uploadFiles[0].errors[0].code;

    dispatch(
      enqueueErrorNotification({ message: renderIntlMessage(FileErrorTypes.message(errorCode)) }),
    );
  };

  const { open, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    maxSize: MAX_FILE_SIZE,
    onDropAccepted,
    onDropRejected,
  });

  const classes = useStyles();

  const handleUpdatePersonAttachments = useCallback(
    (attachments: IAttachment[] | IAttachmentImt) => {
      if (documents) {
        dispatch(
          updatePersonAttachments(
            profileId,
            module,
            isImmutable(attachments)
              ? [...documents.get('attachments')?.toJS(), (attachments as IAttachmentImt).toJS()]
              : attachments,
          ),
        );
      }
    },
    [dispatch, documents, module, profileId],
  );

  useEffect(() => {
    dispatch(fetchPersonDocuments(profileId, module));

    return () => {
      dispatch(resetPersonDocuments(null, profileId));
    };
  }, [dispatch, module, profileId]);

  useEffect(() => {
    if (documentActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(fetchPersonDocuments(profileId, module));
      dispatch(resetUpdatePersonAttachmentsAction(null, profileId));

      setModalsState(initialModalsState);
    }
  }, [dispatch, documentActionResult, module, profileId]);

  useEffect(() => {
    if (
      (signContractActionResult === ActionResult.SUCCESS_ACTION ||
        signWaiverActionResult === ActionResult.SUCCESS_ACTION) &&
      !modalsState.isOpenDocumentsHistoryModal
    ) {
      dispatch(fetchPersonDocuments(profileId, module));
      setSelectedDocument(null);
    }
  }, [
    dispatch,
    modalsState.isOpenDocumentsHistoryModal,
    module,
    profileId,
    signContractActionResult,
    signWaiverActionResult,
  ]);

  useEffect(() => {
    if (fieldId === uploadId && file) {
      handleUpdatePersonAttachments(file);
      dispatch(resetUploadFile());
    }
  }, [dispatch, file, handleUpdatePersonAttachments, uploadId]);

  const handleDeletePersonAttachment = useCallback(
    (attachmentId: string) => {
      dispatch(deletePersonAttachment(profileId, attachmentId, module));
    },
    [dispatch, module, profileId],
  );

  const handleSelectDocument = useCallback((document: IPersonDocumentGroupItemImt) => {
    setSelectedDocument(document);
  }, []);

  const attachments = documents?.get('attachments');
  const documentGroups = documents?.get('documentGroups');

  return (
    <CheckInBlock
      headerDivider
      title={<FormattedMessage {...messages.documentsBlockTitle} />}
      buttonGroup={
        <DocumentsSectionActions
          onOpenBrowseForFilesModal={open}
          onOpenWebcamModal={() =>
            setModalsState(prevState => ({ ...prevState, isOpenWebcamModal: true }))
          }
          onOpenDocumentsHistoryModal={() =>
            setModalsState(prevState => ({ ...prevState, isOpenDocumentsHistoryModal: true }))
          }
          getFileInputProps={getInputProps}
        />
      }
    >
      {!!attachments?.size && (
        <>
          <Box className={classes.subHeader}>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage {...commonMessages.attachmentsLabel} />
            </Typography>
          </Box>

          {attachments.map((attachment: IAttachmentImt) => (
            <DocumentListItem
              attachmentItem={attachment}
              key={attachment.get('id')}
              onDeleteAttachment={handleDeletePersonAttachment}
            />
          ))}
        </>
      )}

      {!!documentGroups?.size && (
        <>
          {documentGroups.map((group: IPersonDocumentGroupImt) => (
            <React.Fragment key={group.get('id')}>
              <Box className={classes.subHeader}>
                <Typography variant="caption" color="textSecondary">
                  {group.getIn(['packageTemplate', 'title'])}
                </Typography>
              </Box>

              {group.get('documents').map((documentItem: IPersonDocumentGroupItemImt) => (
                <DocumentListItem
                  groupItem={documentItem}
                  key={documentItem.get('id')}
                  onSelectSignDocument={handleSelectDocument}
                  profileId={profileId}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      )}

      {attachments?.size || documentGroups?.size ? (
        <>
          <Divider />

          <Button
            onClick={() =>
              setModalsState(prevState => ({ ...prevState, isDocumentsViewModalOpen: true }))
            }
            color="primary"
            fullWidth
            className={classes.viewAllButton}
          >
            <FormattedMessage {...permissionsMessages.viewAll} />
          </Button>
        </>
      ) : (
        <Box className="empty-section-placeholder" p={2}>
          <Typography className="empty-text">
            <FormattedMessage {...messages.emptyDocumentsListMessage} />
          </Typography>
        </Box>
      )}

      <LoadingBackdrop isLoading={isDocumentsLoading} />

      {modalsState.isDocumentsViewModalOpen && (
        <DocumentsViewModal
          isOpen={modalsState.isDocumentsViewModalOpen}
          isAttachmentsUpdateLoading={isDocumentActionLoading}
          onSubmit={handleUpdatePersonAttachments}
          documents={documents}
          profileId={profileId}
          module={module}
          onClose={() => setModalsState(initialModalsState)}
        />
      )}

      {!!selectedDocument?.size && (
        <SignDocumentModal
          personId={profileId}
          isOpen={!!selectedDocument}
          document={selectedDocument}
          onClose={() => setSelectedDocument(null)}
          module={module}
        />
      )}

      {modalsState.isOpenWebcamModal && (
        <WebcamImageModal
          id="document-webcam-image-upload-modal"
          isOpen={modalsState.isOpenWebcamModal}
          onToggle={() => setModalsState(initialModalsState)}
          onSubmit={handleUpdatePersonAttachments}
        />
      )}

      {modalsState.isOpenDocumentsHistoryModal && (
        <DocumentsHistoryModal
          isOpen={modalsState.isOpenDocumentsHistoryModal}
          onClose={() => setModalsState(initialModalsState)}
          module={module}
          profileId={profileId}
          onSelectSignDocument={handleSelectDocument}
          signContractActionResult={signContractActionResult}
          signWaiverActionResult={signWaiverActionResult}
        />
      )}
    </CheckInBlock>
  );
};

export default DocumentsSection;
