import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

// components
import TimeclockJobPositionItem from '../TimeclockJobPositionItem/TimeclockJobPositionItem';
import UnitEventModal from '../UnitEventModal/UnitEventModal';

// actions
import { clockInEmployeePosition, clockOutEmployeePosition } from 'modules/timeclock/state/actions';

// interfaces
import { ITimeClockEmployeeJobPosition } from 'common/interfaces/employee';

// utils
import { getPreparedTimeclockPositionsData } from 'modules/timeclock/utils/utils';
import { EmployeePayTypes } from 'common/constants/employee';

const useStyles = makeStyles((theme: Theme) => ({
  itemWrapper: {
    borderBottom: `2px solid ${theme.palette.secondary.light}`,
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  serviceItemLabel: {
    marginBottom: theme.spacing(2),
  },
}));

interface IProps {
  jobPositions: ITimeClockEmployeeJobPosition[];
  employeeId: number;
  isProfile?: boolean;
}

const TimeClockJobPositionList = ({
  jobPositions,
  employeeId,
  isProfile = false,
}: IProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [selectedUnitItem, setSelectedUnitItem] = useState<ITimeClockEmployeeJobPosition | null>(
    null,
  );

  const preparedData = useMemo(() => {
    if (jobPositions) return getPreparedTimeclockPositionsData(jobPositions);
    return [];
  }, [jobPositions]);

  const handleClockInOrOut = useCallback(
    (position: ITimeClockEmployeeJobPosition) => {
      if (position.payType === EmployeePayTypes.UNIT && !position.clockEdIn) {
        setSelectedUnitItem(position);
        return;
      }
      if (!position.clockEdIn) {
        dispatch(clockInEmployeePosition(employeeId, { jobPositionId: position.id }));
      } else {
        dispatch(clockOutEmployeePosition(employeeId, { jobPositionId: position.id }));
      }
    },
    [dispatch, employeeId],
  );

  return (
    <>
      {preparedData?.map(club => (
        <Box key={club?.id} className={classes.itemWrapper}>
          <Typography variant="h5" color="textSecondary" className={classes.serviceItemLabel}>
            {club.clubName}
          </Typography>
          {club.items.map(position => (
            <Box key={position?.id} mb={2}>
              <TimeclockJobPositionItem onClockInOrOut={handleClockInOrOut} position={position} />
            </Box>
          ))}
        </Box>
      ))}
      {Boolean(selectedUnitItem) && (
        <UnitEventModal
          isOpen={Boolean(selectedUnitItem)}
          selectedPosition={selectedUnitItem}
          onClose={() => setSelectedUnitItem(null)}
          employeeId={employeeId}
          isProfile={isProfile}
        />
      )}
    </>
  );
};

export default TimeClockJobPositionList;
