// libraries
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { Box, Typography } from '@material-ui/core';
// components
import { Button, DialogComponent } from 'common/components';
import CheckingSavingsStep from './steps/checkingSavings/CheckingSavingsStep';
import CreditCardPaymentStep from './steps/creditCard/CreditCardPaymentStep';
import OnAccountStep from './steps/onAccount/onAccountStep';
import SelectPaymentTypeStep from './steps/SelectPaymentTypeStep';
// interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IInvoiceDetailsImt, PaymentsType } from 'common/interfaces/invoices';
// constants
import { PaymentsTypeLabels } from 'modules/pos-settings/constants/paymentMethods';
// messages
import messages from 'common/components/InvoiceOperating/messages';
// icons
import { ReactComponent as BackArrowIcon } from 'img/arrow-circle-left.svg';
// utils
import { formatPrice } from 'common/utils';
// state
import * as selectors from 'memberPortalModules/MemberProfile/state/invoice/selectors';
import * as actions from 'memberPortalModules/MemberProfile/state/invoice/actions';
import { parseErrorArgs } from 'common/utils/http';
import { PMethodSteps, PMethodStepsTitles } from 'common/components/InvoiceOperating/constants';
import { FormattedMessageByCode, ServerError } from 'common/errors/serverErrors';

interface IInvoicePaymentWizardProps {
  isOpen: boolean;
  leftToPay: number;
  memberId?: number;
  registerId: string;
  onClose: () => void;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  leftToPayBlock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
  },
  leftToPayPrice: {
    fontWeight: theme.typography.fontWeightMedium as number,
  },
  backButton: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  backIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  error: {
    marginBottom: theme.spacing(1.5),
  },
}));

const InvoicePaymentWizard: React.FC<IInvoicePaymentWizardProps> = (
  props: IInvoicePaymentWizardProps,
): JSX.Element => {
  const classes = useStyles(props);
  const { isOpen, leftToPay, memberId, onClose, registerId } = props;

  const [stepsStack, setStepsStack] = useState<PMethodSteps[]>([PMethodSteps.SelectMethodStep]);
  const [selectedType, setSelectedType] = useState<PaymentsType>(null);

  // global state

  const dispatch = useDispatch();

  const paymentMethods = useSelector(selectors.selectAvailablePaymentMethods);
  const isPaymentMethodsLoading = useSelector(selectors.selectAvailablePaymentMethodsLoading);
  const paymentError = useSelector(selectors.selectUpdatedInvoiceError);
  const memberInvoice: IInvoiceDetailsImt = useSelector(selectors.selectMemberInvoice);
  const selectedCustomerBalanceLoading = useSelector(selectors.selectCustomerBalanceLoading);
  const selectedCustomerBalance = useSelector(selectors.selectCustomerBalance);

  // constants

  const isOnHandExceededServerError = paymentError
    ?.toJS()
    .codes.find(code => code === ServerError.INVENTORY_CLUB_ON_HAND_EXCEEDED);
  const currentStep = useMemo(() => stepsStack[stepsStack.length - 1], [stepsStack]);
  const paymentTypeId = useMemo(() => {
    const pMethod = paymentMethods.find(method => method.get('type') === selectedType);
    if (pMethod) {
      return pMethod.get('id');
    }

    return null;
  }, [paymentMethods, selectedType]);

  // handlers

  const handleSelectStep = useCallback((step: PMethodSteps, type: PaymentsType): void => {
    setStepsStack(prevStack => {
      if (prevStack.includes(step)) return prevStack;
      return [...prevStack, step];
    });

    setSelectedType(type);
  }, []);

  const handleGoBack = useCallback(() => {
    const prevStep = stepsStack[stepsStack.length - 2];

    setStepsStack(prevSteps => {
      prevSteps.pop();
      return [...prevSteps];
    });

    if (!prevStep || prevStep === PMethodSteps.SelectMethodStep) {
      setSelectedType(null);
    }
  }, [stepsStack]);

  // effects

  useEffect(() => {
    if (!isOpen) {
      setStepsStack([PMethodSteps.SelectMethodStep]);
      setSelectedType(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (memberId && isOpen) {
      dispatch(actions.fetchCustomerBalanceThunk(memberId));
    }
  }, [memberId, dispatch, isOpen]);

  useEffect(() => {
    if (isOnHandExceededServerError && !isOpen) {
      // dispatch(fetchPosKioskRegisterData(registerId));
      dispatch(actions.updateInvoiceWithSyncErrorAction(null));
    }
  }, [dispatch, isOnHandExceededServerError, isOpen, memberInvoice]);

  // effects

  useEffect(() => {
    if (isOpen) {
      dispatch(actions.fetchAvailablePaymentMethods(registerId));
    }

    return () => {
      batch(() => {
        dispatch(actions.resetAvailablePaymentMethodsList());
        dispatch(actions.resetCustomerBalance());
      });
    };
  }, [dispatch, isOpen, registerId]);

  // renders

  const Title: JSX.Element = useMemo(
    () => (
      <>
        {currentStep !== PMethodSteps.SelectMethodStep && (
          <Button
            size="small"
            className={classes.backButton}
            startIcon={<BackArrowIcon className={classes.backIcon} />}
            onClick={handleGoBack}
          />
        )}

        {!selectedType && <FormattedMessage {...PMethodStepsTitles.message(currentStep)} />}

        {!!selectedType && PaymentsTypeLabels[selectedType]}
      </>
    ),
    [classes, currentStep, handleGoBack, selectedType],
  );

  const LeftToPay: JSX.Element = useMemo(
    () => (
      <Box className={classes.leftToPayBlock}>
        <Typography component="span">
          <FormattedMessage {...messages.leftToPay} />
        </Typography>
        <Typography component="span" className={classes.leftToPayPrice}>
          {formatPrice(leftToPay)}
        </Typography>
      </Box>
    ),
    [classes.leftToPayBlock, classes.leftToPayPrice, leftToPay],
  );

  const SubHeader = useMemo(() => {
    const errorCode = paymentError?.getIn(['codes', 0]);
    const errorArgs = paymentError?.get('args')?.toJS();
    const errorValues = errorArgs && parseErrorArgs(errorArgs);
    return (
      <Box mt={2} p={0}>
        {paymentError && (
          <Alert className={classes.error} severity="error">
            {FormattedMessageByCode(errorCode, errorValues) || paymentError.get('message')}
          </Alert>
        )}
        {LeftToPay}
      </Box>
    );
  }, [LeftToPay, classes.error, paymentError]);

  const balance = selectedCustomerBalance?.get('balance') || 0;

  return (
    <DialogComponent
      size="sm"
      isOpen={isOpen}
      title={Title}
      closeCross
      subHeader={SubHeader}
      hasCustomFooter
      supressBottomShadow
      footer={null}
      onClose={onClose}
    >
      {currentStep === PMethodSteps.SelectMethodStep && (
        <SelectPaymentTypeStep
          paymentMethodsList={paymentMethods}
          isLoading={isPaymentMethodsLoading || selectedCustomerBalanceLoading}
          balance={balance}
          onSelectStep={handleSelectStep}
          memberId={memberId}
        />
      )}

      {currentStep === PMethodSteps.CreditCardPayment && (
        <CreditCardPaymentStep
          memberId={memberId}
          leftToPay={leftToPay}
          creditCardType={selectedType}
          paymentTypeId={paymentTypeId}
          onClose={onClose}
        />
      )}

      {currentStep === PMethodSteps.CheckingSavings && (
        <CheckingSavingsStep
          paymentTypeId={paymentTypeId}
          leftToPay={leftToPay}
          onClose={onClose}
          memberId={memberId}
        />
      )}

      {currentStep === PMethodSteps.OnAccount && (
        <OnAccountStep
          leftToPay={leftToPay}
          balance={balance}
          paymentTypeId={paymentTypeId}
          onClose={onClose}
        />
      )}
    </DialogComponent>
  );
};

export default InvoicePaymentWizard;
