// libraries
import React from 'react';
import { List as ImmutableList } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { getInitials } from 'helpers/common';
import cx from 'classnames';
import profilePlaceholder from 'img/photo-placeholder.png';
import SearchUsersAutocompleteSelect from '../../../SearchUsersAutocomplete/SearchUsersAutocomplete';
import { IInvoiceDetailsImt } from '../../../../interfaces/invoices';
import { IEmployeeCommon } from 'modules/employees/interfaces';
// state
import * as actions from '../../../../state/invoice/actions';
import * as selectors from '../../../../state/invoice/selectors';
// messages
import inputLabels from '../../../../messages/inputLabels';
import searchInputMessages from '../../../SearchUsersAutocomplete/messages';
import { PeakModules } from 'common/constants/peakModules';

interface IInvoiceSalespersonSelectorProps {
  selectedInvoice?: IInvoiceDetailsImt;
  module: PeakModules;
  isPaymentStep?: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  optionAvatar: {
    height: 24,
    width: 24,
    marginRight: theme.spacing(1),
  },
  avatarWrap: {
    position: 'relative',
    flex: 'none',
    borderRadius: '50%',
    backgroundColor: theme.palette.secondary.light,
  },
  avatarInitial: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    fontSize: '12px',
    fontWeight: 700,
    color: theme.palette.darkBackground?.light,
  },
  text: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    alignSelf: 'center',
    fontSize: theme.typography.h5.fontSize,
  },
}));

const InvoiceSalespersonSelector: React.FC<IInvoiceSalespersonSelectorProps> = (
  props: IInvoiceSalespersonSelectorProps,
): JSX.Element => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { selectedInvoice, module, isPaymentStep = false, disabled } = props;

  // global state
  const dispatch = useDispatch();

  const optionsSalesPerson: ImmutableList<IEmployeeCommon> = useSelector(
    selectors.selectSalesPersonData(),
  );
  const isUsersListLoading: boolean = useSelector(selectors.selectUsersListLoading());

  // handlers

  const handleSelectSalesperson = (selectedSales): void => {
    dispatch(
      actions.setSalespersonToInvoice(
        selectedInvoice.get('id'),
        selectedSales.id,
        isPaymentStep,
        module,
      ),
    );
  };

  // renders

  return (
    <SearchUsersAutocompleteSelect
      renderOption={option => {
        const initials = getInitials(option.firstName, option.lastName);
        return (
          <>
            <Box mr={12} className={cx(classes.avatarWrap, classes.optionAvatar)}>
              {initials && !option.imageUrl ? (
                <Box className={classes.avatarInitial}>{initials}</Box>
              ) : (
                <Avatar
                  src={option.imageUrl || profilePlaceholder}
                  alt="avatar-icon"
                  className={classes.optionAvatar}
                />
              )}
            </Box>
            <Box className={classes.text}>{`${option.firstName} ${option.lastName}`}</Box>
          </>
        );
      }}
      selectedValue={selectedInvoice?.get('salesperson')?.toJS()}
      loading={isUsersListLoading}
      onChange={handleSelectSalesperson}
      getOptionsByValue={event => {
        dispatch(actions.fetchDataEmployees(event, module, isPaymentStep));
      }}
      hasStartSearchMode
      resetOptions={() => dispatch(actions.resetDataEmployees())}
      hideNOS
      label={<FormattedMessage {...inputLabels.salesperson} />}
      searchPlaceholder={intl.formatMessage({ ...searchInputMessages.searchSalesperson })}
      options={optionsSalesPerson.toJS()}
      error={!selectedInvoice?.get('salesperson')}
      disabled={disabled}
    />
  );
};

export default React.memo(InvoiceSalespersonSelector);
