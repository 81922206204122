import { ImmutableObject } from 'common/state/interfaces';
import { DurationUnit } from 'common/interfaces/common';
import { IAlertSoundItem } from 'modules/dictionaries/interfaces/interfaces';

export enum AlertCondition {
  // predefined alerts
  Anniversary = 'ANNIVERSARY',
  Birthday = 'BIRTHDAY',
  MissingPhoto = 'MISSING_PHOTO',
  MissingFirstName = 'MISSING_FIRST_NAME',
  MissingLastName = 'MISSING_LAST_NAME',
  MissingBirthDate = 'MISSING_BIRTH_DATE',
  MissingSSN = 'MISSING_SSN',
  MissingPhone = 'MISSING_PHONE_NUMBER',
  MissingEmail = 'MISSING_EMAIL',
  MissingAddress = 'MISSING_ADDRESS',
  NotRegisteredForClubLife = 'NOT_REGISTERED_FOR_CLUB_LIFE',
  CreditCardExpired = 'CREDIT_CARD_EXPIRED',
  UnsignedWaiver = 'UNSIGNED_WAIVER',
  MemberWillExpireSoon = 'MEMBER_WILL_EXPIRE_SOON',
  MemberIsFrozen = 'MEMBER_IS_FROZEN',
  PastDuePayments = 'PAST_DUE_PAYMENTS',
  NotAllowedAtThisLocation = 'NOT_ALLOWED_AT_THIS_LOCATION',
  NotAllowedAtThisDayTime = 'NOT_ALLOWED_AT_THIS_DAY_TIME',
  InactiveMember = 'INACTIVE_MEMBER',
  // custom alerts
  UpcomingAppointment = 'UPCOMING_APPOINTMENT',
  MissedAppointment = 'MISSED_APPOINTMENT',
  Note = 'NOTE',
  MissingEmergencyContactInfo = 'MISSING_EMERGENCY_CONTACT_INFO',
  MissingBillingInfo = 'MISSING_BILLING_INFO',
  MissingInsuranceInfo = 'MISSING_INSURANCE_INFO',
  MissingAllergies = 'MISSING_ALLERGIES',
  MissingSalesperson = 'MISSING_SALESPERSON',
}

export enum AlertTypes {
  Danger = 'DANGER',
  Warning = 'WARNING',
  Info = 'INFO',
  Success = 'SUCCESS',
}

export interface IAlertDurationSettings {
  durationUnit: DurationUnit;
  number: number;
}

export interface IAlertSettings {
  id?: string;
  predefined: boolean;
  active: boolean;
  title: string;
  condition: string;
  sound: boolean;
  type: AlertTypes;
  settings: IAlertDurationSettings | null;
}

export interface IAlertSettingsImt extends ImmutableObject<IAlertSettings> {
  get<K extends keyof IAlertSettings>(key: K): IAlertSettings[K];
}

export enum Preferences {
  DisableFrontDeskCheckInsSound = 'disableFrontDeskCheckInsSound',
  StopAtTheDoorCheckInsSound = 'stopAtTheDoorCheckInsSound',
  UsualCheckInsSound = 'usualCheckInsSound',
}

export interface IPreference {
  disableFrontDeskCheckInsSound: boolean;
  stopAtTheDoorCheckInsSound: boolean;
  usualCheckInsSound: boolean;
}

export interface IPreferencesImt extends ImmutableObject<IPreference> {
  get<K extends keyof IPreference>(key: K): IPreference[K];
}

export interface IAlertConfigurations {
  preferences: IPreference;
  settings: Array<IAlertSettings>;
  sounds: Array<IAlertSoundItem>;
}

export interface IRemindLaterDangerAlertRequestPayload {
  personId: number;
  alertCondition: AlertCondition;
  alertId: string;
  noteCodeId: string;
  text: string;
}
