import axios from 'axios';
import ApiService from './ApiService';

import {
  IBalanceHistoryItem,
  IBilling,
  ICheckInHistory,
  ICommerceHubCredentials,
  IFamilyMember,
  IFamilyMemberCreateDTO,
  IPackageInstanceSubscription,
  IPackageInstanceSubscriptionUpdateDTO,
  IPastDue,
  IPaymentAccount,
  IPersonDocuments,
  IProfileInfo,
  IReferralShortDto,
  IRewardsHistoryItem,
  ISetReferredDto,
  ITicketDetailedDto,
  ITicketShortDto,
} from 'common/components/PersonProfile/interfaces';
import {
  IAllergy,
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
} from 'common/interfaces/additionalInfo';
import { ITableParams } from 'common/interfaces/table';
import { IPaginatedData } from 'common/interfaces/pagination';
import {
  IPayment,
  IServiceItemDetails,
  IServiceSectionData,
  InventoryInstanceDetailsDto,
} from 'common/interfaces/service';
import {
  CartUnitTypes,
  IAddInvoiceUnitDTO,
  IBalance,
  IInvoiceDetailsDto,
  IInvoicePaymentSplit,
  INotifications,
  PaymentAccountType,
  PaymentsType,
} from 'common/interfaces/invoices';
import {
  IAppointmentHistoryItem,
  IBookingEvent,
  IFetchSenderAvailabilityData,
  IListSenderAvailability,
  IRecentAppointmentItem,
} from 'modules/booking/interfaces';
import { IBundleView } from 'modules/services/interfaces/packages';
import { IPaymentMethodItem } from 'modules/pos-settings/interfaces/paymentMethods';
import { IAddCreditCard } from 'common/components/CreditCardData/interfaces';
import { IMemberAlert } from 'modules/front-desk/interfaces';
import { IAllergiesFormValues } from 'modules/members/interfaces';
import { ISuccessResponse } from 'common/interfaces/http';
import { INamedEntity, IObject } from 'common/interfaces/common';
import { IMemberPortalReferralCreateDto } from 'memberPortalModules/MemberProfile/interface';

export default class MemberPortalProfileService extends ApiService {
  public getMemberInfoView = (): Promise<Partial<IProfileInfo>> => {
    return axios.get(`/api/v1/member-portal/profile/view`);
  };

  public getEmergencyContact = (): Promise<ICustomerEmergencyContactDto> => {
    return axios.get('/api/v1/member-portal/profile/info/emergency-contact');
  };

  public updateEmergencyContact = (
    requestData: ICustomerEmergencyContactDto,
  ): Promise<ICustomerEmergencyContactDto> => {
    return axios.put('/api/v1/member-portal/profile/info/emergency-contact', requestData);
  };

  public getAllergies = (): Promise<IAllergy[]> => {
    return axios.get('/api/v1/member-portal/profile/info/allergy');
  };

  public updateAllergies = (requestData: IAllergiesFormValues): Promise<IAllergy> => {
    return axios.put('/api/v1/member-portal/profile/info/allergy', requestData);
  };

  public getInsurance = (): Promise<ICustomerInsuranceDto> => {
    return axios.get('/api/v1/member-portal/profile/info/insurance');
  };

  public updateInsurance = (requestData: ICustomerInsuranceDto): Promise<ICustomerInsuranceDto> => {
    return axios.put('/api/v1/member-portal/profile/info/insurance', requestData);
  };

  public getFamilyMembers = (): Promise<IFamilyMember[]> => {
    return axios.get('/api/v1/member-portal/profile/family-members');
  };

  public deleteFamilyMember = (personId: number, id: string): Promise<void> => {
    return axios.delete(`/api/v1/members/${personId}/family-members/${id}`);
  };

  public addFamilyMember = (personId: number, data: IFamilyMemberCreateDTO): Promise<void> => {
    return axios.post(`/api/v1/members/${personId}/service-section/family-members/add`, data);
  };

  public getRecentCheckInsHistory = (): Promise<Array<ICheckInHistory>> => {
    return axios.get('/api/v1/member-portal/profile/checkin-history/recent');
  };

  public getCheckInsHistory = (
    tableParams: ITableParams,
  ): Promise<IPaginatedData<ICheckInHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/member-portal/profile/checkin-history', { params: searchParams });
  };

  public getRecentPersonPayments = (): Promise<IPayment[]> => {
    return axios.get('/api/v1/member-portal/profile/member-payments/recent');
  };

  public getPersonPayments = (tableParams: ITableParams): Promise<IPaginatedData<IPayment>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/member-portal/profile/member-payments', { params: searchParams });
  };

  public voidPersonInvoice = (
    personId: number,
    invoiceId: string,
    reason: string,
  ): Promise<void> => {
    return axios.put(`api/v1/members/${personId}/member-payments/${invoiceId}/void`, { reason });
  };

  public getPaymentDetails = (invoiceId: string): Promise<IInvoiceDetailsDto> => {
    return axios.get(`/api/v1/member-portal/profile/member-payments/${invoiceId}`);
  };

  public getPersonAppointmentsHistory = (
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IAppointmentHistoryItem[]>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get('/api/v1/member-portal/profile/appointment', { params: searchParams });
  };

  public getRecentMemberAppointments = (): Promise<IRecentAppointmentItem[]> => {
    return axios.get('/api/v1/member-portal/profile/appointment/recent');
  };

  public getPersonAppointmentById = (
    appointmentId: string,
    appointmentDate: string,
  ): Promise<IBookingEvent> =>
    axios.get(`/api/v1/member-portal/profile/appointment/${appointmentId}`, {
      params: { eventStartDate: appointmentDate },
    });

  public fetchMembership = (packageId: string): Promise<IBundleView> => {
    return axios.get(`api/v1/member-portal/profile/membership/${packageId}`);
  };

  public getPersonServicesData = async (): Promise<IServiceSectionData> =>
    axios.get('/api/v1/member-portal/profile/service-section');

  public getPersonServiceDetails = (serviceId: string): Promise<IServiceItemDetails> =>
    axios.get(`api/v1/member-portal/profile/services/${serviceId}`);

  public getInventoryDetails = (inventoryId: string): Promise<InventoryInstanceDetailsDto> => {
    return axios.get(`api/v1/member-portal/profile/inventories/${inventoryId}`);
  };

  public getPersonDocuments = (): Promise<IPersonDocuments> =>
    axios.get('/api/v1/member-portal/profile/documents');

  public getPersonTickets = (): Promise<ITicketShortDto[]> => {
    return axios.get(`/api/v1/member-portal/profile/tickets`);
  };

  public getPersonTicket = (ticketId: number): Promise<ITicketDetailedDto> => {
    return axios.get(`/api/v1/member-portal/profile/tickets/${ticketId}`);
  };

  public getPersonPastDues = (requestOptions: ITableParams): Promise<IPaginatedData<IPastDue>> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/member-portal/profile/past-dues', { params: searchParams });
  };

  public getBillingSchedule = (requestOptions: ITableParams): Promise<IBilling> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);

    return axios.get('/api/v1/member-portal/profile/billing-schedules', { params: searchParams });
  };

  public fetchPaymentAccounts = (
    paymentAccountType?: PaymentAccountType,
    cardType?: PaymentsType,
  ): Promise<IPaymentAccount[]> => {
    const config = {
      params: {
        ...(cardType && { cardType }),
        ...(paymentAccountType && { paymentAccountType }),
      },
    };

    return axios.get('/api/v1/member-portal/profile/payment-accounts', config);
  };

  public addCheckingPaymentAccount = (
    data: Omit<IPaymentAccount, 'id'>,
  ): Promise<IPaymentAccount> => {
    return axios.post('/api/v1/member-portal/profile/payment-accounts/add-checking-saving', data);
  };

  public deletePaymentAccount = (paymentAccountId: string): Promise<void> =>
    axios.delete(`/api/v1/member-portal/profile/payment-accounts/${paymentAccountId}`);

  public fetchPersonSubscriptions = (): Promise<Array<IPackageInstanceSubscription>> =>
    axios.get('/api/v1/member-portal/profile/subscriptions');

  public fetchPersonPaymentMethods = (): Promise<IPaginatedData<IPaymentMethodItem>> =>
    axios.get('/api/v1/member-portal/profile/subscriptions/payment-methods');

  public addCreditCardPaymentAccount = (data: IAddCreditCard): Promise<IProfileInfo> => {
    const { expiredIn } = data;
    const expireSplit: string[] = expiredIn.split('/');
    const modifiedData: IObject = {
      ...data,
      number: data.cardNumber.replaceAll(' ', ''),
      expirationMonth: expireSplit[0],
      expirationYear: expireSplit[1],
    };
    delete modifiedData.expiredIn;
    return axios.post(
      '/api/v1/member-portal/profile/payment-accounts/add-credit-card',
      modifiedData,
    );
  };

  public updateMemberSubscriptions = (
    data: IPackageInstanceSubscriptionUpdateDTO[],
  ): Promise<void> => axios.put('/api/v1/member-portal/profile/subscriptions', data);

  public getMemberAlerts = (): Promise<IMemberAlert[]> =>
    axios.get('/api/v1/member-portal/profile/alerts');

  public addInvoiceUnit = (
    invoiceId: string,
    data: Partial<IAddInvoiceUnitDTO>,
    unitType: CartUnitTypes,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post('/api/v1/member-portal/pos/invoice/add-invoice-unit', data, {
      params: { type: unitType, invoiceId },
    });

  // invoices

  public cancelMemberInvoice = (invoiceId: string): Promise<ISuccessResponse> =>
    axios.delete(`/api/v1/member-portal/pos/invoice/${invoiceId}/cancel`);

  public sendNotifications = (invoiceId: string, data: INotifications): Promise<void> => {
    return axios.put(`/api/v1/member-portal/pos/invoice/${invoiceId}/send`, data);
  };

  public voidInvoice = (invoiceId: string, reason: string): Promise<ISuccessResponse> =>
    axios.put(`/api/v1/member-portal/pos/invoice/${invoiceId}/void`, { reason });

  public getMemberInvoice = (): Promise<IInvoiceDetailsDto> =>
    axios.get('api/v1/member-portal/pos/invoice');

  public getInvoiceUnitCount = (): Promise<number> =>
    axios.get('api/v1/member-portal/pos/invoice/amount');

  public deleteInvoiceUnit = (
    invoiceId: string,
    invoiceUnitId: string,
  ): Promise<IInvoiceDetailsDto> =>
    axios.delete(`/api/v1/member-portal/pos/invoice/${invoiceId}/invoice-unit/${invoiceUnitId}`);

  public fetchAvailablePaymentMethods = (registerId: string): Promise<IPaymentMethodItem[]> =>
    axios.get('/api/v1/member-portal/pos/default', { params: { registerId } });

  public addInvoicePaymentSplit = (
    invoiceId: string,
    invoicePaymentSplit: IInvoicePaymentSplit,
  ): Promise<IInvoiceDetailsDto> =>
    axios.post(
      `/api/v1/member-portal/pos/invoice/${invoiceId}/invoice-payment-split`,
      invoicePaymentSplit,
    );

  public fetchBalanceById = (customerId: number): Promise<IBalance> => {
    return axios.get('api/v1/member-portal/pos/balance', { params: { customerId } });
  };

  public proceedFreeInvoice = (invoiceId: string): Promise<IInvoiceDetailsDto> =>
    axios.put(`/api/v1/member-portal/pos/invoice/${invoiceId}/proceed-free`);

  public getInvoiceReceipt = (invoiceId: string): Promise<string> => {
    return axios.get(`api/v1/member-portal/pos/invoice/${invoiceId}/receipt`);
  };

  public getSenderAvailability = async ({
    events,
  }: IFetchSenderAvailabilityData): Promise<IListSenderAvailability> => {
    return axios.get('/api/v1/member-portal/pos/sender-availability', {
      params: { types: events?.join(',') },
    });
  };

  public getPersonBalanceHistory = (customerId: number): Promise<IBalanceHistoryItem[]> => {
    return axios.get('api/v1/member-portal/profile/balance-history', {
      params: { customerId },
    });
  };

  public getPersonRewardsHistory = (): Promise<IRewardsHistoryItem[]> => {
    return axios.get('/api/v1/member-portal/profile/reward-points/history', {});
  };

  // referrals

  public getPersonReferrals = (): Promise<IReferralShortDto> => {
    return axios.get('/api/v1/member-portal/profile/referrals');
  };

  public createPersonReferral = (
    data: IMemberPortalReferralCreateDto,
  ): Promise<IMemberPortalReferralCreateDto> =>
    axios.post('/api/v1/member-portal/profile/referrals', data);

  public setReferredBy = (data: ISetReferredDto): Promise<IMemberPortalReferralCreateDto> =>
    axios.post('/api/v1/member-portal/profile/referrals/referred-by', data);

  public getReferralMembers = (search: string): Promise<INamedEntity[]> => {
    return axios.get('/api/v1/member-portal/profile/referrals/list', { params: { search } });
  };

  // Commerce Hub
  public fetchCommerceHubCredentials = (): Promise<ICommerceHubCredentials> => {
    return axios.get(`api/v1/member-portal/profile/commerce-hub/get-security-credentials`);
  };

  public tokenizeCommerceHubEncryptedCard = (data: IObject): Promise<IPaymentAccount[]> => {
    return axios.post(`api/v1/member-portal/profile/commerce-hub/tokenize-credit-card`, data);
  };
}
