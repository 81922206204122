import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

// hooks
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// interfaces
import { IMembershipInfoImt, MembershipStatus } from 'common/interfaces/membership';
// components
import { ActionItem, ActionsMenu } from 'common/components';
import {
  CancelMembershipModal,
  FreezeMembershipModal,
  ReactivateMembershipModal,
} from 'common/components/PersonProfile/modals';
// constants
import {
  FrequencyTypes,
  PackageCostType,
  PackageCostTypes,
  PackageType,
  PackageTypes,
} from 'modules/services/constants/packages';
import { DEFAULT_DATE_FORMAT, DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
// messages
import commonMessages from 'common/messages/messages';
import messages from 'common/components/PersonProfile/messages';
import frontDeskMessages from 'modules/front-desk/messages/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { QuestionnaireModal } from 'common/modals';
import ViewPackageInfoModal from '../ViewMembershipModal/ViewMembershipModal';
import { ActionResult } from 'common/constants';
import {
  selectAvailableMembershipPackageActions,
  selectAvailableMembershipPackageActionsLoading,
  selectCancelMembershipActionResult,
} from 'common/components/PersonProfile/state/membership/selectors';
import { IMembershipActionDTOImt } from 'common/components/PersonProfile/interfaces';
import { CheckInBlock } from 'common/components/PersonProfile/components/index';
import {
  fetchAvailableMembershipPackageActions,
  resetAvailableMembershipPackageActionsAction,
} from 'common/components/PersonProfile/state/membership/actions';
import { useAppDispatch } from 'store/hooks';

const useStyles = makeStyles((theme: Theme) => ({
  membershipCard: {
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      cursor: 'pointer',
    },
  },
  membershipTitle: {
    marginRight: theme.spacing(1),
  },
  autoRenewText: {
    marginLeft: theme.spacing(0.5),
  },
  wrapText: {
    wordWrap: 'break-word',
  },
  packageTypeChip: {
    height: '20px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.text.secondary,
    borderRadius: 10,

    fontSize: '0.75rem',
    fontWeight: 700,
  },
  actionsLoader: {
    justifyContent: 'center',

    '&:hover': {
      background: 'none',
    },
  },
}));

interface IProps {
  personId: number;
  module: PeakModuleForNewPersonType;

  membership: IMembershipInfoImt;
  openChangePackagePlan?: (id: number) => void;
  isOpenPOSPanel?: boolean;
  handlePOSPanelChange?: (isOpen: boolean) => void;
  handleNewMemberPanelChange?: (isOpen: boolean) => void;
  handleChangePackagePlanChange?: (isOpen: boolean) => void;
  disablePosBtn?: boolean;
}

const MembershipCard = ({
  personId,
  membership,
  module,
  openChangePackagePlan,
  handleChangePackagePlanChange,
  handleNewMemberPanelChange,
  handlePOSPanelChange,
  isOpenPOSPanel,
  disablePosBtn,
}: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const cancelMembershipActionResult: ActionResult = useSelector(
    selectCancelMembershipActionResult,
  );
  const availableMembershipPackageActions: IMembershipActionDTOImt = useSelector(
    selectAvailableMembershipPackageActions,
  );
  const isMembershipPackageActionsLoading: boolean = useSelector(
    selectAvailableMembershipPackageActionsLoading,
  );

  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isOpenViewMembershipModal, setIsOpenViewMembershipModal] = useState(false);
  const [isFreezeModalOpen, setIsFreezeModalOpen] = useState<boolean>(false);
  const [isReactivateModalOpen, setIsReactivateModalOpen] = useState<boolean>(false);
  const [isChangePlanConfirmationModalOpen, setIsChangePlanConfirmationModalOpen] = useState<
    boolean
  >(false);

  const shouldOpenChangePackagePlanModal = useRef(false);

  const [timezoneMoment] = useTimezoneMoment();

  const renderIntlMessage = useRenderIntlMessage();

  const classes = useStyles();

  const pricePerBilling = membership?.get('pricePerBilling');
  const paymentSchedule = membership?.get('paymentSchedule');
  const startDate = membership?.get('startDate');
  const endDate = membership?.get('endDate');
  const membershipId = membership?.get('id');
  const membershipStatus = membership?.get('status');
  const cancellationDate = membership?.get('cancellationDate');

  const isActiveMembership = membershipStatus === MembershipStatus.ACTIVE;

  useEffect(() => {
    if (
      cancelMembershipActionResult === ActionResult.SUCCESS_ACTION &&
      shouldOpenChangePackagePlanModal.current
    ) {
      openChangePackagePlan(personId);
      shouldOpenChangePackagePlanModal.current = false;
    }
  }, [cancelMembershipActionResult, openChangePackagePlan, personId]);

  // handlers

  const handleOpenChangePackagePlanModal = useCallback(() => {
    if (isActiveMembership) {
      setIsChangePlanConfirmationModalOpen(true);
    } else {
      openChangePackagePlan(personId);
    }
  }, [isActiveMembership, openChangePackagePlan, personId]);

  const onToggleCancelMembershipModal = useCallback(() => {
    setIsCancelModalOpen(!isCancelModalOpen);
  }, [isCancelModalOpen]);

  const onToggleFreezeMembershipModal = useCallback(
    () => setIsFreezeModalOpen(!isFreezeModalOpen),
    [isFreezeModalOpen],
  );

  const onToggleReactivateMembershipModal = useCallback(
    () => setIsReactivateModalOpen(!isReactivateModalOpen),
    [isReactivateModalOpen],
  );

  const fetchAvailableActions = useCallback(() => {
    dispatch(fetchAvailableMembershipPackageActions(personId, module));
  }, [personId, module, dispatch]);

  const resetAvailableActions = useCallback(() => {
    dispatch(resetAvailableMembershipPackageActionsAction());
  }, [dispatch]);

  // renders

  const renderActions = () => (
    <>
      {!!membership?.size && (
        <ActionsMenu
          horizontal
          tableActionsMode
          iconColor="primary"
          onOpen={fetchAvailableActions}
          onClose={resetAvailableActions}
        >
          {isMembershipPackageActionsLoading && (
            <ActionItem onClick={null} className={classes.actionsLoader}>
              <CircularProgress color="primary" size="1rem" />
            </ActionItem>
          )}

          {!isMembershipPackageActionsLoading && (
            <>
              <ActionItem
                onClick={handleOpenChangePackagePlanModal}
                disabled={!availableMembershipPackageActions?.get('canChangePlan')}
              >
                <FormattedMessage {...messages.changePlanModalTitle} />
              </ActionItem>

              <ActionItem
                onClick={onToggleCancelMembershipModal}
                disabled={!availableMembershipPackageActions?.get('canCancel')}
              >
                <FormattedMessage {...messages.cancelMembershipModalTitle} />
              </ActionItem>

              <ActionItem
                onClick={onToggleFreezeMembershipModal}
                disabled={!availableMembershipPackageActions?.get('canFreeze')}
              >
                <FormattedMessage {...messages.freezeMembershipModalTitle} />
              </ActionItem>

              <ActionItem
                onClick={onToggleReactivateMembershipModal}
                disabled={!availableMembershipPackageActions?.get('canReactivate')}
              >
                <FormattedMessage {...messages.reactivateMembershipModalTitle} />
              </ActionItem>
            </>
          )}
        </ActionsMenu>
      )}
    </>
  );

  const onOpenViewPackage = () => {
    setIsOpenViewMembershipModal(true);
  };

  return (
    <CheckInBlock
      headerDivider
      title={<FormattedMessage {...commonMessages.membership} />}
      buttonGroup={renderActions()}
    >
      {membership?.size ? (
        <Box onClick={onOpenViewPackage} className={classes.membershipCard}>
          <Box mt={1.5}>
            <Typography gutterBottom>
              <Typography component="span" variant="h5" className={classes.membershipTitle}>
                {membership.get('title')}
              </Typography>

              <Typography
                component="span"
                className={classes.wrapText}
                variant="button"
                color="textSecondary"
              >
                {`(${renderIntlMessage(
                  isActiveMembership ? commonMessages.activeOption : commonMessages.inactiveOption,
                )})`}
              </Typography>
            </Typography>

            {membership.get('type') === PackageType.Trial && (
              <Box display="flex" alignItems="cemter" mt={0.5} mb={1} gridGap={8}>
                <Chip
                  size="small"
                  label={PackageTypes.translate(PackageType.Trial)}
                  className={classes.packageTypeChip}
                />

                {membership.get('costType') === PackageCostType.Free && (
                  <Chip
                    size="small"
                    label={PackageCostTypes.translate(PackageCostType.Free)}
                    className={classes.packageTypeChip}
                  />
                )}
              </Box>
            )}

            {!!pricePerBilling && (
              <Typography component="p" gutterBottom>
                <Typography component="span">{`$${pricePerBilling.toFixed(2)}`}</Typography>

                {paymentSchedule && (
                  <Typography component="span">
                    /{FrequencyTypes.translate(paymentSchedule)}
                  </Typography>
                )}
              </Typography>
            )}

            {!isActiveMembership && (
              <Typography component="p" gutterBottom>
                <FormattedMessage
                  {...commonMessages.reasonLabel}
                  values={{
                    reason: renderIntlMessage(
                      membership.get('canceled')
                        ? commonMessages.canceled
                        : frontDeskMessages.memberStatusExpiredMember,
                    ),
                  }}
                />
              </Typography>
            )}
          </Box>

          <Box>
            {!!(startDate && endDate) && (
              <Typography color="textSecondary" gutterBottom component="p">
                {`${timezoneMoment(startDate).format(DEFAULT_DATE_FORMAT)} - ${timezoneMoment(
                  endDate,
                ).format(DEFAULT_DATE_FORMAT)}`}
              </Typography>
            )}
          </Box>

          {cancellationDate && (
            <Typography variant="caption" color="error">
              <FormattedMessage
                {...messages.cancellationDate}
                values={{
                  date: timezoneMoment(cancellationDate).format(DEFAULT_DATE_TIME_FORMAT),
                }}
              />
            </Typography>
          )}
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gridGap={16} mt={2}>
          <Typography color="textSecondary">
            <FormattedMessage {...commonMessages.noActiveMembershipTitle} />
          </Typography>

          <Button variant="contained" fullWidth onClick={handleOpenChangePackagePlanModal}>
            <FormattedMessage {...commonMessages.addMembershipBtn} />
          </Button>
        </Box>
      )}

      {isCancelModalOpen && (
        <CancelMembershipModal
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          disablePosBtn={disablePosBtn}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handlePOSPanelChange={handlePOSPanelChange}
          isOpenPOSPanel={isOpenPOSPanel}
          isOpen
          personId={personId}
          membershipId={membershipId}
          onCancel={onToggleCancelMembershipModal}
          module={module}
          isMembershipCard
        />
      )}

      {isFreezeModalOpen && (
        <FreezeMembershipModal
          module={module}
          isOpen={isFreezeModalOpen}
          personId={personId}
          onSubmit={onToggleFreezeMembershipModal}
          onCancel={onToggleFreezeMembershipModal}
          isMembershipCard
        />
      )}

      {isReactivateModalOpen && (
        <ReactivateMembershipModal
          handleChangePackagePlanChange={handleChangePackagePlanChange}
          disablePosBtn={disablePosBtn}
          handleNewMemberPanelChange={handleNewMemberPanelChange}
          handlePOSPanelChange={handlePOSPanelChange}
          isOpenPOSPanel={isOpenPOSPanel}
          isOpen={isReactivateModalOpen}
          personId={personId}
          onSubmit={onToggleReactivateMembershipModal}
          onCancel={onToggleReactivateMembershipModal}
          module={module}
          isMembershipCard
        />
      )}

      {isOpenViewMembershipModal && (
        <ViewPackageInfoModal
          module={module}
          isOpen
          packageId={membership?.get('id')}
          personId={personId}
          onClose={() => setIsOpenViewMembershipModal(false)}
        />
      )}

      {isChangePlanConfirmationModalOpen && (
        <QuestionnaireModal
          isOpen={isChangePlanConfirmationModalOpen}
          onClose={() => setIsChangePlanConfirmationModalOpen(false)}
          onSubmit={() => {
            setIsCancelModalOpen(true);
            setIsChangePlanConfirmationModalOpen(false);
            shouldOpenChangePackagePlanModal.current = true;
          }}
          body={<FormattedMessage {...messages.changePlanConfirmationMessage} />}
          submitBtnTitle={<FormattedMessage {...commonMessages.yesOption} />}
          cancelBtnTitle={<FormattedMessage {...commonMessages.noOption} />}
        />
      )}
    </CheckInBlock>
  );
};

export default MembershipCard;
