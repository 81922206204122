import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { DialogComponent, Table } from 'common/components';
import { IHeadCell, ITableParams, ITableRow } from 'common/interfaces/table';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { FilterTypes, IFilterSettings } from 'common/interfaces/filter';
import tableFilters from 'common/messages/tableFilters';
import { makeTableParams } from 'common/utils/http';
import { TableOrderByParams } from 'common/constants';
import { useAppDispatch } from 'store/hooks';
import {
  fetchInventoryRedeemHistory,
  resetInventoryRedeemHistory,
} from 'common/components/PersonProfile/state/servicesModals/actions';
import * as selectors from 'common/components/PersonProfile/state/servicesModals/selectors';
import { PeakModules } from 'common/constants/peakModules';

interface IRedeemingHistoryModalProps {
  module: PeakModules;
  isOpen: boolean;
  profileId: number;
  onClose: () => void;
}

const headerOptions: IHeadCell[] = [
  { id: 'amount', label: <FormattedMessage {...commonMessages.items} />, sort: true },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: true },
  { id: 'redeemed', label: <FormattedMessage {...tableHeaders.redeemed} />, sort: false },
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: true },
];

const RedeemingInventoryHistoryModal = (props: IRedeemingHistoryModalProps): JSX.Element => {
  const { isOpen, onClose, profileId, module } = props;

  const dispatch = useAppDispatch();

  const inventoryRedeemHistory = useSelector(selectors.selectInventoryRedeemHistory);
  const inventoryRedeemHistoryLoading = useSelector(selectors.selectInventoryRedeemHistoryLoading);
  const selectedInventoryInstanceId = useSelector(selectors.selectSelectedInventoryId);

  const redeemHistoryMeta = inventoryRedeemHistory?.get('meta');
  const redeemHistory = inventoryRedeemHistory?.get('data');

  const tableFilterSettings: IFilterSettings[] = useMemo(
    () => [
      {
        name: '',
        title: <FormattedMessage {...tableFilters.period} />,
        type: FilterTypes.DATE_RANGE,
        options: {
          startDate: null,
          endDate: null,
        },
      },
    ],
    [],
  );

  const [tableParams, setTableParams] = useState(() =>
    makeTableParams(tableFilterSettings, null, {
      orderBy: TableOrderByParams.DATE,
      order: 'desc',
    }),
  );

  const [timezoneMoment] = useTimezoneMoment();

  useEffect(() => {
    dispatch(
      fetchInventoryRedeemHistory(profileId, selectedInventoryInstanceId, tableParams, module),
    );
  }, [tableParams, dispatch, profileId, selectedInventoryInstanceId, module]);

  useEffect(() => {
    return () => {
      dispatch(resetInventoryRedeemHistory());
    };
  }, [dispatch]);

  const tableRows: ITableRow[] = useMemo(
    () =>
      redeemHistory
        ?.map(redeemItem => ({
          id: redeemItem.get('id'),
          cells: [
            {
              label: redeemItem.get('amount'),
            },
            {
              label: `${redeemItem.getIn(['customer', 'title'])}`,
            },
            {
              label: `${redeemItem.getIn(['employee', 'title'])}`,
            },
            {
              label: `${timezoneMoment(redeemItem.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}`,
            },
          ],
        }))
        .toJS() || [],
    [redeemHistory, timezoneMoment],
  );

  const onChangeTableParams = useCallback((tableProps: ITableParams): void => {
    setTableParams(tableProps);
  }, []);

  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title="Redeem history"
      hideFooterButtons
      scroll="paper"
      supressBottomShadow
      size="xl"
    >
      <Table
        rows={tableRows}
        headerOptions={headerOptions}
        showPerPageSelect
        isLoading={inventoryRedeemHistoryLoading}
        page={redeemHistoryMeta?.get('page')}
        totalRows={redeemHistoryMeta?.get('total')}
        onChangeParams={onChangeTableParams}
        filters={tableFilterSettings}
        tableParams={tableParams}
      />
    </DialogComponent>
  );
};

export default RedeemingInventoryHistoryModal;
