import React, { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';
import { Box, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import {
  BusinessCenter as WorkIcon,
  Email as EmailIcon,
  LocationOn,
  PhoneIphone as PhoneIcon,
} from '@material-ui/icons';
import { ReactComponent as DotsIcon } from 'img/icons/dots-horizontal.svg';

import { HomeIcon } from 'img/icons';
import { debounce, formatPersonalCode, isStillInTimeGap } from 'common/utils';

import { getPersonAgeString } from 'common/utils/time';
import usePlayAlertSound from 'modules/front-desk/hooks/usePlayAlertSound';
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { genderTypeLabels } from 'common/constants';
import { PeakModules } from 'common/constants/peakModules';

import { PhoneTypes } from 'common/components/PersonProfile/constants';
import {
  IAdditionalFieldsImt,
  IMemberPhoneItemImt,
  IProfileInfoImt,
  ITag,
} from 'common/components/PersonProfile/interfaces';

import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { ReactComponent as Barcode } from 'img/icons/barcode.svg';
import { ReactComponent as EditIcon } from 'img/icons/pencil_deprecated.svg';
import { ReactComponent as EyeIcon } from 'img/icons/eye_deprecated.svg';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import GuestStatus from 'modules/front-desk/components/GuestStatus/GuestStatus';
import { ActionItem, ActionsMenu, Button } from 'common/components';
import { Tags } from 'modules/front-desk/components/index';
import MemberPhoneItem from 'modules/members/components/MembersTable/MemberPhoneItem/MemberPhoneItem';
import AdditionalInfo from 'common/components/PersonProfile/components/AdditionalInfo/AdditionalInfo';

import StatusSelect from '../StatusSelect/StatusSelect';
import componentMessages from 'common/components/PersonProfile/messages';
import frontDeskMessages from 'modules/front-desk/messages';
import leadMessages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import messages from 'common/messages/messages';
import { DEFAULT_DATE_FORMAT } from 'common/constants/dateFormats';
import { ProfileActionId } from 'common/enums/html.enums';
import usePermissionContext from 'common/hooks/context/usePermissionContext';

const useStyles = makeStyles((theme: Theme) => ({
  profileName: {
    fontSize: ({ isLessThanLaptop }: Partial<IProfileDataProps>) =>
      isLessThanLaptop ? '1.25rem' : '1.375rem',
  },
  checkInTitle: {
    display: 'flex',
    alignItems: 'center',

    '&.checkinTitle__small': {
      fontSize: '0.75rem',
    },
  },
  checkInTime: {
    margin: theme.spacing(0, 1, 0, 0.5),
  },
  smallBarcodeIcon: {
    width: 14,
    height: 14,
  },
  personId: {
    marginLeft: theme.spacing(3),
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3.75),
  },
  homePhonesWrapper: {
    position: 'relative',
    paddingLeft: theme.spacing(3),
  },
  profileStatus: {
    display: 'inline',

    '&.lessThanLaptop': {
      display: 'block',
      margin: theme.spacing(0.5, 0, 0, 0),
    },
  },
  joinDateWrapper: {
    display: 'inline',
    marginLeft: theme.spacing(3),

    '&.lessThanLaptop': {
      display: 'block',
      margin: theme.spacing(0.5, 0, 0, 0),
    },
  },
  editIcon: {
    marginRight: theme.spacing(0.75),

    '& svg': {
      width: 16,
      height: 16,
    },
  },
  contactInfoIcon: {
    width: 18,
    height: 18,
    marginRight: theme.spacing(0.5),
    verticalAlign: 'middle',
    color: theme.palette.text.secondary,
  },
  address: {
    marginTop: theme.spacing(1.5),
  },
  mobileRegistration: {
    marginLeft: theme.spacing(2),
  },
  checkIcon: {
    width: '1rem',
    height: '1rem',
    marginRight: theme.spacing(0.5),
    color: theme.palette.primary.main,
    verticalAlign: 'bottom',
  },
}));

export interface IProfileDataProps {
  clientId: string;
  profile: IProfileInfoImt;
  additionalFields: IAdditionalFieldsImt;
  module: PeakModuleForNewPersonType;
  isLessThanLaptop?: boolean;
  isLessThanTablet?: boolean;
  isLessThanXs?: boolean;
  onChangeTags: (updatedTags: Array<Partial<ITag>>) => void;
  onCreateTag: (tag: Partial<ITag>) => void;
  onCheckInMember?: () => void;
  openEditProfile?: (id: number) => void;
  onStatusChange?: (status: string) => void;
  hideTags?: boolean;
  hideProfileMenu?: boolean;
}

const ProfileGeneralInfo: FC<IProfileDataProps> = ({
  clientId,
  profile,
  isLessThanLaptop,
  isLessThanTablet,
  isLessThanXs,
  module,
  onChangeTags,
  onCreateTag,
  onCheckInMember,
  additionalFields,
  openEditProfile,
  onStatusChange,
  hideProfileMenu,
  hideTags,
}) => {
  const renderIntlMessage = useRenderIntlMessage();
  const playAlertSound = usePlayAlertSound();
  const [timezoneMoment] = useTimezoneMoment();
  const navigate = useNavigate();
  const { CRM_LEAD_EDIT, PT_CRM_LEAD_EDIT } = usePermissionContext();
  const classes = useStyles({ isLessThanTablet, isLessThanLaptop });
  const profileId = profile.get('id');
  const checkInDate = profile.getIn(['lastCheckinDto', 'date']);
  const isAbleToCheckIn = checkInDate && isStillInTimeGap(checkInDate);
  const isCrmModule = module === PeakModules.Crm;
  const isPTCrmModule = module === PeakModules.PersonalTrainingCrm;
  let hasActionEditBtn = true;

  if (isCrmModule) {
    hasActionEditBtn = CRM_LEAD_EDIT;
  } else if (isPTCrmModule) {
    hasActionEditBtn = PT_CRM_LEAD_EDIT;
  }

  const onClickEditProfile = (): void => {
    if (module === PeakModules.FrontDesk) {
      document.dispatchEvent(new CustomEvent('closeNewMemberPanel'));
    }

    openEditProfile(profile.get('id'));
  };

  const handleCheckinMember = debounce(() => {
    onCheckInMember();

    playAlertSound(profile.get('alerts'));
  }, 500);

  const checkIsPhoneTypeExist = (phoneType: PhoneTypes) =>
    !!profile
      .get('phones')
      ?.find((phone: IMemberPhoneItemImt) => phone.get('phoneType') === phoneType);

  const crmActionEditMessage = isCrmModule ? leadMessages.editLeadTitle : null;
  const ptCrmActionEditMessage = isPTCrmModule ? ptCrmMessages.editPtLeadProfile : null;
  const crmMessage = crmActionEditMessage || ptCrmActionEditMessage;

  const actionsMenu = hideProfileMenu || (
    <ActionsMenu iconColor="primary" icon={<DotsIcon width={16} height={16} />}>
      {hasActionEditBtn ? (
        <ActionItem
          id={ProfileActionId.ProfileActionBtnEdit}
          icon={<EditIcon width={16} height={16} />}
          onClick={onClickEditProfile}
        >
          <FormattedMessage {...(crmMessage || messages.editProfileBtn)} />
        </ActionItem>
      ) : null}

      <ActionItem
        id={ProfileActionId.ProfileActionBtnView}
        icon={<EyeIcon />}
        onClick={() => navigate(`/members/${profileId}`)}
      >
        <FormattedMessage {...componentMessages.viewFullProfile} />
      </ActionItem>
    </ActionsMenu>
  );

  const editButton = (
    <>
      {!isLessThanTablet ? (
        <Button
          color="primary"
          classes={{ startIcon: classes.editIcon }}
          startIcon={<EditIcon />}
          onClick={onClickEditProfile}
        >
          <FormattedMessage {...messages.editProfileBtn} />
        </Button>
      ) : (
        <IconButton
          component={EditIcon}
          color="primary"
          size="small"
          onClick={onClickEditProfile}
        />
      )}
    </>
  );

  const profileActions = module === PeakModules.Members ? editButton : actionsMenu;

  const renderPhones = useCallback(
    (phoneType: PhoneTypes) => (
      <Grid container spacing={1} className={classes.homePhonesWrapper}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          top={4}
          left={4}
          mr={0.5}
        >
          {phoneType === PhoneTypes.Home && <HomeIcon className={classes.contactInfoIcon} />}
          {phoneType === PhoneTypes.Mobile && <PhoneIcon className={classes.contactInfoIcon} />}
          {phoneType === PhoneTypes.Work && <WorkIcon className={classes.contactInfoIcon} />}
        </Box>

        {!!profile.get('phones')?.size &&
          profile.get('phones').map((phone: IMemberPhoneItemImt) => {
            return (
              <React.Fragment key={phone.get('id')}>
                {phone.get('phoneType') === phoneType && (
                  <Grid item>
                    <MemberPhoneItem phoneData={phone.toJS()} />
                  </Grid>
                )}
              </React.Fragment>
            );
          })}
      </Grid>
    ),
    [classes, profile],
  );

  const isCrmOrPTCrmModule =
    module === PeakModules.Crm || module === PeakModules.PersonalTrainingCrm;

  return (
    <Box>
      <Box
        pl={isLessThanTablet && !isLessThanXs ? '116px' : 0}
        minHeight={isLessThanTablet && !isLessThanXs ? '102px' : 'initial'}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gridGap="16px">
            <Typography variant="h3" component="h4" className={classes.profileName}>
              {profile.get('firstName')} {profile.get('lastName')}
            </Typography>

            {isCrmOrPTCrmModule && isLessThanTablet === false && (
              <StatusSelect onChangeStatus={onStatusChange} status={profile.get('status')} />
            )}
          </Box>

          {module === PeakModules.FrontDesk ? (
            <>
              {isAbleToCheckIn ? (
                <Typography
                  className={cx(classes.checkInTitle, {
                    checkinTitle__small: isLessThanLaptop,
                  })}
                  variant="button"
                  component="p"
                  color="textSecondary"
                >
                  <Typography component="span" variant="inherit">
                    <FormattedMessage {...componentMessages.checkedIn} />
                  </Typography>

                  <Typography component="span" variant="inherit" className={classes.checkInTime}>
                    {timezoneMoment(checkInDate).format('h:mm A')}
                  </Typography>

                  <Barcode className={cx({ [classes.smallBarcodeIcon]: isLessThanLaptop })} />
                </Typography>
              ) : (
                <Button onClick={handleCheckinMember} color="primary">
                  <FormattedMessage {...messages.checkInBtn} />
                </Button>
              )}
            </>
          ) : (
            profileActions
          )}
        </Box>

        <Box display="flex" alignItems="center" justifyContent="space-between" mt={1}>
          <Box>
            <Typography
              component="p"
              className={cx(classes.profileStatus, {
                lessThanLaptop: isLessThanLaptop,
              })}
            >
              <GuestStatus variant="body1" type={profile.get('type')} />

              <Typography component="span" color="textSecondary" className={classes.personId}>
                <FormattedMessage
                  {...frontDeskMessages.ID}
                  values={{
                    personId: formatPersonalCode(profile.get('personalCode')),
                  }}
                />
              </Typography>
            </Typography>

            <Typography
              component="p"
              className={cx(classes.joinDateWrapper, {
                lessThanLaptop: isLessThanLaptop,
              })}
            >
              {profile.get('joinDate') && (
                <Typography component="span" color="textSecondary">
                  <FormattedMessage {...componentMessages.joinedOn} />
                  &nbsp;
                  {timezoneMoment(profile.get('joinDate')).format(DEFAULT_DATE_FORMAT)}
                </Typography>
              )}

              {profile.get('hasMobileSignIn') && !isCrmOrPTCrmModule && (
                <Typography
                  component="span"
                  color="textSecondary"
                  className={classes.mobileRegistration}
                >
                  <CheckIcon className={classes.checkIcon} />
                  <FormattedMessage {...componentMessages.hasMobileAppRegistration} />
                </Typography>
              )}
            </Typography>
          </Box>

          {module === PeakModules.FrontDesk && actionsMenu}
        </Box>
      </Box>

      <Box mt={1}>
        <Typography>
          {profile.get('gender') && (
            <Typography component="span">
              {renderIntlMessage(genderTypeLabels.message(profile.get('gender')))}
            </Typography>
          )}

          {!!profile.get('birthday') && (
            <Typography component="span">
              {`, ${getPersonAgeString(profile.get('birthday'))}`}
            </Typography>
          )}
        </Typography>

        <Box display="flex" mt={1.5} alignItems="flex-start">
          <Typography className={classes.email}>
            <EmailIcon className={classes.contactInfoIcon} />

            {profile.get('email') || '-'}
          </Typography>
        </Box>

        <Box display="flex" flexWrap="wrap">
          {checkIsPhoneTypeExist(PhoneTypes.Home) && (
            <Box mt={1.5} mr={1}>
              {renderPhones(PhoneTypes.Home)}
            </Box>
          )}

          {checkIsPhoneTypeExist(PhoneTypes.Mobile) && (
            <Box mt={1.5} mr={1}>
              {renderPhones(PhoneTypes.Mobile)}
            </Box>
          )}

          {checkIsPhoneTypeExist(PhoneTypes.Work) && (
            <Box mt={1.5}>{renderPhones(PhoneTypes.Work)}</Box>
          )}
        </Box>

        {!!profile.get('addressLine') && (
          <Typography className={classes.address}>
            <LocationOn className={classes.contactInfoIcon} />

            {profile.get('addressLine')}
          </Typography>
        )}
      </Box>

      {!hideTags && (
        <Box display="flex" alignItems="center" mt={2}>
          <Tags
            clientId={clientId}
            profileTags={profile.get('tags')}
            handleChangeTags={onChangeTags}
            handleCreateTag={onCreateTag}
          />
        </Box>
      )}

      {(module === PeakModules.FrontDesk ||
        module === PeakModules.Members ||
        module === PeakModules.Crm ||
        module === PeakModules.PersonalTrainingCrm) && (
        <AdditionalInfo
          additionalFields={additionalFields}
          profileId={profile.get('id')}
          module={module}
        />
      )}
    </Box>
  );
};

export default ProfileGeneralInfo;
