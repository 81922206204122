import * as yup from 'yup';

import { DurationType, IncludedPackageBillingType } from '../../../constants/packages';
import { RedeemType, ServiceType } from 'common/constants/service';
import errorMessages from 'common/errors/messages';
import { getRequiredMessage } from 'common/constants/globalConstants';
import { IPackageServiceBundle } from '../../../interfaces/packages';

const serviceSchema = yup.object().shape({
  id: yup.string().required(getRequiredMessage),
  title: yup.string().required(getRequiredMessage),
  type: yup
    .string()
    .oneOf(Object.values(ServiceType))
    .notRequired(),
  redeemType: yup
    .string()
    .oneOf(Object.values(RedeemType))
    .notRequired(),
  redeemDurationUnit: yup
    .string()
    .nullable()
    .oneOf([...Object.values(DurationType), null])
    .notRequired(),
});

const packageSchema = yup.object().shape({
  id: yup.string(),
  title: yup.string(),
});

const includedServiceSchema = yup.object().shape({
  id: yup.string().notRequired(),
  excludable: yup.boolean(),
  service: serviceSchema.nullable(),
  package: packageSchema.nullable(),
  hasCustomDayTimeAvailability: yup.boolean(),
  dayTimeAvailDtoList: yup.array().nullable(),
  limited: yup.boolean().when('service', {
    is: service => !!service,
    then: yup.boolean().required(getRequiredMessage),
  }),
  limitedRedeemNumber: yup
    .string()
    .nullable()
    .when(['service', 'limited'], {
      is: (service, limited) => !!service && limited,
      then: yup
        .string()
        .test(
          'limitedRedeemNumberValue',
          () => errorMessages.shouldBeMoreThanZero,
          value => {
            return value !== '0';
          },
        )
        .required(getRequiredMessage),
    }),
  billingType: yup
    .string()
    .nullable()
    .when('package', {
      is: pkg => !!pkg,
      then: yup
        .string()
        .oneOf(Object.values(IncludedPackageBillingType))
        .required(getRequiredMessage),
    }),
  billingAmount: yup
    .string()
    .nullable()
    .when(['package', 'billingType'], {
      is: (pkg, billingType) => !!pkg && billingType === IncludedPackageBillingType.OneTimePayment,
      then: yup
        .string()
        .test(
          'amount',
          () => errorMessages.shouldBeMoreThanZero,
          amount => {
            return String(amount) !== '0';
          },
        )
        .required(getRequiredMessage),
    }),
  billingOption: yup
    .object()
    .shape({ id: yup.string() })
    .nullable()
    .when(
      ['package', 'billingType'],
      (
        formPackage: IPackageServiceBundle | null,
        billingType: IncludedPackageBillingType | null,
        newSchema: yup.ObjectSchema<any>,
      ) => {
        if (
          formPackage &&
          billingType &&
          billingType === IncludedPackageBillingType.OwnBillingSettings
        ) {
          return newSchema.required(getRequiredMessage);
        }

        return newSchema;
      },
    ),
});

export const servicesSchema = yup.object().shape({
  includedServices: yup
    .array()
    .of(includedServiceSchema)
    .notRequired(),
});
