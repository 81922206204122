import React, { FC } from 'react';
import { Status } from 'common/components';
import { LeadStatuses } from 'modules/crm/constants/leads';

interface ILeadStatus {
  status?: string | null;
}

const LeadStatus: FC<ILeadStatus> = ({ status }) => {
  if (!status) {
    return null;
  }

  const leadStatus = LeadStatuses.find(status);

  if (!leadStatus) {
    return null;
  }

  return <Status color={leadStatus.color} label={LeadStatuses.translate(status)} />;
};

export default LeadStatus;
