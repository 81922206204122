import { createAction } from '@reduxjs/toolkit';
import { Moment } from 'moment-timezone';

import Services from 'services/network';

import { ICheckIn, ICheckInResponse } from '../../interfaces';

import {
  actionTypes,
  CURRENT_CHECKIN_TIME_MIN,
  UPDATE_CURRENTLY_CHECKING_IN,
} from 'modules/front-desk/constants';

import { setMainPanelPersonAction } from '../actions';
import { enqueueErrorNotification } from 'common/state/notifications/actions';
import { createPersonPrepAction } from 'common/components/PersonProfile/state/actions';
import { mapCheckInDto } from 'common/utils/responseMapper';
import { AlertTypes } from 'common/interfaces/alerts';
import { batch } from 'react-redux';
import { CustomerStatus } from 'modules/front-desk/constants/common/constants';
import { GeneralThunkAction } from 'common/state/interfaces';
import { ActionResult } from 'common/constants';

export const fetchRecentCheckInsAction = createAction<ICheckIn[]>(actionTypes.FETCH_CHECK_INS);
export const fetchRecentCheckInsLoading = createAction<boolean>(
  actionTypes.FETCH_CHECK_INS_LOADING,
);
export const fetchRecentCheckInsErrorAction = createAction<any>(actionTypes.FETCH_CHECK_INS_ERROR);
export const resetCurrentCheckinAction = createAction(actionTypes.RESET_CURRENT_CHECK_IN);

export const setSelectedCheckInAction = createAction<number>(actionTypes.SET_SELECTED_CHECK_IN);
export const resetSelectedCheckInAction = createAction(actionTypes.RESET_SELECTED_CHECK_IN);
export const setCurrentCheckInAction = createAction<ICheckIn>(actionTypes.SET_CURRENT_CHECK_IN);
export const updatePersonStatus = createPersonPrepAction<CustomerStatus>(
  actionTypes.UPDATE_PERSON_STATUS,
);
export const setProfileCurrentCheckInAction = createPersonPrepAction<ICheckIn>(
  UPDATE_CURRENTLY_CHECKING_IN,
);

export const resetRecentCheckInsAction = createAction(actionTypes.RESET_CHECK_INS);

export const fetchRecentCheckInsList = (
  clientId: string,
  entranceId: string,
  customerType: CustomerStatus = null,
  alertType: AlertTypes = null,
  timezoneMoment: (date?: string) => Moment,
  onSuccess?: () => void,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchRecentCheckInsLoading(true));

    try {
      const recentCheckIns = await Services.CheckIn.getRecentCheckIns(
        clientId,
        entranceId,
        customerType,
        alertType,
      );

      if (
        recentCheckIns.length &&
        timezoneMoment(recentCheckIns[0].checkInTime).isAfter(
          timezoneMoment().subtract(CURRENT_CHECKIN_TIME_MIN, 'minute'),
        )
      ) {
        const currentCheckIn = recentCheckIns.shift();
        dispatch(setCurrentCheckInAction(currentCheckIn));
      }

      dispatch(fetchRecentCheckInsAction(recentCheckIns));

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      dispatch(fetchRecentCheckInsErrorAction(error));
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchRecentCheckInsLoading(false));
    }
  };
};

export const setSelectedCheckIn = (personId: number): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setSelectedCheckInAction(personId));
    dispatch(setMainPanelPersonAction(personId));
  };
};

export const setCheckInPersonActionLoading = createAction<boolean>(
  actionTypes.SET_CHECK_IN_ACTION_LOADING,
);
const setCheckInPersonActionResult = createAction<ActionResult>(
  actionTypes.SET_CHECK_IN_ACTION_RESULT,
);
export const resetCheckInPersonActionResult = createAction(
  actionTypes.RESET_CHECK_IN_ACTION_RESULT,
);

export const checkInPerson = (
  clientId: string,
  entranceId: string,
  personId: number,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setCheckInPersonActionLoading(true));

    try {
      const isLockMode = localStorage.getItem('showNavigation');
      let checkInResponse: ICheckInResponse;

      if (isLockMode === null ? true : JSON.parse(isLockMode)) {
        checkInResponse = await Services.CheckIn.memberCheckIn(personId, entranceId);
      } else {
        checkInResponse = await Services.CheckIn.memberKioskModeCheckIn(personId, entranceId);
      }

      const mappedCheckIn = mapCheckInDto(checkInResponse);

      batch(() => {
        dispatch(setCheckInPersonActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(setCurrentCheckInAction(mappedCheckIn));
        dispatch(setProfileCurrentCheckInAction(mappedCheckIn, personId));
      });
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setCheckInPersonActionLoading(false));
    }
  };
};

export const barcodeCheckIn = (barcode: string): GeneralThunkAction => {
  return async dispatch => {
    try {
      await Services.CheckIn.barcodeCheckIn(barcode);
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    }
  };
};
