import axios from 'axios';

import {
  IEmployeeCredentialsUpdateDto,
  IEmployeeDto,
  IEmployeeForm,
  IEmployeeJobPosition,
  IEmployeeProfile,
  IEmployeeProfileDto,
  IEmployeeRole,
  ISecurityRoleToClubListDto,
} from 'common/interfaces/employee';
import { IPaginatedEmployees } from 'modules/employees/interfaces';
import { ITableParams } from 'common/interfaces/table';
import ApiService from './ApiService';
import {
  IModificationHistory,
  IModificationHistoryItem,
} from 'common/components/PersonProfile/interfaces';
import { IPaginatedData } from 'common/interfaces/pagination';
import { IAttachment } from 'common/interfaces/uploadFile';
import { IFormEmployeePermission } from '../../modules/employees/interfaces/employeeInterfaces';

export default class EmployeesService extends ApiService {
  public getEmployees = (requestOptions?: ITableParams): Promise<IPaginatedEmployees> => {
    const searchParams = this.getSearchFromTableParams(requestOptions);
    return axios.get('/api/v1/employees', { params: searchParams });
  };

  public getEmployeePermissions = (id: number): Promise<ISecurityRoleToClubListDto[]> => {
    return axios.get(`/api/v1/employees/${id}/permissions`);
  };

  public updateEmployeePermissions = (
    id: number,
    values: IFormEmployeePermission,
  ): Promise<void> => {
    return axios.put(`/api/v1/employees/${id}/permissions`, values);
  };

  public getEmployeeAttachments = (id: number): Promise<IAttachment[]> => {
    return axios.get(`/api/v1/employees/${id}/attachments`);
  };

  public updateEmployeeAttachments = (id: number, attachments: IAttachment[]): Promise<void> => {
    return axios.put(`/api/v1/employees/${id}/attachments`, attachments);
  };

  public deleteEmployeeAttachment = (id: number, attachmentId: string): Promise<void> => {
    return axios.delete(`/api/v1/employees/${id}/attachments/${attachmentId}`);
  };

  public uploadPhoto = async (id: number, image: IAttachment): Promise<void> => {
    await axios.put(`/api/v1/employees/${id}/avatar`, image);
  };

  public deletePhoto = (id: number): Promise<void> => {
    return axios.delete(`/api/v1/employees/${id}/avatar`);
  };

  public getEmployeeJobPositions = (id: number): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/employees/${id}/job-positions`);
  };

  public getEmployeeJobPositionsHistory = (id: number): Promise<IEmployeeJobPosition[]> => {
    return axios.get(`/api/v1/employees/${id}/job-positions?active=false`);
  };

  public updateEmployeeJobPositions = (
    id: number,
    data: { jobPositions: IEmployeeJobPosition[] },
  ): Promise<void> => {
    return axios.put(`/api/v1/employees/${id}/job-positions`, data);
  };

  public updateEmployeeCredentials = (
    id: number,
    credentials: IEmployeeCredentialsUpdateDto,
  ): Promise<void> => {
    return axios.put(`/api/v1/employees/${id}/credentials/custom`, credentials);
  };

  public getEmployeeById = (id: number): Promise<IEmployeeProfile> =>
    axios.get(`/api/v1/employees/${id}`);

  public getProfile = (id: number): Promise<IEmployeeDto> => {
    return axios.get(`/api/v1/employees/${id}/profile`);
  };

  public getProfileView = (id: number): Promise<IEmployeeProfileDto> => {
    return axios.get(`/api/v1/employees/${id}/profile/view`);
  };

  public getSecurityRoles = (): Promise<Array<IEmployeeRole>> =>
    axios.get(`/api/v1/security/role`, { params: { active: true } });

  // public updateEmployeeRoles = (
  //   id: string,
  //   requestData: Array<IEmployeePermissionSectionItem>,
  // ): Promise<any> => axios.post(`/api/v1/security/employee/${id}`, requestData);

  public createEmployee = (requestData: IEmployeeForm): Promise<IEmployeeProfile> =>
    axios.post(`/api/v1/employees`, requestData);

  public updateEmployee = (id: number, requestData: IEmployeeForm): Promise<IEmployeeProfile> =>
    axios.put(`/api/v1/employees/${id}`, requestData);

  public updateProfile = (requestData: IEmployeeDto): Promise<IEmployeeProfileDto> => {
    return axios.put(`/api/v1/employees/${requestData.id}/profile`, requestData);
  };

  public changeEmployeeStatus = (
    id: number,
    active: boolean,
    terminationDate?: string,
  ): Promise<IEmployeeProfile> => {
    const params = active ? { active, terminationDate } : { active };
    return axios.patch(`/api/v1/employees/${id}/change-status`, params);
  };

  // modification history
  public getModificationHistory = (
    personId: number,
    tableParams: ITableParams,
  ): Promise<IPaginatedData<IModificationHistory>> => {
    const searchParams = this.getSearchFromTableParams(tableParams);

    return axios.get(`/api/v1/employees/${personId}/modification-history`, {
      params: searchParams,
    });
  };

  public getModificationHistoryResent = (
    personId: number,
  ): Promise<Array<IModificationHistory>> => {
    return axios.get(`/api/v1/employees/${personId}/modification-history/recent`);
  };

  public getModificationHistoryItem = (
    personId: number,
    revisionId: number,
  ): Promise<IModificationHistoryItem> =>
    axios.get(`/api/v1/employees/${personId}/modification-history/${revisionId}`);
}
