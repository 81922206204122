import { createAction } from '@reduxjs/toolkit';

import { IPaginatedLeads } from 'services/network/LeadsService';
import Services from 'services/network';

import { actionTypes } from 'modules/crm/constants/leads';

import { ITableParams } from 'common/interfaces/table';
import {
  ILeadBulkSalespersonDto,
  ILeadBulkSalesStatusDto,
  ILeadProfile,
  INewLeadProfileData,
  TViewType,
} from 'modules/crm/interfaces/leads';
import { IStoredFileSimpleDto } from 'common/interfaces/uploadFile';
import { GeneralThunkAction } from 'common/state/interfaces';

import {
  enqueueErrorNotification,
  enqueueSuccessNotification,
} from 'common/state/notifications/actions';

import messages from 'modules/crm/messages/leads';
import { ActionResult } from 'common/constants';
import { batch } from 'react-redux';
import { IPersonSalesInfo } from 'common/components/PersonProfile/interfaces';
import { IDictionaryCommonItem } from 'modules/dictionaries/interfaces/interfaces';
import { fetchProfileInfoAction } from 'common/components/PersonProfile/state/actions';
import { PeakModules } from 'common/constants/peakModules';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { IObject } from 'common/interfaces/common';

const fetchLeadsListLoadingAction = createAction<boolean>(actionTypes.FETCH_LEADS_LIST_LOADING);
const fetchLeadsListSuccessAction = createAction<IPaginatedLeads>(
  actionTypes.FETCH_LEADS_LIST_SUCCESS,
);

export const fetchLeads = (
  module: PeakModules,
  tableParams?: ITableParams,
  viewType?: TViewType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchLeadsListLoadingAction(true));
    try {
      let paginatedData: IPaginatedLeads;
      switch (module) {
        case PeakModules.Crm:
          paginatedData = await Services.Leads.getLeads(tableParams, viewType === 'table');
          break;
        case PeakModules.PersonalTrainingCrm:
          paginatedData = await Services.PTLeads.getLeads(tableParams, viewType === 'table');
          break;
        default:
          paginatedData = null;
      }

      dispatch(fetchLeadsListSuccessAction(paginatedData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchLeadsListLoadingAction(false));
    }
  };
};

const createLeadProfileLoadingAction = createAction<boolean>(
  actionTypes.CREATE_LEAD_PROFILE_LOADING,
);
export const createLeadProfileSuccessAction = createAction<ILeadProfile>(
  actionTypes.CREATE_LEAD_PROFILE_SUCCESS,
);
export const createLeadProfileActionResult = createAction<ActionResult>(
  actionTypes.CREATE_LEAD_PROFILE_ACTION_RESULT,
);

export const createLeadProfile = (
  clubId: string,
  data: INewLeadProfileData,
  module: PeakModules.Crm | PeakModules.PersonalTrainingCrm | PeakModules.Members,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createLeadProfileLoadingAction(true));
    try {
      let profile: ILeadProfile;

      switch (module) {
        case PeakModules.Crm:
          profile = await Services.Leads.createLead(clubId, data);
          break;
        case PeakModules.PersonalTrainingCrm:
          profile = await Services.PTLeads.createLead(clubId, data);
          break;
        default:
          profile = await Services.Members.createMemberLead(clubId, data);
      }

      batch(() => {
        dispatch(createLeadProfileSuccessAction(profile));
        dispatch(createLeadProfileActionResult(ActionResult.SUCCESS_ACTION));
        dispatch(
          enqueueSuccessNotification(
            module === PeakModules.Members
              ? messages.memberCreatedMessage
              : messages.leadCreatedMessage,
          ),
        );
      });
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createLeadProfileLoadingAction(false));
    }
  };
};

const updateLeadProfileLoadingAction = createAction<boolean>(
  actionTypes.UPDATE_LEAD_PROFILE_LOADING,
);
const leadProfileActionResult = createAction<ILeadProfile>(actionTypes.LEAD_ACTION_RESULT);
export const resetLeadProfileActionResult = createAction(actionTypes.RESET_LEAD_ACTION_RESULT);

export const updateLeadProfile = (
  clubId: string,
  data: INewLeadProfileData,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateLeadProfileLoadingAction(true));
    try {
      let lead: ILeadProfile;

      if (module === PeakModules.Crm) {
        lead = await Services.Leads.updateLead(clubId, data);
      } else {
        lead = await Services.PTLeads.updateLead(clubId, data);
      }

      dispatch(leadProfileActionResult(lead));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateLeadProfileLoadingAction(false));
    }
  };
};

const fetchLeadProfileLoadingAction = createAction<boolean>(actionTypes.FETCH_LEAD_LOADING);
export const fetchLeadProfileSuccessAction = createAction<ILeadProfile>(
  actionTypes.FETCH_LEAD_SUCCESS,
);
export const resetLeadProfile = createAction(actionTypes.RESET_LEAD);

export const fetchLeadProfile = (
  id: number,
  module: PeakModules,
  isEditLead?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchLeadProfileLoadingAction(true));
    try {
      let profile;

      switch (module) {
        case PeakModules.Crm:
          profile = isEditLead
            ? await Services.Leads.getLead(id)
            : await Services.Leads.getLeadInfoView(id);
          break;
        default:
          profile = isEditLead
            ? await Services.PTLeads.getLead(id)
            : await Services.PTLeads.getLeadInfoView(id);
      }

      dispatch(fetchLeadProfileSuccessAction(profile));
      // TODO refactor after lead profile will be moved to persons state
      dispatch(fetchProfileInfoAction(profile, id));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchLeadProfileLoadingAction(false));
    }
  };
};

const fetchLeadSalesInfoLoadingAction = createAction<boolean>(
  actionTypes.FETCH_LEAD_PROFILE_SALES_INFO_LOADING,
);
export const fetchLeadSalesInfoSuccessAction = createAction<IPersonSalesInfo>(
  actionTypes.FETCH_LEAD_PROFILE_SALES_INFO_SUCCESS,
);

// TODO - not used
// export const resetLeadSalesInfo = createAction(actionTypes.RESET_LEAD_PROFILE_SALES_INFO);

export const fetchLeadFormSalesInfo = (leadId: number, module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchLeadSalesInfoLoadingAction(true));
    try {
      let salesInfo;

      switch (module) {
        case PeakModules.Crm:
          salesInfo = await Services.Leads.getLeadSalesInfo(leadId);
          break;
        default:
          salesInfo = await Services.PTLeads.getLeadSalesInfo(leadId);
      }

      dispatch(fetchLeadSalesInfoSuccessAction(salesInfo));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchLeadSalesInfoLoadingAction(false));
    }
  };
};

const assignSalespersonLeadLoadingAction = createAction<boolean>(
  actionTypes.SALESPERSON_LEAD_ASSIGN_LOADING,
);
const assignSalespersonLeadAction = createAction<IObject>(
  actionTypes.SALESPERSON_LEAD_ASSIGN_SUCCESS,
);

export const assignSalespersonLeads = (
  payload: ILeadBulkSalespersonDto,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(assignSalespersonLeadLoadingAction(true));
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.assignSalespersonLeads(payload);
      } else {
        await Services.PTLeads.assignSalespersonLeads(payload);
      }

      dispatch(assignSalespersonLeadAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(assignSalespersonLeadLoadingAction(false));
    }
  };
};

const changeLeadStatusLoadingAction = createAction<boolean>(actionTypes.LEAD_STATUS_CHANGE_LOADING);
const changeLeadStatusAction = createAction<IObject>(actionTypes.LEAD_STATUS_CHANGE_SUCCESS);

export const changeLeadsStatus = (
  payload: ILeadBulkSalesStatusDto,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changeLeadStatusLoadingAction(true));
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.changeLeadsStatus(payload);
      } else {
        await Services.PTLeads.changeLeadsStatus(payload);
      }

      dispatch(changeLeadStatusAction({ success: true }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changeLeadStatusLoadingAction(false));
    }
  };
};

export const changeLeadStatus = (
  leadId: number,
  newStatus: string,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(changeLeadStatusLoadingAction(true));
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.changeLeadStatus(leadId, newStatus);
      } else {
        await Services.PTLeads.changeLeadStatus(leadId, newStatus);
      }
      dispatch(changeLeadStatusAction({ success: true, newStatus }));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(changeLeadStatusLoadingAction(false));
    }
  };
};

const updateLeadAvatarLoadingAction = createAction<boolean>(actionTypes.UPDATE_LEAD_AVATAR_LOADING);
const updateLeadAvatarAction = createAction<IStoredFileSimpleDto>(actionTypes.UPDATE_LEAD_AVATAR);
export const updateLeadAvatar = (
  leadId: number,
  image: IStoredFileSimpleDto,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.updateLeadAvatar(leadId, image);
      } else {
        await Services.PTLeads.updateLeadAvatar(leadId, image);
      }
      dispatch(updateLeadAvatarAction(image));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    }
  };
};

export const deleteLeadAvatar = (
  leadId: number,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(updateLeadAvatarLoadingAction(true));
    try {
      if (module === PeakModules.Crm) {
        await Services.Leads.deleteLeadAvatar(leadId);
      } else {
        await Services.PTLeads.deleteLeadAvatar(leadId);
      }

      dispatch(updateLeadAvatarAction(null));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(updateLeadAvatarLoadingAction(false));
    }
  };
};

const fetchLeadActivitiesLoadingAction = createAction<boolean>(
  actionTypes.GET_LEAD_ACTIVITIES_LOADING,
);
const fetchLeadActivitiesAction = createAction<IDictionaryCommonItem[]>(
  actionTypes.GET_LEAD_ACTIVITIES,
);

export const fetchLeadActivities = (module: PeakModules): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchLeadActivitiesLoadingAction(true));
    try {
      let activities;

      switch (module) {
        case PeakModules.Crm:
          activities = await Services.Leads.getLeadActivities();
          break;
        case PeakModules.Members:
          activities = await Services.Members.getMemberActivities();
          break;
        default:
          activities = await Services.PTLeads.getLeadActivities();
      }

      dispatch(fetchLeadActivitiesAction(activities));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchLeadActivitiesLoadingAction(false));
    }
  };
};

const createLeadActivityActionLoading = createAction<boolean>(
  actionTypes.CREATE_LEAD_ACTIVITY_LOADING,
);
const createLeadActivityAction = createAction<IDictionaryCommonItem>(
  actionTypes.CREATE_LEAD_ACTIVITY_RESULT,
);

export const createLeadActivity = (
  data: IDictionaryCommonItem,
  module: PeakModuleForNewPersonType,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(createLeadActivityActionLoading(true));

    try {
      let activity: IDictionaryCommonItem;

      switch (module) {
        case PeakModules.Crm:
          activity = await Services.Leads.createLeadActivity(data);
          break;
        case PeakModules.Members:
          activity = await Services.Members.createMemberActivity(data);
          break;
        default:
          activity = await Services.PTLeads.createLeadActivity(data);
      }

      dispatch(createLeadActivityAction(activity));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(createLeadActivityActionLoading(false));
    }
  };
};

export const resetLeadsActionResult = createAction(actionTypes.RESET_LEADS_ACTION_RESULT);
