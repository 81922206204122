import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, createStyles, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';

import { PeakModules } from 'common/constants/peakModules';
import { RouteBackground } from 'components';
import { LeadProfile } from 'modules/crm/components';
import { selectPersonAdditionalFields } from 'common/components/PersonProfile/state/selectors';
import * as personActions from 'common/components/PersonProfile/state/actions';
import messages from 'modules/crm/messages/leads';
import ptCrmMessages from 'modules/personal-training-crm/messages/messages';
import { selectAdditionalInfoForUpdateResult } from 'common/state/newPerson/additionalInfo/selectors';
import useComponentDidUpdate from 'common/hooks/useComponentDidUpdate';
import { ActionResult } from 'common/constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      height: '100%',
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(0, 2),
      },
    },
    backButton: {
      display: 'inline-block',
      marginRight: theme.spacing(1),
      padding: 0,
      borderRadius: '50%',
      color: '#fff',
      backgroundColor: theme.palette.text.primary,
      '&:hover': {
        backgroundColor: theme.palette.text.primary,
      },
    },
  }),
);

const LeadProfilePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const personId = Number(id);

  const additionalFields = useSelector(selectPersonAdditionalFields(personId));
  const additionalInfoUpdateActionResult = useSelector(selectAdditionalInfoForUpdateResult);

  const classes = useStyles();

  const onBack = useCallback((): void => {
    const backUrl = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
    navigate(backUrl);
  }, [location.pathname, navigate]);

  const module =
    location.pathname.indexOf('personal-training-crm') === -1
      ? PeakModules.Crm
      : PeakModules.PersonalTrainingCrm;
  const isCrmModule = module === PeakModules.Crm;

  useEffect(() => {
    dispatch(personActions.getPersonAdditionalInfo(personId, module));
  }, [dispatch, personId, module]);

  useComponentDidUpdate(() => {
    if (additionalInfoUpdateActionResult === ActionResult.SUCCESS_ACTION) {
      dispatch(personActions.getPersonAdditionalInfo(personId, module));
    }
  }, [additionalInfoUpdateActionResult, id, module, dispatch]);

  return (
    <RouteBackground fullScreenView>
      <Box display="flex" flexDirection="column" className={classes.wrapper}>
        <Box className={classes.pageTitle} display="flex" alignItems="center">
          <IconButton
            color="primary"
            className={classes.backButton}
            aria-label="back"
            onClick={onBack}
          >
            <ArrowBackIcon style={{ fontSize: 16 }} />
          </IconButton>

          <Typography component="h1" variant="h2">
            <FormattedMessage
              {...(isCrmModule ? messages.leadProfileTitle : ptCrmMessages.ptLeadProfile)}
            />
          </Typography>
        </Box>

        <LeadProfile
          additionalFields={additionalFields}
          clientId="1"
          personId={personId}
          module={module}
        />
      </Box>
    </RouteBackground>
  );
};

export default LeadProfilePage;
