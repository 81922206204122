import React, { useMemo, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { Avatar, Grid, makeStyles } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import {
  InventoryInstanceDetailsDtoImt,
  InventoryRedeemHistoryDtoImt,
} from 'common/interfaces/service';
import RedeemingHistory from '../RedeemingHistory/RedeemingHistory';
import RedeemingInventoryHistoryModal from '../RedeemingInventoryHistoryModal/RedeemingInventoryHistoryModal';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { CustomTheme } from 'common/ui/interfaces';
import { FieldInfo } from 'common/components/index';
import inputLabels from 'common/messages/inputLabels';
import { IssueFrequencyType } from 'modules/services/constants/packages';
import { ConstOptions } from 'common/constants/classes';
import messages from 'common/components/PersonProfile/messages';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import { PeakModules } from 'common/constants/peakModules';

interface InventoryDetailsViewProps {
  module: PeakModules;
  profileId: number;
  inventoryInstance: InventoryInstanceDetailsDtoImt;
  redeemHistory: ImmutableList<InventoryRedeemHistoryDtoImt>;
}

const headerOptions: IHeadCell[] = [
  { id: 'items', label: <FormattedMessage {...commonMessages.items} />, sort: false },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: false },
  { id: 'redeemed', label: <FormattedMessage {...tableHeaders.redeemed} />, sort: false },
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: false },
];

const limitations = new ConstOptions([
  { value: IssueFrequencyType.OneTime, message: messages.oneTime },
  { value: IssueFrequencyType.PerCycle, message: messages.perCycle },
  { value: IssueFrequencyType.PerSession, message: messages.perSession },
]);

const useStyles = makeStyles((theme: CustomTheme) => ({
  detailsContainer: {
    marginBottom: theme.spacing(3),
  },
  productImage: {
    width: 176,
    height: 176,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const InventoryDetailsView = (props: InventoryDetailsViewProps): JSX.Element => {
  const { inventoryInstance, redeemHistory, profileId, module } = props;

  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);

  const [timezoneMoment] = useTimezoneMoment();

  const classes = useStyles();

  const tableRows: ITableRow[] = useMemo(
    () =>
      redeemHistory
        ?.map(redeemItem => ({
          id: redeemItem.get('id'),
          cells: [
            {
              label: redeemItem.get('amount'),
            },
            {
              label: `${redeemItem.getIn(['customer', 'title'])}`,
            },
            {
              label: `${redeemItem.getIn(['employee', 'title'])}`,
            },
            {
              label: `${timezoneMoment(redeemItem.get('date')).format(DEFAULT_DATE_TIME_FORMAT)}`,
            },
          ],
        }))
        .toJS() || [],
    [redeemHistory, timezoneMoment],
  );

  const expirationDate = inventoryInstance.get('expirationDate');
  const expirationDateWithTimezone = expirationDate
    ? timezoneMoment(expirationDate).format(DEFAULT_DATE_TIME_FORMAT)
    : '-';

  return (
    <>
      <Grid container spacing={2} className={classes.detailsContainer}>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.expirationDate} />}
            description={expirationDateWithTimezone}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.limitation} />}
            description={limitations.translate(inventoryInstance.get('frequency'))}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.barcode} />}
            description={inventoryInstance.get('barcode') || '-'}
          />
        </Grid>
        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.categories} />}
            description={inventoryInstance.get('category') || '-'}
          />
        </Grid>
        <Avatar
          className={classes.productImage}
          src={inventoryInstance.get('imageUrl')}
          alt={inventoryInstance.get('title')}
          variant="square"
        >
          <DefaultIcon />
        </Avatar>
        <Grid item xs={12}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.description} />}
            description={inventoryInstance.get('description')}
            hasNotEllipsesDescription
          />
        </Grid>
      </Grid>
      <RedeemingHistory
        headerOptions={headerOptions}
        onViewAllHistory={() => setIsOpenHistoryModal(true)}
        tableRows={tableRows}
      />
      {isOpenHistoryModal && (
        <RedeemingInventoryHistoryModal
          module={module}
          profileId={profileId}
          isOpen={isOpenHistoryModal}
          onClose={() => setIsOpenHistoryModal(false)}
        />
      )}
    </>
  );
};

export default InventoryDetailsView;
