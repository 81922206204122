import { List as ImmutableList } from 'immutable';
import { ImmutableObject } from 'common/state/interfaces';
import { AppointmentTypes } from 'common/components/PersonProfile/constants';
import {
  CheckInTypes,
  FreezePackageInfo,
  IAdditionalFieldsImt,
  ICustomerShort,
  INote,
  INoteImt,
  IPastDue,
  IPersonDocumentGroupItem,
  IPersonDocumentGroupItemImt,
  IProfileAdditional,
} from 'common/components/PersonProfile/interfaces';

import { AlertCondition, AlertTypes } from 'common/interfaces/alerts';
import { CustomerStatus } from './constants/common/constants';
import {
  IAllergy,
  ICustomerEmergencyContactDto,
  ICustomerInsuranceDto,
  TCustomerEmergencyContactDtoImt,
  TCustomerInsuranceDtoImt,
} from 'common/interfaces/additionalInfo';
import { INamedEntity, ReminderPeriodType } from 'common/interfaces/common';
import { IServiceListInfo } from 'common/interfaces/service';
import { IEventPerson } from 'modules/booking/interfaces';

export interface ICheckinResponseMember extends ICustomerShort {
  personalCode: number;
  barcode: string;
  clubId: string;
}

export interface ICheckinResponseMemberImt extends ImmutableObject<ICheckinResponseMember> {
  get<K extends keyof ICheckinResponseMember>(key: K): ICheckinResponseMember[K];
}

export interface ICheckInResponse {
  id: number;
  customer: ICheckinResponseMember;
  type: CheckInTypes;
  date: string;
  alerts: Array<AlertCondition>;
  entrance: INamedEntity;
  updated: boolean;
  serviceLimitation?: Partial<IServiceListInfo>;
}

export interface ICheckIn {
  id: number;
  personId: number;
  personalId?: string;
  entranceId: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
  birthday: string | null;
  checkInTime: string;
  checkInType: CheckInTypes;
  personType: CustomerStatus;
  alerts: Array<AlertCondition>;
  barcode: string;
  personalCode: number;
  type: CustomerStatus;
  serviceLimitation?: Partial<IServiceListInfo> | null;
}

export interface ICheckInImt extends ImmutableObject<ICheckIn> {
  get<K extends keyof ICheckIn>(key: K): ICheckIn[K];

  get(key: 'alerts'): ImmutableList<AlertCondition>;
}

export interface ITourStep {
  type?: AppointmentTypes;
  date?: string;
  time?: string;
  durationInMinutes: string;
  reminder: boolean;
  reminderPeriodNumber?: number;
  reminderPeriodType?: ReminderPeriodType;
  salesperson?: IEventPerson;
  description: string;
  tags: INamedEntity[];
  notifyViaEmail?: boolean;
  notifyViaNotification?: boolean;
  notifyViaSms?: boolean;
}

export type ITourStepImt = ImmutableObject<ITourStep>;

export interface IAdditionalInfo {
  allergies: IAllergy[];
  emergencyContactDto: ICustomerEmergencyContactDto;
  insuranceDto: ICustomerInsuranceDto;
  additionalFields: IProfileAdditional[];
}

export interface IAdditionalInfoImt extends ImmutableObject<IAdditionalInfo> {
  get<K extends keyof IAdditionalInfo>(key: K): IAdditionalInfo[K];

  get(key: 'allergies'): ImmutableList<IAllergy>;

  get(key: 'additionalFields'): IAdditionalFieldsImt;

  get(key: 'emergencyContactDto'): TCustomerEmergencyContactDtoImt;

  get(key: 'insuranceDto'): TCustomerInsuranceDtoImt;
}

export enum FDeskSplitViewHistory {
  POS = 'POS',
  NEW_MEMBER = 'NEW_MEMBER',
  EDIT_PROFILE = 'EDIT_PROFILE',
  CHANGE_PACKAGE_PLAN = 'CHANGE_PACKAGE_PLAN',
  MANUAL_CHECKIN = 'MANUAL_CHECKIN',
}

interface IAppointmentMemberAlert {
  id: string;
  date: string;
  time: string;
  title: string;
}

export interface IAppointmentAlertActionDTO {
  appointmentId: string;
  condition: AlertCondition;
  date: string;
  time: string;
}

export interface IMemberAlert extends INamedEntity {
  condition: AlertCondition;
  appointment: IAppointmentMemberAlert;
  note: INote;
  freezePackage: FreezePackageInfo;
  document: IPersonDocumentGroupItem;
  packageId: string;
  soundFileFullPath: string;
  type: AlertTypes;
  pastDue?: IPastDue;
}

export interface IMemberAlertImt extends ImmutableObject<IMemberAlert> {
  get<K extends keyof IMemberAlert>(key: K): IMemberAlert[K];

  get(key: 'note'): INoteImt;

  get(key: 'document'): IPersonDocumentGroupItemImt;
}
