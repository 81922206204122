import { createAction } from '@reduxjs/toolkit';

import Services from 'services/network';

import { enqueueErrorNotification } from 'common/state/notifications/actions';

import { actionTypes } from './constants';
import { ActionResult } from 'common/constants';

import {
  IFamilyMemberServiceInstanceDetailsDto,
  IFamilyMemberToRedeem,
  InventoryInstanceDetailsDto,
  InventoryRedeemHistoryDto,
  IRedeemFamilyMemberServicePayload,
  IServiceItemDetails,
  IServiceRedeemHistoryImt,
} from 'common/interfaces/service';
import { IBookingEvent, IBookingEventListItem } from 'modules/booking/interfaces';
import { GeneralThunkAction } from 'common/state/interfaces';
import {
  IServiceEventsCalendarParams,
  IScheduledServiceRedeemDTO,
  IRedeemResourceTag,
  IUnscheduledServiceRedeemDTO,
} from 'common/components/PersonProfile/interfaces';
import { PeakModules } from 'common/constants/peakModules';
import { IPaginatedData } from 'common/interfaces/pagination';
import { ITableParams } from 'common/interfaces/table';

export const selectServiceItemId = createAction<string>(actionTypes.SET_SELECTED_SERVICE_ITEM);

export const setIsDetailsShown = createAction<boolean>(actionTypes.SET_DETAILS_MODAL_SHOWN);
export const setIsRedeemShown = createAction<boolean>(actionTypes.SET_REDEEM_MODAL_SHOWN);

const serviceItemDetailsAction = createAction<IServiceItemDetails>(
  actionTypes.FETCH_SERVICE_ITEM_DETAILS,
);
const isServiceItemDetailsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SERVICE_ITEM_DETAILS_LOADING,
);
export const resetServiceItemDetails = createAction(actionTypes.RESET_SERVICE_ITEM_DETAILS);
export const fetchServiceItemDetails = (
  personId: number,
  serviceId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(isServiceItemDetailsLoadingAction(true));

    try {
      let serviceDetails;

      switch (module) {
        case PeakModules.FrontDesk:
          serviceDetails = await Services.FrontDeskRedeem.getPersonServiceDetails(
            personId,
            serviceId,
          );
          break;
        case PeakModules.Members:
          serviceDetails = await Services.MembersRedeem.getPersonServiceDetails(
            personId,
            serviceId,
          );
          break;
        case PeakModules.Crm:
          serviceDetails = await Services.Leads.getPersonServiceDetails(personId, serviceId);
          break;
        case PeakModules.PersonalTrainingCrm:
          serviceDetails = await Services.PTLeads.getPersonServiceDetails(personId, serviceId);
          break;
        default:
      }

      dispatch(serviceItemDetailsAction(serviceDetails));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(isServiceItemDetailsLoadingAction(false));
    }
  };
};

// Redeem actions

const fetchServiceEventsAction = createAction<IBookingEventListItem[]>(
  actionTypes.FETCH_SERVICE_EVENTS,
);
const fetchServiceEventsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SERVICE_EVENTS_LOADING,
);
export const resetServiceEvents = createAction(actionTypes.RESET_SERVICE_EVENTS);
export const fetchServiceEvents = (
  personId: number,
  searchParams: IServiceEventsCalendarParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceEventsLoadingAction(true));

    try {
      let events;

      switch (module) {
        case PeakModules.FrontDesk:
          events = await Services.FrontDeskRedeem.getPersonServiceEvents(personId, searchParams);
          break;
        case PeakModules.Members:
          events = await Services.MembersRedeem.getPersonServiceEvents(personId, searchParams);
          break;
        default:
      }

      dispatch(fetchServiceEventsAction(events.data));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceEventsLoadingAction(false));
    }
  };
};

const fetchServiceEventDetailsAction = createAction<IBookingEvent>(
  actionTypes.FETCH_SERVICE_EVENT_DETAILS,
);
const fetchServiceEventDetailsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SERVICE_EVENT_DETAILS_LOADING,
);
export const resetServiceEventDetails = createAction(actionTypes.RESET_SERVICE_EVENT_DETAILS);
export const fetchServiceEventDetails = (
  personId: number,
  eventId: string,
  eventStartDate: string,
  module: PeakModules,
  isServiceSection?: boolean,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceEventDetailsLoadingAction(true));

    try {
      let eventDetails;

      if (isServiceSection) {
        switch (module) {
          case PeakModules.FrontDesk:
            eventDetails = await Services.FrontDeskRedeem.getPersonServiceEventDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.Members:
            eventDetails = await Services.MembersRedeem.getPersonServiceEventDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.Crm:
            eventDetails = await Services.LeadsRedeem.getPersonServiceEventDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            eventDetails = await Services.PTLeadsRedeem.getPersonServiceEventDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            eventDetails = await Services.FrontDeskRedeem.getPersonAppointmentDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.Members:
            eventDetails = await Services.MembersRedeem.getPersonAppointmentDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.Crm:
            eventDetails = await Services.LeadsRedeem.getPersonAppointmentDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            eventDetails = await Services.PTLeadsRedeem.getPersonAppointmentDetails(
              personId,
              eventId,
              eventStartDate,
            );
            break;
          default:
        }
      }

      dispatch(fetchServiceEventDetailsAction(eventDetails));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceEventDetailsLoadingAction(false));
    }
  };
};

const redeemServiceItemAction = createAction<ActionResult>(actionTypes.REDEEM_SERVICE_ITEM);
const redeemServiceItemLoadingAction = createAction<boolean>(
  actionTypes.REDEEM_SERVICE_ITEM_LOADING,
);
export const resetRedeemService = createAction(actionTypes.RESET_REDEEM_SERVICE_ITEM);

export const redeemScheduledService = (
  personId: number,
  serviceId: string,
  redeemData: IScheduledServiceRedeemDTO,
  module: PeakModules,
  appointmentId?: string,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(redeemServiceItemLoadingAction(true));

    try {
      if (appointmentId) {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDeskRedeem.redeemAppointmentScheduledService(
              personId,
              serviceId,
              appointmentId,
              redeemData,
            );
            break;
          case PeakModules.Members:
            await Services.MembersRedeem.redeemAppointmentScheduledService(
              personId,
              serviceId,
              appointmentId,
              redeemData,
            );
            break;
          case PeakModules.Crm:
            await Services.LeadsRedeem.redeemAppointmentScheduledService(
              personId,
              serviceId,
              appointmentId,
              redeemData,
            );
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeadsRedeem.redeemAppointmentScheduledService(
              personId,
              serviceId,
              appointmentId,
              redeemData,
            );
            break;
          default:
        }
      } else {
        switch (module) {
          case PeakModules.FrontDesk:
            await Services.FrontDeskRedeem.redeemScheduledService(personId, serviceId, redeemData);
            break;
          case PeakModules.Members:
            await Services.MembersRedeem.redeemScheduledService(personId, serviceId, redeemData);
            break;
          case PeakModules.Crm:
            await Services.LeadsRedeem.redeemScheduledService(personId, serviceId, redeemData);
            break;
          case PeakModules.PersonalTrainingCrm:
            await Services.PTLeadsRedeem.redeemScheduledService(personId, serviceId, redeemData);
            break;
          default:
        }
      }

      dispatch(redeemServiceItemAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(redeemServiceItemLoadingAction(false));
    }
  };
};

const fetchServiceResourceTagsAction = createAction<IRedeemResourceTag[]>(
  actionTypes.FETCH_SERVICE_RESOURCE_TAGS,
);
const fetchServiceResourceTagsActionLoading = createAction<boolean>(
  actionTypes.FETCH_SERVICE_RESOURCE_TAGS_LOADING,
);
export const resetServiceResources = createAction(actionTypes.RESET_SERVICE_RESOURCE_TAGS);

export const fetchServiceResourceTags = (
  personId: number,
  clubId: string,
  date: string,
  tags: Array<string>,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceResourceTagsActionLoading(true));

    try {
      let resources;

      switch (module) {
        case PeakModules.FrontDesk:
          resources = await Services.FrontDeskRedeem.getServiceResourceTags(
            personId,
            clubId,
            date,
            tags,
          );
          break;
        case PeakModules.Members:
          resources = await Services.MembersRedeem.getServiceResourceTags(
            personId,
            clubId,
            date,
            tags,
          );
          break;
        default:
      }

      dispatch(fetchServiceResourceTagsAction(resources));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceResourceTagsActionLoading(false));
    }
  };
};

export const redeemUnScheduledService = (
  personId: number,
  serviceId: string,
  redeemData: IUnscheduledServiceRedeemDTO,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(redeemServiceItemLoadingAction(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDeskRedeem.redeemUnscheduledService(personId, serviceId, redeemData);
          break;
        case PeakModules.Members:
          await Services.MembersRedeem.redeemUnscheduledService(personId, serviceId, redeemData);
          break;
        default:
      }

      dispatch(redeemServiceItemAction(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(redeemServiceItemLoadingAction(false));
    }
  };
};

const fetchServiceRedeemRecentHistorySectionAction = createAction<IServiceRedeemHistoryImt>(
  actionTypes.FETCH_SERVICE_REDEEM_RECENT_HISTORY_SUCCESS,
);
const fetchServiceRedeemRecentHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SERVICE_REDEEM_RECENT_HISTORY_LOADING,
);
export const resetServiceRedeemRecentHistory = createAction(
  actionTypes.RESET_SERVICE_REDEEM_RECENT_HISTORY,
);

export const fetchServiceRedeemRecentHistory = (
  personId: number,
  serviceId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceRedeemRecentHistoryLoadingAction(true));

    try {
      let serviceHistoryData;

      switch (module) {
        case PeakModules.FrontDesk:
          serviceHistoryData = await Services.FrontDeskRedeem.getServiceRedeemRecentHistory(
            personId,
            serviceId,
          );
          break;
        case PeakModules.Members:
          serviceHistoryData = await Services.MembersRedeem.getServiceRedeemRecentHistory(
            personId,
            serviceId,
          );
          break;
        case PeakModules.Crm:
          serviceHistoryData = await Services.LeadsRedeem.getServiceRedeemRecentHistory(
            personId,
            serviceId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          serviceHistoryData = await Services.PTLeadsRedeem.getServiceRedeemRecentHistory(
            personId,
            serviceId,
          );
          break;
        default:
          serviceHistoryData = await Services.MembersRedeem.getServiceRedeemRecentHistory(
            personId,
            serviceId,
          );
      }

      dispatch(fetchServiceRedeemRecentHistorySectionAction(serviceHistoryData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceRedeemRecentHistoryLoadingAction(false));
    }
  };
};

const fetchServiceRedeemHistorySectionAction = createAction<IServiceRedeemHistoryImt>(
  actionTypes.FETCH_SERVICE_REDEEM_HISTORY_SUCCESS,
);
const fetchServiceRedeemHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_SERVICE_REDEEM_HISTORY_LOADING,
);
export const resetServiceRedeemHistory = createAction(actionTypes.RESET_SERVICE_REDEEM_HISTORY);

export const fetchServiceRedeemHistory = (
  personId: number,
  serviceId: string,
  tableParams: ITableParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchServiceRedeemHistoryLoadingAction(true));

    try {
      let serviceHistoryData;

      switch (module) {
        case PeakModules.FrontDesk:
          serviceHistoryData = await Services.FrontDeskRedeem.getServiceRedeemHistory(
            personId,
            serviceId,
            tableParams,
          );
          break;
        case PeakModules.Members:
          serviceHistoryData = await Services.MembersRedeem.getServiceRedeemHistory(
            personId,
            serviceId,
            tableParams,
          );
          break;
        case PeakModules.Crm:
          serviceHistoryData = await Services.LeadsRedeem.getServiceRedeemHistory(
            personId,
            serviceId,
            tableParams,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          serviceHistoryData = await Services.PTLeadsRedeem.getServiceRedeemHistory(
            personId,
            serviceId,
            tableParams,
          );
          break;
        default:
          serviceHistoryData = await Services.MembersRedeem.getServiceRedeemHistory(
            personId,
            serviceId,
            tableParams,
          );
      }

      dispatch(fetchServiceRedeemHistorySectionAction(serviceHistoryData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchServiceRedeemHistoryLoadingAction(false));
    }
  };
};

// inventory

export const resetRedeemInventory = createAction(actionTypes.RESET_REDEEM_INVENTORY);
const redeemInventoryLoadingAction = createAction<boolean>(actionTypes.REDEEM_INVENTORY_LOADING);
const redeemInventoryActionResult = createAction<ActionResult>(actionTypes.REDEEM_INVENTORY);

export const redeemInventoryItem = (
  personId: number,
  inventoryId: string,
  redeemedNumber: number,
  pinCode: string,
  clubId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(redeemInventoryLoadingAction(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDeskRedeem.redeemInventory(
            personId,
            inventoryId,
            redeemedNumber,
            pinCode,
            clubId,
          );
          break;
        case PeakModules.Members:
          await Services.MembersRedeem.redeemInventory(
            personId,
            inventoryId,
            redeemedNumber,
            pinCode,
            clubId,
          );
          break;
        case PeakModules.Crm:
          await Services.LeadsRedeem.redeemInventory(
            personId,
            inventoryId,
            redeemedNumber,
            pinCode,
            clubId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeadsRedeem.redeemInventory(
            personId,
            inventoryId,
            redeemedNumber,
            pinCode,
            clubId,
          );
          break;
        default:
      }

      dispatch(redeemInventoryActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(redeemInventoryLoadingAction(false));
    }
  };
};

export const selectInventoryId = createAction<string>(actionTypes.SET_SELECTED_INVENTORY_ID);

export const setIsInventoryDetailsShown = createAction<boolean>(
  actionTypes.SET_INVENTORY_DETAILS_MODAL_SHOWN,
);
export const setIsInventoryRedeemShown = createAction<boolean>(
  actionTypes.SET_REDEEM_INVENTORY_MODAL_SHOWN,
);

const setInventoryDetailsSuccessAction = createAction<InventoryInstanceDetailsDto>(
  actionTypes.FETCH_INVENTORY_DETAILS_SUCCESS,
);
const setIsInventoryDetailsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_INVENTORY_DETAILS_LOADING,
);
export const resetInventoryDetails = createAction(actionTypes.RESET_INVENTORY_DETAILS);
export const fetchInventoryDetails = (
  personId: number,
  inventoryId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setIsInventoryDetailsLoadingAction(true));

    try {
      let inventoryDetails: InventoryInstanceDetailsDto;

      switch (module) {
        case PeakModules.FrontDesk:
          inventoryDetails = await Services.FrontDeskRedeem.getRedeemedInventoryDetails(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.Members:
          inventoryDetails = await Services.MembersRedeem.getRedeemedInventoryDetails(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.Crm:
          inventoryDetails = await Services.LeadsRedeem.getRedeemedInventoryDetails(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          inventoryDetails = await Services.PTLeadsRedeem.getRedeemedInventoryDetails(
            personId,
            inventoryId,
          );
          break;
        default:
      }

      dispatch(setInventoryDetailsSuccessAction(inventoryDetails));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setIsInventoryDetailsLoadingAction(false));
    }
  };
};

const fetchInventoryRedeemRecentHistorySuccessAction = createAction<InventoryRedeemHistoryDto[]>(
  actionTypes.FETCH_INVENTORY_REDEEM_RECENT_HISTORY_SUCCESS,
);
const fetchInventoryRedeemRecentHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_INVENTORY_REDEEM_RECENT_HISTORY_LOADING,
);
export const resetInventoryRedeemRecentHistory = createAction(
  actionTypes.RESET_INVENTORY_REDEEM_RECENT_HISTORY,
);

export const fetchInventoryRedeemRecentHistory = (
  personId: number,
  inventoryId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInventoryRedeemRecentHistoryLoadingAction(true));

    try {
      let inventoryHistoryData: InventoryRedeemHistoryDto[];

      switch (module) {
        case PeakModules.FrontDesk:
          inventoryHistoryData = await Services.FrontDeskRedeem.getRedeemedInventoryRecentHistory(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.Members:
          inventoryHistoryData = await Services.MembersRedeem.getRedeemedInventoryRecentHistory(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.Crm:
          inventoryHistoryData = await Services.LeadsRedeem.getRedeemedInventoryRecentHistory(
            personId,
            inventoryId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          inventoryHistoryData = await Services.PTLeadsRedeem.getRedeemedInventoryRecentHistory(
            personId,
            inventoryId,
          );
          break;
        default:
      }

      dispatch(fetchInventoryRedeemRecentHistorySuccessAction(inventoryHistoryData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInventoryRedeemRecentHistoryLoadingAction(false));
    }
  };
};

const fetchInventoryRedeemHistorySuccessAction = createAction<
  IPaginatedData<InventoryRedeemHistoryDto>
>(actionTypes.FETCH_INVENTORY_REDEEM_HISTORY_SUCCESS);
const fetchInventoryRedeemHistoryLoadingAction = createAction<boolean>(
  actionTypes.FETCH_INVENTORY_REDEEM_HISTORY_LOADING,
);
export const resetInventoryRedeemHistory = createAction(actionTypes.RESET_INVENTORY_REDEEM_HISTORY);

export const fetchInventoryRedeemHistory = (
  personId: number,
  inventoryId: string,
  tableParams: ITableParams,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchInventoryRedeemHistoryLoadingAction(true));

    try {
      let inventoryHistoryData: IPaginatedData<InventoryRedeemHistoryDto>;

      switch (module) {
        case PeakModules.FrontDesk:
          inventoryHistoryData = await Services.FrontDeskRedeem.getRedeemedInventoryHistory(
            personId,
            inventoryId,
            tableParams,
          );
          break;
        case PeakModules.Members:
          inventoryHistoryData = await Services.MembersRedeem.getRedeemedInventoryHistory(
            personId,
            inventoryId,
            tableParams,
          );
          break;
        case PeakModules.Crm:
          inventoryHistoryData = await Services.LeadsRedeem.getRedeemedInventoryHistory(
            personId,
            inventoryId,
            tableParams,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          inventoryHistoryData = await Services.PTLeadsRedeem.getRedeemedInventoryHistory(
            personId,
            inventoryId,
            tableParams,
          );
          break;
        default:
      }

      dispatch(fetchInventoryRedeemHistorySuccessAction(inventoryHistoryData));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(fetchInventoryRedeemHistoryLoadingAction(false));
    }
  };
};

export const selectFamilyMemberServiceId = createAction<string>(
  actionTypes.SET_SELECTED_FAMILY_MEMBER_SERVICE_ID,
);

export const setIsFamilyMemberServiceDetailsShown = createAction<boolean>(
  actionTypes.SET_FAMILY_MEMBER_SERVICE_DETAILS_MODAL_SHOWN,
);
export const setIsFamilyMemberServiceRedeemShown = createAction<boolean>(
  actionTypes.SET_REDEEM_FAMILY_MEMBER_SERVICE_MODAL_SHOWN,
);

const setFamilyMemberServiceDetailsSuccessAction = createAction<
  IFamilyMemberServiceInstanceDetailsDto
>(actionTypes.FETCH_FAMILY_MEMBER_SERVICE_DETAILS_SUCCESS);
const setIsFamilyMemberServiceDetailsLoadingAction = createAction<boolean>(
  actionTypes.FETCH_FAMILY_MEMBER_SERVICE_DETAILS_LOADING,
);
export const resetFamilyMemberServiceDetails = createAction(
  actionTypes.RESET_FAMILY_MEMBER_SERVICE_DETAILS,
);

export const fetchFamilyMemberServiceDetails = (
  personId: number,
  serviceInstanceId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(setIsFamilyMemberServiceDetailsLoadingAction(true));

    try {
      let serviceDetails: IFamilyMemberServiceInstanceDetailsDto;

      switch (module) {
        case PeakModules.FrontDesk:
          serviceDetails = await Services.FrontDeskRedeem.getRedeemedFamilyMemberServiceDetails(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.Members:
          serviceDetails = await Services.MembersRedeem.getRedeemedFamilyMemberServiceDetails(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.Crm:
          serviceDetails = await Services.LeadsRedeem.getRedeemedFamilyMemberServiceDetails(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          serviceDetails = await Services.PTLeadsRedeem.getRedeemedFamilyMemberServiceDetails(
            personId,
            serviceInstanceId,
          );
          break;
        default:
      }

      dispatch(setFamilyMemberServiceDetailsSuccessAction(serviceDetails));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(setIsFamilyMemberServiceDetailsLoadingAction(false));
    }
  };
};

const fetchFamilyMembersToRedeemSuccess = createAction<IFamilyMemberToRedeem[]>(
  actionTypes.FETCH_FAMILY_MEMBERS_TO_REDEEM_SUCCESS,
);
const fetchFamilyMembersToRedeemLoading = createAction<boolean>(
  actionTypes.FETCH_FAMILY_MEMBERS_TO_REDEEM_LOADING,
);
export const resetFamilyMembersToRedeem = createAction(actionTypes.RESET_FAMILY_MEMBERS_TO_REDEEM);

export const fetchFamilyMembersToRedeem = (
  personId: number,
  serviceInstanceId: string,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(fetchFamilyMembersToRedeemLoading(true));

    try {
      let familyMembers: IFamilyMemberToRedeem[];

      switch (module) {
        case PeakModules.FrontDesk:
          familyMembers = await Services.FrontDeskRedeem.getFamilyMembersToRedeem(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.Members:
          familyMembers = await Services.MembersRedeem.getFamilyMembersToRedeem(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.Crm:
          familyMembers = await Services.LeadsRedeem.getFamilyMembersToRedeem(
            personId,
            serviceInstanceId,
          );
          break;
        case PeakModules.PersonalTrainingCrm:
          familyMembers = await Services.PTLeadsRedeem.getFamilyMembersToRedeem(
            personId,
            serviceInstanceId,
          );
          break;
        default:
      }

      dispatch(fetchFamilyMembersToRedeemSuccess(familyMembers));
    } catch (e) {
      dispatch(enqueueErrorNotification(e));
    } finally {
      dispatch(fetchFamilyMembersToRedeemLoading(false));
    }
  };
};

export const resetRedeemFamilyMembers = createAction(actionTypes.RESET_REDEEM_FAMILY_MEMBERS);
const redeemFamilyMembersLoading = createAction<boolean>(actionTypes.REDEEM_FAMILY_MEMBERS_LOADING);
const redeemFamilyMembersActionResult = createAction<ActionResult>(
  actionTypes.REDEEM_FAMILY_MEMBERS_ACTION_RESULT,
);

export const redeemFamilyMembers = (
  personId: number,
  serviceInstanceId: string,
  payload: IRedeemFamilyMemberServicePayload,
  module: PeakModules,
): GeneralThunkAction => {
  return async dispatch => {
    dispatch(redeemFamilyMembersLoading(true));

    try {
      switch (module) {
        case PeakModules.FrontDesk:
          await Services.FrontDeskRedeem.redeemFamilyMembers(personId, serviceInstanceId, payload);
          break;
        case PeakModules.Members:
          await Services.MembersRedeem.redeemFamilyMembers(personId, serviceInstanceId, payload);
          break;
        case PeakModules.Crm:
          await Services.LeadsRedeem.redeemFamilyMembers(personId, serviceInstanceId, payload);
          break;
        case PeakModules.PersonalTrainingCrm:
          await Services.PTLeadsRedeem.redeemFamilyMembers(personId, serviceInstanceId, payload);
          break;
        default:
      }

      dispatch(redeemFamilyMembersActionResult(ActionResult.SUCCESS_ACTION));
    } catch (error) {
      dispatch(enqueueErrorNotification(error));
    } finally {
      dispatch(redeemFamilyMembersLoading(false));
    }
  };
};
