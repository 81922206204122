import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

import { selectCheckInAllChildrenActionLoading } from 'modules/kid-zone/state/selectors';
import { selectUserSelectedClubId } from 'modules/authentication/state/selectors';
import { checkInAllChildren } from 'modules/kid-zone/state/actions';

import { useAppDispatch } from 'store/hooks';

import { ICheckInManuallySearchImt } from 'modules/kid-zone/interfaces';

import { DialogComponent } from 'common/components';
import { ChildCard } from 'modules/kid-zone/containers';
import { DaycareRedeemModal, GuardiansBlock } from 'modules/kid-zone/components';

import messages from 'modules/kid-zone/messages';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    child: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  member: ICheckInManuallySearchImt;
  onAddBirthday: (id: number) => void;
}

const CheckInAllModal = ({ isOpen, onClose, member, onAddBirthday }: IProps): JSX.Element => {
  // state
  const dispatch = useAppDispatch();

  const selectedClubId: string = useSelector(selectUserSelectedClubId);
  const isCheckInAllChildrenActionLoading: boolean = useSelector(
    selectCheckInAllChildrenActionLoading,
  );

  const [guardianId, setGuardianId] = useState(null);

  const classes = useStyles();

  const handleOpenRedeemModal = (personId: number) => {
    setGuardianId(personId);
  };

  const handleCheckInAllChildren = useCallback(
    (serviceId: string) => {
      const childrenIds = member
        .get('children')
        .filter(child => !child.get('checkedIn'))
        .map(child => child.get('id'))
        .toJS();

      dispatch(checkInAllChildren(selectedClubId, guardianId, serviceId, childrenIds));
    },
    [dispatch, guardianId, member, selectedClubId],
  );

  return (
    <>
      {!guardianId ? (
        <DialogComponent
          isOpen={isOpen}
          onClose={onClose}
          size="xs"
          title={<FormattedMessage {...messages.checkInAllTitle} />}
          cancelBtn={null}
          submitBtn={null}
        >
          <Box mb={3}>
            {member.get('children').map(child => (
              <>
                {!child.get('checkedIn') && <ChildCard child={child} className={classes.child} />}
              </>
            ))}
          </Box>

          <GuardiansBlock
            guardians={member.get('guardians')}
            otherMembers={member.get('other')}
            isSmallScreen
            isCheckInPanel
            isActionLoading={isCheckInAllChildrenActionLoading}
            onAddBirthday={onAddBirthday}
            onCheckInKid={handleOpenRedeemModal}
          />
        </DialogComponent>
      ) : (
        <DaycareRedeemModal
          isOpen={!!guardianId}
          onClose={() => setGuardianId(null)}
          guardianId={guardianId}
          isRedeemActionLoading={isCheckInAllChildrenActionLoading}
          onSubmit={handleCheckInAllChildren}
        />
      )}
    </>
  );
};

export default CheckInAllModal;
