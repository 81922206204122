import React, { useCallback, useEffect, useState } from 'react';
import { List as ImmutableList } from 'immutable';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Box, Button, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// icons
import { Add } from '@material-ui/icons';

import { ReactComponent as RegisterIcon } from 'img/icons/sidebar/cash-register.svg';
// interfaces
import {
  IPosKioskOpenRegisterDTO,
  IPosKioskRegisterDataImt,
} from 'modules/pos-kiosk/interfaces/registers';
import { IUserOrganizationImt } from 'common/interfaces/clients';
import { IInvoiceDetailsImt, InvoiceStatus } from 'common/interfaces/invoices';
// state
import * as actions from 'common/state/invoice/actions';
import * as personActions from 'common/components/PersonProfile/state/actions';
import * as selectors from 'common/state/invoice/selectors';
import * as registerSelectors from 'modules/pos-kiosk/state/register/selectors';
import * as registerActions from 'modules/pos-kiosk/state/register/actions';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';
// hooks
import { useAppDispatch } from 'store/hooks';
// constants
import { ENotificationType } from 'modules/booking/constants/notificationType';
import { PeakModules } from 'common/constants/peakModules';
import { SearchInvoiceProductsContextProvider } from 'common/createContext/searchInvoiceProductsContext';
// components
import InvoicesForm from './InvoiceForm/InvoicesForm';
import { CancelInvoiceModal, CheckOutResponseModal, VoidInvoiceModal } from './InvoiceModals';
import { OpenRegisterModal } from 'modules/pos-kiosk/components';
// messages
import messages from './messages';
import posMessages from 'modules/pos-kiosk/messages/messages';
import commonMessages from 'common/messages/messages';
import { LoadingBackdrop, UnavailableModule } from 'common/components/index';
import { TRegisterDictionaryImt } from 'common/interfaces/dictionary';
import {
  selectDictionaryList,
  selectDictionaryListMeta,
  selectIsDictionaryListLoading,
} from 'common/state/dictionary/selectors';
import { DictionaryList } from 'common/constants';
import StorageServices from 'services/storage';
import {
  ADD_TOP_UP_BALANCE,
  CREATE_INVOICE,
  SELECT_MEMBER_INVOICE,
} from 'common/constants/delayedActionsKeys';
import { fetchDictionaryList, updateDictionaryList } from 'common/state/dictionary/actions';
import { ISuccessResponseImt } from 'common/interfaces/http';
import { removeInvoice, resetOpenInvoicesList, selectInvoice } from 'common/state/invoice/actions';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    height: '100%',
    flex: 1,
    boxShadow: theme.shadows[3],
    position: 'relative',
    flexDirection: 'column',
  },
  newInvoiceButton: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.h6.fontSize,
    '& .MuiSvgIcon-root': {
      width: 16,
      height: 16,
    },
  },
  buttonHeight: {
    height: 'auto',
  },
  disabledNewInvoice: {
    color: theme.palette.secondary.main,
    opacity: 0.4,
    pointerEvents: 'none',
  },
  noFoundText: {
    fontSize: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
}));

interface IInvoiceOperating {
  clubId: string;
  isPaymentStep?: boolean;
  shouldGoBackAfterRevokeInvoice?: boolean;
  isCustomerSelectDisabled?: boolean;
  isSalespersonSelectDisabled?: boolean;
  disableInvoiceSelection?: boolean;
  disableInvoiceCreation?: boolean;
  isPOS?: boolean;
  onBack?: () => void;
  onNext?: () => void;
  module: PeakModules;
  event: ENotificationType;
  profileId?: number;
}

const { execute, isExitDelayedAction, cancelDelayedAction } = StorageServices.delayedActions;

export default function InvoiceOperating({
  isPaymentStep,
  isPOS,
  shouldGoBackAfterRevokeInvoice,
  disableInvoiceSelection,
  disableInvoiceCreation,
  isCustomerSelectDisabled,
  isSalespersonSelectDisabled,
  clubId,
  module,
  onBack,
  onNext,
  event,
  profileId,
}: IInvoiceOperating): JSX.Element {
  const classes = useStyles();
  // local state
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);
  const [isVoidModalOpen, setIsVoidModalOpen] = useState<boolean>(false);
  const [isOpenRegisterModalOpen, setIsOpenRegisterModalOpen] = useState<boolean>(false);

  // global state
  const dispatch = useAppDispatch();

  const selectedRegisterId: string = useSelector(registerSelectors.selectSelectedRegisterId);
  const register: IPosKioskRegisterDataImt = useSelector(
    registerSelectors.selectPosKioskRegisterData,
  );
  const isRegisterLoading: IPosKioskRegisterDataImt = useSelector(
    registerSelectors.selectPosKioskRegisterDataLoading,
  );

  const isListLoading: boolean = useSelector(selectors.selectInvoicesListLoading());
  const selectedInvoice: IInvoiceDetailsImt = useSelector(selectors.selectCurrentInvoice);

  const currentRegisterInvoicesList = useSelector(selectors.selectInvoicesForRegisterList);
  const isFetchedInvoiceList = useSelector(selectors.selectIsFetchedInvoiceList);
  const registersList: ImmutableList<TRegisterDictionaryImt> = useSelector(
    selectDictionaryList(DictionaryList.REGISTERS),
  );
  const registersListMeta = useSelector(selectDictionaryListMeta(DictionaryList.REGISTERS));
  const isRegistersListLoading: boolean = useSelector(
    selectIsDictionaryListLoading(DictionaryList.REGISTERS),
  );
  const cancelInvoiceResult: actions.IVoidInvoiceSuccessResponseImt = useSelector(
    selectors.selectCancelResult(),
  );
  const isCancelInvoiceLoading: boolean = useSelector(selectors.selectCancelResultLoading());
  const isCreateInvoiceActionLoading: boolean = useSelector(
    selectors.selectCreateInvoiceActionLoading(),
  );
  const selectedOrg: IUserOrganizationImt = useSelector(selectUserSelectedOrganization);
  const openRegisterResult: ISuccessResponseImt = useSelector(
    registerSelectors.selectPosKioskOpenRegisterResult,
  );
  const isOpenRegisterActionLoading: boolean = useSelector(
    registerSelectors.selectPosKioskOpenRegisterLoading,
  );
  const registerSelectionProcessLoading = useSelector(
    registerSelectors.selectRegisterSelectionProcessLoading,
  );
  const checkOutResponse: IInvoiceDetailsImt = useSelector(selectors.selectInvoiceCheckoutResult);

  const isCheckoutResponseModalOpen = checkOutResponse?.size;

  const navigate = useNavigate();

  const isRecentPersonPaymentsUpdate =
    module === PeakModules.FrontDesk &&
    Boolean(profileId) &&
    selectedInvoice?.getIn(['customer', 'id']) === profileId;

  const updateInvoiceCountOfRegister = useCallback(() => {
    const updatedRegisterList = registersList.toJS().map(item => {
      if (item.id === register.get('id')) {
        return { ...item, openInvoiceCount: item.openInvoiceCount + 1 };
      }

      return item;
    });

    dispatch(
      updateDictionaryList({
        dictionary: DictionaryList.REGISTERS,
        list: updatedRegisterList,
        meta: registersListMeta?.toJS(),
      }),
    );
  }, [dispatch, registersListMeta, register, registersList]);

  const handleNewInvoiceBtnClick = useCallback((): void => {
    if (register.get('id')) {
      dispatch(
        actions.createInvoice({
          module,
          registerId: selectedRegisterId,
          isCustomer: isPaymentStep,
          shouldFocus: true,
          callback: updateInvoiceCountOfRegister,
          profileId,
        }),
      );
    }
  }, [
    dispatch,
    isPaymentStep,
    module,
    profileId,
    register,
    selectedRegisterId,
    updateInvoiceCountOfRegister,
  ]);

  const onOpenRegister = (data: IPosKioskOpenRegisterDTO): void => {
    dispatch(
      registerActions.openPosKioskRegisterItem(
        module,
        register.get('id'),
        { ...data },
        isPaymentStep,
      ),
    );
  };

  const onSubmitCancel = (): void => {
    dispatch(
      actions.cancelInvoice(
        module,
        selectedInvoice.get('id'),
        profileId,
        isPaymentStep,
        shouldGoBackAfterRevokeInvoice,
        isRecentPersonPaymentsUpdate,
      ),
    );
  };

  const onVoidInvoice = (reason: string): void => {
    dispatch(
      actions.voidInvoice({
        module,
        invoiceId: selectedInvoice?.get('id'),
        reason,
        isMembershipStep: isPaymentStep,
        shouldGoPrevStep: shouldGoBackAfterRevokeInvoice,
        profileId,
        isRecentPersonPaymentsUpdate,
      }),
    );
  };

  const onOpenRegisterModalOpen = useCallback(() => {
    setIsOpenRegisterModalOpen(true);
  }, []);

  const onOpenRegisterModalClose = useCallback(() => {
    setIsOpenRegisterModalOpen(false);
  }, []);

  const handleNotificationSubmit = useCallback(
    ({ checkoutData, notificationData }): void => {
      const paidInvoiceStatus = checkoutData.get('status');
      const paidInvoiceId = checkoutData.get('id');
      let selectedInvoiceId;
      const firstInvoiceId = currentRegisterInvoicesList?.getIn([0, 'id']);
      const secondInvoiceId = currentRegisterInvoicesList?.getIn([1, 'id']);

      dispatch(
        actions.sendNotifications(module, checkoutData.get('id'), isPaymentStep, notificationData),
      );
      if (module === PeakModules.FrontDesk && profileId) {
        dispatch(personActions.fetchPersonSuggestedProducts(profileId, selectedOrg.get('id')));
      }
      dispatch(registerActions.fetchPosKioskRegisterData(selectedRegisterId));

      // remove from the list after successful updateInvoiceWithSync execution
      if (paidInvoiceStatus === InvoiceStatus.PAID) {
        selectedInvoiceId = firstInvoiceId === paidInvoiceId ? secondInvoiceId : firstInvoiceId;
        dispatch(removeInvoice(paidInvoiceId));
      } else {
        selectedInvoiceId = firstInvoiceId;
      }

      dispatch(actions.selectInvoice(selectedInvoiceId));
      if (onNext) onNext();
    },
    [
      currentRegisterInvoicesList,
      dispatch,
      module,
      isPaymentStep,
      profileId,
      selectedRegisterId,
      onNext,
      selectedOrg,
    ],
  );

  useEffect(() => {
    if (register?.get('id') && register?.get('open') && isExitDelayedAction(CREATE_INVOICE)) {
      setTimeout(() => execute<string[]>(CREATE_INVOICE, [register.get('id')]));
    }
  }, [register, dispatch]);

  useEffect(() => {
    if (
      register?.get('id') &&
      register?.get('open') &&
      isExitDelayedAction(ADD_TOP_UP_BALANCE) &&
      isFetchedInvoiceList
    ) {
      execute(ADD_TOP_UP_BALANCE, [currentRegisterInvoicesList?.toJS(), register.get('id')]);
    }
  }, [register, isFetchedInvoiceList, currentRegisterInvoicesList]);

  useEffect(() => {
    return () => {
      dispatch(selectInvoice(null));
      dispatch(resetOpenInvoicesList());

      if (isExitDelayedAction(CREATE_INVOICE)) {
        cancelDelayedAction(CREATE_INVOICE);
      }
    };
  }, [dispatch]);

  useEffect(() => {
    if (
      register?.get('id') &&
      register?.get('open') &&
      isExitDelayedAction(SELECT_MEMBER_INVOICE) &&
      isFetchedInvoiceList
    ) {
      execute(SELECT_MEMBER_INVOICE, [register.get('id'), currentRegisterInvoicesList?.toJS()]);
    }
  }, [currentRegisterInvoicesList, isFetchedInvoiceList, register]);

  useEffect(() => {
    if (selectedRegisterId && !isFetchedInvoiceList && !registerSelectionProcessLoading) {
      dispatch(actions.fetchOpenInvoicesList(module, selectedRegisterId, isPaymentStep, isPOS));
    }
  }, [
    isFetchedInvoiceList,
    dispatch,
    isPaymentStep,
    module,
    selectedRegisterId,
    isPOS,
    registerSelectionProcessLoading,
  ]);

  useEffect(() => {
    if (openRegisterResult?.get('success')) {
      onOpenRegisterModalClose();
      dispatch(registerActions.resetPosKioskOpenRegisterResult());
      dispatch(registerActions.fetchPosKioskRegisterData(selectedRegisterId));
    }
  }, [dispatch, onOpenRegisterModalClose, openRegisterResult, selectedRegisterId]);

  useEffect(() => {
    if (cancelInvoiceResult?.get('success')) {
      if (isCancelModalOpen || isVoidModalOpen) {
        dispatch(
          fetchDictionaryList(DictionaryList.REGISTERS, {
            clubIds: clubId ? `${clubId}` : '',
            module,
            isCustomer: false,
            quietLoading: true,
          }),
        );
      }

      if (isCancelModalOpen) {
        setIsCancelModalOpen(false);
      }

      if (isVoidModalOpen) {
        setIsVoidModalOpen(false);
      }

      if (isPaymentStep && cancelInvoiceResult.get('shouldGoBack')) {
        onBack();
      } else if (!isPaymentStep) {
        dispatch(actions.selectInvoice(currentRegisterInvoicesList?.getIn([0, 'id']) ?? null));
      }

      dispatch(actions.resetCancelInvoice());
    }
  }, [
    currentRegisterInvoicesList,
    dispatch,
    isPaymentStep,
    isCancelModalOpen,
    onBack,
    cancelInvoiceResult,
    isVoidModalOpen,
    clubId,
    module,
    profileId,
  ]);

  // renders

  const NoOpenRegisterView = useCallback(
    (): JSX.Element => (
      <Box
        p={2}
        height="100%"
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={2} alignItems="center" direction="column">
          <Grid item>
            <Typography align="center">
              <FormattedMessage {...posMessages.closedRegisterPosPanelText} />
            </Typography>
          </Grid>
          <Grid item>
            <Button color="primary" variant="contained" onClick={onOpenRegisterModalOpen}>
              <FormattedMessage {...posMessages.openRegister} />
            </Button>
          </Grid>
        </Grid>
      </Box>
    ),
    [onOpenRegisterModalOpen],
  );

  const NoInvoiceRegisterView = useCallback((): JSX.Element => {
    const isOpenRegister = register.get('id') && register.get('open') && !isListLoading;
    return (
      <Box
        p={2}
        height="100%"
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          component="button"
          variant="text"
          color="primary"
          type="button"
          className={clsx('btn-text', classes.newInvoiceButton, classes.buttonHeight, {
            [classes.disabledNewInvoice]:
              !isOpenRegister || disableInvoiceCreation || isCreateInvoiceActionLoading,
          })}
          onClick={handleNewInvoiceBtnClick}
        >
          <Add />
          <FormattedMessage {...messages.newInvoice} />
        </Button>
      </Box>
    );
  }, [
    register,
    isListLoading,
    classes,
    disableInvoiceCreation,
    isCreateInvoiceActionLoading,
    handleNewInvoiceBtnClick,
  ]);

  const renderedModals = (
    <>
      {isOpenRegisterModalOpen && (
        <OpenRegisterModal
          onSubmit={onOpenRegister}
          onClose={onOpenRegisterModalClose}
          isOpen={isOpenRegisterModalOpen}
          registerId={selectedRegisterId}
          isOpenRegisterActionLoading={isOpenRegisterActionLoading}
        />
      )}

      {isCheckoutResponseModalOpen && (
        <CheckOutResponseModal
          isCustomer={isPaymentStep}
          checkOutResponse={checkOutResponse}
          onSubmit={handleNotificationSubmit}
          module={module}
          event={event}
        />
      )}

      {isCancelModalOpen && (
        <CancelInvoiceModal
          isLoading={isCancelInvoiceLoading}
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          onSubmit={onSubmitCancel}
        />
      )}

      {isVoidModalOpen && (
        <VoidInvoiceModal
          isLoading={isCancelInvoiceLoading}
          isOpen={isVoidModalOpen}
          onClose={() => setIsVoidModalOpen(false)}
          onSubmit={onVoidInvoice}
        />
      )}
    </>
  );

  const hasNotRegister =
    !isRegisterLoading &&
    !registersList?.size &&
    !isRegistersListLoading &&
    !registerSelectionProcessLoading;

  return (
    <Paper className={classes.paper}>
      {(registerSelectionProcessLoading ||
        (!selectedInvoice && currentRegisterInvoicesList?.size > 0)) && (
        <LoadingBackdrop isLoading />
      )}
      {hasNotRegister && (
        <UnavailableModule
          hasVisibleBackToBtn={false}
          lockIcon={<RegisterIcon />}
          description={
            <Typography className={classes.noFoundText}>
              <FormattedMessage {...commonMessages.noRegistersText} />
            </Typography>
          }
          additionalButton={
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/pos-settings/register/new')}
            >
              <FormattedMessage {...commonMessages.createNewOneBtn} />
            </Button>
          }
        />
      )}

      {!registerSelectionProcessLoading &&
        !isRegisterLoading &&
        selectedRegisterId &&
        !register?.get('open') && <NoOpenRegisterView />}

      {!registerSelectionProcessLoading &&
        !isRegisterLoading &&
        register?.get('open') &&
        !currentRegisterInvoicesList?.size && <NoInvoiceRegisterView />}

      {!registerSelectionProcessLoading &&
        !isRegisterLoading &&
        register?.get('open') &&
        selectedInvoice &&
        currentRegisterInvoicesList?.size > 0 && (
          <SearchInvoiceProductsContextProvider>
            <InvoicesForm
              selectedInvoice={selectedInvoice}
              disableInvoiceCreation={disableInvoiceCreation}
              disableInvoiceSelection={disableInvoiceSelection}
              updateInvoiceCountOfRegister={updateInvoiceCountOfRegister}
              setIsCancelInvoiceModalOpen={setIsCancelModalOpen}
              setIsVoidInvoiceModalOpen={setIsVoidModalOpen}
              isPaymentStep={isPaymentStep}
              clubId={clubId}
              module={module}
              profileId={profileId}
              isCustomerSelectDisabled={isCustomerSelectDisabled}
              isSalespersonSelectDisabled={isSalespersonSelectDisabled}
              isCreateInvoiceActionLoading={isCreateInvoiceActionLoading}
            />
          </SearchInvoiceProductsContextProvider>
        )}

      {renderedModals}
    </Paper>
  );
}
