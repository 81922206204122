import React from 'react';
import { List as ImmutableList } from 'immutable';
import { Box, makeStyles, Typography, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { getInitials } from 'helpers/common';
import { AttendeeTypes } from 'modules/booking/constants';

import { colors } from 'common/ui/theme/default';

import { IEventPersonEntityImt, PersonAppointmentStatus } from 'modules/booking/interfaces';
import { ReactComponent as CheckIcon } from 'img/icons/check.svg';
import { ReactComponent as CanceledIcon } from 'img/icons/times.svg';
import { ReactComponent as UserCalendarIcon } from 'img/icons/user-calendar.svg';
import { ReactComponent as UserPlusIcon } from 'img/icons/users-plus.svg';

import { ReactComponent as PendingIcon } from 'img/icons/hourglass.svg';
import { AllowedTo, AvatarWithInitials } from 'common/components';
import commonMessages from 'common/messages/messages';

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    margin: theme.spacing(1.75, 0),
    opacity: '0.3',
    textTransform: 'uppercase',
  },
  list: {
    marginBottom: '12px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  listItem: {
    flex: 1,
    flexBasis: '50%',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginBottom: theme.spacing(1),
    wordBreak: 'break-word',
  },
  participantName: {
    display: 'flex',
    flexDirection: 'column',

    '& span': {
      lineHeight: '1.125rem',
    },
  },
  personStatus: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 20,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    color: theme.palette.primary.contrastText,

    '& svg': {
      width: 12,
      height: 12,
    },
  },
  confirmStatus: {
    backgroundColor: colors.primary,
  },
}));

interface IParticipantsListProps {
  title: JSX.Element;
  data: ImmutableList<IEventPersonEntityImt>;
  isParticipants?: boolean;
}

const ParticipantsList = React.memo(({ title, data, isParticipants }: IParticipantsListProps) => {
  const classes = useStyles();

  const renderPersonStatusIcon = (personStatus: PersonAppointmentStatus) => (
    <>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Confirmed}>
        <Box bgcolor="primary.main" className={cx(classes.personStatus, classes.confirmStatus)}>
          <CheckIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Canceled}>
        <Box bgcolor="error.main" className={classes.personStatus}>
          <CanceledIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Pending}>
        <Box bgcolor="warning.main" className={classes.personStatus}>
          <PendingIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Missed}>
        <Box bgcolor="celebration.main" className={classes.personStatus}>
          <UserPlusIcon />
        </Box>
      </AllowedTo>
      <AllowedTo perform={personStatus === PersonAppointmentStatus.Attended}>
        <Box bgcolor="info.main" className={classes.personStatus}>
          <UserCalendarIcon />
        </Box>
      </AllowedTo>
    </>
  );

  return (
    <Box>
      <Typography className={classes.listTitle} variant="h5">
        {title}
      </Typography>
      <Box className={classes.list}>
        {data.map(item => {
          const customer = item.get('customer');
          const salesperson = item.get('salesperson');

          const person = customer || salesperson;

          const type = person.get('type');

          const photo = person.get('imageUrl');
          const initials = getInitials(person.get('firstName'), person.get('lastName'));

          const participantInactiveStatus =
            person.get('active') || customer ? null : (
              <FormattedMessage {...commonMessages.inactiveOption} />
            );

          return (
            <Box key={item.get('id')} className={classes.listItem}>
              <Box className={classes.listItemContainer}>
                <Box position="relative" marginRight={1}>
                  <AvatarWithInitials
                    initials={initials}
                    imageUrl={photo}
                    width="42px"
                    height="42px"
                    marginRight={0}
                  />

                  {isParticipants && renderPersonStatusIcon(item.get('status'))}
                </Box>

                <Typography className={classes.participantName}>
                  <Typography component="span">{`${person.get('firstName')} ${person.get(
                    'lastName',
                  )}`}</Typography>
                  <Typography color="textSecondary" component="span">
                    {isParticipants && <>{AttendeeTypes.translate(type)}&nbsp;</>}
                    {participantInactiveStatus && <>({participantInactiveStatus})</>}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
});

export default ParticipantsList;
