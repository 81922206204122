import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, makeStyles } from '@material-ui/core';
import { ReactComponent as DefaultIcon } from 'img/icons/default-icon.svg';
import { INamedEntityImt } from 'common/interfaces/common';
import { HeaderId } from 'common/enums/html.enums';
import { selectUserSelectedOrganization } from 'modules/authentication/state/selectors';

const useStyles = makeStyles(() => ({
  clubLogo: {
    width: 36,
    height: 36,
  },
}));

const HeaderClubLogo: FC = () => {
  const classes = useStyles();
  const selectedOrg: INamedEntityImt = useSelector(selectUserSelectedOrganization);

  return (
    <Avatar
      id={HeaderId.HeaderBtnClubLogo}
      className={classes.clubLogo}
      variant={selectedOrg?.get('imageUrl') ? 'rounded' : 'circular'}
      alt={selectedOrg?.get('title')}
      src={selectedOrg?.get('imageUrl')}
    >
      <DefaultIcon />
    </Avatar>
  );
};

export default HeaderClubLogo;
