import { ImmutableObject } from 'common/state/interfaces';
import {
  DurationType,
  FrequencyType,
  IncludedPackageBillingType,
  IssueFrequencyType,
  PackageCostType,
  PackageType,
} from 'modules/services/constants/packages';
import {
  IBillingOption,
  ICreditCardServiceFee,
  IDownPayment,
  IEditable,
  IFreezeSector,
  IPackageFee,
  IPaymentGrace,
  IPaymentSplitting,
} from 'modules/services/interfaces/packages';
import { IPackageRCodeListItem } from 'modules/pos-settings/interfaces/revenueCodes';
import { RedeemType, ServiceType } from 'common/constants/service';
import {
  DurationUnit,
  IDayTimeAvailabilityDto,
  INamedEntity,
  INamedEntityImt,
} from 'common/interfaces/common';
import { List as ImmutableList } from 'immutable';
// eslint-disable-next-line import/no-cycle
import {
  IPackageInventory,
  IRevenueCodeWithCodeDto,
  IRevenueCodeWithCodeDtoImt,
  IServiceImt,
  MenuItemTypes,
} from './invoices';
import { IFee } from 'modules/services/interfaces/fees';

export interface IRequestIncludedPackageService {
  id?: string;
  services: IAddPackageServiceDTO[];
  fees: IAddPackageFeeDTO[];
}

export interface IBaseServicePackage {
  id: string;
  packageTemplateId?: string;
  type?: PackageType;
  title: string;
  paymentSchedule?: FrequencyType;
  totalAmount: number;
  pricePerBilling?: number;
  amountToPay?: number;
  inventories: IPackageInventory[];
  services: IPackageService[];
  fees: IPackageFee[];
  makeFirstPayment?: boolean;
  costType?: PackageCostType;
}

export interface IService {
  active: boolean;
  id: string;
  redeemDurationUnit: DurationUnit;
  redeemType: RedeemType;
  title: string;
  type: ServiceType;
}

export interface IConfiguredIncludedPackage {
  fees: Array<IPackageFee>;
  active?: boolean;
  id: string;
  inventories: Array<IPackageInventory>;
  services: Array<IConfiguredPackageService>;
  title: string;
}

export interface IConfiguredPackageService {
  id: string;
  limited: boolean;
  limitedRedeemNumber: number;
  package: IConfiguredIncludedPackage;
  service: IService;
}

export interface IConfiguredPackage {
  availableToSellByCurrentEmployee: boolean;
  fees: Array<IPackageFee>;
  id: string;
  inventories: Array<IPackageInventory>;
  paymentSchedule: FrequencyType;
  pricePerBilling: number;
  services: Array<IConfiguredPackageService>;
  title: string;
  totalAmount: number;
}

export interface IOutOfTermBillingOption extends IBillingOption {
  billingOption: string;
  freezeDaysNumber: number;
  paymentNumber: number;
  paymentSplits: IEditable[];
  paymentSchedule: FrequencyType;
  paymentGraceEnabled: boolean;
  paymentGraceNumber: number;
  paymentSplitEnabled: boolean;
}

export interface IPackageConfiguration {
  billingOptions?: Array<IBillingOption>;
  clubs: Array<INamedEntity>;
  costType?: PackageCostType;
  customDates?: ICustomDates;
  dayTimeAvailDtoList: IDayTimeAvailabilityDto[];
  durationEditableNumber: IEditable;
  durationType: DurationType;
  feeSection?: {
    hasEditable: boolean;
    fees: Array<IPackageFee>;
  };
  // fees: Array<IPackageFee>;
  id: string;
  inventories: Array<IPackageInventory>;
  pricePerBilling?: number;
  amountToPay?: number;
  revenueCode?: IPackageRCodeListItem;
  services: Array<IPackageService>;
  title: string;
  type?: PackageType;
  outOfTermBillingOption?: IBillingOption;
}

export interface IPackageConfigurationImt extends ImmutableObject<IPackageConfiguration> {
  get<K extends keyof IPackageConfiguration>(key: K): IPackageConfiguration[K];
}

export interface IEditablePackageConfiguration extends IPackageConfiguration {
  billingOption: string;

  splitting: IPaymentSplitting;
  downPayment: IDownPayment;
  paymentGrace: IPaymentGrace;
  renewalNumber: number;
  freeze: IFreezeSector;
  creditCardServiceFee: ICreditCardServiceFee;
  paymentEditableSchedule: Omit<IEditable, 'value'> & { value: FrequencyType };
  paymentSchedule?: FrequencyType;

  totalAmount: number;

  taxAmount?: number;
  makeFirstPayment?: boolean;
}

export interface IEditablePackageConfigurationImt
  extends ImmutableObject<IEditablePackageConfiguration> {
  get<K extends keyof IEditablePackageConfiguration>(key: K): IEditablePackageConfiguration[K];
}

export interface IPackageService {
  billingAmount: number;
  billingOption: IBillingOption;
  billingType: IncludedPackageBillingType;
  dayTimeAvailDtoList: IDayTimeAvailabilityDto[];
  excludable: boolean;
  hasCustomDayTimeAvailability: boolean;
  id: string;
  limited: boolean;
  limitedRedeemNumber: number;
  package: IEditablePackageConfiguration;
  service: IService;
  // This is responsible for removing service upon membership package sale
  included?: boolean;
}

export interface IBaseInvoicePackage extends IBaseServicePackage {
  menuItemType: MenuItemTypes.Service | MenuItemTypes.Membership;
  taxAmount: number;
  pricePerBilling?: number;
  membershipId?: string;
  invoiceUnitId?: string;
}

export interface IBaseInvoicePackageImt extends ImmutableObject<IBaseInvoicePackage> {
  get<K extends keyof IBaseInvoicePackage>(key: K): IBaseInvoicePackage[K];

  get(key: 'services'): ImmutableList<IServiceImt>;

  get(key: 'inventories'): ImmutableList<INamedEntityImt>;
}

export type IMembershipInvoicePackage = IBaseInvoicePackage;
export type IServiceInvoicePackage = IBaseInvoicePackage & IEditablePackageConfiguration;

export interface IAddPackageServiceDTO {
  id: string;
  service: IService;
  package: IRequestIncludedPackageService;
}

export interface IAddIncludedPackageDTO {
  billingOption: string;
  dayTimeAvails: IDayTimeAvailabilityDto[];
  downPaymentAmount: number;
  downPaymentEnabled: boolean;
  downPaymentNumber: number;
  downPaymentSplits: IEditable[];
  durationNumber: number;
  fees: IAddPackageFeeDTO[];
  freezeDaysNumber?: number;
  hasCustomDayTimeAvailability: boolean;
  id: string;
  paymentGraceEnabled: boolean;
  paymentGraceNumber: number;
  paymentNumber: number;
  paymentSchedule: FrequencyType;
  paymentSplitEnabled: boolean;
  paymentSplits: IEditable[];
  services: IAddPackageServiceDTO[];
  title: string;
  makeFirstPayment?: boolean;
  outOfTermBillingOption?: IOutOfTermBillingOption | IBillingOption;
}

export interface InvoicePackageInstanceUpdateDto extends IAddIncludedPackageDTO {
  membershipId?: string;
  menuItemType: MenuItemTypes.Service | MenuItemTypes.Membership;
  packageTemplateId?: string;
  pricePerBilling?: number;
  amountToPay?: number;
  taxAmount?: number;
}

export interface ICustomDates {
  startDate: string;
  endDate: string;
}

export type ICustomDatesImt = ImmutableObject<ICustomDates>;

export enum MembershipStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface IMembershipInfo {
  canceled: boolean;
  endDate: string;
  id: string;
  packageTemplateId: string;
  paymentSchedule: FrequencyType;
  pricePerBilling: number;
  startDate: string;
  status: MembershipStatus;
  title: string;
  totalAmount: number;
  costType: PackageCostType;
  type: PackageType;
  cancellationDate?: string;
}

export type IMembershipInfoImt = ImmutableObject<IMembershipInfo>;

export interface IMembershipPackageShort extends INamedEntity {
  clubId: string;
  makeFirstPayment?: boolean;
}

interface BaseMembershipForm {
  club: string;
  membership: string;
}

export interface IMembershipFormNew extends BaseMembershipForm {
  packageConfiguration?: IEditablePackageConfiguration;
}

export interface IMembershipTransformed extends BaseMembershipForm {
  packageConfiguration: IAddIncludedPackageDTO;
}

export interface IAddPackageFeeDTO {
  chargeAfterDays: number;
  fee: IFee;
  id: string;
  paymentNumber?: number;
  paymentSplits?: IEditable[];
  totalAmount: number;

  // This is responsible for removing service upon membership package sale
  included?: boolean;
}

export interface IShortPackage extends ICustomDates {
  id: string;
  title: string;
  membership: boolean;
  availableToSellByCurrentClub: boolean;
  availableToSellByCurrentEmployee: boolean;
}

export interface IShortPackages {
  available: IShortPackage[];
  comingSoon: IShortPackage[];
}

export type IShortPackageImt = ImmutableObject<IShortPackage>;

export interface IShortPackagesImt extends ImmutableObject<IShortPackages> {
  get<K extends keyof IShortPackages>(key: K): IShortPackages[K];

  get(key: 'available'): IShortPackageImt;

  get(key: 'comingSoon'): IShortPackageImt;
}

export interface IPackageInventoryViewDto {
  id: string;
  imageUrl: string;
  barcode: string;
  category: string;
  description: string;
  expirationDate: string;
  title: string;
  revenueCode: IRevenueCodeWithCodeDto;
  frequency: IssueFrequencyType;
}

export interface IPackageInventoryViewDtoImt extends ImmutableObject<IPackageInventoryViewDto> {
  get<K extends keyof IPackageInventoryViewDto>(key: K): IPackageInventoryViewDto[K];

  get(key: 'revenueCode'): IRevenueCodeWithCodeDtoImt;
}
