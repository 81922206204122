import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Grid, MenuItem, FormControlLabel, FormHelperText } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentAdapter from '@date-io/moment';

import { RepeatEnds, RepeatFrequencies, Repeats } from 'modules/booking/constants';
import { times } from 'common/constants/times';

import { EventRepeatEnd, EventRepeatFrequency } from 'modules/booking/interfaces';

import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';

import {
  AdaptiveDatePicker,
  Checkbox,
  DateTimePicker,
  NumberTextField,
  Select,
} from 'common/components';
import WeekdaysSelector from 'common/components/DayTimeAvailabilitySelector/WeekdaysSelector';

import inputLabels from 'common/messages/inputLabels';
import messages from 'modules/booking/messages';
import { getDefaultSelectMenuPosition } from 'common/ui/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    durationMenu: {
      maxHeight: '300px',
      margin: theme.spacing(2, 0),
    },
    weekday: {
      flex: 1,

      '&:last-child': {
        '& .MuiBox-root': {
          borderRight: '1px solid #ededed',
          borderTopRightRadius: '3px',
          borderBottomRightRadius: '3px',
        },
      },
      '&:first-child': {
        '& .MuiBox-root': {
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
      },
    },
    weekendBtn: {
      height: '32px',
      margin: theme.spacing(1, 0),
      border: '1px solid #ededed',
      borderRight: 0,
      fontSize: '12px',
      fontWeight: 'bold',
      textTransform: 'uppercase',
      cursor: 'pointer',
    },
    weekendBtnActive: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  }),
);

const ScheduleSection = (): JSX.Element => {
  const classes = useStyles();
  const renderErrorMessage = useRenderIntlMessage();
  const { watch, control, formState } = useFormContext();
  const { errors } = formState;

  const [repeated, repeatingFrequency, repeatingDurationType, date] = watch([
    'repeated',
    'repeatingFrequency',
    'repeatingDurationType',
    'date',
    'repeatingWeekdays',
  ]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={1} wrap="nowrap">
          <Grid item xs={8}>
            <DateTimePicker disablePast dateFieldSize={7} timeFieldSize={5} />
          </Grid>

          <Grid item xs={4}>
            <Controller
              name="durationInMinutes"
              control={control}
              render={({ field }) => (
                <Select
                  label={<FormattedMessage {...inputLabels.duration} />}
                  value={field.value}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  renderValue={(v: string) => `${+v.split(':')[0]}h ${v.split(':')[1]}m`}
                  MenuProps={{
                    classes: { paper: classes.durationMenu },
                    ...getDefaultSelectMenuPosition(),
                  }}
                  fullWidth
                  error={!!errors.durationInMinutes}
                  helperText={renderErrorMessage(errors.durationInMinutes?.message)}
                >
                  {times.map(timeItem => (
                    <MenuItem key={timeItem} value={timeItem}>
                      {timeItem}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Controller
          name="changeDuration"
          control={control}
          render={({ field }) => (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={field.value}
                    onBlur={field.onBlur}
                    onChange={e => field.onChange(e.target.checked)}
                  />
                }
                label={<FormattedMessage {...messages.allowDurationChangeLabel} />}
              />
              {errors.changeDuration && (
                <FormHelperText error>
                  {renderErrorMessage(errors.changeDuration?.message)}
                </FormHelperText>
              )}
            </>
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name="repeated"
              control={control}
              render={({ field }) => (
                <Select
                  label={<FormattedMessage {...inputLabels.repeats} />}
                  fullWidth
                  value={Number(field.value)}
                  onBlur={field.onBlur}
                  onChange={v => field.onChange(Boolean(v))}
                  error={!!errors.repeated}
                  helperText={renderErrorMessage(errors.repeated?.message)}
                >
                  {Repeats.map(repeatItem => (
                    <MenuItem value={Number(repeatItem.value)} key={repeatItem.key}>
                      <FormattedMessage {...repeatItem.message} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </Grid>

          {repeated && (
            <>
              <Grid item xs={6}>
                <Controller
                  name="repeatingFrequency"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.frequency} />}
                      fullWidth
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      error={!!errors.repeatingFrequency}
                      helperText={renderErrorMessage(errors.repeatingFrequency?.message)}
                    >
                      {RepeatFrequencies.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>

              {repeatingFrequency === EventRepeatFrequency.WEEKLY && (
                <Controller
                  name="repeatingWeekdays"
                  control={control}
                  render={({ field }) => (
                    <Grid item xs={12}>
                      <Grid container>
                        <WeekdaysSelector
                          selectedDays={field.value}
                          onChange={field.onChange}
                          errorMessage={
                            !!errors.repeatingWeekdays &&
                            renderErrorMessage(errors.repeatingWeekdays.message)
                          }
                        />
                      </Grid>
                    </Grid>
                  )}
                />
              )}

              <Grid item xs={6}>
                <Controller
                  name="repeatingDurationType"
                  control={control}
                  render={({ field }) => (
                    <Select
                      label={<FormattedMessage {...inputLabels.endRepeat} />}
                      fullWidth
                      value={field.value}
                      onBlur={field.onBlur}
                      onChange={field.onChange}
                      error={!!errors.repeatingDurationType}
                      helperText={renderErrorMessage(errors.repeatingDurationType?.message)}
                    >
                      {RepeatEnds.getSelectOptions()}
                    </Select>
                  )}
                />
              </Grid>

              {repeatingDurationType === EventRepeatEnd.ON_DATE && (
                <Grid item xs={6}>
                  <MuiPickersUtilsProvider utils={MomentAdapter}>
                    <Controller
                      name="repeatingEndDate"
                      control={control}
                      render={({ field }) => (
                        <AdaptiveDatePicker
                          value={field.value}
                          onChange={field.onChange}
                          onBlur={field.onBlur}
                          label={<FormattedMessage {...inputLabels.endDate} />}
                          minDate={date}
                          disablePast
                          error={!!errors.repeatingEndDate}
                          helperText={renderErrorMessage(errors.repeatingEndDate?.message)}
                        />
                      )}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              )}

              {repeatingDurationType === EventRepeatEnd.N_TIMES && (
                <Grid item xs={6}>
                  <Controller
                    name="repeatingTimesNumber"
                    control={control}
                    render={({ field }) => (
                      <NumberTextField
                        label={<FormattedMessage {...inputLabels.amount} />}
                        value={field.value}
                        variant="outlined"
                        autoComplete="off"
                        onBlur={field.onBlur}
                        onChange={field.onChange}
                        numberFormatProps={{ min: 1 }}
                        error={!!errors.repeatingTimesNumber}
                        helperText={renderErrorMessage(errors.repeatingTimesNumber?.message)}
                      />
                    )}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ScheduleSection;
