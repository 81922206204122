import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { ResizeObserver } from 'resize-observer';
import { List as ImmutableList } from 'immutable';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import {
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
// components
import { ReactComponent as Times } from 'img/times.svg';
import { ButtonWithCircularProgress, LoadingBackdrop, ScrollBox } from 'common/components';
import { QuestionnaireModal } from 'common/modals';
import ColorPicker from './ColorPicker/ColorPicker';
import ScheduleSection from './ScheduleSection/ScheduleSection';
import ClubSection from './ClubSection/ClubSection';
import ParticipantsSection from './ParticipantsSection/ParticipantsSection';
import AdditionalSection from './AdditionalSection/AdditionalSection';
// constants
import { DictionaryList } from 'common/constants';
import { BookingEventValidationSchema } from 'modules/booking/components/EventForm/Schemas';
import { initialValuesEvent } from 'modules/booking/components/Modals/initialValues';
import { SenderAvailabilityTypeList } from 'modules/booking/constants/senderAvailability';
// interfaces
import {
  EventAttendeeType,
  EventOverbookingType,
  EventRepeatEnd,
  EventRepeatFrequency,
  IBookingEventImt,
  IEventFormValues,
  IEventPersonEntity,
  IEventPersonFormValue,
  IShortResourceImt,
} from 'modules/booking/interfaces';
import { INamedEntityImt } from 'common/interfaces/common';
import { PeakModules } from 'common/constants/peakModules';
// state
import { selectDictionaryList } from 'common/state/dictionary/selectors';
import { selectCurrentUserAvailableClubs } from 'modules/authentication/state/selectors';
// utils
import { useRenderIntlMessage } from 'common/hooks/useRenderIntlMessage';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
import { getTimeFromMinutes } from 'common/utils/time';
// messages
import messages from 'modules/booking/messages';
import commonMessages from 'common/messages/messages';
import inputLabels from 'common/messages/inputLabels';
import { getString } from 'common/utils/typeUtils';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  formWrapper: {
    overflow: 'hidden',
    padding: theme.spacing(1, 0),
  },
  formTitle: {
    padding: theme.spacing(2),
  },
  closeBtn: {
    width: '14px',
    height: '14px',
    position: 'absolute',
    top: '10px',
    right: '7px',
  },
  closeBtnIcon: {
    fontSize: '14px',
    opacity: '0.3',
  },
  title: {
    lineHeight: '21px',
    letterSpacing: 0,
  },
  nameField: {
    position: 'relative',
  },
  picker: {
    position: 'absolute',
    top: '28px',
    right: '24px',
  },
  sectionDivider: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  sectionBtn: {
    padding: '6px 0',
    justifyContent: 'flex-start',
    color: theme.palette.text.secondary,
  },
  inputBlock: {
    flex: 1,
    minHeight: 0,
  },
  submitRow: {
    padding: theme.spacing(2),
  },
}));

interface IProps {
  onCancel: () => void;
  onSubmit: (formValues: IEventFormValues) => void;
  onLoadResources: (clubId: string) => void;
  handleOverflow: (value) => void;
  clearSearchServicesResult: (personId?: number) => void;
  searchServices: (search: string, personId?: number) => void;
  initialData?: Partial<IEventFormValues>;
  event: IBookingEventImt;
  isSubmitting: boolean;
  isEventLoading: boolean;
  personId?: number;
  eventDate: string;
  selectedClubId?: string;
  type: SenderAvailabilityTypeList;
  module: PeakModules;
}

const transformFormValues = (event: IBookingEventImt): IEventFormValues => {
  if (!event) return null;

  const transformedEvent = event.toJS();

  const duration = transformedEvent.durationInMinutes;
  const reminder = !!transformedEvent.reminderPeriodType;

  const persons = transformedEvent.persons?.map<IEventPersonFormValue>(
    (person: IEventPersonEntity) => {
      return {
        id: person.id,
        joinedDate: person.joinedDate,
        attendanceType: person.attendanceType,
        status: person.status,
        type: person.type,
        resourceId: person.resourceId,
        ...(person.eventDate ? { eventDate: person.eventDate } : {}),
        ...(person.customer ? { customer: person.customer } : { salesperson: person.salesperson }),
      };
    },
  );

  return {
    ...transformedEvent,
    persons,
    durationInMinutes: getTimeFromMinutes(duration),
    reminder,
    repeatingFrequency: event.get('repeatingFrequency') || EventRepeatFrequency.DAILY,
    repeatingDurationType: event.get('repeatingDurationType') || EventRepeatEnd.FOREVER,
    overbookingType: event.get('overbookingType') || EventOverbookingType.WAIT_LIST,
  };
};

function EventForm({
  eventDate,
  event,
  initialData,
  isSubmitting,
  isEventLoading,
  onCancel,
  onSubmit,
  onLoadResources,
  handleOverflow,
  personId,
  clearSearchServicesResult,
  searchServices,
  selectedClubId,
  module,
  type,
}: IProps): JSX.Element {
  const formRef = useRef(null);
  const navigate = useNavigate();
  const classes = useStyles();
  const renderErrorMessage = useRenderIntlMessage();

  // local state
  const [showParticipantsSection, setShowParticipantsSection] = useState<boolean>(false);
  const [showAdditionalSection, setShowAdditionalSection] = useState<boolean>(false);
  const [isOpenChangeAllowedParticipantsModal, setIsOpenAllowedParticipantsModal] = useState<
    boolean
  >(false);
  const [updatedAllowedParticipants, setUpdatedAllowedParticipants] = useState<
    Array<EventAttendeeType>
  >();
  const [formHeight, setFormHeight] = useState<number>(0);

  // global state
  const resources: ImmutableList<IShortResourceImt> = useSelector(
    selectDictionaryList(DictionaryList.RESOURCES),
  );
  const clubs: ImmutableList<INamedEntityImt> = useSelector(selectCurrentUserAvailableClubs);

  const [timezoneMoment] = useTimezoneMoment();

  const initialDates = useMemo(() => {
    const nowDate = timezoneMoment()
      .add(10, 'minutes')
      .utc();

    return {
      date: nowDate.format('YYYY-MM-DD'),
      time: nowDate.format('HH:mm'),
      endDate: nowDate.format(),
    };
  }, [timezoneMoment]);

  const formMethods = useForm<IEventFormValues>({
    defaultValues: {
      ...initialValuesEvent,
      ...initialDates,
    },
    resolver: yupResolver(BookingEventValidationSchema(!!selectedClubId)) as any, // TODO - PRM-1810 need resolver type
    mode: 'all',
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
    getValues,
  } = formMethods;

  const club = watch('club');

  // effects

  useEffect(() => {
    const clubId = club?.id;

    if (clubId) {
      onLoadResources(clubId);
    }
  }, [club, onLoadResources]);

  useEffect(() => {
    let formValues;

    if (event?.size) {
      formValues = {
        ...initialValuesEvent,
        ...initialDates,
        ...transformFormValues(event),
      };
    }

    const selectedClub =
      clubs.find((clubItem: INamedEntityImt) => clubItem.get('id') === selectedClubId)?.toJS() ||
      null;

    if (initialData && !event?.size) {
      formValues = {
        ...initialValuesEvent,
        ...initialDates,
        ...initialData,
        club: selectedClub,
      };
    }

    if (!initialData && !event?.size) {
      formValues = {
        ...initialValuesEvent,
        ...initialDates,
        club: selectedClub,
      };
    }

    if (formValues && !event?.size && eventDate) {
      const [date, time] = timezoneMoment(eventDate)
        .utc(true)
        .format('YYYY-MM-DD HH:mm')
        .split(' ');

      formValues = { ...formValues, date, time };
    }

    if (formValues) {
      reset(formValues);
    }
  }, [event, initialData, reset, initialDates, selectedClubId, clubs, timezoneMoment, eventDate]);

  useEffect(() => {
    const observedRef = formRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      const boxHeight = entries[0].contentRect.height;
      const isBlockOverflow = window.innerHeight - 178 < boxHeight;

      if (isBlockOverflow) {
        setFormHeight(boxHeight);
      }
      handleOverflow(isBlockOverflow);
    });
    if (observedRef) {
      resizeObserver.observe(observedRef);
    }
    return () => {
      if (observedRef) {
        resizeObserver.unobserve(observedRef);
      }
    };
  }, [formRef, handleOverflow]);

  useEffect(() => {
    const resizeFunction = () => {
      if (window.innerHeight - 178 < formHeight) {
        handleOverflow(true);
      } else {
        handleOverflow(false);
      }
    };

    window.addEventListener('resize', resizeFunction);
    return () => {
      window.removeEventListener('resize', resizeFunction);
    };
  }, [formHeight, handleOverflow, showParticipantsSection]);

  // handlers

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else {
      navigate(-1);
    }
  };

  const handleAllowedParticipantsChange = (types: Array<EventAttendeeType>) => {
    const { allowedParticipantTypes } = getValues();
    if (allowedParticipantTypes.length < types.length) {
      setValue('allowedParticipantTypes', types);
    } else {
      setIsOpenAllowedParticipantsModal(true);
      setUpdatedAllowedParticipants(types);
    }
  };

  const handleChangeAllowedParticipantsModalSubmit = () => {
    const persons = getValues('persons');

    if (persons.length) {
      setValue(
        'persons',
        persons.filter(personItem => {
          if (personItem.salesperson?.type === EventAttendeeType.RESOURCE_EMPLOYEE) {
            return true;
          }

          return updatedAllowedParticipants.includes(
            personItem.customer?.type || (!!personItem.salesperson && EventAttendeeType.EMPLOYEE),
          );
        }),
      );
    }

    setValue('allowedParticipantTypes', updatedAllowedParticipants);

    setIsOpenAllowedParticipantsModal(false);
    setUpdatedAllowedParticipants(null);
  };

  const handleSubmitEventForm = (data: IEventFormValues) => {
    onSubmit({
      ...data,
      persons: data.persons?.map(item => {
        const clonedPerson = { ...item };
        delete clonedPerson.resourceId;
        return clonedPerson;
      }),
    });
  };

  // renders

  return (
    <>
      {!isEventLoading && (
        <FormProvider {...formMethods}>
          <form
            id="event-form"
            className={classes.root}
            autoComplete="none"
            onSubmit={handleSubmit(handleSubmitEventForm)}
          >
            <Box className={classes.formTitle}>
              <IconButton className={classes.closeBtn} size="small" onClick={handleCancel}>
                <SvgIcon className={classes.closeBtnIcon}>
                  <Times />
                </SvgIcon>
              </IconButton>

              <Typography className={classes.title} variant="h4">
                {!!event && !!event.get('id') ? (
                  <FormattedMessage {...messages.editEvent} />
                ) : (
                  <FormattedMessage {...messages.newEvent} />
                )}
              </Typography>
            </Box>

            <Box className={classes.inputBlock}>
              <ScrollBox suppressScrollX hasShadowsOnScroll>
                <div ref={formRef} className={classes.formWrapper}>
                  <Box pl={2} pr={2}>
                    <Grid container spacing={3}>
                      <Grid item className={classes.nameField} xs={12}>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <TextField
                              fullWidth
                              variant="outlined"
                              autoComplete="none"
                              onChange={onChange}
                              onBlur={onBlur}
                              label={<FormattedMessage {...inputLabels.name} />}
                              value={value}
                              error={!!errors.title}
                              helperText={renderErrorMessage(errors?.title?.message)}
                            />
                          )}
                        />

                        <Controller
                          name="color"
                          control={control}
                          render={({ field: { value, onChange } }) => (
                            <ColorPicker
                              value={value}
                              onChange={onChange}
                              className={classes.picker}
                              error={!!errors.color}
                              helperText={getString(errors?.color?.message)}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ScheduleSection />
                      </Grid>

                      <Grid item xs={12}>
                        <ClubSection
                          personId={personId}
                          resources={resources}
                          clearSearchResults={clearSearchServicesResult}
                          searchServices={searchServices}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item className={classes.sectionDivider} xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              className={classes.sectionBtn}
                              color="secondary"
                              fullWidth
                              onClick={() => setShowParticipantsSection(!showParticipantsSection)}
                              startIcon={
                                showParticipantsSection ? (
                                  <ExpandMore fontSize="large" />
                                ) : (
                                  <ChevronRight fontSize="large" />
                                )
                              }
                            >
                              <FormattedMessage {...inputLabels.participants} />
                            </Button>

                            <Collapse
                              in={showParticipantsSection || !!errors.allowedParticipantTypes}
                            >
                              <ParticipantsSection
                                personId={personId}
                                module={module}
                                resources={resources}
                                onAllowedParticipantsChange={handleAllowedParticipantsChange}
                              />
                            </Collapse>
                          </Grid>

                          <Grid item className={classes.sectionDivider} xs={12}>
                            <Divider />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              className={classes.sectionBtn}
                              color="secondary"
                              fullWidth
                              onClick={() => setShowAdditionalSection(!showAdditionalSection)}
                              startIcon={
                                showAdditionalSection ? (
                                  <ExpandMore fontSize="large" />
                                ) : (
                                  <ChevronRight fontSize="large" />
                                )
                              }
                            >
                              <FormattedMessage {...messages.additional} />
                            </Button>
                            <Collapse in={showAdditionalSection}>
                              <AdditionalSection type={type} />
                            </Collapse>
                          </Grid>

                          <Grid item className={classes.sectionDivider} xs={12}>
                            <Divider />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </ScrollBox>
            </Box>
            <Box className={classes.submitRow}>
              <Grid container justifyContent="flex-end" spacing={1}>
                <Grid item>
                  <Button onClick={handleCancel} color="primary">
                    <FormattedMessage {...commonMessages.cancelBtn} />
                  </Button>
                </Grid>
                <Grid item>
                  <ButtonWithCircularProgress isSubmitting={isSubmitting}>
                    <FormattedMessage {...commonMessages.saveBtn} />
                  </ButtonWithCircularProgress>
                </Grid>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      )}
      <LoadingBackdrop isLoading={isEventLoading} />

      {isOpenChangeAllowedParticipantsModal && (
        <QuestionnaireModal
          isOpen={isOpenChangeAllowedParticipantsModal}
          onClose={() => setIsOpenAllowedParticipantsModal(false)}
          onSubmit={handleChangeAllowedParticipantsModalSubmit}
          title={<FormattedMessage {...messages.approveChangeAllowedParticipantsTitle} />}
          body={<FormattedMessage {...messages.approveChangeAllowedParticipantsMsg} />}
          submitBtnTitle={<FormattedMessage {...commonMessages.continueBtn} />}
        />
      )}
    </>
  );
}

export default EventForm;
