import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dompurify from 'dompurify';
import { FormattedMessage } from 'react-intl';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Reply as ReplyIcon } from '@material-ui/icons';
import Markdown from 'react-markdown';

import {
  selectPersonEmail,
  selectPersonEmailLoading,
} from 'common/components/PersonProfile/state/selectors';
import { fetchPersonEmail, resetPersonEmail } from 'common/components/PersonProfile/state/actions';

import { useAppDispatch } from 'store/hooks';
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';

import { IEmailImt } from 'common/components/PersonProfile/interfaces';

import { DialogComponent, LoadingBackdrop } from 'common/components';

import inputLabels from 'common/messages/inputLabels';
import messages from 'common/components/PersonProfile/messages';
import { PeakModuleForNewPersonType } from 'common/interfaces/steps';
import { EmailStatus } from 'common/components/PersonProfile/constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiDialogContent-root': {
      padding: theme.spacing(0),
      display: 'flex',
      flexDirection: 'column',
    },
  },
  header: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRow: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  headerLabel: {
    marginRight: theme.spacing(1),
  },
  contentContainer: {
    fontSize: '0.875rem',
    lineHeight: '1.5',
  },
  footer: {
    padding: theme.spacing(3, 3, 3, 2),
  },
  emailContent: {
    whiteSpace: 'pre-wrap',
  },
}));

interface IProps {
  emailId: string;
  isOpen: boolean;
  personId: number;
  onReply: () => void;
  onClose: () => void;
  module: PeakModuleForNewPersonType;
}

const EmailDetailsModal = (props: IProps): JSX.Element => {
  const { isOpen, onReply, onClose, personId, emailId, module } = props;
  const classes = useStyles();

  // global state

  const dispatch = useAppDispatch();

  const emailData: IEmailImt = useSelector(selectPersonEmail(personId));
  const isLoadingEmailData: boolean = useSelector(selectPersonEmailLoading(personId));

  const [timezoneMoment] = useTimezoneMoment();

  const sanitizer = dompurify.sanitize;

  // effects

  useEffect(() => {
    dispatch(fetchPersonEmail(personId, emailId, module));

    return () => {
      dispatch(resetPersonEmail(null, personId));
    };
  }, [dispatch, personId, emailId, module]);

  // render constants

  const title = emailData?.get('subject') ?? <FormattedMessage {...messages.emailDetailsTitle} />;
  const fromLabel = emailData
    ? `${emailData?.get('emailSenderName')} (${emailData?.get('senderEmail')})`
    : '';

  const toLabel = emailData?.get('receiverEmail');
  const campaign = emailData?.getIn(['campaign', 'title']) || '-';
  const dateTime = emailData ? timezoneMoment(emailData?.get('date')).format('h:mm A, MMM D') : '';
  let body = emailData ? emailData.get('emailBody') : '';

  const emailStatus = emailData?.get('status');

  if (emailStatus && emailStatus !== EmailStatus.Received) {
    body = sanitizer(body, {
      ALLOWED_ATTR: ['href', 'target', 'style'],
    });
  }

  // renders

  return (
    <DialogComponent
      className={classes.root}
      title={title}
      size="lg"
      isOpen={isOpen}
      onClose={onClose}
      subHeader={
        <Box className={classes.header}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.from} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {fromLabel}
              </Typography>
            </Box>
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.to} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {toLabel}
              </Typography>
            </Box>
            <Box className={classes.headerRow}>
              <Typography variant="h5" className={classes.headerLabel}>
                <FormattedMessage {...inputLabels.campaign} />:
              </Typography>
              <Typography variant="body2" className={classes.headerLabel}>
                {campaign}
              </Typography>
            </Box>
          </Box>
          <Box ml={1} pt={0.5}>
            <Typography variant="body2" color="textSecondary" noWrap>
              {dateTime}
            </Typography>
          </Box>
        </Box>
      }
      footer={
        <Box className={classes.footer}>
          <Button
            disabled={isLoadingEmailData}
            variant="contained"
            onClick={onReply}
            startIcon={<ReplyIcon />}
          >
            <FormattedMessage {...messages.replyTitle} />
          </Button>
        </Box>
      }
      disableFullScreenBehavior
    >
      <Box className={classes.contentContainer}>
        {emailStatus === EmailStatus.Received ? (
          <Markdown className={classes.emailContent}>{body}</Markdown>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }} />
        )}
      </Box>
      <LoadingBackdrop isLoading={isLoadingEmailData} />
    </DialogComponent>
  );
};

export default EmailDetailsModal;
