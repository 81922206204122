// libraries
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { List as ImmutableList } from 'immutable';
import { Typography, makeStyles, Grid } from '@material-ui/core';
// custom interfaces
import { CustomTheme } from 'common/ui/interfaces';
import { IServiceItemDetailsImt, IServiceRedeemHistoryImt } from 'common/interfaces/service';
import { FieldInfo } from 'common/components/index';
import PersonSimpleView from 'common/components/PersonSimpleView/PersonSimpleView';
import RedeemingHistory from 'common/components/PersonProfile/components/Services/modals/RedeemingHistory/RedeemingHistory';
import AvailabilitiesResultBlock from 'common/components/AvailabilityScheduleSelector/AvailabilitiesResultBlock';
// constants
import {
  FrequencyTypes,
  PackageCostType,
  PackageCostTypes,
} from 'modules/services/constants/packages';
// utils
import useTimezoneMoment from 'common/hooks/useTimezoneMoment';
// messages
import inputLabels from 'common/messages/inputLabels';
import personProfileMessages from 'common/components/PersonProfile/messages';
import { IHeadCell, ITableRow } from 'common/interfaces/table';
import commonMessages from 'common/messages/messages';
import tableHeaders from 'common/messages/tableHeaders';
import { DEFAULT_DATE_TIME_FORMAT } from 'common/constants/dateFormats';
import RedeemingServiceHistoryModal from '../RedeemingServiceHistoryModal/RedeemingServiceHistoryModal';
import { PeakModules } from 'common/constants/peakModules';

interface IServiceDetailsViewProps {
  module: PeakModules;
  profileId: number;
  serviceInstance: IServiceItemDetailsImt;
  redeemHistory: ImmutableList<IServiceRedeemHistoryImt>;
}

const useStyles = makeStyles((theme: CustomTheme) => ({
  detailsContainer: {
    marginBottom: theme.spacing(2),
  },
}));

const headerOptions: IHeadCell[] = [
  { id: 'items', label: <FormattedMessage {...commonMessages.items} />, sort: false },
  { id: 'member', label: <FormattedMessage {...tableHeaders.member} />, sort: false },
  { id: 'date', label: <FormattedMessage {...tableHeaders.date} />, sort: false },
];

const ServiceDetailsView: React.FC<IServiceDetailsViewProps> = ({
  serviceInstance,
  redeemHistory,
  profileId,
  module,
}: IServiceDetailsViewProps): JSX.Element => {
  const [timezoneMoment] = useTimezoneMoment();

  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);

  const classes = useStyles();

  // constants

  const packageInstance = serviceInstance.get('packageInstance');
  const salesperson = packageInstance.get('salesperson');
  const packagePricePerBilling = packageInstance.get('pricePerBilling')?.toFixed(2);

  const getAvailabilityLabel = useCallback((): JSX.Element => {
    if (serviceInstance.get('hasSameAsBundleCustomAvailability')) {
      return <FormattedMessage {...inputLabels.accessAsPackage} />;
    }

    if (serviceInstance.get('hasSameAsClubAvailability')) {
      return <FormattedMessage {...inputLabels.accessAsClub} />;
    }

    return <FormattedMessage {...personProfileMessages.includedServicesDayAndHoursAccessCustom} />;
  }, [serviceInstance]);

  // renders

  const tableRows: ITableRow[] = useMemo(
    () =>
      redeemHistory
        ?.map(redeemItem => ({
          id: redeemItem.get('id'),
          cells: [
            {
              label: `${redeemItem.get('amount')} ${redeemItem.get('type').toLowerCase()}`,
            },
            {
              label: `${redeemItem.getIn(['customer', 'title'])}`,
            },
            {
              label: `${timezoneMoment(redeemItem.get('date')).format('MMM D, YYYY, h:mm A')}`,
            },
          ],
        }))
        .toJS() || [],
    [redeemHistory, timezoneMoment],
  );

  const expirationDate = packageInstance.get('expirationDate');
  const expirationDateWithTimezone = expirationDate
    ? timezoneMoment(expirationDate).format(DEFAULT_DATE_TIME_FORMAT)
    : '-';

  return (
    <>
      <Grid container spacing={2} className={classes.detailsContainer}>
        <Grid item xs={6}>
          <Typography variant="h5">{packageInstance.get('title')}</Typography>

          {packageInstance.get('pricePerBilling') === null ? (
            <Typography>{PackageCostTypes.translate(PackageCostType.Free)}</Typography>
          ) : (
            <Typography>
              ${packagePricePerBilling}&nbsp;
              {FrequencyTypes.translate(packageInstance.get('paymentSchedule'))}
            </Typography>
          )}
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5" color="textSecondary">
            {timezoneMoment(packageInstance.get('startDate')).format('MMM D, YYYY')}&nbsp;-&nbsp;
            {timezoneMoment(packageInstance.get('endDate')).format('MMM D, YYYY')}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.salesperson} />}
            content={
              <PersonSimpleView
                firstName={salesperson.get('firstName')}
                lastName={salesperson.get('lastName')}
                imageUrl={salesperson.get('imageUrl')}
              />
            }
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.expirationDate} />}
            description={expirationDateWithTimezone}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.signDate} />}
            description={timezoneMoment(packageInstance.get('joinDate')).format('MMM D, YYYY')}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.renewalDate} />}
            description={timezoneMoment(packageInstance.get('renewalDate')).format('MMM D, YYYY')}
          />
        </Grid>

        <Grid item xs={6}>
          <FieldInfo
            label={<FormattedMessage {...inputLabels.daysAndHoursForAccess} />}
            description={getAvailabilityLabel()}
          />
        </Grid>

        {!!serviceInstance.get('customAvailability')?.size && (
          <Grid item xs={12}>
            <FieldInfo
              label={<FormattedMessage {...inputLabels.date} />}
              description={
                <AvailabilitiesResultBlock
                  availabilities={serviceInstance.get('customAvailability')?.toJS()}
                />
              }
            />
          </Grid>
        )}
      </Grid>

      {/* <Box className={classes.paramsItem}>First payment</Box> */}

      {/* <Box className={classes.paramsItem}>Next payment</Box> */}

      {/* <Box className={classes.paramsItem}>Payment method</Box> */}

      <RedeemingHistory
        onViewAllHistory={() => setIsOpenHistoryModal(true)}
        headerOptions={headerOptions}
        tableRows={tableRows}
      />
      {isOpenHistoryModal && (
        <RedeemingServiceHistoryModal
          isOpen={isOpenHistoryModal}
          onClose={() => setIsOpenHistoryModal(false)}
          module={module}
          profileId={profileId}
        />
      )}
    </>
  );
};

export default ServiceDetailsView;
